import { Form, Modal, Select } from "antd";
import React, { useEffect } from "react";
import Paragraph from "antd/es/typography/Paragraph";
import { useTranslation } from "react-i18next";
import PickVariant from "../PickVariant";
import { ITEM_TYPE } from "../../constants";
import { RENTALS, isRental } from "../../../../helpers/rentals";
import { useSelector } from "react-redux";
import {
  getSupplierData,
  getSupplierSettings,
} from "../../../../Redux/Selectors/appConfigSelector";
import { WarningOutlined } from "@ant-design/icons";

const AssignVariantAndPeopleDataForm = ({
  visible,
  onCancel,
  handleOnSubmit,
  data,
  isBundle,
  people,
}) => {
  const { t } = useTranslation(["shop", "common"]);
  const supplierData = useSelector(getSupplierData);
  const settings = useSelector(getSupplierSettings);
  const [form] = Form.useForm();
  const handleOnCancel = () => {
    form.resetFields();
    onCancel();
  };

  const renderPickVariants = () => {
    if (Array.isArray(data?.id)) {
      return data.id?.map((id) => <PickVariant productId={id} multiple />);
    }
    return <PickVariant productId={data?.id} />;
  };
  const handleOnOk = () => {
    form.validateFields().then((values) => {
      form.resetFields();
      handleOnSubmit({
        ...values,
        passenger: values.passenger ? values.passenger : people[0].id,
        productId: data?.id,
        bundleId: data?.bundleId,
        fieldKey: data?.fieldKey,
        type: isBundle ? ITEM_TYPE.BUNDLE : ITEM_TYPE.VARIANT,
      });
      onCancel();
    });
  };
  useEffect(() => {
    if (data?.assigned) {
      form.setFieldsValue({
        passenger: data.assigned?.passenger,
        [`variant-${data.id}`]: data.assigned?.reference,
      });
    }
  }, [JSON.stringify(data)]);

  const renderConditionalPassenger = () => {
    const { addMemberEnabled } = settings;
    if (addMemberEnabled) {
      return (
        <>
          <Paragraph>
            {t("shop:assign.pickWho", { equipment: data?.title })}
          </Paragraph>
          <Form.Item
            name="passenger"
            rules={[
              {
                required: true,
                message: t("shop:assign.personMissing"),
              },
            ]}
          >
            <Select data-testid="quote-pick-passenger-select">
              {people.map((passenger, index) => (
                <Select.Option key={index} value={passenger.id}>
                  {passenger.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      );
    }
  };

  return (
    <Modal
      open={visible}
      title={t("shop:assign.assignEquipment")}
      okText={t("shop:assign.assign")}
      cancelText={t("back")}
      onCancel={handleOnCancel}
      onOk={handleOnOk}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        {renderConditionalPassenger()}
        <Paragraph>{t("shop:assign.pickVariant")}</Paragraph>
        {renderPickVariants()}
        {isRental(supplierData._id, RENTALS.ananda) &&
          data?.id === "64a70b028ff15984107ffbfc" && (
            <>
              <Paragraph type="secondary">
                <WarningOutlined /> Estatura entre 1.52 y 1.68 metros -&gt;
                Talle S
              </Paragraph>
              <Paragraph type="secondary">
                <WarningOutlined /> Estatura más de 1.68 metros -&gt; Talle M.
              </Paragraph>
            </>
          )}
      </Form>
    </Modal>
  );
};

export default AssignVariantAndPeopleDataForm;
