import { map } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import BootstrapIcon from "../components/BootstrapIcon/BoostrapIcon";
import { getSocialMediaIconName } from "../helpers/socialMedia";
import { getSupplierData } from "../Redux/Selectors/appConfigSelector";

const useGlobalUtils = () => {
  const supplierData = useSelector(getSupplierData, shallowEqual);

  const getSocialMediaLinksData = () => {
    const socialLinks = supplierData?.socialMedia
      ? map(supplierData?.socialMedia, ({ label, url }) => ({
          icon: <BootstrapIcon iconName={getSocialMediaIconName(label)} />,
          link: url,
        }))
      : [];
    return socialLinks;
  };

  const handleSendWhatsApp = (message = "") => {
    const phoneNumber = supplierData?.phone;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappLink, "_blank");
  };

  return {
    handleSendWhatsApp,
    getSocialMediaLinksData,
  };
};

export default useGlobalUtils;
