import { isEmpty } from "lodash";
import { getProductById } from "../../../Redux/Selectors/productSelectors";
import moment from "moment";
import { getSearchValueByKey } from "../../../Redux/Selectors/searchSelectors";
import { getBundleById } from "../../../Redux/Selectors/bundlesSelectors";
import { getVariants } from "../../../Redux/Selectors/variantSelectors";
import { RENTALS, isRental } from "../../../helpers/rentals";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import {
  categorizeProducts,
  combineCategories,
  requiresSizeVariant,
} from "../tools/helpers";

export const getPassengerDataById = (state, id) => {
  return state.pages.quote.passengers[id];
};

export const getPassengersData = (state) => {
  return Object.values(state.pages.quote.passengers);
};

export const getInfoValidationByPassenger = (state, id) => {
  const passenger = getPassengerDataById(state, id);
  const requiredArray =
    passenger?.required?.filter((require) => {
      return (
        passenger[require] === null || typeof passenger[require] === "undefined"
      );
    }).length || 0;
  return requiredArray === 0;
};

const productRequiresVariant = (state, product) => {
  const productDetails = getProductById(state, product.productId);
  const bundleDetails = getBundleById(state, product.productId);
  if (!isEmpty(productDetails)) {
    return requiresSizeVariant(productDetails.category._id);
  }
  if (!isEmpty(bundleDetails)) {
    const bundle = bundleDetails.products.filter((product) => {
      const productDetails = getProductById(state, product.productId);
      return requiresSizeVariant(productDetails.category._id);
    }).length;
    return bundle > 0;
  }
};

const checkVariantsSelected = (state, passenger) => {
  const allVariantsAreSelected = Object.values(passenger.products).filter(
    (product) => {
      if (productRequiresVariant(state, product)) {
        return product.variantSelected;
      } else {
        return true;
      }
    }
  );
  return (
    allVariantsAreSelected.length === Object.values(passenger.products).length
  );
};

export const getInfoValidation = (state) => {
  const passengers = state.pages.quote.passengers;
  const supplierData = getSupplierData(state);
  const requiresVariants = isRental(supplierData?._id, RENTALS.windfly);
  const getValidation = Object.values(passengers).filter((passenger) => {
    // Check de variantes seleccionadas
    const checkVariants = requiresVariants
      ? checkVariantsSelected(state, passenger)
      : false;
    // Check de datos requeridos
    const requiredArray = passenger?.required?.filter((require) => {
      return (
        passenger[require] === null || typeof passenger[require] === "undefined"
      );
    });

    if (isEmpty(passenger.name)) return true;

    // No se necesita ningun dato específico y las variantes están seleccionadas se devuelve null para que el array sea de length 0
    if ((!requiredArray || requiredArray.length === 0) && checkVariants) {
      return null;
    }
    // Se necesitan datos o faltan variantes
    if (!requiresVariants && (!requiredArray || requiredArray.length === 0)) {
      return null;
    }
    return requiredArray || !checkVariants;
  });
  return getValidation.length === 0;
};

export const getPassengersDataWithProducts = (state) => {
  const dates = getSearchValueByKey(state, "dates");
  return Object.values(state.pages.quote.passengers).map((passenger) => {
    const subtotalPerPassenger = getSubtotalByPassenger(state, passenger.id);
    return {
      ...passenger,
      subtotal: subtotalPerPassenger,
      products: Object.values(passenger.products).map((product) => ({
        ...product,
        total: product.total.toString(),
        dates: {
          from: moment(product.dates.from || dates.from).format("DD-MM-YYYY"),
          to: moment(product.dates.to || dates.to).format("DD-MM-YYYY"),
        },
        product:
          getProductById(state, product.productId) ||
          getBundleById(state, product.productId),
        variants: getVariants(state, Object.values(product.variants || {})),
      })),
    };
  });
};

export const getQuotePricingByRow = (state, id, rowId) => {
  const { total, currency, timeQuantity } =
    state.pages.quote.passengers[id].products[rowId];
  return { total, currency, timeQuantity };
};

export const isPricingFetchingByRow = (state, id, rowId) => {
  const { fetchingPrice } = state.pages.quote.passengers[id].products[rowId];
  return fetchingPrice;
};

export const getIndividualDatesByRow = (state, id, rowId) => {
  const { dates } = state.pages.quote.passengers[id].products[rowId];
  return dates;
};

export const getSubtotalByPassenger = (state, id) => {
  const products = getProductsByPassenger(state, id);
  const total = products?.reduce((acc, arr) => {
    return acc + arr.total;
  }, 0);
  return total;
};

export const getActivePassenger = (state) => {
  return state.pages.quote.activePassenger;
};

export const getProductsByPassenger = (state, passengerId) => {
  const passengerData = getPassengerDataById(state, passengerId);
  if (isEmpty(passengerData?.products)) return [];
  const products = Object.values(passengerData?.products).map((passenger) => {
    return {
      ...passenger,
      product:
        getProductById(state, passenger.productId) ||
        getBundleById(state, passenger.productId),
    };
  });
  return products;
};

export const getInitalPassengerData = (state) => {
  return Object.values(state.pages.quote.passengers)[0];
};

export const getCategories = (state) => {
  return state.pages.quote.categories;
};

export const isProductsFetching = (state) => {
  return state.pages.quote.productsFetching;
};

export const getQuoteTotalPrice = (state) => {
  const passengers = Object.values(state.pages.quote.passengers);
  const products = passengers.flatMap((passenger) => passenger.products);
  const flatProducts = products.reduce((acc, arr) => {
    return acc.concat(Object.values(arr));
  }, []);
  const total = flatProducts.reduce((acc, arr) => {
    return acc + arr.total;
  }, 0);
  return total;
};

export const getFinalPriceFetching = (state) => {
  const passengers = Object.values(state.pages.quote.passengers);
  const products = passengers.flatMap((passenger) => passenger.products);
  const flatProducts = products.reduce((acc, arr) => {
    return acc.concat(Object.values(arr));
  }, []);
  const isFetching = !!flatProducts.find((product) => product.fetchingPrice);
  return isFetching;
};

export const getProductsByCategory = (state, category) => {
  const products = state.pages.quote.products;
  const bundles = state.pages.quote.bundles;
  const supplierData = getSupplierData(state);

  if (category === "combo") {
    return bundles;
  }

  const filteredProducts = products.filter((product) => {
    if (product.category.value === category && product.visibleOnWeb) {
      return product;
    }
    return null;
  });
  const filteredBundles = bundles.filter((bundle) => {
    if (bundle?.category?.value === category) {
      return bundle;
    }
    return null;
  });
  const allProducts = isRental(supplierData?._id, RENTALS.travesiaCatedral)
    ? filteredProducts
    : [...filteredProducts, ...filteredBundles];
  return isRental(supplierData?._id, RENTALS.alborde)
    ? sortedAlBordeProducts(filteredProducts, category)
    : allProducts?.sort((a, b) => a?.title?.localeCompare(b?.title));
};

export const getCategoriesQuote = (state, t) => {
  const bundles = state.pages.quote?.bundles;
  const products = state.pages.quote?.products;
  const bundlesWithoutCategory = getBundlesWithOutCategory(state);

  const combinedCategories = combineCategories([...bundles, ...products]);
  return categorizeProducts(combinedCategories, bundlesWithoutCategory, t);
};

export const getIsProcessFinished = (state) => {
  return state.pages.quote.processFinished;
};

// Todo: Allow supplier to decide the sorting
const sortedAlBordeProducts = (products, category) => {
  const config = {
    ski: {
      "6440f14dffb81edba0fb5dae": { order: 1 },
      "6440f14dffb81edba0fb5daf": { order: 2 },
      "6440f14dffb81edba0fb5db0": { order: 3 },
      "6440f14dffb81edba0fb5db4": { order: 4 },
      "6440f14dffb81edba0fb5db6": { order: 5 },
      "647a3e18c55b41572ada2eb6": { order: 6 },
    },
    bootsSki: {
      "6440f14dffb81edba0fb5db3": { order: 1 },
      "6440f14dffb81edba0fb5db5": { order: 2 },
      "647a3d0802c6d2016ba6dc50": { order: 3 },
    },
    snowboard: {
      "6440f14dffb81edba0fb5db1": { order: 1 },
      "6440f14dffb81edba0fb5db2": { order: 2 },
      "6440f14dffb81edba0fb5dba": { order: 3 },
      "6440f14dffb81edba0fb5dbb": { order: 4 },
    },
    bootsSnowboard: {
      "6440f14dffb81edba0fb5db9": { order: 1 },
      "6440f14dffb81edba0fb5db8": { order: 2 },
    },
  };
  return products.sort((a, b) => {
    const orderA = config[category][a._id]?.order || Infinity;
    const orderB = config[category][b._id]?.order || Infinity;
    return orderA - orderB;
  });
};

export const getPricingDataByProduct = (state, productId, type = "product") => {
  const product = state.pages.quote?.[`${type}s`]?.filter(
    (product) => product._id === productId
  )[0];
  return product?.pricing ? product.pricing.detail[0] : {};
};

export const getBundlesWithOutCategory = (state) => {
  const bundles = state.pages.quote?.bundles;

  return bundles?.filter((bundle) => isEmpty(bundle.category));
};
