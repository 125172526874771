import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/es/typography/Paragraph";
import AddPassengerForm from "./Forms/AddPassengerForm";
import FloatingSummary from "../FloatingSummary/FloatingSummary";
import useIsMobile from "../../../../hooks/useIsMobile";

const Container = styled.div`
  padding-bottom: 100px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

const FormContainer = styled.div`
  flex: 1;
`;

const PassengerBuilder = ({
  formController,
  handleOnFinish,
  handleOnRemovePassenger,
  initialFormData,
  handleOnBlur,
  handleOnFocus,
  handleOnClick,
  pricingData,
  isPricingFetching,
  quoteSummary,
  itemsLength,
  selectedProducts,
  dates,
  discounts,
}) => {
  const { t } = useTranslation("shop");
  const isMobile = useIsMobile();

  return (
    <Container>
      <Body>
        <FormContainer>
          <Paragraph strong>{t("passengers.description")}</Paragraph>
          <AddPassengerForm
            formController={formController}
            handleOnFinish={handleOnFinish}
            handleOnRemovePassenger={handleOnRemovePassenger}
            initialFormData={initialFormData}
            handleOnBlur={handleOnBlur}
            handleOnFocus={handleOnFocus}
          />
        </FormContainer>
        {!isMobile && (
          <div
            style={{
              width: "40%",
              paddingTop: 30,
              paddingLeft: 40,
              marginRight: 15,
            }}
          >
            <FloatingSummary
              visible={true}
              itemsLength={itemsLength}
              pricingData={pricingData}
              period={quoteSummary?.period}
              selectedProducts={selectedProducts}
              isPricingFetching={isPricingFetching}
              expandedDefault
              expandable={false}
              offset={{
                offsetTop: 120,
              }}
              onActionClick={handleOnClick}
              dates={dates}
              discounts={discounts}
            />
          </div>
        )}
      </Body>
    </Container>
  );
};

export default PassengerBuilder;
