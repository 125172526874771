import React from "react";
import {
  ReviewsContainer as Container,
  Wrapper,
} from "./ReviewsSection.styles";
import ReviewsContainer from "../../containers/ReviewsContainer/ReviewsContainer";
import ReviewCard from "./components/ReviewCard";

const ReviewsSection = () => {
  return (
    <Container>
      <ReviewsContainer>
        {({ reviews }) => {
          return (
            <Wrapper>
              {reviews?.map(
                ({
                  author_name,
                  profile_photo_url,
                  relative_time_description,
                  text,
                  rating,
                }) => (
                  <ReviewCard
                    author={author_name}
                    avatar={profile_photo_url}
                    time={relative_time_description}
                    description={text}
                    rating={rating}
                  />
                )
              )}
            </Wrapper>
          );
        }}
      </ReviewsContainer>
    </Container>
  );
};

export default ReviewsSection;
