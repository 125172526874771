import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import esCommon from "../locales/common/es.json";
import esCLCommon from "../locales/common/es-CL.json";
import enCommon from "../locales/common/en.json";
import enGBCommon from "../locales/common/en-GB.json";

import esHome from "../locales/home/es.json";
import esARHome from "../locales/home/es-AR.json";
import enHome from "../locales/home/en.json";
import enGBHome from "../locales/home/en-GB.json";

import esShop from "../locales/shop/es.json";
import esCLShop from "../locales/shop/es-CL.json";
import enShop from "../locales/shop/en.json";

import esExperiences from "../locales/experiences/es.json";
import enExperiences from "../locales/experiences/en.json";

import esSpaces from "../locales/spaces/es.json";
import enSpaces from "../locales/spaces/en.json";

import esResult from "../locales/result/es.json";
import esCLResult from "../locales/result/es-CL.json";
import enResult from "../locales/result/en.json";

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: "es",
  resources: {
    es: {
      common: esCommon,
      home: esHome,
      shop: esShop,
      experiences: esExperiences,
      result: esResult,
      spaces: esSpaces,
    },
    "es-AR": {
      home: esARHome,
    },
    "es-CL": {
      common: esCLCommon,
      shop: esCLShop,
      result: esCLResult,
    },
    en: {
      common: enCommon,
      home: enHome,
      shop: enShop,
      experiences: enExperiences,
      result: enResult,
      spaces: enSpaces,
    },
    "en-GB": {
      common: enGBCommon,
      home: enGBHome,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  ns: ["common"],
  defaultNS: "common",
});

export default i18n;
