import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const CardHeader = styled.div`
  background-color: ${({ theme }) => theme.background.highlightBackground};
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
`;
export const StyledUl = styled.ul`
  list-style-type: none;
  padding: 0;
`;
export const StyledLi = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.borders.light};
  }
`;
export const StyledText = styled(Text)`
  font-size: ${({ fontSize }) => fontSize}px;
`;
