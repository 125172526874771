import { Cloudinary } from "cloudinary-core";

/**
 * @param {string} url
 * @returns {Object.<{
 *  cloudName: string,
 *  publicId: string,
 * }>}
 */
const parseCloudinaryUrl = (url) => {
  const regex =
    /^https?:\/\/res\.cloudinary\.com\/([^/]+)\/(?:image|video|raw)\/upload\/(?:v\d+\/)?(.+)$/i;
  const matches = url.match(regex);
  return matches ? { cloudName: matches[1], publicId: matches[2] } : null;
};

/**
 * @param {string} url
 * @param  {object} transformations
 * @returns {string}
 */
export const getImageUrl = (url, transformations = {}) => {
  if (!url) return null;
  const { cloudName, publicId } = parseCloudinaryUrl(url);
  const cld = new Cloudinary({
    cloud: {
      cloudName,
    },
  });
  return cld.url(publicId, {
    crop: "scale",
    ...transformations,
    cloud_name: cloudName,
  });
};
