import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import useWebsiteDomain from "../hooks/useWebsiteDomain";
import { appConfigRequest } from "../Redux/Actions/appConfigActions";
import {
  getAppConfigState,
  getAppThemeConfig,
  getScripts,
  getSupplierData,
} from "../Redux/Selectors/appConfigSelector";
import {
  getLanguageLocalStorage,
  setInitialConfigOnLocalStorage,
} from "../helpers/tools";
import useWebsiteLanguage from "../hooks/useWebsiteLanguage";
import useWebsiteCurrency from "../hooks/useWebsiteCurrency";
import useWebsiteRegion from "../hooks/useWebsiteCountry";
import { RENTALS, isRental } from "../helpers/rentals";

const AppContainer = ({ children }) => {
  const dispatch = useDispatch();
  const {
    loading: loadingAppConfig,
    success: successAppConfig,
    error: errorAppConfig,
    data: dataAppConfig,
  } = useSelector(getAppConfigState, shallowEqual);
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const theme = useSelector(getAppThemeConfig, shallowEqual);
  const scripts = useSelector((state) => getScripts(state, shallowEqual));
  const { language, setLanguage } = useWebsiteLanguage();
  const { currency, setCurrency } = useWebsiteCurrency();
  const { region } = useWebsiteRegion();
  const { domain, error } = useWebsiteDomain();

  useEffect(() => {
    const initialLanguage = isEmpty(getLanguageLocalStorage())
      ? dataAppConfig?.siteLang
      : getLanguageLocalStorage();

    if (isRental(supplierData?._id, RENTALS.spAventuras)) {
      if (!region) {
        return;
      }
      const config = setInitialConfigOnLocalStorage(region);
      if (!currency) {
        setCurrency(config?.currency ?? dataAppConfig?.defaultCurrency);
      }
      if (!language) {
        setLanguage(config?.language ?? initialLanguage);
      }
      return;
    }
    if (dataAppConfig && (!currency || !language)) {
      setLanguage(initialLanguage);
      setCurrency(dataAppConfig?.defaultCurrency); // Por ahora la moneda no es editable por el usuario
    }
  }, [dataAppConfig, supplierData, region]);

  useEffect(() => {
    if (error || !domain) {
      return;
    }
    dispatch(appConfigRequest(domain));
  }, [error, domain]);

  return children({
    appConfig: {
      ready: successAppConfig || errorAppConfig,
      loading: loadingAppConfig,
      success: successAppConfig,
      error: errorAppConfig,
      data: dataAppConfig,
    },
    supplierData,
    theme,
    language,
    domain,
    region,
    scripts,
  });
};

export default AppContainer;
