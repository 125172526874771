export const PRODUCT_MODAL_SHOW = "PRODUCT_MODAL_SHOW";
export const PRODUCT_MODAL_RESET = "PRODUCT_MODAL_RESET";

export const productModalShow = (
  visible,
  productId,
  optionType,
  productType
) => {
  return {
    type: PRODUCT_MODAL_SHOW,
    payload: {
      visible,
      productId,
      optionType,
      productType,
    },
  };
};

export const productModalReset = () => {
  return {
    type: PRODUCT_MODAL_RESET,
  };
};
