import { getPassengersData } from "../../ShopPage/redux/selectors/ShopSelectors";
import {
  mapPassengerData,
  createSizeMap,
  parseCheckinData,
  mergePassengerAndChekinData,
} from "./helpers/helpers";

export const checkInPassengersDetails = (state) => {
  return state.pages.checkin.details?.passengers;
};

export const getNormalizePassengers = (state) => {
  const passengersData = getPassengersData(state);
  const passengersSize = checkInPassengersDetails(state);

  if (!passengersData || passengersData.length === 0) return null;
  const sizeMap = createSizeMap(passengersSize);

  const arrayOfPassengers = passengersData.map((passenger) =>
    mapPassengerData(passenger, sizeMap, state)
  );

  return arrayOfPassengers;
};

export const getPassengersDataCheckIn = (state) => {
  const passengersData = getPassengersData(state);
  const checkInDetails = checkInPassengersDetails(state);

  if (!passengersData) return [];
  if (!checkInDetails) return passengersData;

  const checkInPassengersData = parseCheckinData(checkInDetails);
  return mergePassengerAndChekinData(passengersData, checkInPassengersData);
};

export const getSearchProductValue = (state) => {
  const searchValue = state.pages.checkin.details?.productSearch;
  if (searchValue && searchValue?.trim() === "") return null;
  return searchValue;
};

export const getSupplierHostSelected = (state) => {
  return state.pages.checkin.details?.supplierHost;
};

export const getDropOffBranchSelected = (state) => {
  return state.pages.checkin.details?.dropOffBranch;
};
