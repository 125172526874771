import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100px;
  position: relative;
  background: grey;
  width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(35, 35, 35, 0.6);
  }
`;

const BannerCollapsed = ({ bgImage }) => {
  return <Container bgImage={bgImage}></Container>;
};

export default BannerCollapsed;
