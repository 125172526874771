export const SET_WEBISTE_LANGUAGE = "SET_WEBSITE_LANGUAGE";

export const setWebsiteLanguage = (language) => {
  return {
    type: SET_WEBISTE_LANGUAGE,
    payload: {
      language,
    },
  };
};
