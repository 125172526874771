import breakpoints from "../utils/breakpoints";
import useWindowSize from "./useWindowSize";

function useIsMobile() {
  const { width } = useWindowSize();
  const { tablet } = breakpoints;
  return width < tablet;
}
export default useIsMobile;

