import Steps from "antd/es/steps";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const FormSteps = () => {
  const { t } = useTranslation("shop");
  const location = useLocation();

  const getCurrent = () => {
    const url = location.pathname;
    if (url.includes("participants")) {
      return 1;
    }
    if (url.includes("checkout")) {
      return 2;
    }
    return 0;
  };

  return (
    <Steps
      style={{
        width: "100%",
        justifyContent: "space-around",
        marginBottom: 10,
        marginTop: 20,
        fontSize: 16
      }}
      current={getCurrent()}
      size="small"
      initial={0}
      items={[
        {
          title: t("checkout.mainMember"),
        },
        {
          title: t("checkout.members"),
        },
        {
          title: t("checkout.products"),
        },
      ]}
    />
  );
};

export default FormSteps;
