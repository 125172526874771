import queryString from "query-string";
import Cookies from "universal-cookie";

export const getURLQuery = () => {
  const { search } = window.location;
  return queryString.parse(search);
};

export const setCookieFromValue = (cookieName, value) => {
  const cookies = new Cookies();
  const oldCookie = cookies.get(cookieName);
  // Remove old cookie and then replace it
  if (oldCookie) {
    cookies.remove(cookieName);
  }
  cookies.set(cookieName, value, { path: "/" });
};

export const setCookie = (cookieName) => {
  const queries = getURLQuery();
  const cookies = new Cookies();
  const oldCookie = cookies.get(cookieName);

  // Remove old cookie and then replace it
  if (queries[cookieName] && oldCookie) {
    cookies.remove(cookieName);
  }
  if (queries[cookieName]) {
    cookies.set(cookieName, queries[cookieName], { path: "/" });
  }
};

export const getCookieValue = (name) => {
  const cookies = new Cookies();
  const cookie = cookies.get(name);
  return cookie;
};

export const generateCookies = (cookies) => {
  const cookiesString = cookies.map((cookie) => {
    return `${cookie}=${getCookieValue(cookie)}`;
  });
  return cookiesString.join("; ");
};
