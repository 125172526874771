import { useEffect, useState } from "react";
import queryString from "query-string";
import environment from "../config/environment";

const useWebsiteDomain = () => {
  const [domain, setDomain] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    let hostname = window.location.hostname;
    if (hostname === environment.demoHostname) {
      hostname = getDomainFromQuery();
    }
    if (!hostname) {
      setError(true);
      return;
    }
    setDomain(sanitizeDomain(hostname));
  }, []);

  const getDomainFromQuery = () => {
    const query = queryString.parse(window.location.search);
    return query.domain;
  };

  /**
   * Remove www.
   * @param {string} hostname
   * @returns {string}
   */
  const sanitizeDomain = (hostname) => hostname.replace(/^www\./, "");

  return {
    error,
    domain,
  };
};

export default useWebsiteDomain;
