import { denormalize } from "normalizr";
import { isEmpty, sortBy } from "lodash";
import { preSaleSchema, preSalesSchema } from "../Schemas/preSaleSchema";

export const getPreSaleById = (state, id) => {
  if (id) {
    const denormalizeData = denormalize(id, preSaleSchema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};

export const getPreSales = (state, arrayOfIds) => {
  if (arrayOfIds) {
    const denormalizeData = denormalize(
      arrayOfIds,
      preSalesSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return sortBy(denormalizeData, "createdAt").reverse();
    }
    return [];
  }
  return [];
};
