import React from "react";
import styled from "styled-components";
import SelectedProductsContainer from "../SelectedProductsContainer/SelectedProductsContainer";
import SubtotalFooter from "../../components/SubtotalFooter/SubtotalFooter";
import PassengerDetailsContainer from "../PassengerDetailsContainer/PassengerDetailsContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TabContainer = ({ passengerId }) => {
  return (
    <Container>
      <PassengerDetailsContainer id={passengerId} />
      <SelectedProductsContainer id={passengerId} />
      <SubtotalFooter id={passengerId} />
    </Container>
  );
};

export default TabContainer;
