export const getDates = (array) => {
  return array.map((item) => item.date);
};

export const addAvailabilityColor = (availabilityDays) => {
  return availabilityDays?.map((item) => {
    const percentage = item.occupiedPercentage;

    if (percentage >= 100) {
      return { date: item.date, className: "unavailable-day" };
    } else if (percentage >= 50) {
      return { date: item.date, className: "low-availability-day" };
    } else {
      return { date: item.date, className: "available-day" };
    }
  });
};
