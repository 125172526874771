import { ShopOutlined } from "@ant-design/icons";
import { Select } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
  padding-top: 90px;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const FormContainer = styled.div`
  min-height: 60vh;
  width: 100%;
  max-width: 600px;

  && .ant-form-item-required {
    font-size: 14px;
    height: auto;
  }

  && .ant-form-item-explain-error {
    font-size: 14px;
  }
  && .ant-form-item-label {
    padding-bottom: 5px;
  }

  && .ant-input {
    font-size: 16px;
  }

  && .ant-select-selector {
    font-size: 16px;
  }
`;

export const PricingSection = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const ShopIcon = styled(ShopOutlined)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border: ${({ theme }) => `1px solid ${theme.colors.borders}`} !important;
    border-radius: 20px !important;
    padding-left: 45px !important;
    font-size: 16px !important;
  }
`;

export const SelectBranchContainer = styled.div`
  margin-bottom: 20px;
`;

export const NotFoundProductContainer = styled.div`
  padding: 20px 0;
`;

export const SpinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;
