export const GET_PRESALE_BY_ID_REQUEST = "GET_PRESALE_BY_ID_REQUEST";
export const GET_PRESALE_BY_ID_SUCCESS = "GET_PRESALE_BY_ID_SUCCESS";
export const GET_PRESALE_BY_ID_FAILURE = "GET_PRESALE_BY_ID_FAILURE";

export const getPreSaleByIdRequest = (id) => {
  return {
    type: GET_PRESALE_BY_ID_REQUEST,
    payload: {
      id,
    },
  };
};

export const getPreSaleByIdSuccess = (result) => {
  return {
    type: GET_PRESALE_BY_ID_SUCCESS,
    payload: {
      result,
    },
  };
};

export const getPreSaleByIdFailure = (error) => {
  return {
    type: GET_PRESALE_BY_ID_FAILURE,
    payload: {
      error,
    },
  };
};
