export const SUPPLIER_SETTINGS_SUCCESS = "SUPPLIER_SETTINGS_SUCCESS";

export const supplierSettingsSuccess = (data) => {
  return {
    type: SUPPLIER_SETTINGS_SUCCESS,
    payload: {
      data,
    },
  };
};
