import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Image from "antd/es/image";
import { GalleryWrapper } from "./Gallery.styles";

const Gallery = ({ images, visible, setVisible }) => {
  const { t } = useTranslation();
  return (
    <>
      <GalleryWrapper>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {images?.map((img, index) => (
            <Image src={img} alt={t("experience")} key={index} />
          ))}
        </Image.PreviewGroup>
      </GalleryWrapper>
    </>
  );
};

Gallery.defaultProps = {
  images: [],
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};

export default Gallery;
