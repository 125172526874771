import { countBy, first, isEmpty } from "lodash";
import moment from "moment";
import i18n from "i18next";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-GB";
import ptLocale from "date-fns/locale/pt-BR";
import { ReactComponent as ArgentinaFlagIcon } from "../media/language/ar.svg";
import { ReactComponent as BritainFlagIcon } from "../media/language/bg.svg";
import { ReactComponent as ChileFlagIcon } from "../media/language/cl.svg";
import { calculatePrevDaysForBooking } from "../features/ShopPage/utils";
import { PRINT_FORMATS } from "./dates";

export const languageConstants = Object.freeze({
  esAR: "es-AR",
  esCL: "es-CL",
  enGB: "en-GB",
  ptBR: "pt-BR",
});

export const domainConstants = Object.freeze({
  AR: ".ar",
  CL: ".cl",
  UK: ".uk",
  ES: ".es",
  CO: ".co",
  BR: ".br",
  UY: ".uy",
  EU: ".eu",
});

export const calculateItemsLength = (products) => {
  if (products && !isEmpty(products)) {
    return Object.values(products).reduce((acc, item) => {
      return acc + item;
    }, 0);
  }
  return 0;
};

export const sortAlphabetically = (arrayOfStrings) => {
  const array = [...arrayOfStrings];
  return array.sort((a, b) => {
    if (a > b) {
      return 1;
    }

    if (a < b) {
      return -1;
    }
    return 1;
  });
};

export const normalizeProducts = (products) => {
  if (isEmpty(products)) return [];
  const productKeys = Object.keys(products);
  let arrayOfIds = [];

  for (let i = 0; i < productKeys.length; i++) {
    const quantity = products[productKeys[i]];
    for (let j = 0; j < quantity; j++) {
      arrayOfIds.push(productKeys[i]);
    }
  }
  return arrayOfIds;
};

export const bundlesFirst = (productsCategory) => {
  const arrCategories = Object.keys(productsCategory);
  const bundles = arrCategories.find((key) => key === "bundles");
  if (bundles) {
    const categories = arrCategories.filter(
      (category) => category !== "bundles"
    );
    categories.unshift(bundles);
    return categories;
  }
  return arrCategories;
};

export const normalizeParams = (params, paramName = "filters") => {
  return Object.entries(params).reduce(
    (normalizedObject, [key, value]) => ({
      ...normalizedObject,
      [`${paramName}[${key}]`]: value,
    }),
    {}
  );
};

export const calculateSupplierDaysLength = (from, to) => {
  if (!from || !to) return null;
  try {
    to?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    from?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // The plus 1 includes the starting day as a full day.
    const dayLength = to?.diff(from, "days") + 1;
    const fixedLength = dayLength === 0 ? 1 : dayLength;
    return fixedLength;
  } catch (err) {
    return null;
  }
};

export const createUTCDate = (dateString, isUTCString = false) => {
  return moment(dateString).utc(!isUTCString);
};

export const ActivitiesAndCategories = [
  {
    value: "ski",
    category: "snow",
    season: ["winter"],
    color: "#91abbcff",
  },
  {
    value: "snowboard",
    category: "snow",
    season: ["winter"],
    color: "#86a1b5ff",
  },
  {
    value: "climbing",
    category: "land",
    season: ["winter", "summer", "autumn", "spring"],
    color: "#787e5fff",
  },
  {
    value: "rockClimbing",
    category: "land",
    season: ["winter", "summer", "autumn", "spring"],
    color: "#787e5fff",
  },
  {
    value: "iceClimbing",
    category: "snow",
    season: ["winter", "summer", "autumn", "spring"],
    color: "#787e5fff",
  },
  {
    value: "trekking",
    category: "land",
    season: ["winter", "summer", "autumn", "spring"],
    color: "#5e8a7dff",
  },
  {
    value: "kayaking",
    category: "water",
    season: ["summer", "autumn", "spring"],
    color: "#456b89ff",
  },
  {
    value: "mountainbike",
    category: "land",
    season: ["winter", "summer", "autumn", "spring"],
    color: "#4a7969ff",
  },
  {
    value: "canoeing",
    category: "water",
    season: ["summer", "autumn", "spring"],
    color: "#6587b6ff",
  },
  {
    value: "paddling",
    category: "water",
    season: ["summer", "autumn", "spring"],
    color: "#6587b6ff",
  },
  {
    value: "snorkling",
    category: "water",
    season: ["summer", "autumn", "spring"],
    color: "#6587b6ff",
  },
  {
    value: "cycling",
    category: "land",
    season: ["summer", "autumn", "spring", "winter"],
    color: "#4a7969ff",
  },
  {
    value: "camping",
    category: "land",
    season: ["summer", "autumn", "spring"],
    color: "#699788ff",
  },
  {
    value: "surf",
    category: "water",
    season: ["summer", "autumn", "spring"],
    color: "#6587b6ff",
  },
  {
    value: "kitesurf",
    category: "water",
    season: ["summer", "autumn", "spring"],
    color: "#6587b6ff",
  },
  {
    value: "standuppaddle",
    category: "water",
    season: ["summer", "autumn", "spring"],
    color: "#6587b6ff",
  },
  {
    value: "tech",
    category: "all",
    season: ["summer", "autumn", "spring"],
    color: "#d9e8d1ff",
  },
  {
    value: "diving",
    category: "water",
    season: ["summer", "autumn", "spring"],
    color: "#6587b6ff",
  },
  {
    value: "sledding",
    category: "snow",
    season: ["winter"],
    color: "#abc0e6ff",
  },
  {
    value: "snowshoeing",
    category: "snow",
    season: ["winter"],
    color: "#abc0e6ff",
  },
  {
    value: "vehicles",
    category: "land",
    season: ["winter", "summer", "autumn", "spring"],
    color: "#d9e8d1ff",
  },
  {
    value: "windsurf",
    category: "water",
    season: ["summer", "autumn", "spring"],
    color: "#6587b6ff",
  },
];

export const languageConfig = {
  "es-CL": {
    label: "ES",
    flagIcon: (props) => (
      <ChileFlagIcon
        className="anticon anticon-unordered-list"
        width={20}
        {...props}
      />
    ),
    name: "Español",
    code: "es-CL",
    symbol: "(CHL)",
    country: "Chile",
  },
  "es-AR": {
    label: "ES",
    flagIcon: (props) => (
      <ArgentinaFlagIcon
        className="anticon anticon-unordered-list"
        width={20}
        {...props}
      />
    ),
    name: "Español",
    code: "es-AR",
    symbol: "(ARG)",
    country: "Argentina",
  },
  "en-GB": {
    label: "EN",
    flagIcon: (props) => (
      <BritainFlagIcon
        className="anticon anticon-unordered-list"
        width={20}
        {...props}
      />
    ),
    name: "English",
    code: "en-GB",
    symbol: "(UK)",
    country: "United Kingdom",
  },
};

export const languageConfigKeys = () => {
  const configKeys = Object.keys(languageConfig).map((key) => {
    return { key, ...languageConfig[key] };
  });
  return configKeys;
};

export const getLanguageLocalStorage = () => {
  const language = localStorage.getItem("language");
  return language;
};

export const getCurrencyLocalStorage = () => {
  const currency = localStorage.getItem("currency");
  return currency ?? null;
};

export const getRegionLocalStorage = () => {
  const region = localStorage.getItem("region");
  return region ?? null;
};

export const setInitialConfigOnLocalStorage = (region) => {
  const regionConfig = {
    argentina: { currency: "ARS", language: "es-AR" },
    foreign: { currency: "USD", language: "en-GB" },
  };
  if (!region) return null;
  const config = regionConfig[region];
  return config ?? null;
};

export const getDateLocal = () => {
  const { language: defaultLanguage } = getDefaultLocalization();
  const language = getValidLanguageFromLocalStorage() || defaultLanguage;
  const config = {
    "es-AR": esLocale,
    "es-CL": esLocale,
    "pt-BR": ptLocale,
    "en-GB": enLocale,
  };
  return config[language] || esLocale;
};

export const getValidLanguageFromLocalStorage = () => {
  const language = localStorage.getItem("language");
  const validLanguage = Object.values(languageConstants).filter(
    (item) => item === language
  )[0];
  return validLanguage;
};

export const getDefaultLocalization = () => {
  const host = window.location.host;
  const domains = [
    domainConstants.CL,
    domainConstants.UK,
    domainConstants.ES,
    domainConstants.AR,
    domainConstants.CO,
    domainConstants.BR,
    domainConstants.UY,
    domainConstants.EU,
  ];
  const userDomain = domains.filter((domain) => {
    return host.includes(domain);
  });
  const userDomainValue = first(userDomain);
  return localizationConfigs[userDomainValue] || localizationConfigs[".ar"];
};

export const currencyConstants = Object.freeze({
  GBP: "gbp",
  USD: "usd",
  BRL: "brl",
  EUR: "eur",
  ARS: "ars",
  CLP: "clp",
});

export const regionConstants = Object.freeze({
  ARGENTINA: "argentina",
  FOREIGN: "foreign",
});

export const localizationConfigs = {
  [domainConstants.AR]: {
    currency: currencyConstants.ARS,
    language: languageConstants.esAR,
  },
  [domainConstants.CL]: {
    currency: currencyConstants.CLP,
    language: languageConstants.esCL,
  },
  [domainConstants.UK]: {
    currency: currencyConstants.GBP,
    language: languageConstants.enGB,
  },
  [domainConstants.ES]: {
    currency: currencyConstants.EUR,
    language: languageConstants.esAR,
  },
  [domainConstants.CO]: {
    currency: currencyConstants.USD,
    language: languageConstants.enGB,
  },
  [domainConstants.BR]: {
    currency: currencyConstants.BRL,
    language: languageConstants.ptBR,
  },
  [domainConstants.UY]: {
    currency: currencyConstants.USD,
    language: languageConstants.esAR,
  },
  [domainConstants.EU]: {
    currency: currencyConstants.EUR,
    language: languageConstants.enGB,
  },
};

export const currenciesConfig = {
  ars: {
    label: "$ - ARS",
    currency: "ARS",
    symbol: "$",
    name: "pesosArgentinos",
  },
  clp: {
    label: "$ - CLP",
    currency: "CLP",
    symbol: "CL$",
    name: "pesosChilenos",
  },
  brl: {
    label: "R$ - BRL",
    currency: "BRL",
    symbol: "R$",
    name: "reales",
  },
  gbp: {
    label: "£ - GBP",
    currency: "GBP",
    symbol: "£",
    name: "sterlingPounds",
  },
  usd: {
    label: "$ - USD",
    currency: "USD",
    symbol: "u$s",
    name: "americanDollars",
  },
  eur: {
    label: "€ - EUR",
    currency: "EUR",
    symbol: "€",
    name: "euros",
  },
  mxn: {
    label: "$ - MXN",
    currency: "MXN",
    symbol: "$",
    name: "pesosMexicanos",
  },
};

export const normalizeSearchParam = (params) => {
  function normalizeActivities(activities) {
    let text = "";
    if (!activities) return text;
    activities.forEach((item) => {
      if (!item.value) return;
      text = text + `&activities=${item.value}`;
    });
    return text;
  }

  function normalizeDates(value) {
    return `&from=${calculatePrevDaysForBooking(
      value.from,
      params.prevDaysForBooking,
      PERIODS.day
    ).format(PRINT_FORMATS.day.isoFormat)}&to=${calculatePrevDaysForBooking(
      value.to,
      params.prevDaysForBooking,
      PERIODS.day
    ).format(PRINT_FORMATS.day.isoFormat)}`;
  }

  function normalizeTimes(key, value) {
    return `&${key}=${value}`;
  }

  return Object.entries(params).reduce((normalizedObject, [key, value]) => {
    if (key === "activities") {
      const activities = normalizeActivities(value);
      if (activities) {
        return `${normalizedObject}${activities}`;
      }
      return `${normalizedObject}`;
    }
    if (key === "dates") {
      const dates = normalizeDates(value);
      return `${normalizedObject}${dates}`;
    }
    if (key === "pickupTime" || key === "dropoffTime") {
      const times = normalizeTimes(key, value);
      return `${normalizedObject}${times}`;
    }
    return `${normalizedObject}`;
  }, "");
};

export const buildSearchParams = (searchValues) => {
  const params = normalizeSearchParam(searchValues);
  return params;
};

export const PERIODS = {
  day: "day",
  hour: "hour",
};

export const toISOFormatString = (date) => {
  if (!date) return null;
  const dateMoment = moment.isMoment(date) ? date : createUTCDate(date);
  return renderCustomFormatDate(dateMoment, "YYYY-MM-DDTHH:mm:ss");
};

export const renderCustomFormatDate = (
  dateMoment = createUTCDate(),
  format = ""
) => {
  return moment(dateMoment).locale(getLanguage()).format(format);
};

export const getLanguage = () => {
  const { language: defaultLanguage } = getDefaultLocalization();
  const language = getValidLanguageFromLocalStorage() || defaultLanguage;
  return language;
};

export const getCurrencyConfig = (currency) => {
  if (!currency) return "";
  const sanitiseCurrency = currency.toLowerCase();
  return currenciesConfig[sanitiseCurrency] || {};
};

/**
 * @param {number} value
 * @param {string} currency
 */
export const renderPrice = (value, currency) => {
  const decimals = currency && currency.toLowerCase() === "clp" ? 0 : 2;
  return sanitisePrice(value, decimals);
};

export const sanitisePrice = (number, decimals) => {
  if (!number) return number;
  const parsedNumber = Number(number).toFixed(decimals);
  const [integerPart, decimalPart] = parsedNumber.split(".");
  const thousands = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const decimal = decimalPart ? `,${decimalPart}` : "";
  return `${thousands}${decimal}`;
};

export const normalizeVariants = (assignmentsData) => {
  const arrayOfVariants = Object.values(assignmentsData).map((assign) => {
    return assign.variant;
  });

  const countVariants = countBy(arrayOfVariants);
  return Object.keys(countVariants).map((variantId) => {
    return {
      variantId,
      quantity: countVariants[variantId],
    };
  });
};

export const isSameCurrency = (guestCurrency, hostCurrency) => {
  return guestCurrency === hostCurrency;
};

export const sanitizeGear = (passengerAssignment) => {
  return passengerAssignment.map((assignment, index) => {
    const refAssignmentData = assignment.assigned[0];
    const { title } = refAssignmentData.productId;
    const properties = [];

    if (refAssignmentData.variants) {
      const bundleProducts = refAssignmentData.variants.map((item) => {
        const { title } = item.productId;
        const { variant } = item.variant;
        const { type, value } = variant ?? {};

        return {
          title: `-${title}`,
          properties: [{ label: type, value }],
        };
      });

      return {
        title: `${index + 1}. ${title}`,
        bundleProducts,
      };
    } else {
      const { variant } = refAssignmentData.variant;
      const { type, value } = variant ?? {};

      properties.push({ label: type, value });

      return {
        title: `${index + 1}. ${title}`,
        properties,
      };
    }
  });
};

export const sanitizePersonalInfo = (requiredInfo = {}) => {
  return Object.entries(requiredInfo).map(([key, value]) => ({
    label: i18n.t(key),
    value,
  }));
};

export const sanitizePassenger = (passengerAssigment) => {
  const refPassengerAssigmentData = first(passengerAssigment).paxData;
  const name = refPassengerAssigmentData.name;
  const personalInfo = sanitizePersonalInfo(
    refPassengerAssigmentData.requiredInfo
  );
  const gear = sanitizeGear(passengerAssigment);
  return {
    name,
    personalInfo,
    gear,
  };
};

export const sanitizeSummaryCheckout = (checkoutSummary) => {
  return checkoutSummary.map(sanitizePassenger);
};

export const getGuestExchange = (pricing, version) => {
  if (!pricing) return 0;
  const subtotal = calculateSubtotal(pricing, "guest", version);
  return Number.parseFloat(subtotal).toFixed(2);
};

export const getHostExchange = (pricing, version) => {
  if (!pricing) return 0;
  const subtotal = calculateSubtotal(pricing, "host", version);
  return Number.parseFloat(subtotal).toFixed(2);
};

export const getOuttripExchange = (pricing, version) => {
  if (!pricing) return 0;
  const subtotal = calculateSubtotal(pricing, "outtrip", version);
  return Number.parseFloat(subtotal).toFixed(2);
};

const calculateSubtotal = (pricing, userKey, version) => {
  if (version !== 3) {
    return pricing.total;
  }
  const { detail } = pricing;
  return detail.reduce((sum, item) => {
    const hostRate = item.exchangeRates.host.rate;
    const useKeyRate = item.exchangeRates[userKey].rate;
    const convertedAmount = (item.lineTotal / hostRate) * useKeyRate;
    return sum + convertedAmount;
  }, 0);
};

export const getGuestCurrency = (pricing, version) => {
  let currency = pricing.currency;
  const { detail } = pricing;
  if (detail) {
    currency = getCurrency(detail, "guest", version);
  }
  return getCurrencyConfig(currency);
};

const getCurrency = (detail, key, version) => {
  if (version !== 3) {
    return detail[0].currency;
  }
  return detail[0].exchangeRates[key].currency;
};

export const buildItems = (quoteSummary, selectedProducts) => {
  if (!quoteSummary || isEmpty(selectedProducts)) return {};
  const items = {};
  Object.entries(quoteSummary?.products).forEach(([id, quantity]) => {
    const matchingProduct = selectedProducts.find(
      (product) => product._id === id
    );
    const isBundle = matchingProduct && matchingProduct?.products;
    const key = isBundle ? "bundle" : "product";
    items[[key, id]] = quantity;
  });
  return items;
};

export const sanitizeScript = (scripts) => {
  const regex = /<script\b[^>]*>([\s\S]*?)<\/script\s*>/gi;
  const matches = scripts.match(regex);

  if (!matches) return [];

  const sanitizedScripts = matches.map((script) => {
    return script.replace(/<\/?script[^>]*>/gi, "").trim();
  });
  return sanitizedScripts;
};

export const CUSTOMER_SIZE = {
  FOOT: "foot",
  HEIGHT: "height",
};

export const sanitizePhonePrefix = (phonePrefix) => {
  if (!phonePrefix || typeof phonePrefix !== "string") return null;

  const dashIndex = phonePrefix.indexOf("-");
  if (dashIndex !== -1) {
    return phonePrefix.substring(0, dashIndex);
  }
  return phonePrefix;
};
