import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../../../../components/Button/Button";
import baseTheme from "../../../../../config/baseTheme";

const Container = styled.div`
  padding-bottom: 100px;
`;

const AddPassengerForm = ({
  formController,
  handleOnFinish,
  handleOnRemovePassenger,
  initialFormData,
  handleOnBlur,
  handleOnFocus,
}) => {
  const { t } = useTranslation("shop");
  return (
    <Container>
      <Form
        onFinish={handleOnFinish}
        form={formController}
        initialValues={{
          names: initialFormData.map((data) => data.name),
        }}
      >
        <Form.List
          name="names"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(
                    new Error("Al menos ingresa un integrante")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            const handleRemove = (field) => {
              handleOnRemovePassenger(field);
              remove(field.name);
            };
            return (
              <>
                {fields.map((field, index) => (
                  <Form.Item required={true} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Ingresa el nombre del integrante",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        placeholder={t("passengers.placeholder")}
                        data-testid="quote-passenger-name-input"
                        style={{
                          width: "100%",
                          maxWidth: 300,
                          marginTop: 20,
                        }}
                        autoComplete="off"
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <DeleteOutlined
                        style={{ marginLeft: 10, fontSize: 16 }}
                        className="dynamic-delete-button"
                        data-testid="quote-remove-passenger-button"
                        onClick={() => handleRemove(field)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="link"
                    style={{ padding: 0, marginTop: 30 }}
                    onClick={() => add()}
                    data-testid="quote-add-passenger-button"
                  >
                    <UserAddOutlined
                      className="anticon anticon-unordered-list"
                      style={{
                        color: baseTheme.colors.activeColor,
                        fontSize: 25,
                      }}
                    />
                    {t("passengers.addPassenger")}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form>
    </Container>
  );
};

export default AddPassengerForm;
