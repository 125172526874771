import React from "react";
import Text from "antd/es/typography/Text";
import { Container } from "../HoursItinerary/HoursItinerary.styles";
import HoursItinerary from "../HoursItinerary/HoursItinerary";
import useIsMobile from "../../../../../../../../../hooks/useIsMobile";
import { Collapse } from "antd";
import PropTypes from "prop-types";

const Panel = Collapse.Panel;

const DaysItinerary = ({ title, infoHours }) => {
  const isMobile = useIsMobile();

  const renderMobile = () => {
    return (
      infoHours && (
        <Collapse style={{ width: "100%" }}>
          <Panel header={title} key="1">
            {infoHours?.map((item) => (
              <HoursItinerary hours={item} />
            ))}
          </Panel>
        </Collapse>
      )
    );
  };

  const renderDesktop = () => {
    return (
      infoHours && (
        <>
          <Text
            strong
            style={{ marginBottom: "10px", marginTop: "10px", width: "100%" }}
          >
            {title && title}
          </Text>
          {infoHours?.map((item) => (
            <HoursItinerary hours={item} />
          ))}
        </>
      )
    );
  };
  return <Container>{isMobile ? renderMobile() : renderDesktop()}</Container>;
};

export default DaysItinerary;

DaysItinerary.propTypes = {
  title: PropTypes.string,
  infoHours: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};
