import React from "react";
import PropTypes from "prop-types";
import { size } from "lodash";
import { ReactComponent as ClockHistory } from "../../../../../../components/Icons/clockHistory.svg";
import { ReactComponent as Age } from "../../../../../../components/Icons/usersIcon.svg";
import { ReactComponent as Language } from "../../../../../../components/Icons/language.svg";
import { ReactComponent as Bars } from "../../../../../../components/Icons/bars.svg";
import { ReactComponent as ActivityIcon } from "../../../../../../components/Icons/personWalking.svg";
import { useTranslation } from "react-i18next";
import Text from "antd/es/typography/Text";
import Space from "antd/es/space";
import { Wrapper } from "./Details.styles";
import { renderHours } from "../helpers/helpers";

const Details = ({ details }) => {
  const { t } = useTranslation(["common", "experiences"]);
  if (!details) return null;
  const { age, duration, languages, capacity, difficulty, activity } = details;
  const iconStyle = { marginRight: 5 };

  return (
    <Wrapper>
      <Text style={{ fontSize: 16 }}>
        <Bars style={iconStyle} className="anticon anticon-unordered-list" />
        <b>{t("experiences:difficulty")}:</b>{" "}
        {t(`experiences:difficultyList.${difficulty}`)}
      </Text>

      <Text style={{ fontSize: 16 }}>
        <Age style={iconStyle} className="anticon anticon-unordered-list" />
        <b>{t("experiences:age")}:</b> {age.min}-{age.max}{" "}
        {t("experiences:years").toLowerCase()}
      </Text>

      <Text style={{ fontSize: 16 }}>
        <Age style={iconStyle} className="anticon anticon-unordered-list" />
        <b>{t("experiences:capacity")}:</b>{" "}
        {t("experiences:people", { count: capacity }).toLowerCase()}
      </Text>

      <Text style={{ fontSize: 16 }}>
        <ClockHistory
          style={iconStyle}
          className="anticon anticon-unordered-list"
        />
        <b>{t("experiences:duration")}:</b>{" "}
        {renderHours(duration.unit, duration.value)}
      </Text>

      <Text style={{ fontSize: 16 }}>
        <ActivityIcon
          style={{ width: 18, height: 18, marginRight: 5 }}
          className="anticon anticon-unordered-list"
        />
        <b>{t("experiences:activity", { count: size(activity) })}:</b>{" "}
        <Space direction="horizontal" split={"|"} wrap={true}>
          {activity.map((activity) => (
            <Text key={activity} style={{ fontSize: 16 }}>
              {t(`activitiesList.${activity}`)}
            </Text>
          ))}
        </Space>
      </Text>

      <Text style={{ fontSize: 16 }}>
        <Language
          style={iconStyle}
          className="anticon anticon-unordered-list"
        />
        <b>{t("experiences:language", { count: size(languages) })}:</b>{" "}
        <Space direction="horizontal" split={"|"} wrap={true}>
          {languages.map((lang) => (
            <Text key={lang} style={{ fontSize: 16 }}>
              {t(`languagesList.${lang}`)}
            </Text>
          ))}
        </Space>
      </Text>
    </Wrapper>
  );
};

Details.propTypes = {
  details: PropTypes.shape({
    difficulty: PropTypes.string,
    duration: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string,
    }),
    languages: PropTypes.arrayOf(PropTypes.string),
    capacity: PropTypes.number,
    age: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),
};

export default Details;
