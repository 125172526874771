import React from "react";
import styled from "styled-components";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 10px;
`;

const EmptyPlaceholder = () => {
  const { t } = useTranslation(["shop", "common"]);
  return (
    <Container>
      <Text type="secondary" style={{ textAlign: "center", fontWeight: 300 }}>
        {t("emptyBasket")}
      </Text>
    </Container>
  );
};

export default EmptyPlaceholder;
