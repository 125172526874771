import Text from "antd/es/typography/Text";
import { TextCustom } from "../InfoExtraItinerary/InfoExtraItinerary.styles";
import { Col, Image, Row } from "antd";
import {
  ContainerImages,
  SectionHeader,
  Wrapper,
} from "./ItinerarySection.styles";
import { TextTime } from "../HoursItinerary/HoursItinerary.styles";
import { useTranslation } from "react-i18next";
import { EXPERIENCE_DURATION } from "../../../../../../../list/helpers/constants";

const ItinerarySection = ({ sections }) => {
  const { t } = useTranslation("experiences");

  return (
    <>
      <Wrapper>
        <Col>
          <SectionHeader>
            {sections?.activity?.type === EXPERIENCE_DURATION.PER_DAY && (
              <TextTime type="light-primary" strong>
                {`${t("day")}`} {sections?.order}
              </TextTime>
            )}
            {sections?.activity?.duration && (
              <TextTime type="light-primary" strong>
                {sections?.activity?.duration}
              </TextTime>
            )}
            <Row>
              <TextCustom strong>{sections?.title}</TextCustom>
              {sections?.subtitle && (
                <Text
                  type="secondary"
                  style={{
                    fontSize: 15,
                    marginLeft: 10,
                    alignContent: "center",
                  }}
                >
                  - {sections?.subtitle}
                </Text>
              )}
            </Row>
          </SectionHeader>
          <Row></Row>
        </Col>
        {sections?.description && (
          <TextCustom>{sections?.description}</TextCustom>
        )}
        {sections?.attachments && (
          <ContainerImages>
            {sections?.attachments.map((attachment, index) => (
              <Image
                src={attachment}
                alt={`${sections?.title}-${index}`}
                width={150}
                style={{ borderRadius: 10 }}
              />
            ))}
          </ContainerImages>
        )}
      </Wrapper>
    </>
  );
};

export default ItinerarySection;
