import styled from "styled-components";
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";

export const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: ${({ columnsLength }) =>
    `repeat(${columnsLength}, 1fr)`};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
`;

export const ContainerCard = styled.div`
  margin: auto;
  width: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: ${({ theme }) => `1px solid ${theme.borders.light}`};
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }
`;

export const HeaderCard = styled.div`
  position: relative;
`;

export const ExperienceImage = styled.img`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 180px;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 16 / 9;
`;

export const ContainerActivity = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  width: 130px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const ActivityText = styled(Text)`
  color: #fff;
  font-size: 14px;
`;

export const TitleCard = styled(Title)`
  margin: 0;
  margin-top: 10px;
`;

export const Location = styled(Text)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const BodyCard = styled.div`
  padding: 0 20px;
  padding-bottom: 10px;
  min-height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
export const BottomCard = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  color: ${({ theme }) => theme.text.darkGray};
`;
