import React from "react";
import PropTypes from "prop-types";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Wrapper, ValueDisplay, StyledButton } from "./styled";
import { isFunction } from "lodash";

export const Counter = ({
  value,
  onAdd,
  onSubtract,
  disabled,
  min = 0,
  max,
}) => {
  const handleAdd = () => {
    isFunction(onAdd) && onAdd();
  };

  const handleSubtract = () => {
    isFunction(onSubtract) && onSubtract();
  };
  return (
    <Wrapper>
      <StyledButton
        type="primary"
        shape="circle"
        disabled={disabled || min === value}
        onClick={handleSubtract}
        icon={<MinusOutlined />}
      />
      <ValueDisplay disabled={disabled}>{value}</ValueDisplay>
      <StyledButton
        type="primary"
        shape="circle"
        disabled={disabled || max === value}
        onClick={handleAdd}
        icon={<PlusOutlined />}
      />
    </Wrapper>
  );
};

Counter.defaultProps = {
  value: 0,
};

Counter.propTypes = {
  value: PropTypes.number,
  onAdd: PropTypes.func,
  onSubtract: PropTypes.func,
};

export default Counter;
