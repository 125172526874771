import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ExperienceCard from "../components/ExperienceCard";
import { ExperienceContent } from "../styles/styles";
import Container from "../../../../components/Container/Container";
import Skeleton from "antd/es/skeleton";
import SkeletonContainer from "../../../../components/Skeleton/SkeletonContainer";
import { Wrapper } from "../../../HomePage/components/FeaturedExperiences/styles/FeaturedExperiences.styles";
import { getConvertedPrice } from "../helpers/helpers";
import { navigateExperience } from "../../../HomePage/helpers/helpers";

const ExperiencesContent = ({
  experiences,
  loading,
  location,
  promotionExperience,
}) => {
  if (loading) {
    return (
      <Container>
        <SkeletonContainer>
          <Skeleton active={true} />
          <Skeleton active={true} />
        </SkeletonContainer>
      </Container>
    );
  }

  return (
    <Wrapper>
      <ExperienceContent>
        {experiences.map((experience) => {
          return (
            <Link
              to={navigateExperience(
                experience?._id,
                experience?.title,
                location
              )}
              key={experience?._id}
            >
              <ExperienceCard
                customPrice={getConvertedPrice(
                  experience._id,
                  experience?.convertedPrice?.adultPrice,
                  promotionExperience
                )}
                images={experience?.images}
                title={experience?.title}
                activity={experience?.activity}
                location={experience?.location}
                price={experience?.convertedPrice?.adultPrice}
                duration={experience?.duration}
                difficulty={experience?.difficulty}
                currency={experience?.convertedCurrency}
              />
            </Link>
          );
        })}
      </ExperienceContent>
    </Wrapper>
  );
};

ExperiencesContent.propTypes = {
  experiences: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      activity: PropTypes.arrayOf(PropTypes.string),
      location: PropTypes.string,
      price: PropTypes.shape({
        adultPrice: PropTypes.number,
      }),
      duration: PropTypes.shape({
        unit: PropTypes.string,
        value: PropTypes.number,
      }),
      difficulty: PropTypes.string,
      languages: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};
export default ExperiencesContent;
