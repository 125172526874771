import styled from "styled-components";
import { CalendarOutlined } from "@ant-design/icons";
import Input from "antd/es/input";
import Title from "antd/lib/typography/Title";
import "react-datepicker/dist/react-datepicker.css";
import SpinAntd from "antd/es/spin";

export const DatePickerWrapper = styled.div`
  width: 100%;
  max-width: 350px;
  z-index: 99 !important;

  .react-datepicker-wrapper {
    width: 100% !important;
    max-width: 350px !important;
  }
  .react-datepicker-popper {
    width: 100% !important;
    max-width: 350px !important;
  }
`;

export const StyledInput = styled(Input)`
  border: 1px solid ${({ theme }) => theme.colors.borders};
  border-radius: 20px;
  padding-left: 50px;
  width: 100%;
  max-width: 350px;
`;

export const CalendarIcon = styled(CalendarOutlined)`
  position: absolute;
  left: 15px;
  color: ${({ theme }) => theme.colors.primaryColor};
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
`;

export const CustomCalendar = styled.div`
  border-radius: 20px !important;
  border: none;
  width: 100% !important;
  max-width: 350px !important;
  margin: 0 auto;

  .react-datepicker__header {
    background-color: transparent;
    border: none;
  }
  .react-datepicker__children-container {
    width: 100% !important;
  }
  .react-datepicker__current-month {
    padding-bottom: 10px;
  }
  .react-datepicker__month-container {
    width: 100%;
    font-size: 16px;
    color: #cdcdcd;
    margin-bottom: 20px;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 0.4rem;
  }
  .react-datepicker__day-name {
    margin-bottom: 0;
    color: #909090 !important;
  }

  .react-datepicker__day--disabled {
    background-color: #f0f0f0;
  }
`;
export const HeaderCalendarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
`;

export const TitleHeaderCalendar = styled(Title)`
  margin: 0;
  color: #909090 !important;
`;

export const CustomNavigation = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner = styled(SpinAntd)`
  border-radius: 20px !important;
  border: none !important;
  box-shadow: none !important;
`;
