import {
  PRODUCT_MODAL_RESET,
  PRODUCT_MODAL_SHOW,
} from "../actions/productModalActions";

export const INITIAL_STATE = {
  visible: false,
  productId: null,
  productType: null,
  type: null,
};

const productModalReducer = (state = INITIAL_STATE, action) => {
  const { payload: { visible, productId, optionType, productType } = {} } =
    action;
  switch (action.type) {
    case PRODUCT_MODAL_SHOW:
      return {
        visible,
        productId,
        optionType,
        productType,
      };
    case PRODUCT_MODAL_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default productModalReducer;
