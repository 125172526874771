import { SET_WEBISTE_CURRENCY } from "../Actions/currencyActions";

const INITIAL_STATE = {
  currency: null,
};

export const currencyReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_WEBISTE_CURRENCY: {
      return {
        ...state,
        currency: payload.currency,
      };
    }
    default:
      return state;
  }
};

export default currencyReducer;
