export const EXCHANGE_CONVERTER_REQUEST = "EXCHANGE_CONVERTER_REQUEST";
export const EXCHANGE_CONVERTER_SUCESSS = "EXCHANGE_CONVERTER_SUCESSS";
export const EXCHANGE_CONVERTER_FAILURE = "EXCHANGE_CONVERTER_FAILURE";
export const EXCHANGE_CONVERTER_RESET = "EXCHANGE_CONVERTER_RESET";
export const TRANSACTION_PAYMENT_RESET = "TRANSACTION_PAYMENT_RESET";
export const SET_INVOICE_DATA_REQUEST = "SET_INVOICE_DATA_REQUEST";
export const SET_INVOICE_DATA_SUCCESS = "SET_INVOICE_DATA_SUCCESS";
export const SET_INVOICE_DATA_FAILURE = "SET_INVOICE_DATA_FAILURE";
export const TRANSACTION_PAMYENT_RESET_IDENTIFIER =
  "TRANSACTION_PAMYENT_RESET_IDENTIFIER";
export const TRANSACTION_PAYMENT_SET_PAYMENT_IDENTIFIER =
  "TRANSACTION_PAYMENT_SET_PAYMENT_IDENTIFIER";
export const TRANSACTION_PAYMENT_CREATE_REQUEST =
  "TRANSACTION_PAYMENT_CREATE_REQUEST";
export const TRANSACTION_PAYMENT_CREATE_SUCCESS =
  "TRANSACTION_PAYMENT_CREATE_SUCCESS";
export const TRANSACTION_PAYMENT_CREATE_FAILURE =
  "TRANSACTION_PAYMENT_CREATE_FAILURE";
export const TRANSACTION_PAYMENT_ACTION_REQUEST =
  "TRANSACTION_PAYMENT_ACTION_REQUEST";
export const TRANSACTION_PAYMENT_ACTION_SUCCESS =
  "TRANSACTION_PAYMENT_ACTION_SUCCESS";
export const TRANSACTION_PAYMENT_ACTION_FAILURE =
  "TRANSACTION_PAYMENT_ACTION_FAILURE";
export const GET_PAYMENT_METHODS_FROM_SUPPLIER_REQUEST =
  "GET_PAYMENT_METHODS_FROM_SUPPLIER_REQUEST";
export const GET_PAYMENT_METHODS_FROM_SUPPLIER_SUCCESS =
  "GET_PAYMENT_METHODS_FROM_SUPPLIER_SUCCESS";
export const GET_PAYMENT_METHODS_FROM_SUPPLIER_FAILURE =
  "GET_PAYMENT_METHODS_FROM_SUPPLIER_FAILURE";

export const getPaymentMethodsFromSupplierRequest = (supplierId) => {
  return {
    type: GET_PAYMENT_METHODS_FROM_SUPPLIER_REQUEST,
    payload: {
      supplierId,
    },
  };
};

export const getPaymentMethodsFromSupplierSuccess = (results) => {
  return {
    type: GET_PAYMENT_METHODS_FROM_SUPPLIER_SUCCESS,
    payload: {
      methods: results,
    },
  };
};

export const getPaymentMethodsFromSupplierFailure = () => {
  return {
    type: GET_PAYMENT_METHODS_FROM_SUPPLIER_FAILURE,
  };
};

export const exchangeConverterRequest = (amount, currency, convertTo) => {
  return {
    type: EXCHANGE_CONVERTER_REQUEST,
    payload: {
      amount,
      currency,
      convertTo,
    },
  };
};

export const exchangeConverterSucess = (data) => {
  return {
    type: EXCHANGE_CONVERTER_SUCESSS,
    payload: data,
  };
};

export const exchangeConverterFailure = (err) => {
  return {
    type: EXCHANGE_CONVERTER_FAILURE,
    payload: err,
  };
};

export const exchangeConverterReset = () => {
  return {
    type: EXCHANGE_CONVERTER_RESET,
  };
};

export const transactionPaymentResetIdentifier = () => {
  return {
    type: TRANSACTION_PAMYENT_RESET_IDENTIFIER,
  };
};

export const transactionPaymentReset = () => {
  return {
    type: TRANSACTION_PAYMENT_RESET,
  };
};

export const setPaymentMethodIdentifier = (paymentMethodIdentifier) => {
  return {
    type: TRANSACTION_PAYMENT_SET_PAYMENT_IDENTIFIER,
    payload: {
      paymentMethodIdentifier,
    },
  };
};

/**
 * @param {Object} params
 * @param {string} params.provider
 * @param {number} params.amount
 * @param {string} params.currency
 * @param {string} params.transactionId
 */
export const transactionPaymentCreateRequest = (params) => {
  return {
    type: TRANSACTION_PAYMENT_CREATE_REQUEST,
    payload: params,
  };
};

export const transactionPaymentCreateSuccess = (paymentId) => {
  return {
    type: TRANSACTION_PAYMENT_CREATE_SUCCESS,
    payload: {
      paymentId,
    },
  };
};

export const transactionPaymentCreateFailure = (err) => {
  return {
    type: TRANSACTION_PAYMENT_CREATE_FAILURE,
    payload: {
      err,
    },
  };
};

/**
 * @param {Object} params
 * @param {string} params.paymentId
 * @param {number} params.action
 * @param {string} [params.actionParams]
 */
export const transactionPaymentActionRequest = (params) => {
  return {
    type: TRANSACTION_PAYMENT_ACTION_REQUEST,
    payload: params,
  };
};

export const transactionPaymentActionSuccess = (action) => {
  return {
    type: TRANSACTION_PAYMENT_ACTION_SUCCESS,
    payload: {
      action,
    },
  };
};

export const transactionPaymentActionFailure = (action, err) => {
  return {
    type: TRANSACTION_PAYMENT_ACTION_FAILURE,
    payload: {
      action,
      err,
    },
  };
};

export const GET_PAYMENT_BY_ID_REQUEST = "GET_PAYMENT_BY_ID_REQUEST";
export const GET_PAYMENT_BY_ID_SUCCESS = "GET_PAYMENT_BY_ID_SUCCESS";
export const GET_PAYMENT_BY_ID_FAILURE = "GET_PAYMENT_BY_ID_FAILURE";

/**
 * @param {string} paymentId
 * @returns {object}
 */
export const getPaymentByIdRequest = (paymentId, supplierId) => {
  return {
    type: GET_PAYMENT_BY_ID_REQUEST,
    payload: {
      paymentId,
      supplierId,
    },
  };
};

/**
 * @param {string} paymentId
 * @returns {object}
 */
export const getPaymentByIdSuccess = (paymentId) => {
  return {
    type: GET_PAYMENT_BY_ID_SUCCESS,
    payload: {
      paymentId,
    },
  };
};

/**
 * @param {object|string} err
 * @returns {object}
 */
export const getPaymentByIdFailure = (err) => {
  return {
    type: GET_PAYMENT_BY_ID_FAILURE,
    payload: {
      err,
    },
  };
};

export const setInvoiceDataRequest = (data) => {
  return {
    type: SET_INVOICE_DATA_REQUEST,
    payload: {
      data,
    },
  };
};

export const setInvoiceDataSuccess = () => {
  return {
    type: SET_INVOICE_DATA_SUCCESS,
  };
};

export const setInvoiceDataFailure = () => {
  return {
    type: SET_INVOICE_DATA_FAILURE,
  };
};

export const UPDATE_WEBPAY_PAYMENT_STATUS_REQUEST =
  "UPDATE_WEBPAY_PAYMENT_STATUS_REQUEST";

export const updateWebpayPaymentStatusRequest = (
  paymentId,
  supplierId,
  token
) => {
  return {
    type: UPDATE_WEBPAY_PAYMENT_STATUS_REQUEST,
    payload: {
      paymentId,
      supplierId,
      token,
    },
  };
};
