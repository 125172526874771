import React from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import {
  SelectStyled,
  DropdownContainer,
  IconWrapper,
  DropdownOptions,
} from "./Dropdown.styles";

const Dropdown = ({
  size = "large",
  bordered = false,
  placeholder,
  options,
  onChange,
  value,
  disabled,
  icon,
  children,
  extraInfo,
  optionsGap,
  ...restProps
}) => {
  const hasIcon = !!icon;

  return (
    <DropdownContainer>
      {hasIcon && <IconWrapper disabled={disabled}>{icon}</IconWrapper>}
      <SelectStyled
        $hasIcon={hasIcon}
        value={value}
        onSelect={onChange}
        placeholder={placeholder}
        size={size}
        disabled={disabled}
        bordered={bordered}
        dropdownStyle={{ borderRadius: "10px" }}
        {...restProps}
      >
        {!isEmpty(children) && children}
        {isEmpty(children) &&
          options?.map((option) => (
            <SelectStyled.Option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              style={{ background: "transparent" }}
            >
              <DropdownOptions optionsGap={optionsGap}>
                {option.label}
                {option.extraInfo && option.extraInfo}
              </DropdownOptions>
            </SelectStyled.Option>
          ))}
      </SelectStyled>
    </DropdownContainer>
  );
};

Dropdown.propTypes = {
  size: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      extraInfo: PropTypes.node,
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  bordered: PropTypes.bool,
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default Dropdown;
