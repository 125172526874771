import styled from "styled-components";
import { Typography } from "antd";
import baseTheme from "../../config/baseTheme";
import ButtonAntd from "antd/es/button";
const { Text } = Typography;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
`;

export const ValueDisplay = styled(Text)`
  display: block;
  padding: 0 15px;
  color: ${baseTheme.colors.fonts} !important;
`;

export const StyledButton = styled(ButtonAntd)`
display: flex;
align-items: center;
justify-content: center;
}`;

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 15px 15px 15px 0px;
`;
