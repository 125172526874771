import styled from "styled-components";
import {
  Content as ContentComponent,
  Footer as FooterComponent,
} from "antd/es/layout/layout";

export const Header = styled.header`
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

export const Content = styled(ContentComponent)`
  position: relative;
`;

export const Footer = styled(FooterComponent)`
  background-color: ${({ theme }) => theme.background.dark} !important;
`;
