import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { UserAddOutlined } from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import { uniqueId } from "lodash";
import TabContainer from "../../containers/TabContainer/TabContainer";
import { setActivePassenger } from "../../redux/QuoteActions";
import { useDispatch } from "react-redux";
import TabLabel from "../TabLabel/TabLabel";
import useIsMobile from "../../../../hooks/useIsMobile";
import DeletePassengerTabModal from "../DeletePassengerTabModal/DeletePassengerTabModal";
import { ITEM_ACTIONS } from "../../../CheckInPage/constants";

const TabController = ({ initialValues, onAddTab, onRemoveTab }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const initialTab = [
    {
      label: <TabLabel id={initialValues.id} />,
      children: <TabContainer passengerId={initialValues.id} />,
      key: initialValues.id,
      closable: false,
    },
  ];
  const [showConfirmationModal, setShowConfirmationModal] = useState({
    visible: false,
    target: null,
  });
  const [activeKey, setActiveKey] = useState(initialTab[0].key);
  const [items, setItems] = useState(initialTab);

  useEffect(() => {
    dispatch(setActivePassenger(activeKey));
  }, [activeKey]);

  const add = () => {
    const newActiveKey = uniqueId();
    const newPanes = [...items];
    newPanes.push({
      label: <TabLabel id={newActiveKey} />,
      children: <TabContainer passengerId={newActiveKey} />,
      key: newActiveKey,
      closable: true,
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
    onAddTab(newActiveKey);
  };
  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
    setShowConfirmationModal({ visible: false, target: null });
    onRemoveTab(targetKey);
  };

  const onEdit = (targetKey, action) => {
    if (action === ITEM_ACTIONS.add) {
      add();
    } else {
      setShowConfirmationModal({ visible: true, target: targetKey });
    }
  };

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        width: "100%",
      }}
    >
      <Tabs
        lang={"es-AR"}
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        items={items}
        addIcon={
          isMobile ? (
            <UserAddOutlined />
          ) : (
            <div style={{ minWidth: 80 }}>
              <Text>
                <UserAddOutlined />
              </Text>
            </div>
          )
        }
        animated
        tabPosition={"top"}
        tabBarStyle={{ marginBottom: 8, height: 40 }}
      />
      <DeletePassengerTabModal
        visible={showConfirmationModal.visible}
        id={showConfirmationModal.target}
        onCancel={() =>
          setShowConfirmationModal({ visible: false, target: null })
        }
        onOK={() => remove(showConfirmationModal.target)}
      />
    </div>
  );
};

export default TabController;
