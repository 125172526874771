import { schema } from "normalizr";
import { supplierSchema } from "./supplierSchema";

export const bundleSchema = new schema.Entity(
  "bundles",
  {
    owner: supplierSchema,
  },
  {
    idAttribute: "_id",
  }
);

export const bundlesSchema = [bundleSchema];
