import axios from "axios";
const isE2E = process.env.IS_CI;

export const get = async (url, params = {}) => {
  try {
    const res = await axios.get(url, params);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const post = async (url, data, config) => {
  try {
    const res = await axios.post(url, data, config);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const update = async (url, data, config) => {
  try {
    const res = await axios.put(url, data, config);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const patch = async (url, data, config) => {
  try {
    const res = await axios.patch(url, data, config);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const deleteRequest = async (url, data = {}, config) => {
  try {
    const res = await axios.delete(url, config);
    return res.data;
  } catch (err) {
    throw err.response;
  }
};

export const getConfigs = async () => {
  // eslint-disable-next-line no-undef
  const token = localStorage.getItem("userToken");
  const origin = isE2E ? { Origin: "https://staging.outtrip.co" } : {};
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      ...origin,
    },
  };
};