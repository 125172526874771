// minimum for breakpoints
// Mobile First
const breakpoints = {
  smallMobile: 400,
  mobile: 600,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1200,
  xLargeDesktop: 1300,
};

export default breakpoints;
