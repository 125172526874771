import Title from "antd/es/typography/Title";
import React from "react";
import Text from "antd/es/typography/Text";
import { useSelector } from "react-redux";
import {
  getFinalPriceFetching,
  getQuoteTotalPrice,
} from "../../redux/QuoteSelectors";
import { getCurrencyConfig, renderPrice } from "../../../../helpers/tools";
import { getSupplierSettings } from "../../../../Redux/Selectors/appConfigSelector";
import baseTheme from "../../../../config/baseTheme";
import { Spin } from "antd";
import useIsMobile from "../../../../hooks/useIsMobile";

const TotalPriceContainer = () => {
  const isMobile = useIsMobile();
  const totalPrice = useSelector(getQuoteTotalPrice);
  const settings = useSelector(getSupplierSettings);
  const isFetching = useSelector(getFinalPriceFetching);
  if (isFetching) {
    if (isMobile) return <Spin />;
    return (
      <Text>
        <Spin style={{ marginRight: 10 }} /> Calcuando precio final..
      </Text>
    );
  }
  return (
    <div
      style={{
        borderLeft: `1px solid ${baseTheme.borders.gray}`,
        borderRight: `1px solid ${baseTheme.borders.gray}`,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Text type="secondary">Total:</Text>
      <Title level={3} style={{ margin: 0, fontSize: isMobile ? 16 : 24 }}>
        {getCurrencyConfig(settings.currency).symbol}{" "}
        {renderPrice(totalPrice, settings.currency)}
      </Title>
    </div>
  );
};

export default TotalPriceContainer;
