import React from "react";
import PropTypes from "prop-types";
import Descriptions from "antd/es/descriptions";
import GearItem from "../../../ShopPage/components/GearItem/GearItem";
import {
  CardTitle,
  StyledDivider,
  Wrapper,
} from "./SummaryExperienceCard.styles";

const SummaryExperienceCard = ({ title, personalInfo, ...restProps }) => {
  const renderDescriptionItem = (label, properties, key) => (
    <Descriptions.Item
      key={key}
      contentStyle={{
        paddingBottom: 2,
        display: "flex",
        flexDirection: "row",
      }}
      span={2}
      label={label}
    >
      {[properties].map((data, index) => (
        <GearItem key={index} properties={data} />
      ))}
    </Descriptions.Item>
  );

  return (
    <Wrapper {...restProps}>
      <CardTitle level={3}>{title}</CardTitle>
      <Descriptions
        column={2}
        size="small"
        style={{
          padding: "0 17px",
        }}
        labelStyle={{
          fontWeight: "bold",
        }}
        bordered={true}
      >
        {personalInfo.map(({ label, properties }, index) =>
          renderDescriptionItem(label, properties, index)
        )}
      </Descriptions>
      <StyledDivider />
    </Wrapper>
  );
};

SummaryExperienceCard.defaultProps = {
  personalInfo: [],
};

SummaryExperienceCard.propTypes = {
  title: PropTypes.string.isRequired,
  personalInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      properties: PropTypes.array.isRequired,
    })
  ),
};

export default SummaryExperienceCard;
