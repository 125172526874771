import styled from "styled-components";
import Text from "antd/es/typography/Text";
import AvailabilityCalendar from "../../../../../components/AvailabilityCalendar/AvailabilityCalendar";
import baseTheme from "../../../../../config/baseTheme";

export const Calendar = styled(AvailabilityCalendar)`
  .available-day,
  .low-availability-day,
  .unavailable-day {
    color: ${({ theme }) => theme.colors.fonts};
    border-radius: 10px;
    &:hover {
      color: ${({ theme }) => theme.colors.fonts};
      border-radius: 10px;
    }
  }
  .available-day {
    background-color: rgba(66, 255, 0, 0.25);
  }
  .low-availability-day {
    background-color: rgb(255, 196, 12, 0.25);
  }
  .unavailable-day {
    background-color: rgba(255, 6, 6, 0.36);

    &:hover {
      background-color: rgba(255, 6, 6, 0.36);
    }
  }
  box-shadow: none !important;
  .react-datepicker__day--selected {
    border: 2px solid ${baseTheme.colors.black};
    color: ${baseTheme.colors.black};
  }
`;

export const ReferenceContainer = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
`;

export const ReferenceText = styled(Text)`
  color: #909090;
  font-size: 10px;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  color: #909090;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const GreenCircle = styled(Circle)`
  background-color: rgba(66, 255, 0, 0.25);
`;

export const YellowCircle = styled(Circle)`
  background-color: rgba(255, 196, 12, 0.25);
`;

export const RedCircle = styled(Circle)`
  background-color: rgba(255, 6, 6, 0.36);
`;

export const WhiteCircle = styled(Circle)`
  border: 1px solid ${baseTheme.borders.gray};
`;
