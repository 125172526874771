import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getAssigmentsData,
  getAssignmentFailure,
  getProductsIsFetching,
  getShopProducts,
  getSummary,
} from "../../ShopPage/redux/selectors/ShopSelectors";
import {
  getDropOffBranchSelected,
  getNormalizePassengers,
  getSearchProductValue,
  getSupplierHostSelected,
} from "../redux/checkinSelectors";
import { useCallback, useEffect } from "react";
import {
  assignDefaultVariantToPassenger,
  getAllProductsShopRequest,
  resetAssignments,
  resetSummaryProducts,
  unassignVariantToPassenger,
} from "../../ShopPage/redux/actions/shopActions";
import {
  getSupplierData,
  getSupplierSettings,
} from "../../../Redux/Selectors/appConfigSelector";
import {
  performTransactionRequest,
  resetTransactionStatus,
} from "../../TransactionPage/TransactionActions";
import { PERIODS, toISOFormatString } from "../../../helpers/tools";
import { listTypes, sanitizeVariantListing } from "../../ShopPage/utils";
import useCalculateDates from "../../../hooks/useCalculateDays";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { getMainMemberInfo } from "../../ShopPage/redux/selectors/MainMemberSelectors";
import { getTransactionStatus } from "../../ShopPage/redux/selectors/TransactionStatusSelectors";
import { useState } from "react";
import { ITEM_ACTIONS } from "../constants";
import { notification } from "antd";
import { transactionStatus as transactionStatusConstants } from "../../TransactionPage/helpers";
import {
  createUTCDate,
  dateRoundHour,
  generateDateTime,
  PRINT_FORMATS,
} from "../../../helpers/dates";
import {
  resetCheckinData,
  setCheckinSearchFilter,
  setDropOffBranch,
  setSupplierHost,
} from "../redux/checkinActions";
import { debounce } from "lodash";
import { getSupplierGroupsRequest } from "../redux/supplierGroups/supplierGroupsActions";
import {
  getAllSupplierGroups,
  supplierGroupsFetching,
} from "../redux/supplierGroups/supplierGroupsSelector";
import { branchOptionsGenerator } from "../helpers/helpers";
import { getSupplierPlan } from "../../Subscription/redux/selectorSubscription";
import { useTranslation } from "react-i18next";
import { saveMainMemberInfo } from "../../ShopPage/redux/actions/MainMemberActions";
import { isEmpty } from "lodash";
import { getPassengersData } from "../../ShopPage/redux/selectors/ShopSelectors";
import { quoteBuildAddPassengers } from "../../ShopPage/redux/actions/shopActions";

const ProductsContainer = ({ children }) => {
  const localStorageData = JSON.parse(localStorage.getItem("checkinForm"));
  const supplierData = useSelector(getSupplierData);
  const { t } = useTranslation("shop");
  const supplierHost = useSelector(getSupplierHostSelected);
  const settings = useSelector(getSupplierSettings);
  const dropOffBranch = useSelector(getDropOffBranchSelected, shallowEqual);
  const assignmentsData = useSelector(getAssigmentsData);
  const assignmentFailure = useSelector(getAssignmentFailure);
  const [showAssignmentModal, setShowAssignmentModal] = useState({
    visible: false,
    action: null,
    productId: null,
    selectedValues: null,
  });
  const passengers = useSelector(getPassengersData);
  const [quantityDays, setQuantityDays] = useState(1);
  const [searchLoading, setSearchLoading] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const params = queryString.parse(location.search);
  const quoteSummary = useSelector(getSummary);
  const transactionStatus = useSelector(getTransactionStatus);
  const mainMember = useSelector(getMainMemberInfo);
  const normalizePassengers = useSelector(getNormalizePassengers);
  const dispatch = useDispatch();
  const searchValue = useSelector(getSearchProductValue, shallowEqual);
  const products = useSelector((state) =>
    getShopProducts(state, null, searchValue, quoteSummary?.period, false)
  );
  const supplierGroups = useSelector(getAllSupplierGroups, shallowEqual);
  const loadingSupplierGroups = useSelector(
    supplierGroupsFetching,
    shallowEqual
  );
  const features = useSelector((state) => getSupplierPlan(state));
  const supplierGroupsOptions = branchOptionsGenerator(
    supplierGroups,
    supplierData
  );
  const productsFetching = useSelector(getProductsIsFetching, shallowEqual);
  const { from, to, pricingTo } = useCalculateDates({
    fromDate: params.from,
    toDate: createUTCDate(params.from)
      .add(quantityDays - 1, quoteSummary.period)
      .format(PRINT_FORMATS.day.isoFormat),
    period: quoteSummary.period,
  });
  const startDate = from;
  const endDate =
    quoteSummary.period === PERIODS.hour
      ? to.add(quantityDays - 1, quoteSummary.period)
      : pricingTo;
  const isHourPeriod = quoteSummary?.period === PERIODS.hour;
  const pickUpDatePerHour = dateRoundHour(createUTCDate());
  const pickUpTimePerHour = pickUpDatePerHour.format("HH:mm");
  const dropOffDatePerHour = pickUpDatePerHour
    .clone()
    .add(quantityDays, "hours");
  const dropOffTimePerHour = pickUpDatePerHour
    .clone()
    .add(quantityDays, "hours")
    .format("HH:mm");

  useEffect(() => {
    dispatch(resetCheckinData());
  }, []);

  useEffect(() => {
    dispatch(
      getAllProductsShopRequest(
        {
          owner: supplierHost || supplierData._id,
          published: true,
          active: true,
        },
        settings?.currency
      )
    );
  }, [supplierHost]);

  useEffect(() => {
    if (transactionStatus.status === transactionStatusConstants.ACCEPTED) {
      navigate(`/checkin/done`, { replace: true });
    }
    if (transactionStatus.error) {
      notification.error({
        message: t(transactionStatus.errorMessage),
      });
      dispatch(resetTransactionStatus());
    }
  }, [transactionStatus]);

  useEffect(() => {
    if (assignmentFailure) {
      notification.error({
        message: "Hubo un problema",
        description: "No pudimos agregar el producto",
      });
    }
  }, [assignmentFailure]);

  useEffect(() => {
    dispatch(
      getSupplierGroupsRequest(
        { active: true },
        ["members", "admin"],
        supplierData?._id
      )
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(localStorageData)) {
      const { mainMember, passengers } = localStorageData;
      dispatch(saveMainMemberInfo(mainMember));
      dispatch(quoteBuildAddPassengers(passengers));
      localStorage.setItem("checkinForm", JSON.stringify({}));
    }
  }, [mainMember]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      localStorage.setItem(
        "checkinForm",
        JSON.stringify({
          mainMember,
          passengers,
        })
      );
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [mainMember]);

  const handleSubmitTransaction = () => {
    const autoConfirm = true;
    const dropOffShop = features.tools.transferItems
      ? dropOffBranch ?? supplierData._id
      : dropOffBranch;
    const sanitizedVariants = sanitizeVariantListing(
      assignmentsData,
      listTypes.PRODUCT
    );
    const pickAndDrop = {
      pickup: {
        date: toISOFormatString(
          isHourPeriod
            ? pickUpDatePerHour
            : generateDateTime(from, params.pickupTime)
        ),
        time: isHourPeriod ? pickUpTimePerHour : params.pickupTime,
      },
      dropoff: {
        date: toISOFormatString(
          isHourPeriod
            ? dropOffDatePerHour
            : generateDateTime(to, params.dropoffTime)
        ),
        time: isHourPeriod ? dropOffTimePerHour : params.dropoffTime,
      },
    };

    dispatch(
      performTransactionRequest(
        sanitizedVariants,
        toISOFormatString(startDate),
        toISOFormatString(endDate),
        quoteSummary.period,
        settings.currency,
        { passengers: normalizePassengers },
        pickAndDrop,
        null,
        mainMember.referral,
        "checkin",
        mainMember.housingInfo,
        autoConfirm,
        dropOffShop
      )
    );
  };

  const handleOnAddProduct = (productId) => {
    setShowAssignmentModal({
      visible: true,
      action: ITEM_ACTIONS.add,
      productId,
    });
  };

  const handleOnSubstractProduct = (productId) => {
    setShowAssignmentModal({ visible: true, action: "substract", productId });
  };

  const handleAssignmentModalOnClose = () => {
    setShowAssignmentModal(false, { action: null });
  };

  const onAssignMember = (value) => {
    const { productId, action } = showAssignmentModal;
    if (action === ITEM_ACTIONS.add) {
      dispatch(
        assignDefaultVariantToPassenger({
          passenger: value,
          variantId: null,
          productId,
          bundleId: null,
          index: `${value}-${productId}-${
            quoteSummary.products[productId] || 0
          }`,
          type: "",
          experienceId: null,
          fieldkey: "",
          id: "",
        })
      );
    } else {
      dispatch(
        unassignVariantToPassenger({
          index: `${value}-${productId}`,
          productId,
        })
      );
    }
  };

  const handleOnAdd = () => {
    setQuantityDays(quantityDays + 1);
  };

  const handleOnSubtract = () => {
    setQuantityDays(quantityDays - 1);
  };

  const handleSelectBranch = (data) => {
    dispatch(resetAssignments());
    dispatch(resetSummaryProducts());
    dispatch(setSupplierHost(data));
  };

  const handleDropOffBranch = (data) => {
    dispatch(setDropOffBranch(data));
  };

  const delayedQuery = useCallback(
    debounce((value) => {
      dispatch(setCheckinSearchFilter(value.trim()));
      setSearchLoading(false);
    }, 500),
    []
  );

  const onSearchProduct = (value) => {
    setInputSearchValue(value);
    setSearchLoading(true);
    delayedQuery(value);
  };

  return children({
    products,
    quoteSummary,
    productsFetching,
    handleSubmitTransaction,
    transactionStatus,
    handleOnAddProduct,
    handleOnSubstractProduct,
    showAssignmentModal,
    handleAssignmentModalOnClose,
    onAssignMember,
    assignmentsData,
    handleOnSubtract,
    handleOnAdd,
    quantityDays,
    handleSelectBranch,
    handleDropOffBranch,
    supplierGroupsOptions,
    loadingSupplierGroups,
    onSearchProduct,
    searchValue: inputSearchValue,
    searchLoading,
    supplierHost,
    isHourPeriod,
    dropOffBranch,
  });
};

export default ProductsContainer;
