import styled from "styled-components";
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";

export const BodyContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const ContainerButton = styled.div`
  width: 100%;
  text-align: end;
  padding: 15px;
  border-top: ${({ border, theme }) =>
    border ? `1px solid ${theme.borders.gray}` : 0};
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 50px !important;
`;

export const Description = styled(Text)`
  margin: 70px 0;
`;
