import {
  getCurrencyConfig,
  getGuestCurrency,
  getGuestExchange,
  getOuttripExchange,
} from "../../helpers/tools";
import { calculateBalance } from "../TransactionPage/helpers";

export const parsePreSale = (preSale) => {
  if (!preSale) {
    return {};
  }

  const balance = calculateBalance(preSale.account, preSale.pricing.total);

  return {
    ...preSale,
    balance,
    guestPrice: getGuestExchange(preSale.pricing, 3),
    subtotal: preSale.pricing.subtotal,
    discount: preSale.pricing?.discounts[0]?.amountDiscounted ?? null,
    hostPrice: preSale.pricing.total,
    guestCurrency: getGuestCurrency(preSale.pricing, 3),
    hostCurrency: getCurrencyConfig(preSale.pricing.currency),
    adminPrice: getOuttripExchange(preSale.pricing, 3),
  };
};
