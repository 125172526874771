import {
  HIGHLIGHT_PRODUCTS_FAILURE,
  HIGHLIGHT_PRODUCTS_REQUEST,
  HIGHLIGHT_PRODUCTS_SUCCESS,
} from "../actions/highlightProductsActions";

const INITIAL_STATE = {
  products: [],
  loading: false,
  success: false,
  error: false,
};

export const highlightProductsReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case HIGHLIGHT_PRODUCTS_REQUEST: {
      return {
        products: [],
        loading: true,
        success: false,
        error: false,
      };
    }
    case HIGHLIGHT_PRODUCTS_SUCCESS: {
      return {
        products: payload.productIds,
        loading: false,
        success: true,
        error: false,
      };
    }
    case HIGHLIGHT_PRODUCTS_FAILURE: {
      return {
        products: [],
        loading: false,
        success: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export default highlightProductsReducer;
