import { denormalize } from "normalizr";
import { isEmpty } from "lodash";
import { productSchema, productsSchema } from "../Schemas/productSchema";

export const getProductById = (state, id) => {
  if (id) {
    const denormalizeData = denormalize(id, productSchema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};

export const getProducts = (state, arrayOfIds) => {
  if (arrayOfIds) {
    const denormalizeData = denormalize(
      arrayOfIds,
      productsSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return [];
  }
  return [];
};
