import {
  put,
  take,
  takeEvery,
  takeLatest,
  all,
  select,
  call,
} from "redux-saga/effects";
import {
  ASSIGN_DEFAULT_VARIANT_TO_PASSENGER,
  GET_REFERRALS_REQUEST,
  GET_VARIANTS_FROM_PRODUCT_SUCCESS,
  UNASSIGN_VARIANT_TO_PASSENGER,
  assignDefaultVariantToPassengerFailure,
  assignVariantToPassenger,
  getReferralsSuccess,
  getVariantsFromProductRequest,
  summaryAddItem,
  summarySubstractItem,
} from "../../ShopPage/redux/actions/shopActions";
import { get } from "../../../services/Api";
import environment from "../../../config/environment";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import { getVariants } from "../../../Redux/Selectors/variantSelectors";
import { getProductById } from "../../../Redux/Selectors/productSelectors";
import { getBundleById } from "../../../Redux/Selectors/bundlesSelectors";
import { ITEM_TYPE } from "../../ShopPage/constants";
import { UNASSIGNMENT_VARIANT } from "../constants";

function* handleAddDefaultVariant(action) {
  try {
    const { productId, index, passenger } = action.payload;
    const bundleVariants = [];
    const productDetail = yield select(getProductById, productId);
    const bundleDetail = yield select(getBundleById, productId);
    if (productDetail) {
      yield put(getVariantsFromProductRequest(productId));
      const variantSuccess = yield take(GET_VARIANTS_FROM_PRODUCT_SUCCESS);
      const variants = yield select(
        getVariants,
        variantSuccess.payload.results
      );
      const defaultVariant = variants.find(
        (variant) =>
          variant.variant.value.toLowerCase() ===
          UNASSIGNMENT_VARIANT.toLowerCase()
      );
      yield put(
        assignVariantToPassenger({
          passenger,
          variantId: defaultVariant._id,
          productId,
          bundleId: null,
          index,
          type: "variant",
          experienceId: null,
          id: null,
        })
      );
      yield put(summaryAddItem(productId));
    }
    if (bundleDetail) {
      for (let i = 0; i < bundleDetail.products.length; i++) {
        yield put(
          getVariantsFromProductRequest(bundleDetail.products[i].productId)
        );
        const variantSuccess = yield take(GET_VARIANTS_FROM_PRODUCT_SUCCESS);
        const variants = yield select(
          getVariants,
          variantSuccess.payload.results
        );
        const defaultVariant = variants.find(
          (variant) =>
            variant.variant.value === "Sin asignar" ||
            variant.variant.value === "Sin Asignar"
        );
        if (!defaultVariant) return;

        const variant = {
          ...defaultVariant,
          reference: defaultVariant._id,
          type: ITEM_TYPE.VARIANT,
          _id: bundleDetail._id,
          productId: defaultVariant.product._id,
        };
        bundleVariants.push(variant);
      }
      yield put(
        assignVariantToPassenger({
          passenger,
          variantId: bundleVariants,
          productId: null,
          bundleId: bundleDetail._id,
          index,
          type: ITEM_TYPE.BUNDLE,
          experienceId: null,
          id: null,
        })
      );
      yield put(summaryAddItem(productId));
    }
  } catch (err) {
    console.log(err);
    yield put(assignDefaultVariantToPassengerFailure());
  }
}

function* handleRemoveVariant(action) {
  const { productId } = action.payload;
  yield put(summarySubstractItem(productId));
}

function* handleGetReferrals(action) {
  try {
    const supplierData = yield select(getSupplierData);
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/v2/referrals`,
      {
        params: {
          id: supplierData._id,
          pagination: false,
        },
      }
    );
    if (response.status === 200) {
      yield put(getReferralsSuccess(response.data.data.docs));
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* checkinSaga() {
  yield all([
    takeEvery(ASSIGN_DEFAULT_VARIANT_TO_PASSENGER, handleAddDefaultVariant),
    takeLatest(UNASSIGN_VARIANT_TO_PASSENGER, handleRemoveVariant),
    takeLatest(GET_REFERRALS_REQUEST, handleGetReferrals),
  ]);
}
