import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBookingPeriod } from "../features/ShopPage/redux/actions/shopActions";
import { getSupplierSettings } from "../Redux/Selectors/appConfigSelector";
import { getSanitizedBookingPeriod } from "../helpers/supplier";

const usePeriod = () => {
  const dispatch = useDispatch();
  const settings = useSelector(getSupplierSettings);
  const period = getSanitizedBookingPeriod(settings?.bookingPeriodTypes);

  useEffect(() => {
    if (period) {
      dispatch(setBookingPeriod(period));
    }
  }, [period]);
};

export default usePeriod;
