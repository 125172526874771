import { all, call, put, takeLatest } from "redux-saga/effects";
import { normalizeParams } from "../../../../helpers/tools";
import { get, getConfigs } from "../../../../services/httpService";
import { normalizeData } from "../../../../Redux/Actions/normalizrActions";
import { supplierGroupsSchema } from "../../../../Redux/Schemas/supplierGroupsSchema";
import environment from "../../../../config/environment";
import {
  GET_SUPPLIER_GROUPS_REQUEST,
  getSupplierGroupsFailure,
  getSupplierGroupsSuccess,
} from "./supplierGroupsActions";

function* handleGetSupplierGroups(action) {
  const { params, relationships, supplierId } = action.payload;
  const normalizedParams = normalizeParams({
    ...params,
  });
  try {
    const token = yield call(getConfigs);

    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/v2/suppliers/${supplierId}/groups`,
      {
        params: {
          pagination: false,
          relationships,
          ...normalizedParams,
        },
        ...token,
      }
    );
    if (response.status === 1) {
      const arrayOfIds = response.data.docs.map((group) => group._id);
      yield put(normalizeData(response.data.docs, supplierGroupsSchema));
      yield put(getSupplierGroupsSuccess(arrayOfIds));
    }
  } catch (err) {
    yield put(getSupplierGroupsFailure(err));
  }
}

export default function* supplierGroupsSaga() {
  yield all([takeLatest(GET_SUPPLIER_GROUPS_REQUEST, handleGetSupplierGroups)]);
}
