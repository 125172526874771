import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExperiencesRequest } from "../features/ExperiencesPage/redux/actions/ExperiencesActions";
import {
  getAllExperiences,
  getAllExperiencesIsFetching,
} from "../features/ExperiencesPage/redux/selectors/ExperiencesSelector";
import { getSupplierData } from "../Redux/Selectors/appConfigSelector";
import useWebsiteCurrency from "../hooks/useWebsiteCurrency";

const useExperiences = () => {
  const dispatch = useDispatch();
  const { currency } = useWebsiteCurrency();
  const supplierData = useSelector(getSupplierData);
  const experiences = useSelector((state) => getAllExperiences(state));
  const experiencesIsFetching = useSelector((state) =>
    getAllExperiencesIsFetching(state)
  );

  useEffect(() => {
    if (supplierData?._id) {
      dispatch(
        getExperiencesRequest(
          {
            owner: supplierData?._id,
            published: true,
            active: true,
          },
          currency
        )
      );
    }
  }, [JSON.stringify(supplierData), currency]);

  return { experiences, loading: experiencesIsFetching };
};

export default useExperiences;
