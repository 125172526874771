import Button from "antd/es/button";
import PropTypes from "prop-types";
import { ContainerFooter } from "./PaymentResult.styles";

const FooterCard = ({ onClick, buttonText }) => {
  return (
    <ContainerFooter>
      <Button
        onClick={onClick}
        style={{ padding: "6px 18px", height: "40px" }}
        type="primary"
        shape="round"
      >
        {buttonText}
      </Button>
    </ContainerFooter>
  );
};

FooterCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};
export default FooterCard;
