import React from "react";
import Form from "antd/es/form/Form";
import Input from "antd/es/input/Input";
import { useTranslation } from "react-i18next";
import PhoneCountryCodeSelect from "../PhoneCountryCodeSelect/PhoneCountryCodeSelect";
const PhoneInput = ({
  showCountryName,
  prefixWidth = 150,
  required = false,
  placeholder = "",
}) => {
  const { t } = useTranslation("shop");
  const prefixSelector = (
    <Form.Item
      name="phonePrefix"
      rules={[
        {
          required,
        },
      ]}
      noStyle
    >
      <PhoneCountryCodeSelect
        width={prefixWidth}
        showCountryName={showCountryName}
      />
    </Form.Item>
  );
  return (
    <Form.Item
      name="phoneNumber"
      label={t("form.phoneNumber")}
      rules={[
        {
          required,
          message: t("form.error", { field: t("form.phoneNumber") }),
        },
      ]}
    >
      <Input
        addonBefore={prefixSelector}
        aria-label="phone-input"
        size="large"
        type="number"
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default PhoneInput;
