import {
  TRANSACTION_PAYMENT_ACTION_FAILURE,
  TRANSACTION_PAYMENT_ACTION_REQUEST,
  TRANSACTION_PAYMENT_ACTION_SUCCESS,
  TRANSACTION_PAYMENT_RESET,
} from "./paymentActions";

const INITIAL_STATE = {};

const paymentActionReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case TRANSACTION_PAYMENT_RESET: {
      return INITIAL_STATE;
    }
    case TRANSACTION_PAYMENT_ACTION_REQUEST: {
      return {
        ...state,
        [payload.action]: {
          loading: true,
          success: false,
          error: false,
        },
      };
    }
    case TRANSACTION_PAYMENT_ACTION_SUCCESS: {
      return {
        ...state,
        [payload.action]: {
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case TRANSACTION_PAYMENT_ACTION_FAILURE: {
      return {
        ...state,
        [payload.action]: {
          loading: false,
          success: false,
          error: true,
        },
      };
    }
    default:
      return state;
  }
};

export default paymentActionReducer;
