import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const PickUpAndDropOffWrapper = styled.div`
  border: ${({ theme, $isMobile }) =>
    !$isMobile && `1px solid ${theme.borders.gray}`};
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: ${({ $isMobile }) => !$isMobile && "20px"};
  width: ${({ $isMobile }) => !$isMobile && "100%"};
  gap: 10px;
`;

export const StyledRow = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns ? `repeat(${columns}, 1fr)` : `repeat(2, 1fr)`};
  gap: 20px;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledText = styled(Text)`
  font-size: 16px;
`;
