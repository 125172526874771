import { isEmpty, sortBy } from "lodash";
import { denormalize } from "normalizr";
import {
  transactionSchema,
  transactionsSchema,
} from "../../Redux/Schemas/transactionSchema";
import { getProductById } from "../../Redux/Selectors/productSelectors";
import { parseBooking } from "./helpers";

export const getSimulatedBookingData = (state) => {
  let data = state.pages.transaction.simulated;
  if (data && data.variant) {
    const product = getProductById(state, data.variant.product);
    return {
      ...data,
      product: product,
    };
  }
};

export const getTransactionDetails = (state, id) => {
  const transaction = getTransactionById(state, id);
  return transaction ? parseBooking(transaction) : {};
};

export const getTransactionIsFetching = (state) => {
  return state.pages.transaction.fetching;
};

export const getTransactionFailed = (state) => {
  const { error, errorMessage } = state.pages.shop.transactionStatus;
  return { error, errorMessage };
};

export const getTransactionById = (state, id) => {
  if (id) {
    const denormalizeData = denormalize(id, transactionSchema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};

export const getTransactions = (state, arrayOfIds) => {
  if (arrayOfIds) {
    const denormalizeData = denormalize(
      arrayOfIds,
      transactionsSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return sortBy(denormalizeData, "createdAt").reverse();
    }
    return [];
  }
  return [];
};
