import React from "react";
import { useTranslation } from "react-i18next";
import Counter from "../../../../../components/Counter/Counter";
import Typography from "antd/es/typography/Typography";
import { Member } from "./AddParticipants.styles";
import PropTypes from "prop-types";
import { PRICE_TYPES } from "../../../list/helpers/constants";
const { Text } = Typography;

const AddParticipants = ({
  participantOptions,
  handleOnAddTicket,
  handleOnRemoveTicket,
  tickets,
  ageSettings,
}) => {
  const { t } = useTranslation("experiences");
  const AddMember = ({ rate }) => {
    return (
      <Member>
        <div style={{ gap: 10 }}>
          <Text strong> {t(`booking.${rate}`, { count: "" })} </Text>
          {ageSettings[rate] ? (
            <Text style={{ fontSize: "14px" }}>
              {t(`booking.minMaxAge`, {
                min: ageSettings[rate].min,
                max: ageSettings[rate].max,
              })}
            </Text>
          ) : (
            ""
          )}
        </div>
        <Counter
          min={Number(rate === PRICE_TYPES.adult)}
          onAdd={(val) => handleOnAddTicket(rate, val)}
          onSubtract={() => handleOnRemoveTicket(rate)}
          value={tickets[rate]}
        />
      </Member>
    );
  };
  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      {participantOptions?.sort().map((participant, index) => (
        <AddMember rate={participant} key={index} />
      ))}
    </div>
  );
};

AddParticipants.propTypes = {
  participantOptions: PropTypes.array,
  handleOnAddTicket: PropTypes.func,
  handleOnRemoveTicket: PropTypes.func,
  tickets: PropTypes.object,
  ageSettings: PropTypes.object,
};

export default AddParticipants;
