import React from "react";
import PropTypes from "prop-types";
import RichTextRender from "../../../../../../../../../components/RichTextRender/RichTextRender";
import { TextCustom } from "./InfoExtraItinerary.styles";

const InfoExtraItinerary = ({ description }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {description?.title && (
        <TextCustom strong>{description?.title}</TextCustom>
      )}
      {description?.description && (
        <TextCustom>{description?.description}</TextCustom>
      )}
      {description?.distancia && (
        <TextCustom strong>{description?.distancia}</TextCustom>
      )}
      {description?.descriptionGeneral && (
        <RichTextRender content={description?.descriptionGeneral} />
      )}
    </div>
  );
};

export default InfoExtraItinerary;

InfoExtraItinerary.propTypes = {
  description: PropTypes.object,
};
