import { all, call, put, takeLatest } from "redux-saga/effects";
import environment from "../../../../config/environment";
import { normalizeParams } from "../../../../helpers/tools";
import { normalizeData } from "../../../../Redux/Actions/normalizrActions";
import { get } from "../../../../services/Api";
import {
  productSchema,
  productsSchema,
} from "../../../../Redux/Schemas/productSchema.js";
import {
  GET_SPACES_REQUEST,
  GET_SPACE_DETAIL_REQUEST,
  getSpaceDetailFailure,
  getSpaceDetailSuccess,
  getSpacesFailure,
  getSpacesSuccess,
} from "../actions/spacesActions.js";

function* handleGetSpaces(action) {
  try {
    const { params } = action.payload;
    const normalizedParams = normalizeParams({ ...params });
    const endpoint = `${environment.api.apiBaseUrl}/api/v2/products`;

    const response = yield call(get, endpoint, {
      params: {
        pagination: false,
        ...normalizedParams,
      },
    });

    if (response.status === 200) {
      yield put(normalizeData(response.data.data.docs, productsSchema));
      yield put(
        getSpacesSuccess(response.data.data.docs.map((doc) => doc._id))
      );
    }
  } catch (error) {
    yield put(getSpacesFailure(error));
  }
}

function* handleGetSpaceDetail(action) {
  try {
    const { spaceId } = action.payload;
    const endpoint = `${environment.api.apiBaseUrl}/api/product/${spaceId}`;

    const response = yield call(get, endpoint);

    if (response.status === 200) {
      yield put(normalizeData(response.data.data, productSchema));
      yield put(getSpaceDetailSuccess(response.data.data._id));
    }
  } catch (error) {
    yield put(getSpaceDetailFailure(error));
  }
}

export default function* spacesSaga() {
  yield all([
    takeLatest(GET_SPACES_REQUEST, handleGetSpaces),
    takeLatest(GET_SPACE_DETAIL_REQUEST, handleGetSpaceDetail),
  ]);
}
