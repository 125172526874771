const baseTheme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    primary: "#F34E00",
    red: "#F0515B",
    green: "#02a591",
    yellow: "#de9f0b",
    transparent: "transparent",
  },
  text: {
    gray: "#727272",
    disabled: "#999",
    darkGray: "#444444",
  },
  background: {
    dark: "#232323",
    highlightBackground: "#d8f0f2",
  },
  borders: {
    gray: "#dad8d8a1",
    light: "#e5e5e5a1",
  },
  size: {
    fontSize: 18,
  },
};

export default baseTheme;
