import React from "react";
import PropTypes from "prop-types";
import Container from "../../../../components/Container/Container";
import Skeleton from "antd/es/skeleton";
import SkeletonContainer from "../../../../components/Skeleton/SkeletonContainer";
import HostingCard from "../../../../components/HostingCard/HostingCard";
import { HostingContent } from "../styles/Styles";
import { Wrapper } from "../../../HomePage/components/FeaturedExperiences/styles/FeaturedExperiences.styles";

const SpacesContent = ({ spaces, loading }) => {
  if (loading) {
    return (
      <Container>
        <SkeletonContainer>
          <Skeleton active={true} />
          <Skeleton active={true} />
        </SkeletonContainer>
      </Container>
    );
  }

  return (
    <Wrapper>
      <HostingContent>
        {spaces.map((space) => {
          return (
            <HostingCard
              images={space?.images}
              title={space?.title}
              subtitle={space?.subtitle}
              activity={space?.activity}
              location={space?.location}
              price={space?.price}
              duration={space?.duration}
              difficulty={space?.difficulty}
              currency={space?.currency}
              id={space._id}
            />
          );
        })}
      </HostingContent>
    </Wrapper>
  );
};

SpacesContent.propTypes = {
  spaces: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      activity: PropTypes.arrayOf(PropTypes.string),
      location: PropTypes.string,
      price: PropTypes.shape({
        adultPrice: PropTypes.number,
      }),
      duration: PropTypes.shape({
        unit: PropTypes.string,
        value: PropTypes.number,
      }),
      difficulty: PropTypes.string,
      languages: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};
export default SpacesContent;
