import React, { useEffect } from "react";
import styled from "styled-components";
import LayoutWithDataContainer from "../../containers/LayoutWithDataContainer";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../Redux/Selectors/appConfigSelector";
import WinterQuote from "./winter/WinterQuote";
import Footer from "./components/Footer/Footer";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../services/mixpanel";
import { getTransactionStatus } from "../ShopPage/redux/selectors/TransactionStatusSelectors";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import usePeriod from "../../hooks/usePeriod";

const Container = styled.div`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  min-height: 60vh;
`;

const QuoteScreen = ({ isIframe }) => {
  const navigate = useNavigate();
  usePeriod();
  const supplierData = useSelector(getSupplierData);
  const transactionStatus = useSelector(getTransactionStatus);

  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "QuotePage",
      supplier: supplierData.name,
      supplierId: supplierData._id,
    });
  }, []);

  useEffect(() => {
    if (transactionStatus.status === "PREAUTHORIZED") {
      navigate(
        `/transaction/${transactionStatus._id}/pay?customerId=${transactionStatus.data.guest}`,
        { replace: true }
      );
    }
  }, [transactionStatus]);

  return (
    <LayoutWithDataContainer isIframe={isIframe}>
      <Container>
        <WinterQuote />
        <Footer />
      </Container>
      <ScrollRestoration />
    </LayoutWithDataContainer>
  );
};

export default QuoteScreen;
