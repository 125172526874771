import React from "react";
import PropTypes from "prop-types";
import Text from "antd/es/typography/Text";
import { useTranslation } from "react-i18next";
import { VariantContainer } from "./GearItem.styles";

const GearItem = ({ title, properties, bundleProducts }) => {
  const { t } = useTranslation("shop");
  const renderVariantProduct = (item) => {
    return item.properties.map((prop) => (
      <span style={{ marginLeft: "10px" }}>
        ({t(prop.label)}: {prop.value})
      </span>
    ));
  };
  return (
    <div style={{ marginBottom: 10 }}>
      <Text strong>{title}</Text>
      {bundleProducts &&
        bundleProducts.map((item, index) => (
          <div key={index} style={{ marginLeft: "10px" }}>
            <Text type="secondary">{item.title}</Text>
            {renderVariantProduct(item)}
          </div>
        ))}
      <VariantContainer>
        {properties.map(({ label, value }, index) => (
          <div key={index}>
            <Text style={{ fontWeight: 600 }}>{t(label)}:</Text>{" "}
            <Text>{value}</Text>
          </div>
        ))}
      </VariantContainer>
    </div>
  );
};

GearItem.defaultProps = {
  properties: [],
};

GearItem.propTypes = {
  title: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

export default GearItem;
