import React, { createContext, useReducer } from "react";
import { dateOfBirthReducer, DEFAULT_VALUES } from "./DateOfBirthReducer";
import { SET_DATE_OF_BIRTH } from "./constants";

export const DateOfBirthContext = createContext(DEFAULT_VALUES);

export const DateOfBirthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(dateOfBirthReducer, DEFAULT_VALUES);

    const setDateOfBirth = (value, key) => {
        dispatch({ type: SET_DATE_OF_BIRTH, payload: { value, key } });
    };

    return (
        <DateOfBirthContext.Provider
            value={{
                setDateOfBirth,
                dateOfBirth: { day: state.day, month: state.month, year: state.year },
            }}
        >
            {children}
        </DateOfBirthContext.Provider>
    );
};