export const SET_NORMALIZED_DATA = "SET_NORMALIZED_DATA";
export const NORMALIZE_DATA = "NORMALIZE_DATA";

export const normalizeData = (data, schema) => {
  return {
    type: NORMALIZE_DATA,
    payload: {
      data,
      schema,
    },
  };
};

export const setNormalizedData = (data, schemaKey) => {
  return {
    type: SET_NORMALIZED_DATA,
    payload: {
      data,
      schemaKey,
    },
  };
};
