import React, { useEffect } from "react";
import MainMemberForm from "../forms/MainMemberForm";
const MainMemberScreen = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <MainMemberForm {...props} />;
};

export default MainMemberScreen;
