import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 64px);
`;

export const ContainerHeader = styled.div`
  background: ${({ bgColor }) => bgColor};
  margin-top: 50px;
  width: 70px;
  height: 70px;
  padding: 7px;
  border-radius: 50%;
  border: 5px solid white;
`;

export const ContainerBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  gap: 10px;
`;

export const ContainerFooter = styled.div`
  text-align: end;
  margin-right: 15px;
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;
