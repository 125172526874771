import { schema } from "normalizr";

export const bookingSchema = new schema.Entity(
  "bookings",
  {},
  {
    idAttribute: "_id",
  }
);

export const bookingsSchema = [bookingSchema];
