import Text from "antd/es/typography/Text";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { formatAmount } from "../../utils";
import { getCurrencyConfig, renderPrice } from "../../../../helpers/tools";

const Item = styled.div``;

const PromoItem = ({
  amount,
  periodAmount,
  periodType,
  operation,
  type,
  currency,
  price,
}) => {
  const { t } = useTranslation(["shop", "common"]);
  return (
    <Item>
      <Text type="secondary">
        {t("promotions.moreThan")} {t(periodType, { count: periodAmount })}
      </Text>{" "}
      -&gt;{" "}
      <Text strong>
        {formatAmount(amount, type, currency)} {t(`common:${operation}`)}{" "}
      </Text>
      <Text strong style={{ fontSize: 16 }} type="success">
        ({getCurrencyConfig(currency).symbol}
        {renderPrice(price - (amount / 100) * price, currency)}{" "}
        {t(`common:byPeriods.per_${periodType}`)})
      </Text>
    </Item>
  );
};

export default PromoItem;
