import React from "react";
import Search from "antd/es/input/Search";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
`;

const SearchBarProducts = ({ handleChange }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Search
        placeholder={t("searchProduct")}
        onChange={(e) => handleChange(e.target.value)}
        style={{ width: "100%", maxWidth: "675px", padding: 10 }}
      />
    </Container>
  );
};
export default SearchBarProducts;
