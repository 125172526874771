import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";

export const ReviewsContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 30px;

  @media ${mediaQueries.mobile} {
    grid-template-columns: 1fr;
  }

  @media ${mediaQueries.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${mediaQueries.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
