import { isEmpty, isNil, uniq } from "lodash";
import { EXPECTED_SORT_CATEGORIES } from "./constants";
import { ANY_NUMBERS, ONLY_NUMBERS_WITHOUT_ZERO } from "./constants";

export const groupReferrals = (referrals = []) => {
  const groupedReferrals = {};

  referrals?.forEach((referral) => {
    const { category, name, _id } = referral;
    if (!groupedReferrals[category]) {
      groupedReferrals[category] = [{ name, _id }];
    } else {
      const existingReferral = groupedReferrals[category].find(
        (r) => r.name === name
      );
      if (!existingReferral) {
        groupedReferrals[category].push({ name, _id });
      }
    }
  });

  return groupedReferrals;
};

export const getInitialReferral = (
  groupedReferrals = {},
  selectedCategory,
  referralName
) => {
  if (
    isEmpty(groupedReferrals) ||
    isNil(selectedCategory) ||
    isNil(referralName)
  ) {
    return null;
  }
  return (
    groupedReferrals[selectedCategory]?.find(
      (referral) => referral.name === referralName
    ) ?? null
  );
};

export const referralIdentifier = (referralCategory, referral) => {
  const isReferralId = /^[A-Za-z]+$/.test(referralCategory);
  return isReferralId ? referral : referralCategory;
};

export const sanitizeReferrals = (referrals = []) => {
  if (isEmpty(referrals)) return null;
  return referrals?.filter((referral) => referral?.visibleOnWebsite);
};

export const sortCategories = (categories = {}) => {
  const sortedCategories = Object.keys(categories)
    .sort((a, b) => {
      if (a === EXPECTED_SORT_CATEGORIES.HOTEL) return -1;
      if (b === EXPECTED_SORT_CATEGORIES.HOTEL) return 1;
      return a.localeCompare(b);
    })
    .filter((category) => category !== EXPECTED_SORT_CATEGORIES.HOTEL);

  if (categories[EXPECTED_SORT_CATEGORIES.HOTEL]) {
    sortedCategories.unshift(EXPECTED_SORT_CATEGORIES.HOTEL);
  }

  return sortedCategories;
};

export const parseMembers = (group = {}) => {
  const { adminMustIncluded, members } = group;
  if (adminMustIncluded) {
    return uniq([...members, group.admin]);
  }
  return uniq(members);
};

export const parseGroups = (groups = []) => {
  return groups.map((group) => ({
    ...group,
    members: parseMembers(group),
  }));
};

export const branchOptionsGenerator = (groups, supplierData) => {
  if (!Array.isArray(groups) || isEmpty(groups)) return [];
  let options = groups.map((group, index) => {
    const { title, description, members } = group;
    const memberOptions = members.map((member) => {
      return {
        value: member._id,
        label: member.name,
        group: group._id,
        key: `${member._id}-${index}`,
      };
    });
    return {
      label: `${title} ${description ?? ""}`,
      group: group._id,
      options: [...memberOptions],
      key: `${group._id}-${index}`,
    };
  });
  if (supplierData) {
    options.unshift({
      value: supplierData._id,
      label: supplierData.name,
    });
  }
  return options;
};

export const nameOfPassengers = (passengersData) => {
  const arrayNames = (passengersData?.names ?? []).map((name) => name?.name);
  return arrayNames;
};

/**
 * Generates an object with passenger data from an array of names.
 * @param {Array<{
 *   uniqueId: number,
 *   name: string,
 * }>} passengersData - Array of objects containing passenger details.
 * @returns {Array} Object with passenger data.
 */
export const generatePassengers = (passengersData) => {
  return passengersData.reduce((acc, passenger, i) => {
    acc[i] = {
      id: passenger.uniqueId,
      name: passenger.name,
    };
    return acc;
  }, {});
};

/**
 * Generates an array of passenger details from an array of passenger Details with details.
 *
 * @param {Array<{
 *   uniqueId: number,
 *   experience: string,
 *   footLength: object,
 *   weight: object,
 *   height: object,
 * }>} passengersDetails - Array of objects containing passenger details.
 * @returns {Array} Array of passenger details.
 */
export const generatePassengerDetails = (passengersDetails = []) => {
  return passengersDetails.map((passenger) => ({
    id: passenger.uniqueId || 0,
    experience: passenger.experience,
    footLength: passenger.footLength,
    height: passenger.height,
    weight: passenger.weight,
  }));
};

export const validateHeight = (t) => (_, value) => {
  if (isNil(value) || value === "") {
    return Promise.reject(t("shop:form.error", { field: t("height") }));
  }
  if (!ANY_NUMBERS.test(value)) {
    return Promise.reject(t("shop:form.onlyNumbers", { field: t("height") }));
  }
  if (
    value &&
    (!ONLY_NUMBERS_WITHOUT_ZERO.test(value) ||
      parseInt(value, 10) < 90 ||
      parseInt(value, 10) > 220)
  ) {
    return Promise.reject(
      t("shop:form.errorHeightRange", { field: t("height") })
    );
  }
  return Promise.resolve();
};

export const validateWeight = (t) => (_, value) => {
  if (isNil(value) || value === "") {
    return Promise.reject(t("shop:form.error", { field: t("weight") }));
  }
  if (!ANY_NUMBERS.test(value)) {
    return Promise.reject(t("shop:form.onlyNumbers", { field: t("weight") }));
  }

  if (
    value &&
    (!ONLY_NUMBERS_WITHOUT_ZERO.test(value) || parseInt(value, 10) <= 0)
  ) {
    return Promise.reject(
      t("shop:form.errorWeightRange", { field: t("weight") })
    );
  }
  return Promise.resolve();
};
