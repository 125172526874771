import React from "react";
import { Button as ButtonStyled } from "./Button.styles";

const Button = ({ children, ...restProps }) => {
  return <ButtonStyled {...restProps}>{children}</ButtonStyled>;
};

Button.defaultProps = {
  shape: "round",
};

Button.propTypes = {
  ...ButtonStyled.propTypes,
};

export default Button;
