import { Button } from "antd";
import styled from "styled-components";
import baseTheme from "../../../../config/baseTheme";

export const Section = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
`;

export const CategoryButton = styled(Button)`
  align-self: start;
  width: 100%;
  text-align: left;
  margin: 0;
  border-bottom: 1px solid ${baseTheme.borders.gray};
  border-radius: 0;
  height: auto;
  padding: 8px 0;
  white-space: normal;
  word-break: break-word;
`;
