import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import BaseModal from "../../../../components/BaseModal/BaseModal";
import SummaryCard from "../SummaryCard/SummaryCard";
import { listTypes } from "../../utils";
import SummaryExperienceCard from "../../../ExperiencesPage/components/SummaryExperienceCard/SummaryExperienceCard";
import SummarySpaceCard from "../../../SpacesPage/components/SummarySpaceCard/SummarySpaceCard";

const StyledSummaryCard = styled(SummaryCard)`
  margin-bottom: 20px;
`;
const StyledSummaryExperienceCard = styled(SummaryExperienceCard)`
  margin-bottom: 20px;
`;

const StyledSummarySpaceCard = styled(SummarySpaceCard)`
  margin-bottom: 20px;
`;

const DetailCheckoutModal = ({
  open,
  onCancel,
  onContinue,
  passengers,
  isLoading,
  bookingType = listTypes.PRODUCT,
  description,
}) => {
  const { t } = useTranslation(["common", "shop"]);
  return (
    <BaseModal
      open={open}
      title={t("shop:checkout.detailModalTitle")}
      description={description}
      textCancel={null}
      onOk={onContinue}
      onCancel={onCancel}
      isLoading={isLoading}
      showFooter
      textOk={t("common:continue")}
      maskClosable={false}
    >
      {passengers.map((passengerProps, index) => {
        if (bookingType === listTypes.EXPERIENCE) {
          return (
            <StyledSummaryExperienceCard key={index} {...passengerProps} />
          );
        }
        if (bookingType === listTypes.SPACE) {
          return (
            <StyledSummarySpaceCard key={index} passenger={passengerProps} />
          );
        }
        return <StyledSummaryCard key={index} {...passengerProps} />;
      })}
    </BaseModal>
  );
};

DetailCheckoutModal.defaultProps = {
  open: false,
  passengers: [],
};

DetailCheckoutModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  passengers: PropTypes.arrayOf(
    PropTypes.shape(
      PropTypes.oneOfType([
        SummaryCard.propTypes,
        SummaryExperienceCard.propTypes,
      ])
    )
  ),
};

export default DetailCheckoutModal;
