import { Button } from "antd";
import styled from "styled-components";

export const Container = styled(Button)`
  border: 2px solid #f3f3f3;
  border-radius: 45px;
  height: 30px;
  width: auto;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding: 7px;
  padding-left: 9px;
  gap: 8px;
  cursor: pointer;
`;
