import axios from "axios";

export const get = async (url, params = {}) => {
  return axios
    .get(url, params)
    .then((response) => response)
    .catch((err) => err.response);
};

export const post = async (url, data, config) => {
  try {
    const res = await axios.post(url, data, config);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export const update = async (url, data, config) => {
  try {
    const res = await axios.put(url, data, config);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export const patch = async (url, data, config) => {
  try {
    const res = await axios.patch(url, data, config);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export const deleteRequest = async (url, data = {}, config) => {
  try {
    const res = await axios.delete(url, config);
    return res.data;
  } catch (err) {
    console.log(err.response);
  }
};
