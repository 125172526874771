import { SET_SUBCRIPTION_SUPPLIER } from "../Actions/subscriptionActions";

const INITIAL_STATE = {
  subscription: null,
};

export const subscriptionReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_SUBCRIPTION_SUPPLIER: {
      return {
        ...state,
        subscription: payload.subscription,
      };
    }
    default:
      return state;
  }
};

export default subscriptionReducer;
