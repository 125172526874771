import React from "react";
import HeaderItinerary from "./components/HeaderItinerary/HeaderItinerary";
import DetailsExperience from "./components/InfoItinerary/DetailsExperience";
import { Container } from "./Itinerary.styles";
import PropTypes from "prop-types";
import ItinerarySection from "./components/InfoItinerary/ItinerarySection/ItinerarySection";
import { Divider } from "antd";

const Itinerary = ({ infoExperience }) => {
  return (
    <Container>
      {/* TODO: Refactor when jsonExperiences.experiences is deleted */}
      {infoExperience?.hardcoded ? (
        <>
          {infoExperience?.metadata?.infoHours && (
            <>
              <HeaderItinerary
                from={infoExperience?.metadata?.infoHours?.from}
                to={infoExperience?.metadata?.infoHours?.to}
              />
            </>
          )}
          {infoExperience &&
            infoExperience?.metadata?.days.map((item) => (
              <DetailsExperience experienceDetails={item} />
            ))}
        </>
      ) : (
        infoExperience?.metadata?.map((data, index) => (
          <div key={`${data?.title}-${index}`}>
            <Divider />
            <ItinerarySection sections={data} />
          </div>
        ))
      )}
    </Container>
  );
};

export default Itinerary;

Itinerary.propTypes = {
  infoExperience: PropTypes.object,
};
