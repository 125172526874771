import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getVariantsFromProductRequest } from "../features/ShopPage/redux/actions/shopActions";
import { getVariantsFromAllProducts } from "../features/ShopPage/redux/selectors/ShopSelectors";

const useVariants = (productId) => {
  const dispatch = useDispatch();
  const variants = useSelector(
    (state) => getVariantsFromAllProducts(state, productId),
    shallowEqual
  );
  useEffect(() => {
    dispatch(getVariantsFromProductRequest(productId));
  }, [JSON.stringify(productId)]);

  return variants;
};

export default useVariants;
