import styled from "styled-components";
import mediaQueries from "../../../../../../../../utils/mediaQueries";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  @media ${mediaQueries.largeDesktop} {
    width: 63%;
  }
`;
