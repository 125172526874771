import {
  SEARCH_CITIES_FAILURE,
  SEARCH_CITIES_REQUEST,
  SEARCH_CITIES_SUCCESS,
} from "./CityContext";

const INITIAL_STATE = {
  cities: [],
  fetching: false,
  error: false,
};

const CityReducer = (state, action) => {
  const { payload: { results, error } = {} } = action;
  switch (action.type) {
    case SEARCH_CITIES_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case SEARCH_CITIES_SUCCESS:
      return {
        ...state,
        cities: results,
        fetching: false,
        error: false,
      };
    case SEARCH_CITIES_FAILURE:
      return {
        ...state,
        cities: results,
        fetching: false,
        error,
      };
    default:
      return state;
  }
};

export { INITIAL_STATE, CityReducer };
