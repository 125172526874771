import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import TypographyText from "antd/es/typography/Text";

export const IconWrapper = styled.span`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary};

  @media ${mediaQueries.desktop} {
    font-size: 40px;
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  padding: 20px;
  max-width: 1150px;
  width: 90%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  position: absolute;
  z-index: 0;
  top: -30px;
`;

export const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 250px;
`;

export const RightArrow = styled.div`
  display: none;

  @media ${mediaQueries.desktop} {
    display: flex;
    align-items: center;
  }
`;

export const Text = styled(TypographyText)`
  font-size: 14px;

  @media ${mediaQueries.desktop} {
    font-size: 18px;
  }
`;
