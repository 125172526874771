import { schema } from "normalizr";
import { productSchema } from "./productSchema";

export const variantSchema = new schema.Entity(
  "variants",
  { product: productSchema },
  {
    idAttribute: "_id",
  }
);

export const variantsSchema = [variantSchema];
