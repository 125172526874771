import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowRight } from "../../components/Icons/chevronRight.svg";
import { ReactComponent as ArrowLeft } from "../../components/Icons/chevronLeft.svg";
import Button from "antd/es/button";

export const HorizontalScroll = ({ children, config }) => {
  const isMobile = false;
  const {
    content,
    element,
    behavior = "smooth",
    minLength = 0,
    scrollPerView = 1,
  } = config;
  const [maxScroll, setMaxScroll] = useState(true);
  const [configScrollTo, setConfigScrollTo] = useState({
    left: 0,
    behavior,
  });

  useEffect(() => {
    if (element) {
      const reset = { ...configScrollTo, left: 0 };
      element.scrollTo({ ...reset });
      setMaxScroll(!isMobile);
      setConfigScrollTo({ ...reset });
    }
  }, [content, isMobile]);

  if (!element) return children;

  const scrollWidthFragment = element.scrollWidth / scrollPerView;
  const display = content.length >= minLength || isMobile ? "block" : "none";
  const handleScrollCategory = (side) => {
    const setUpConfig = (obj, px) => {
      return (obj = {
        ...obj,
        left: obj.left + Number(px),
      });
    };
    let config = setUpConfig(configScrollTo, side);
    setConfigScrollTo(config);
    element.scrollTo({
      ...config,
    });
    if (config.left && element.scrollWidth - config.left <= window.innerWidth) {
      element.scrollTo({
        ...config,
        left: element.scrollWidth,
      });
      return setMaxScroll(false);
    }
    setMaxScroll(true);
  };

  return (
    <>
      <Button
        style={{
          border: "none",
          display,
        }}
        shape="circle"
        size="medium"
        ghost
        disabled={isMobile || !configScrollTo.left}
        onClick={() => handleScrollCategory(-scrollWidthFragment)}
      >
        <ArrowLeft
          style={{ color: `${configScrollTo.left ? "black" : "grey"}` }}
        />
      </Button>
      {children}
      <Button
        style={{
          border: "none",
          display,
        }}
        shape="circle"
        size="medium"
        ghost
        disabled={isMobile || !maxScroll}
        onClick={() => handleScrollCategory(scrollWidthFragment)}
      >
        <ArrowRight style={{ color: `${maxScroll ? "black" : "grey"}` }} />
      </Button>
    </>
  );
};
