import { flatten } from "lodash";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { parseApiDate, toDateFromUTC } from "./dates";

const moment = extendMoment(Moment);

export const listRestrictedDates = (restrictedDates = []) => {
  const disabledDates = restrictedDates.map((restringedRange) => {
    const range = moment.range(
      parseApiDate(restringedRange.from),
      parseApiDate(restringedRange.to)
    );
    return Array.from(range.by("day")).map((date) => toDateFromUTC(date));
  });
  return flatten(disabledDates);
};
