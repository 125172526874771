export const PERSONAL_ID_TYPES = {
  DNI: "dni",
  INE: "ine",
  RUT: "rut",
  PASSPORT: "passport",
  DRIVER_LICENSE: "driver_license",
};

export const BUSINESS_ID_TYPES = {
  CUIT: "cuit",
  RUT: "rut",
};

export const CUSTOMER_TYPE = {
  BUSINESS: "business",
  PERSONAL: "personal",
};
