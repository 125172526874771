import { es, ptBR, enGB } from "date-fns/locale";
import { isEmpty } from "lodash";
import { createUTCDate } from "../../helpers/dates";

export const getCalendarLanguage = (language) => {
  es.options.weekStartsOn = 0;
  const languageMap = {
    "pt-BR": ptBR,
    "en-GB": enGB,
  };
  return languageMap[language] || es;
};

export const filterAvailableDates = (date, availableDates) => {
  if (isEmpty(availableDates)) return null;
  const availabilityInfo = availableDates.find(
    (item) => item.toDateString() === date.toDateString()
  );
  return availabilityInfo;
};

export const filterDisabledDates = (disabledDates) => {
  if (isEmpty(disabledDates) || !disabledDates) return [];
  const disabledInfo = disabledDates.filter((item) => {
    return createUTCDate(item).toDate();
  });
  return disabledInfo;
};
