import {
  GET_REVIEWS_FAILURE,
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
} from "../actions/reviewsActions";

const INITIAL_STATE = {
  reviews: [],
  loading: false,
  success: false,
  error: false,
};

export const reviewsReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case GET_REVIEWS_REQUEST: {
      return {
        results: [],
        loading: true,
        success: false,
        error: false,
      };
    }
    case GET_REVIEWS_SUCCESS: {
      return {
        results: payload.results,
        loading: false,
        success: true,
        error: false,
      };
    }
    case GET_REVIEWS_FAILURE: {
      return {
        results: [],
        loading: false,
        success: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export default reviewsReducer;
