import styled from "styled-components";
import Title from "antd/es/typography/Title";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

export const Amount = styled(Title)`
  margin: 0;
  margin-bottom: 0 !important;
`;
