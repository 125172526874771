import {
  GET_PRESALE_BY_ID_REQUEST,
  GET_PRESALE_BY_ID_SUCCESS,
  GET_PRESALE_BY_ID_FAILURE,
} from "./PreSaleActions";

export const INITIAL_STATE = {
  fetching: false,
  error: false,
  data: null,
};

const preSaleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRESALE_BY_ID_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case GET_PRESALE_BY_ID_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        data: action.payload.result,
      };
    case GET_PRESALE_BY_ID_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return INITIAL_STATE;
  }
};

export default preSaleReducer;
