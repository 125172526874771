import React from "react";
import LayoutWithDataContainer from "../../containers/LayoutWithDataContainer";
import Container from "../../components/Container/Container";
import CheckinContainer from "./containers/CheckinContainer";
import { Wrapper } from "./CheckinScreen.styles";
import Router from "./router/Router";
import Navbar from "./components/NavBar";
import FormSteps from "./components/Steps";
import { Divider } from "antd";

const CheckInScreen = ({ isCheckin }) => {
  return (
    <LayoutWithDataContainer isCheckin={isCheckin}>
      <Container>
        <Navbar />
        <Wrapper>
          <FormSteps />
          <Divider />
          <CheckinContainer>
            {({ onSubmitMainMember }) => (
              <Router onSubmitMainMember={onSubmitMainMember} />
            )}
          </CheckinContainer>
        </Wrapper>
      </Container>
    </LayoutWithDataContainer>
  );
};

export default CheckInScreen;
