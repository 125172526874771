import { shallowEqual, useSelector } from "react-redux";
import {
  BUSINESS_ID_TYPES,
  PERSONAL_ID_TYPES,
} from "../components/FormInputs/constants";
import { getSupplierData } from "../Redux/Selectors/appConfigSelector";

export const useSupplierConfig = () => {
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const config = {
    Chile: {
      createCustomer: {
        phonePrefix: "+56",
        phonePrefixByRegion: "+56-CL",
        personalIdType: PERSONAL_ID_TYPES.RUT,
        nationality: "Chile",
      },
      createCompany: {
        phonePrefix: "+56",
        companyIdType: BUSINESS_ID_TYPES.RUT,
        nationality: "Chile",
      },
    },
    Argentina: {
      createCustomer: {
        phonePrefix: "+54",
        phonePrefixByRegion: "+54-AR",
        personalIdType: PERSONAL_ID_TYPES.DNI,
        nationality: "Argentina",
      },
      createCompany: {
        phonePrefix: "+54",
        phonePrefixByRegion: "+54-AR",
        companyIdType: BUSINESS_ID_TYPES.CUIT,
        nationality: "Argentina",
      },
    },
    default: {
      createCustomer: {
        phonePrefix: "+54",
        phonePrefixByRegion: "+54-AR",
        personalIdType: PERSONAL_ID_TYPES.DNI,
        nationality: "Argentina",
      },
      createCompany: {
        phonePrefix: "+54",
        phonePrefixByRegion: "+54-AR",
        companyIdType: BUSINESS_ID_TYPES.CUIT,
        nationality: "Argentina",
      },
    },
  };

  const configuration = config[supplierData.country]
    ? config[supplierData.country]
    : config.default;

  return configuration;
};
