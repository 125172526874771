import React from "react";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../Redux/Selectors/appConfigSelector";
import { Button } from "antd";
import styled from "styled-components";
import { WhatsAppOutlined } from "@ant-design/icons";
import { clickEvents, trackEvent } from "../../services/mixpanel";
import useIsMobile from "../../hooks/useIsMobile";
import { generateUrl } from "./helpers";

const StyledButton = styled(Button)`
  background-color: #59ce72;
  color: white;
  border-color: #59ce72;
  ${({ ghost }) =>
    ghost &&
    `color: #59ce72 !important;
    box-shadow:none;`}
  padding-top: 3px;
  padding-bottom: 3px;
  &:hover {
    background-color: #35897e !important;
    color: white !important;
    border-color: #35897e !important;
  }
  &:focus {
    background-color: #59ce72;
    color: white;
    border-color: #59ce72;
  }
`;

const Container = styled.div`
  width: ${({ mobile }) => (mobile ? "25px" : "60px")};
  height: ${({ mobile }) => (mobile ? "25px" : "60px")};
  border-radius: ${({ mobile }) => (mobile ? "12px" : "30px")};
  background-color: #59ce72;
  justify-content: center;
  align-items: center;
  display: flex;
  -webkit-box-shadow: 0px 3px 18px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 3px 18px -4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 3px 18px -4px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
`;

const Float = styled.div`
  position: fixed;
  right: 20px;
  bottom: 50px;
`;

const WhatsAppFloat = () => {
  const supplierData = useSelector(getSupplierData);
  const isMobile = useIsMobile();

  const handleOnClick = () => {
    trackEvent(clickEvents.CLICK_WHATSAPP, {
      supplier: supplierData.name,
    });
  };

  return (
    <Float>
      <a
        href={generateUrl(supplierData?.phone)}
        target={"_blank"}
        rel="noreferrer"
      >
        <Container mobile={isMobile}>
          <StyledButton
            icon={
              <WhatsAppOutlined
                style={{
                  fontSize: isMobile ? 20 : 25,
                  marginTop: isMobile ? 4 : 1,
                }}
              />
            }
            shape="circle"
            size="large"
            onClick={handleOnClick}
          />
        </Container>
      </a>
    </Float>
  );
};

export default WhatsAppFloat;
