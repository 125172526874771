export const displayType = Object.freeze({
  UNIVERSAL: "UNIVERSAL",
  WINTER: "WINTER",
});

export const urlVisualization = Object.freeze({
  UNIVERSAL: "/shop",
  WINTER: "/quote",
});

export const matchVisualization = (displayType) => {
  if (!displayType) return urlVisualization.UNIVERSAL;
  else if (urlVisualization[displayType]) {
    return urlVisualization[displayType];
  } else {
    return urlVisualization.UNIVERSAL;
  }
};
