import styled, { css } from "styled-components";
import TitleComponent from "antd/es/typography/Title";
import TextAntd from "antd/es/typography/Text";
import { lighten } from "polished";

export const Wrapper = styled.section`
  padding: 50px 40px;
  background-color: ${({ dark, theme }) =>
    dark ? theme.background.dark : theme.colors.white};
`;

export const TitleContainer = styled.div`
  max-width: 1150px;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-wrap: wrap;
  gap: 20px;
`;

export const Title = styled(TitleComponent)`
  display: inline-block;
  position: relative;
  margin: 0 !important;
  font-weight: 400 !important;
  font-size: 40px !important;
  font-family: "Francois One", sans-serif;
  text-transform: uppercase;
  ${({ dark, theme }) =>
    dark
      ? css`
          color: ${theme.colors.white} !important;
        `
      : css`
          color: ${theme.text.darkGray} !important;
        `}
`;

export const Text = styled(TextAntd)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.primary};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }
`;
