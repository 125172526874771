import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  padding: 10px;
  border-radius: 10px;
`;
