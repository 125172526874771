import { CreditCardOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import styled from "styled-components";
import React from "react";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MakePaymentModal = ({
  visible,
  pricingData,
  isBookingFetching,
  onRequestBooking,
  handleOnCancel,
}) => {
  const { t } = useTranslation();

  const checkAuth = (provider) => {
    onRequestBooking(provider);
  };

  return (
    <Modal
      open={visible}
      title={t("checkout_pickPayment")}
      footer={null}
      centered
      onCancel={handleOnCancel}
    >
      <Container>
        <Title level={5}>
          Total: {pricingData?.currency.symbol} {pricingData?.total}
        </Title>
        <Button
          size="large"
          loading={isBookingFetching}
          icon={<CreditCardOutlined />}
          type="primary"
          shape="round"
          style={{ width: "100%" }}
          onClick={() => checkAuth()}
          data-testid="checkout-creditcard-button"
        >
          {t("bookIt")}
        </Button>
      </Container>
    </Modal>
  );
};

export default MakePaymentModal;
