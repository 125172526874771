import styled, { css } from "styled-components";
import breakpoints from "../../../../../../utils/breakpoints";
import Button from "antd/es/button";

export const StyledColumns = styled.div`
  display: grid;
  overflow: hidden;
  border-radius: 25px;
  grid-template-columns: ${({ numColumns }) =>
    `repeat(${numColumns}, calc(${breakpoints.largeDesktop}px / ${numColumns}))`};
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const StyledRows = styled.div`
  display: grid;
  height: 45vh;
  overflow: hidden;
  grid-template-rows: ${({ rows }) =>
    rows > 1 ? `repeat(${rows}, calc(45vh/ ${rows} ))` : "100%"};
  gap: 5px;
`;

export const ArrowContainer = styled.div`
  position: absolute;
  z-index: 999;
  width: 40px;
  padding: 20px;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ oriented }) =>
    oriented === "left"
      ? css`
          left: 20px;
        `
      : css`
          right: 20px;
        `}
`;

export const ImageCounter = styled.span`
  font-size: 16px;
  color: white;
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-weight: 600;
`;

export const GalleryButton = styled(Button)`
  position: absolute;
  bottom: 3%;
  right: 3%;
`;
