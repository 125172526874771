import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getExperienceDetail,
  getExperienceDetailIsFetching,
} from "../../redux/selectors/ExperiencesSelector";
import { getExperienceDetailRequest } from "../../redux/actions/ExperiencesActions";
import { getConvertedPrice } from "../../list/helpers/helpers";
import { getSpExperiencePromotion } from "../../../../helpers/rentals";
import useWebsiteCurrency from "../../../../hooks/useWebsiteCurrency";
import useWebsiteRegion from "../../../../hooks/useWebsiteCountry";

const ExperienceDetailContainer = ({ children, experienceId, supplierId }) => {
  const dispatch = useDispatch();
  const { currency } = useWebsiteCurrency();
  const { region } = useWebsiteRegion();
  const experience = useSelector((state) =>
    getExperienceDetail(state, experienceId)
  );
  const isFetching = useSelector(getExperienceDetailIsFetching);
  const spExperiencePromotion = getSpExperiencePromotion(supplierId, region);

  useEffect(() => {
    dispatch(getExperienceDetailRequest(experienceId, currency));
  }, []);

  return children({
    customPrice: getConvertedPrice(
      experience?._id,
      experience?.convertedPrice?.adultPrice,
      spExperiencePromotion
    ),
    experience,
    isFetching: isFetching,
  });
};

export default ExperienceDetailContainer;
