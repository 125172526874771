import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Form from "antd/es/form";
import Paragraph from "antd/es/typography/Paragraph";
import Text from "antd/es/typography/Text";
import Typography from "antd/es/typography/Typography";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import Button from "../../../../components/Button/Button";
import {
  MapWrapper,
  Container,
  PhoneSpace,
  TextArea,
  FormColumn,
  Map,
  AddressLink,
} from "./ContactUsContent.styles";
import { EnvironmentOutlined } from "@ant-design/icons";

const ContactUsContent = ({
  formInstance,
  onSendMessage,
  phoneNumbers,
  coordinates,
  map,
  address,
  ...restProps
}) => {
  const { t } = useTranslation("home");
  const { isDesktop } = useBreakpoints();

  return (
    <Container noPadding={!isDesktop} {...restProps}>
      <Row gutter={[{ xs: 0, sm: 0, md: 0, lg: 70, xl: 70, xxl: 70 }, 0]}>
        <Col span={24} lg={{ span: 14 }}>
          <MapWrapper>
            <Map {...map} />
          </MapWrapper>
          <AddressLink
            href={`https://www.google.com/maps/search/?api=1&query=${address}`}
            target="_blank"
            rel="noreferrer"
          >
            {<EnvironmentOutlined />} {address}
          </AddressLink>
        </Col>
        <FormColumn span={24} lg={{ span: 10 }}>
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Form
                form={formInstance}
                layout="vertical"
                requiredMark={false}
                onFinish={onSendMessage}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="message"
                      label={t("contactUs.sendUsAWhatsappMessage")}
                      required
                    >
                      <TextArea onPressEnter={(e) => e.preventDefault()} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="end">
                  <Form.Item dependencies={["message"]}>
                    {({ getFieldValue }) => {
                      const message = getFieldValue("message");
                      return (
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={isEmpty(message)}
                        >
                          {t("send", { ns: "common" })}
                        </Button>
                      );
                    }}
                  </Form.Item>
                </Row>
              </Form>
            </Col>
            {!isEmpty(phoneNumbers) && (
              <Col span={24}>
                <Typography align="center">
                  <Paragraph>{t("contactUs.orCallUs")}</Paragraph>
                  <Paragraph>
                    <PhoneSpace
                      split={"/"}
                      align="center"
                      wrap={true}
                      style={{ justifyContent: "center" }}
                    >
                      {phoneNumbers.map((phoneNumber, index) => (
                        <Text key={index}>{phoneNumber}</Text>
                      ))}
                    </PhoneSpace>
                  </Paragraph>
                </Typography>
              </Col>
            )}
          </Row>
        </FormColumn>
      </Row>
    </Container>
  );
};

ContactUsContent.defaultProps = {
  formInstance: null,
  phoneNumbers: [],
};

ContactUsContent.propTypes = {
  onSendMessage: PropTypes.func,
  formInstace: PropTypes.instanceOf(Form),
  phoneNumbers: PropTypes.arrayOf(PropTypes.string.isRequired),
  map: PropTypes.shape(Map.propTypes),
  address: PropTypes.string.isRequired,
};

export default ContactUsContent;
