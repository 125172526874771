import React, { useEffect, useState } from "react";
import { sanitizeSummaryCheckout } from "../../../helpers/tools";
import DetailCheckout from "../components/DetailCheckout/DetailCheckout";
import DetailCheckoutModal from "../components/DetailCheckoutModal/DetailCheckoutModal";
import LayoutWrapper from "../components/LayoutWrapper/LayoutWrapper";
import MakePaymentModal from "../components/MakePaymentModal/MakePaymentModal";
import ShopWrapper from "../components/ShopWrapper/ShopWrapper";
import CheckoutContainer from "../containers/CheckoutContainer/CheckoutContainer";
import OverlaySpiner from "../../../components/OverlaySpinner/OverlaySpinner";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../../hooks/useScrollToTop";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../../services/mixpanel";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import { ScrollRestoration } from "react-router-dom";
import PricingContainer from "../containers/PricingContainer/PricingContainer";
import { getTransactionFailed } from "../../TransactionPage/TransactionSelectors";
import { generateTransactionErrorMessage } from "../../TransactionPage/helpers";
import { DateOfBirthProvider } from "../../../components/DateOfBirthSelector/context/DateOfBirthContext";

const CheckoutScreen = ({ isIframe }) => {
  const { t } = useTranslation();
  const supplierData = useSelector(getSupplierData);
  const { error, errorMessage } = useSelector((state) =>
    getTransactionFailed(state)
  );
  const [showModal, setShowModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [buttonIsEnabled, setButtonIsEnabled] = useState(false);

  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "CheckoutPage",
      supplier: supplierData.name,
      supplierId: supplierData._id,
    });
  }, []);

  const handleOnCancelPayment = () => {
    setShowModal(false);
  };

  const handleContinue = () => {
    setShowSummaryModal(true);
  };

  const handleOnFormIsValid = (isValid) => {
    setButtonIsEnabled(isValid);
  };

  return (
    <LayoutWrapper isIframe={isIframe}>
      <PricingContainer>
        {({
          pricingData,
          isPricingFetching,
          discount: {
            handleApplyDiscount,
            handleRemoveDiscount,
            discountError,
          },
        }) => {
          const discountsProps = {
            onApplyDiscount: handleApplyDiscount,
            onRemoveDiscount: handleRemoveDiscount,
            discountError,
          };
          return (
            <CheckoutContainer>
              {({
                quoteSummary,
                checkoutSummary,
                handleOnRequestPayment,
                handleOnInfoSubmit,
                checkPassengers,
                isBookingFetching,
                supplierData,
                searchValues,
                itemsLength,
                selectedProducts,
              }) => {
                return (
                  <ShopWrapper
                    itemsLength={itemsLength}
                    pricingData={pricingData}
                    quoteSummary={quoteSummary}
                    selectedProducts={selectedProducts}
                    isPricingFetching={isPricingFetching}
                    buttonLabel={t("shop:continueAndPay")}
                    handleOnSummaryClick={handleContinue}
                    onFormIsValid={handleOnFormIsValid}
                    isButtonEnabled={buttonIsEnabled}
                  >
                    <ScrollToTop />
                    <DateOfBirthProvider>
                      <DetailCheckout
                        quoteSummary={quoteSummary}
                        checkoutSummary={checkoutSummary}
                        handleOnInfoSubmit={handleOnInfoSubmit}
                        supplierData={supplierData}
                        onContinue={handleContinue}
                        checkPassengers={checkPassengers}
                        pricingData={pricingData}
                        isPricingFetching={isPricingFetching}
                        itemsLength={itemsLength}
                        searchValues={searchValues}
                        discounts={discountsProps}
                        onFormIsValid={handleOnFormIsValid}
                        isButtonEnabled={buttonIsEnabled}
                      />
                    </DateOfBirthProvider>
                    <MakePaymentModal
                      visible={showModal}
                      onRequestBooking={handleOnRequestPayment}
                      pricingData={pricingData}
                      handleOnCancel={handleOnCancelPayment}
                      isBookingFetching={isBookingFetching}
                    />
                    <DetailCheckoutModal
                      open={showSummaryModal}
                      isLoading={isBookingFetching}
                      onContinue={handleOnRequestPayment}
                      onCancel={() => setShowSummaryModal(false)}
                      passengers={sanitizeSummaryCheckout(checkoutSummary)}
                      description={t("shop:checkout.detailModalDescription")}
                    />
                    <OverlaySpiner
                      isError={error}
                      errorMessage={generateTransactionErrorMessage(
                        errorMessage
                      )}
                      isLoading={isBookingFetching}
                      label={t("shop:creatingBooking")}
                    />
                  </ShopWrapper>
                );
              }}
            </CheckoutContainer>
          );
        }}
      </PricingContainer>
      <ScrollRestoration />
    </LayoutWrapper>
  );
};

export default CheckoutScreen;
