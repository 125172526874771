import { getSupplierGroups } from "../../../../Redux/Selectors/supplierGroupsSelector";
import { parseGroups } from "../../helpers/helpers";

export const getAllSupplierGroups = (state) => {
  const groupIds = state.pages.checkin.supplierGroups.results ?? [];
  const branchGroupDetail = getSupplierGroups(state, groupIds);
  return parseGroups(branchGroupDetail);
};

export const supplierGroupsFetching = (state) => {
  return state.pages.checkin.supplierGroups.fetching;
};
