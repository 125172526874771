import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getWebsiteLanguage } from "../Redux/Selectors/languageSelector";
import { setWebsiteLanguage } from "../Redux/Actions/languageActions";

const useWebsiteLanguage = () => {
  const dispatch = useDispatch();
  const language = useSelector(getWebsiteLanguage, shallowEqual);

  const setLanguage = (language) => {
    if (language) {
      localStorage.setItem("language", language);
    } else {
      localStorage.removeItem("language");
    }
    dispatch(setWebsiteLanguage(language));
  };

  return { language, setLanguage };
};

export default useWebsiteLanguage;
