import React from "react";
import { Container, TextTime, TextDescription } from "./HoursItinerary.styles";
import { isEmpty } from "lodash";
import useIsMobile from "../../../../../../../../../hooks/useIsMobile";
import PropTypes from "prop-types";

const HoursItinerary = ({ hours }) => {
  const isMobile = useIsMobile();
  return (
    <Container addMargin={isMobile}>
      {!isEmpty(hours?.time) && (
        <TextTime type="light-primary" strong>
          {hours?.time}
        </TextTime>
      )}
      {isEmpty(hours?.time) ? (
        <TextDescription strong>{hours?.description}</TextDescription>
      ) : (
        <TextDescription>{hours?.description}</TextDescription>
      )}
    </Container>
  );
};

export default HoursItinerary;

HoursItinerary.propTypes = {
  hours: PropTypes.object,
};
