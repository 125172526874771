import { addDays } from "date-fns";
import { isEmpty } from "lodash";
import moment from "moment";

export const PERIODS = {
  day: "day",
  hour: "hour",
};

export const FORMATS = {
  short: "short",
  shortest: "shortest",
  long: "long",
  longDate: "longDate",
};

export const PRINT_FORMATS = {
  day: {
    short: "DD/MM/YYYY",
    shortest: "DD/MM",
    long: "DD MMM YYYY",
    longDate: "dddd, D MMMM YYYY",
    isoFormat: "YYYY-MM-DD",
  },
  hour: {
    short: "DD/MM/YYYY HH:mm[hs]",
    long: "DD MMM YYYY, HH:mm[hs]",
  },
};

export const isValidPeriod = (period) =>
  Object.values(PERIODS).includes(period);

export const createUTCDate = (dateString, isUTCString = false) => {
  return moment(dateString).utc(!isUTCString);
};

export const parseApiDate = (dateString) => createUTCDate(dateString, true);

export const toISOFormatString = (dateMoment) => {
  if (!dateMoment) return null;
  return renderCustomFormatDate(dateMoment, "YYYY-MM-DDTHH:mm:ss");
};

export const renderDate = (dateMoment, format, period) => {
  if (!dateMoment) return null;
  const printPeriod = isValidPeriod(period) ? period : PERIODS.day;
  return renderCustomFormatDate(
    dateMoment,
    PRINT_FORMATS[printPeriod][format] ||
      PRINT_FORMATS[printPeriod][FORMATS.long]
  );
};

export const printDateTo = (
  toDateMoment,
  referenceDateMoment = createUTCDate()
) => {
  return referenceDateMoment.to(toDateMoment);
};

export const renderCustomFormatDate = (
  dateMoment = createUTCDate(),
  format = ""
) => {
  const date = createUTCDate(dateMoment);
  return date.format(format);
};

export const toDateFromUTC = (dateMoment) =>
  new Date(toISOFormatString(dateMoment));

export const calculateDaysLength = (from, to, period = PERIODS.day) => {
  if (!from || !to) return null;
  return to.diff(from, period) + 1;
};

export const deadlineNotArrived = (deadlineDate) => {
  return createUTCDate().isSameOrBefore(deadlineDate);
};

export const isTimePeriod = (period) => {
  return period === PERIODS.hour || period === PERIODS.ticket;
};

export const isSameDay = (from, to) => {
  return moment(from).isSame(moment(to));
};

export const getDatesInRange = (startDate, endDate) => {
  const sanitizeStartDate = new Date(startDate);
  const sanitizedEndDate = new Date(endDate);
  const date = new Date(sanitizeStartDate.getTime());

  const dates = [];

  while (date <= sanitizedEndDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const generateYearsBack = () => {
  const year = new Date().getFullYear();
  return Array.from({ length: 3 }, (v, i) => year - 3 + i + 1);
};

export const generateDateTime = (date, time) => {
  const [hour, minute] = time ? time.split(":") : [null, null];
  const baseDate = createUTCDate(date).startOf("day");
  baseDate.set({
    hour,
    minute,
  });
  return baseDate;
};

/**
 * Rounds the given date to the nearest hour based on the minutes.
 * Rounds down if the minutes are 30 or less, or rounds up if they are more than 30.
 *
 * @param {Date | undefined} date - The date object to round. If not provided, the current date and time will be used.
 * @returns {Date} A new Date object rounded to the nearest hour.
 */
export const dateRoundHour = (date) => {
  const momentDate = date ? createUTCDate(date) : createUTCDate();
  const minutes = momentDate.minutes();

  const roundMinutes = minutes <= 30 ? 0 : 60;

  return momentDate.startOf("hour").add(roundMinutes, "minutes");
};

/**
 * Parses a time string in the "HH:00" format and returns the hour as a number.
 *
 * @param {string} timeString - The time string in the format "HH:00".
 * @returns {number|null} - The hour as a number or null if the input is invalid.
 */
export const parseHour = (timeString) => {
  if (!timeString || isEmpty(timeString)) return null;
  // Split the time string by the ":" character to extract the hour
  const [hour] = timeString?.split(":");

  // Convert the hour to a number
  return Number(hour);
};

export const isRestrictedDate = (date, restrictedDates = []) => {
  const restrictedDate = restrictedDates.some((compareDate) => {
    return parseApiDate(compareDate).isSame(createUTCDate(date), "day");
  });
  return restrictedDate;
};

export const isDayClosed = (
  current,
  opennedDaysOfWeek = [],
  restrictedDates
) => {
  const isClosedDay =
    !isEmpty(opennedDaysOfWeek) && !opennedDaysOfWeek[current.getDay()];
  return isClosedDay || isRestrictedDate(current, restrictedDates);
};

export const getNextAvailableDate = (
  date,
  restrictedDates = [],
  opennedDaysOfWeek = []
) => {
  while (
    isRestrictedDate(date, restrictedDates) ||
    isDayClosed(date, opennedDaysOfWeek)
  ) {
    date = addDays(date, 1);
  }
  return date;
};
