import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const PickUpAndDropOffSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
`;

export const StyledText = styled(Text)`
  font-size: 14px;
`;

export const StyledTitle = styled(Text)`
  font-size: 16px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
