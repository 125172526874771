import styled from "styled-components";
import CollapseAntd from "antd/es/collapse";
import Title from "antd/es/typography/Title";

export const Panel = styled(CollapseAntd.Panel)`
  border: 1px solid ${({ theme }) => theme.colors.darkGray} !important;
  border-radius: 30px !important;
  margin-bottom: 30px;
`;

export const PanelTitle = styled(Title)`
  display: inline-block;
  margin: 0 !important;
  color: ${({ theme }) => theme.colors.darkGray} !important;
`;
