import { parseTransactionErrorMessages } from "../helpers/helpers";

export const getTransactionStatus = (state) => {
  const { data, fetching, errorMessage, error } =
    state.pages.shop.transactionStatus;

  return {
    data,
    status: data?.status,
    _id: data?._id,
    fetching,
    error,
    errorMessage: parseTransactionErrorMessages(errorMessage),
  };
};
