import styled from "styled-components";
import ProductCardComponent from "../../../../components/ProductCard/ProductCard";
import mediaQueries from "../../../../utils/mediaQueries";

export const ProductsList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px 10px;

  @media ${mediaQueries.desktop} {
    gap: 25px 40px;
  }
`;

export const ProductCard = styled(ProductCardComponent)`
  width: 100%;
  max-width: 250px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 50px;
`;
