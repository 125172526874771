import baseTheme from "../config/baseTheme";

/**
 * Combines the values of the `theme` object with the values of the `overrides` object.
 * The values in `overrides` will overwrite the corresponding values in `theme`.
 *
 * @param {Object} [overrides={}]
 * @param {Object} [overrides.colors = {}]
 * @param {string} [overrides.colors.primary]
 * @returns {object}
 *
 */
export const generateTheme = (overrides = {}) => {
  const { colors = {} } = overrides;
  const keysToOverride = {
    colors: {
      ...baseTheme.colors,
      primary: colors.primary || baseTheme.colors.primary,
    },
  };
  return { ...baseTheme, ...keysToOverride };
};
