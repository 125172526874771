import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SocialButtons from "../../../../components/SocialButtons/SocialButtons";
import { PRIMARY_LINK_TYPES } from "../../helpers/constants";
import {
  Description,
  Title,
  WrapperWithOverlay,
  Content,
  ContainerButtons,
  CustomButton,
} from "./HeaderSection.styles";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import { RENTALS, isRental } from "../../../../helpers/rentals";
import BannerCarousel from "../../../../components/BannerCarousel/BannerCarousel";

const HeaderSection = ({
  title,
  description,
  bgImage,
  socialLinks,
  onClickButton,
  experiencesLength,
  ...restProps
}) => {
  const { t } = useTranslation();
  const supplierData = useSelector(getSupplierData);

  const renderContent = () => {
    return (
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ContainerButtons>
          {isRental(RENTALS.campingLaIndia, supplierData._id) && (
            <CustomButton
              type="primary"
              size="large"
              onClick={() => onClickButton(PRIMARY_LINK_TYPES.SPACE)}
            >
              {t("rentStove")}{" "}
            </CustomButton>
          )}
          {!isRental(RENTALS.highPeak, supplierData._id) &&
            !isRental(RENTALS.spAventuras, supplierData._id) &&
            !isRental(RENTALS.rumboNorte, supplierData._id) &&
            !isRental(RENTALS.campingLaIndia, supplierData._id) &&
            !isRental(RENTALS.intiAventura, supplierData._id) &&
            !isRental(RENTALS.bicitarte, supplierData._id) &&
            !isRental(RENTALS.travesiaCatedral, supplierData._id) &&
            !isRental(RENTALS.villavicencio, supplierData._id) && (
              <CustomButton type="primary" size="large" onClick={onClickButton}>
                {isRental(supplierData._id, RENTALS.ananda)
                  ? t("rentBike")
                  : t("rentGear")}
              </CustomButton>
            )}
          {experiencesLength >= 1 && (
            <CustomButton
              size="large"
              type="light-primary"
              onClick={() => onClickButton(PRIMARY_LINK_TYPES.EXPERIENCE)}
            >
              {t("menu.experiences")}
            </CustomButton>
          )}
        </ContainerButtons>
      </Content>
    );
  };
  if (isRental(RENTALS.travesiaCatedral, supplierData._id)) {
    return <BannerCarousel bgImage={bgImage} restProps={restProps} />;
  }
  return (
    <WrapperWithOverlay bgImage={bgImage} {...restProps}>
      {renderContent()}
    </WrapperWithOverlay>
  );
};

HeaderSection.propTypes = {
  bgImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  socialLinks: SocialButtons.propTypes.links,
  onClickButton: PropTypes.func,
};

export default HeaderSection;
