import React from "react";
import Select from "antd/es/select/index";
import { provinces } from "../../config/provinces";

const ProvinceSelector = ({
  width = "100%",
  size = "large",
  showSearch = true,
  country,
  ...props
}) => {
  return (
    <Select
      style={{ width }}
      {...props}
      size={size}
      showSearch={showSearch}
    >
      {provinces[country].map((province) => {
        return (
          <Select.Option key={province.id} value={province.name}>
            {province.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default ProvinceSelector;
