import React from "react";
import Text from "antd/es/typography/Text";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import baseTheme from "../../config/baseTheme";

const DisclaimerContainer = styled.div`
  padding: 10px;
  border-radius: 20px;
  border: ${({ bordered, theme }) =>
    bordered ? `1px solid ${theme.borders.gray}` : "0px"};
  font-size: 14px;
  display: flex;
  justify-content: center;
`;
const Disclaimer = ({
  enphasis,
  text,
  bordered = true,
  color = baseTheme.text.disabled,
  size = "regular",
}) => {
  return (
    <DisclaimerContainer bordered={bordered}>
      <Text
        type="secondary"
        style={{ color: color, fontSize: size === "small" ? 12 : 12 }}
      >
        <ExclamationCircleOutlined /> {enphasis} {text}
      </Text>
    </DisclaimerContainer>
  );
};

export default Disclaimer;
