import { PAYMENT_METHOD_LABELS, URL_FROM_DASHBOARD } from "../PaymentMethods";

export const filterPaymentMethods = (
  paymentMethods = [],
  paymentMethodsData = [],
  source = ""
) => {
  const isDashboardSource = source.includes(URL_FROM_DASHBOARD.DASHBOARD);
  const allowedPaymentMethods = [
    PAYMENT_METHOD_LABELS.mercadopago,
    PAYMENT_METHOD_LABELS.stripe,
    PAYMENT_METHOD_LABELS.webpay,
    PAYMENT_METHOD_LABELS.shopCash,
    PAYMENT_METHOD_LABELS.wireTransfer,
    PAYMENT_METHOD_LABELS.westernUnion,
  ];
  if (!isDashboardSource) {
    const filteredPaymentMethodsList = paymentMethods.map(
      (item) => item.paymentMethod
    );

    const filteredResults = paymentMethodsData.filter((item) =>
      filteredPaymentMethodsList.includes(item.paymentMethod)
    );

    return paymentMethods.map((method) => {
      const matchingDescription = filteredResults.find(
        (data) => data.paymentMethod === method.paymentMethod
      );
      return matchingDescription
        ? { ...method, comment: matchingDescription?.data?.comment }
        : method;
    });
  }
  const filteredPaymentMethods = paymentMethods.filter((method) => {
    if (allowedPaymentMethods.includes(method.paymentMethod)) {
      return true;
    }
    return false;
  });

  return filteredPaymentMethods;
};
