import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import Spin from "antd/es/spin";
import { renderPrice } from "../../helpers/tools";

const StyledTitle = styled(Title)`
  margin-top: 3px !important;
  margin-bottom: 5px !important;
  font-size: ${({ size }) =>
    size === "large" ? "28px !important" : "16px !important"};
  font-weight: "600 !important";
`;

const TypeText = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: #444;
`;

const StyledParagraph = styled(Paragraph)`
  margin: 0px !important;
  font-size: 18px;
  font-weight: 600;
`;

const Container = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const Price = ({
  currency,
  currencySymbol,
  price,
  isTotal,
  isPricingFetching,
  showTotal,
  rentalPeriodType,
  size = "regular",
}) => {
  const { t } = useTranslation(["shop", "common"]);
  const renderBookingPeriod = () => {
    return isTotal ? null : (
      <TypeText>{t(`common:byPeriods.per_${rentalPeriodType}`)}</TypeText>
    );
  };

  if (isPricingFetching) {
    return (
      <Container>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            fontSize: 16,
          }}
        >
          <Spin size="small" /> - {t("summaryLoader")}
        </div>
      </Container>
    );
  }

  if (!showTotal) {
    return null;
  }
  const renderTotal = () => {
    return (
      <span>
        {currencySymbol} {renderPrice(price, currency)} {renderBookingPeriod()}
      </span>
    );
  };

  return (
    <Container>
      {isTotal ? <StyledParagraph>{t("total")}</StyledParagraph> : null}
      <StyledTitle size={size}>{renderTotal()}</StyledTitle>
    </Container>
  );
};

Price.propTypes = {
  currencySymbol: PropTypes.string,
  currency: PropTypes.string,
  price: PropTypes.number,
  isTotal: PropTypes.bool,
  isPricingFetching: PropTypes.bool,
  itemsLength: PropTypes.number,
};

Price.defaultProps = {};

export default Price;
