/**
 * @param {string} url
 * @returns {string|null}
 */
export const getEmbedLink = (url) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let videoId = null;
  const match = url.match(regExp);
  if (match && match[7].length === 11) {
    videoId = match[7];
  }

  return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
};
