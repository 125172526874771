import React from "react";
import DateInput from "../../forms/AddPeopleForm/CalendarInput/DateInput";
import { DateAndTimePickerWrapper } from "./DateAndTimePicker.styles";
import TimePicker from "../TimePicker/TimePicker";
import useIsMobile from "../../../../hooks/useIsMobile";
import { parseHour } from "../../../../helpers/dates";

const DateAndTimePicker = ({
  fromTime,
  toTime,
  keyVal,
  handleOnTimeChange,
  dateSelected,
  onClickDate,
  timeSelected,
}) => {
  const isMobile = useIsMobile();

  return (
    <DateAndTimePickerWrapper $isMobile={isMobile}>
      <DateInput onClick={onClickDate} value={dateSelected} />
      <TimePicker
        onChange={handleOnTimeChange}
        keyVal={keyVal}
        fromTime={fromTime}
        toTime={toTime}
        dateSelected={dateSelected}
        timeSelected={parseHour(timeSelected)}
      />
    </DateAndTimePickerWrapper>
  );
};

export default DateAndTimePicker;
