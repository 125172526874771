const iconMap = {
  facebook: "facebook",
  instagram: "instagram",
  twitter: "twitter",
  youtube: "youtube",
  linkedin: "linkedin",
  tiktok: "tiktok",
  snapchat: "snapchat",
  pinterest: "pinterest",
  whatsapp: "whatsapp",
  telegram: "telegram",
  google: "google",
  twitch: "twitch",
};

export const getSocialMediaIconName = (socialMediaLabel) => {
  return iconMap[socialMediaLabel] || "link";
};
