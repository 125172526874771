import React, { useState } from "react";
import { getPassengerDataById } from "../../redux/QuoteSelectors";
import { useSelector } from "react-redux";
import { InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";
import { config } from "../../tools/helpers";
import { isEmpty } from "lodash";
import { getFootOptions } from "../../helpers/helpers";
import { CUSTOMER_SIZE } from "../../../../helpers/tools";
import { Container } from "./ProductRequiredInfoContainer.styles";

const { Option } = Select;

const ProductRequiredInfoContainer = ({
  passengerId,
  style,
  onChange,
  orientation,
}) => {
  const [value, setValue] = useState({
    height: null,
    foot: null,
  });

  const { t } = useTranslation("common");
  const requiredInfo = useSelector((state) =>
    getPassengerDataById(state, passengerId)
  );
  const handleOnChange = (val, info) => {
    setValue({ ...value, [info]: val });
    onChange(passengerId, { [info]: val });
  };

  const footOptions = getFootOptions();

  return (
    <Container orientation={orientation}>
      {!isEmpty(requiredInfo?.required)
        ? requiredInfo?.required.map((info) => {
            if (info === CUSTOMER_SIZE.FOOT) {
              return (
                <Select
                  key={info}
                  size="large"
                  placeholder={t("shoeSize")}
                  style={style}
                  onChange={(val) => handleOnChange(val, info)}
                >
                  {footOptions.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return (
                <InputNumber
                  type="number"
                  addonAfter="Cm."
                  addonBefore={t(info)}
                  size="large"
                  key={info}
                  status={value[info] === null ? "error" : "success"}
                  max={config[info].max}
                  min={config[info].min}
                  placeholder={t(info)}
                  style={style}
                  onChange={(val) => handleOnChange(val, info)}
                />
              );
            }
          })
        : null}
    </Container>
  );
};

export default ProductRequiredInfoContainer;
