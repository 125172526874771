import { SET_WEBISTE_REGION } from "../Actions/regionActions";

const INITIAL_STATE = {
  region: null,
};

export const regionReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_WEBISTE_REGION: {
      return {
        ...state,
        region: payload?.region,
      };
    }
    default:
      return state;
  }
};

export default regionReducer;
