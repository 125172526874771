import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .calendar-wrapper {
    width: ${({ isMobile }) => (isMobile ? "100%" : "80%")};
    max-width: ${({ isMobile }) => (isMobile ? "450px" : "auto")};
  }
  .rdrMonth {
    width: 100%;
  }
`;
