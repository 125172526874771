export const sanitizeSubscriptionFeatures = (subscriptionsFeatures = {}) => {
  const { tools } = subscriptionsFeatures;
  const defaultConfig = {
    tools: {
      transferItems: false,
    },
  };
  return {
    tools: { ...defaultConfig.tools, ...tools },
  };
};
