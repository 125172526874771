import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const NavbarContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.borders.gray};
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  gap: 10px;
`;

export const LanguageWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 20px;
`;

export const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 600;
`;
