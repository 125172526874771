import i18next from "i18next";
import { formatHourToString } from "./helpers";
import styled from "styled-components";
import baseTheme from "../../../../../config/baseTheme";
import { isEmpty } from "lodash";

const DropdownExtra = styled.span`
  color: ${({ color }) => (color ? color : "none")};
`;

export const generatePickUpTimeOptions = (matchingObject, startTimes) => {
  if (isEmpty(matchingObject)) return null;
  return matchingObject?.entries?.map((entry) => {
    const matchingStartTime = startTimes?.find(
      (startTime) => startTime._id === entry.startTimeId
    );

    const { hour, minute } = matchingStartTime.startTime;
    const time = formatHourToString(hour, minute);
    const disabled = entry.occupiedPercentage >= 100;

    return {
      value: matchingStartTime._id,
      label: `${time} hs.`,
      disabled: disabled,
      extraInfo: (
        <DropdownExtra color={disabled ? "none" : baseTheme.colors.primary}>
          {entry.available} {i18next.t("common:quotas")}
        </DropdownExtra>
      ),
    };
  });
};
