import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import {
  getSupplierSettings,
  getWebsiteContentConfig,
} from "../../../../Redux/Selectors/appConfigSelector";
import { getSpaceDetail } from "../../redux/selectors/spacesSelectors";
import {
  getAssigmentsData,
  getCheckoutSummary,
  getNormalizePassengers,
  getPricingData,
  getPricingIsFetching,
  getSummary,
  getVariantsFromAllProducts,
} from "../../../ShopPage/redux/selectors/ShopSelectors";
import { getTransactionStatus } from "../../../ShopPage/redux/selectors/TransactionStatusSelectors";
import {
  listTypes,
  removeSpaces,
  sanitizeVariantListing,
} from "../../../ShopPage/utils";
import { createUTCDate, sanitizePhonePrefix } from "../../../../helpers/tools";
import { saveMainMemberInfo } from "../../../ShopPage/redux/actions/MainMemberActions";
import {
  assignVariantToPassenger,
  quoteBuildAddPassengers,
} from "../../../ShopPage/redux/actions/shopActions";
import { PERIODS, toISOFormatString } from "../../../../helpers/dates";
import { performTransactionRequest } from "../../../TransactionPage/TransactionActions";
import { DateOfBirthContext } from "../../../../components/DateOfBirthSelector/context/DateOfBirthContext";
import { sanitizeDateOfBirth } from "../../../../tools/dateTools";

const ParticipantContainer = ({ children, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonIsEnabled, setButtonIsEnabled] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const { dateOfBirth } = useContext(DateOfBirthContext);
  const content = useSelector(getWebsiteContentConfig, shallowEqual);
  const space = useSelector((state) => getSpaceDetail(state, id));
  const pricingData = useSelector(getPricingData);
  const isPricingFetching = useSelector(getPricingIsFetching);
  const transactionStatus = useSelector(getTransactionStatus);
  const assignmentsData = useSelector(getAssigmentsData);
  const checkoutSummary = useSelector(getCheckoutSummary);
  const normalizePassengers = useSelector(getNormalizePassengers);
  const quoteSummary = useSelector(getSummary);
  const settings = useSelector(getSupplierSettings);
  const variants = useSelector((state) =>
    getVariantsFromAllProducts(state, id)
  );

  useEffect(() => {
    if (transactionStatus.status === "PREAUTHORIZED") {
      navigate(
        `/transaction/${transactionStatus._id}/pay?customerId=${transactionStatus.data.guest}`,
        { replace: true }
      );
    }
  }, [transactionStatus]);

  const handleOnFormIsValid = (isValid) => {
    setButtonIsEnabled(isValid);
  };

  const saveMainMember = (val) => {
    for (let i = 0; i < quoteSummary.products[id]; i++) {
      dispatch(
        assignVariantToPassenger({
          index: i,
          passenger: 0,
          type: listTypes.VARIANT,
          productId: id,
          variantId: variants[0].id,
        })
      );
    }

    const data = {
      ...val,
      name: removeSpaces(val.name),
      surname: removeSpaces(val.surname),
      dateOfBirth: sanitizeDateOfBirth(dateOfBirth),
      phonePrefix: val.phonePrefix && sanitizePhonePrefix(val.phonePrefix),
    };
    dispatch(
      quoteBuildAddPassengers([
        {
          id: 0,
          name: `${removeSpaces(val.name)} ${removeSpaces(val.surname)}`,
          quantity: quoteSummary.products[id],
          from: createUTCDate(quoteSummary.dates.from).format("DD-MM-YYYY"),
          to: createUTCDate(quoteSummary.dates.to).format("DD-MM-YYYY"),
        },
      ])
    );
    dispatch(saveMainMemberInfo(data));
  };

  const handleOnRequestPayment = () => {
    const sanitizedVariants = sanitizeVariantListing(
      assignmentsData,
      listTypes.PRODUCT,
      space._id
    );

    const pickAndDrop = {
      pickup: {
        date: toISOFormatString(
          createUTCDate(quoteSummary.dates.from).set("hour", 10)
        ),
      },
      dropoff: {
        date: toISOFormatString(
          createUTCDate(quoteSummary.dates.to).set("hour", 23)
        ),
      },
    };

    dispatch(
      performTransactionRequest(
        sanitizedVariants,
        toISOFormatString(quoteSummary?.dates.from),
        toISOFormatString(
          createUTCDate(quoteSummary?.dates.from).add(
            pricingData.length - 1,
            "days"
          )
        ),
        PERIODS.day,
        settings.currency,
        { passengers: normalizePassengers },
        pickAndDrop
      )
    );
  };

  const handleOnContinue = () => {
    setShowSummaryModal(true);
  };
  return children({
    content,
    spaceData: space,
    pricingData,
    isFetching: isPricingFetching,
    dates: quoteSummary?.dates,
    showSummaryModal,
    setShowSummaryModal,
    isBookingFetching: transactionStatus.fetching,
    handleOnContinue,
    handleOnRequestPayment,
    checkoutSummary,
    buttonIsEnabled,
    saveMainMember,
    handleOnFormIsValid,
  });
};

export default ParticipantContainer;
