import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  border-radius: 8px;
`;

export const StyledTitle = styled(Title)`
  margin: 0px !important;
  max-width: 150px;
  padding: 0px;
  font-size: 18px !important;
`;

export const StyledParagraph = styled(Paragraph)`
  margin: 0px !important;
  padding: 0px;
  font-size: 14px;
`;

export const Description = styled(Paragraph)`
  font-size: 16px;
  line-height: 1.5;
`;
