import { Col, Descriptions, Row } from "antd";
import Text from "antd/es/typography/Text";
import React from "react";
import { useTranslation } from "react-i18next";
import ContainerComponent from "../../../../components/Container/Container";
import styled from "styled-components";

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const Wrapper = styled.div`
  max-width: 600px;
  width: 100%;
`;

const OpeningHours = ({ openingHours, openingConfig }) => {
  const { t } = useTranslation("home");
  const openHours = JSON.parse(openingHours);

  const renderClosed = (delivery) => {
    return (
      <Col>
        <Row>
          <Col>
            <Text strong style={{ fontWeight: 200, fontSize: 16 }}>
              {t("openingHours.closed")}
            </Text>
            {delivery && (
              <>
                {" "}
                <Text type="secondary" style={{ fontSize: 16 }}>
                  ({t("openingHours.deliveryToBeAgreed")})
                </Text>
              </>
            )}
          </Col>
        </Row>
      </Col>
    );
  };

  const renderHours = (hours) => {
    return (
      <Col>
        <Text style={{ fontSize: 14 }}>
          {hours.from}hs. a {hours.to}hs.
        </Text>
      </Col>
    );
  };

  if (openingConfig === "always_open") {
    return null;
  }
  if (openingConfig === "selected_hours") {
    return (
      <ContainerComponent>
        <Wrapper>
          <Descriptions size="small" column={1} bordered>
            {days.map((day) => {
              if (!openHours[`hours_${day}`]) {
                return null;
              }
              return (
                <Descriptions.Item label={t(`openingHours.${day}`)}>
                  {openHours[`hours_${day}`].map((hours) => {
                    if (!hours.open) return renderClosed(hours?.delivery);
                    return renderHours(hours);
                  })}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Wrapper>
      </ContainerComponent>
    );
  }
  return null;
};

export default OpeningHours;
