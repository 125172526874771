import {
  GET_TRANSACTION_BY_ID_REQUEST,
  GET_TRANSACTION_BY_ID_SUCCESS,
} from "./TransactionActions";

export const INITIAL_STATE = {
  fetching: false,
  error: false,
  result: null,
};

const transactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTION_BY_ID_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case GET_TRANSACTION_BY_ID_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        results: action.payload.results,
      };
    default:
      return INITIAL_STATE;
  }
};

export default transactionReducer;
