import styled from "styled-components";
import CarouselAntd from "antd/es/carousel";

export const Carousel = styled(CarouselAntd)`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;

  .slick-dots {
    z-index: 0;
  }

  .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: ${({ theme }) => theme.text.gray};
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.38);
  }
  .slick-dots li.slick-active button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: ${({ theme }) => theme.text.darkGray};
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.38);
  }
`;

export const MobileCarousel = styled(CarouselAntd)`
  width: 100vw;
  height: 600px;
  background-color: ${({ theme }) => theme.background.dark};
`;

export const SlideWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 15px;
  align-items: center;
  height: 100%;
`;

export const MobileImage = styled.img`
  width: 100%;
  height: 600px;
  object-fit: contain;
`;
