export const ADD_PASSENGER_QUOTE = "ADD_PASSENGER_QUOTE";
export const REMOVE_PASSENGER_QUOTE = "REMOVE_PASSENGER_QUOTE";
export const EDIT_PASSENGER_VALUE = "EDIT_PASSENGER_VALUE";
export const GET_PRODUCTS_QUOTE_REQUEST = "GET_PRODUCTS_QUOTE_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_PRODUCTS_QUOTE_SUCCESS = "GET_PRODUCTS_QUOTE_SUCCESS";
export const QUOTE_ADD_ITEM = "QUOTE_ADD_ITEM";
export const QUOTE_SUBSTRACT_ITEM = "QUOTE_SUBSTRACT_ITEM";
export const SET_ACTIVE_PASSENGER = "SET_ACTIVE_PASSENGER";
export const GET_QUOTE_PRICING_REQUEST = "GET_QUOTE_PRICING_REQUEST";
export const GET_QUOTE_PRICING_SUCCESS = "GET_QUOTE_PRICING_SUCCESS";
export const SET_INDIVIDUAL_DATES = "SET_INDIVIDUAL_DATES";
export const RESET_INDIVIDUAL_DATES = "RESET_INDIVIDUAL_DATES";
export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SET_REQUIRED_INFO_MISSING = "SET_REQUIRED_INFO_MISSING";
export const RESET_PROCESS = "RESET_PROCESS";
export const GET_BUNDLES_QUOTE_SUCCESS = "GET_BUNDLES_QUOTE_SUCCESS";
export const SELECT_VARIANT = "SELECT_VARIANT";
export const RESET_REQUIRED_INFO = "RESET_REQUIRED_INFO";
export const REMOVE_REQUIRED_INFO = "REMOVE_REQUIRED_INFO";
export const PRODUCT_REQUIRES_VARIANT = "PRODUCT_REQUIRES_VARIANT";
export const GET_QUOTE_PRICING_BY_PRODUCT_REQUEST =
  "GET_QUOTE_PRICING_BY_PRODUCT_REQUEST";
export const GET_QUOTE_PRICING_BY_PRODUCT_SUCCESS =
  "GET_QUOTE_PRICING_BY_PRODUCT_SUCCESS";

export const productRequiresVariant = (productId) => {
  return {
    type: PRODUCT_REQUIRES_VARIANT,
    payload: {
      productId,
    },
  };
};

export const getBundlesQuoteSuccess = (results) => {
  return {
    type: GET_BUNDLES_QUOTE_SUCCESS,
    payload: { results },
  };
};

export const resetProccess = () => {
  return {
    type: RESET_PROCESS,
  };
};
export const setRequiredInfoMissing = (
  category,
  passengerId,
  rowId,
  variantRequired
) => {
  return {
    type: SET_REQUIRED_INFO_MISSING,
    payload: {
      category,
      id: passengerId,
      rowId,
      variantRequired,
    },
  };
};

export const removeRequiredInfo = (category, id) => {
  return {
    type: REMOVE_REQUIRED_INFO,
    payload: {
      category,
      id,
    },
  };
};

export const resetRequiredInfo = (id) => {
  return {
    type: RESET_REQUIRED_INFO,
    payload: {
      id,
    },
  };
};

export const resetIndividualDates = (passengerId, rowId) => {
  return {
    type: RESET_INDIVIDUAL_DATES,
    payload: {
      id: passengerId,
      rowId,
    },
  };
};

export const setIndividualDates = (key, date, passengerId, rowId) => {
  return {
    type: SET_INDIVIDUAL_DATES,
    payload: {
      key,
      date,
      id: passengerId,
      rowId,
    },
  };
};

export const setActivePassenger = (id) => {
  return {
    type: SET_ACTIVE_PASSENGER,
    payload: {
      activePassenger: id,
    },
  };
};

export const addPassengerQuote = (id, name, experience, products) => {
  return {
    type: ADD_PASSENGER_QUOTE,
    payload: {
      id,
      name,
      experience,
      products,
    },
  };
};

export const removePassengerQuote = (id) => {
  return {
    type: REMOVE_PASSENGER_QUOTE,
    payload: {
      id,
    },
  };
};

export const editPassengerValue = (id, value) => {
  return {
    type: EDIT_PASSENGER_VALUE,
    payload: {
      id,
      value,
    },
  };
};

export const getProducsQuoteRequest = (params) => {
  return {
    type: GET_PRODUCTS_QUOTE_REQUEST,
    payload: {
      params,
    },
  };
};

export const getProducsQuoteSuccess = (results) => {
  return {
    type: GET_PRODUCTS_QUOTE_SUCCESS,
    payload: {
      results,
    },
  };
};

export const getCategoriesSuccess = (results) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: {
      results,
    },
  };
};

export const quoteAddItem = (productId, passengerId, rowId) => {
  return {
    type: QUOTE_ADD_ITEM,
    payload: {
      productId,
      id: passengerId,
      rowId,
    },
  };
};

export const quoteSubstractItem = (passengerId, rowId) => {
  return {
    type: QUOTE_SUBSTRACT_ITEM,
    payload: {
      id: passengerId,
      rowId,
    },
  };
};

export const getQuotePricingRequest = (data, id) => {
  return {
    type: GET_QUOTE_PRICING_REQUEST,
    payload: {
      ...data,
      id,
    },
  };
};

export const getQuotePricingSuccess = (results, id, rowId) => {
  return {
    type: GET_QUOTE_PRICING_SUCCESS,
    payload: {
      ...results,
      id,
      rowId,
    },
  };
};

export const sendEmailRequest = (email, metadata) => {
  return {
    type: SEND_EMAIL_REQUEST,
    payload: {
      email,
      metadata,
    },
  };
};

export const sendEmailSuccess = () => {
  return {
    type: SEND_EMAIL_SUCCESS,
  };
};

export const selectVariant = (
  variants,
  uniqueKey,
  passengerId,
  rowId,
  autoSelect = true
) => {
  return {
    type: SELECT_VARIANT,
    payload: { variants, uniqueKey, id: passengerId, rowId, autoSelect },
  };
};

export const getQuotePricingByProductRequest = (data, productId) => {
  return {
    type: GET_QUOTE_PRICING_BY_PRODUCT_REQUEST,
    payload: {
      ...data,
      productId,
    },
  };
};

export const getQuotePricingByProductSuccess = (results, productId) => {
  return {
    type: GET_QUOTE_PRICING_BY_PRODUCT_SUCCESS,
    payload: {
      productId,
      results,
    },
  };
};
