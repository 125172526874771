import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import { ReactComponent as CarIcon } from "../../../../components/Icons/car.svg";
import Text from "antd/es/typography/Text";
import { getCurrencyConfig, sanitisePrice } from "../../../../helpers/tools";
import { Wrapper, Amount } from "./Price.styles";

const Price = ({
  level,
  amount,
  currency,
  period,
  deleteText = false,
  ...restProps
}) => {
  const { t } = useTranslation();

  const getIcon = () => {
    if (period === "car") {
      return <CarIcon className="anticon" />;
    } else {
      return <UserOutlined />;
    }
  };

  return (
    <Wrapper {...restProps}>
      <Amount delete={deleteText} level={level}>
        {getCurrencyConfig(currency)?.symbol} {sanitisePrice(amount, currency)}
      </Amount>
      {period && (
        <div>
          {getIcon()}{" "}
          <Text style={{ fontSize: 12 }}>{t(`byPeriods.per_${period}`)}</Text>
        </div>
      )}
    </Wrapper>
  );
};

Price.defaultProps = {
  level: 1,
};

Price.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  level: PropTypes.number,
  period: PropTypes.string,
};

export default Price;
