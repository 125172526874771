import { getPreSaleById } from "../../Redux/Selectors/preSaleSelectors";
import { parsePreSale } from "./helpers";

export const getPresaleDetails = (state) => {
  const id = state.pages.preSale.data;
  const preSale = getPreSaleById(state, id);
  return parsePreSale(preSale);
};

export const getPreSaleIsFetching = (state) => {
  return state.pages.preSale.fetching;
};

export const getPreSaleFailed = (state) => {
  return state.pages.preSale.error;
};
