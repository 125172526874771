import React from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { StyledInput } from "../../../ExperiencesPage/detail/components/ParticipantGroupForm/ParticipantForm/ParticipantForm.styles";
import { StyledForm } from "./ParticipantDetailsForm.styles";
import Paragraph from "antd/es/typography/Paragraph";
import { getFootOptions } from "../../../QuotePage/helpers/helpers";
import { validateHeight, validateWeight } from "../../helpers/helpers";
import { StyledSelect } from "./ParticipantDetailsForm.styles";

const { Option } = Select;

const ParticipantDetailsForm = ({ field }) => {
  const { t } = useTranslation("common");
  const footOptions = getFootOptions();

  return (
    <>
      <Paragraph style={{ fontSize: 14, marginTop: 15 }} type="secondary">
        {t("sizeParticipant")}:
      </Paragraph>
      <StyledForm>
        <Form.Item
          name={[field.name, "height"]}
          layout="vertical"
          rules={[{ validator: validateHeight(t) }]}
          style={{ marginBottom: 8 }}
        >
          <StyledInput type="text" placeholder={t("height")} />
        </Form.Item>
        <Form.Item
          name={[field.name, "weight"]}
          layout="vertical"
          rules={[{ validator: validateWeight(t) }]}
          style={{ marginBottom: 8 }}
        >
          <StyledInput type="text" placeholder={t("weight")} />
        </Form.Item>
        <Form.Item
          name={[field.name, "experience"]}
          layout="vertical"
          rules={[
            {
              required: true,
              message: t("shop:form.error", { field: t("experience") }),
            },
          ]}
          style={{ marginBottom: 8 }}
        >
          <StyledSelect placeholder={t("experience")}>
            <Option value="beginner">{t("shop:checkout.beginner")}</Option>
            <Option value="intermediate">
              {t("shop:checkout.intermediate")}
            </Option>
            <Option value="expert">{t("shop:checkout.expert")}</Option>
          </StyledSelect>
        </Form.Item>
        <Form.Item
          name={[field.name, "footLength"]}
          layout="vertical"
          rules={[
            {
              required: true,
              message: t("shop:form.error", { field: t("shoeSize") }),
            },
          ]}
          style={{ marginBottom: 8 }}
        >
          <StyledSelect placeholder={t("shoeSize")}>
            {footOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </StyledSelect>
        </Form.Item>
      </StyledForm>
    </>
  );
};

export default ParticipantDetailsForm;
