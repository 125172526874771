import styled, { css } from "styled-components";
import Button from "../Button/Button";

export const SocialButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  border: none;

  ${({ $iconSize }) =>
    $iconSize
      ? css`
          font-size: ${$iconSize};
        `
      : null}
`;

SocialButton.propTypes = {
  ...Button.propTypes,
};
