import i18next from "i18next";
import { first, groupBy, size } from "lodash";
import moment from "moment";
import {
  getCurrencyConfig,
  getGuestCurrency,
  getGuestExchange,
  getOuttripExchange,
  renderCustomFormatDate,
} from "../../helpers/tools";
import { systemEvents, trackEvent } from "../../services/mixpanel";

export const parseBooking = (booking) => {
  const {
    customId,
    booking: { from, to } = {},
    guest: { _id: guestId },
    host: {
      name,
      phone,
      location,
      geolocation: { coordinates } = {},
      logo,
      email,
      _id: hostId,
      openingConfig,
      openingHours,
      slug,
    } = {},
    period,
    status,
    items,
    _id,
    pricing,
    version,
    price,
    currency,
    account,
    commissionFromHost = 1,
    assignments,
    type,
  } = booking;

  const balance = calculateBalance(account, price);
  const variants = groupVariants(items);
  const assignment = assignments.passengers.map(
    (passenger) => passenger.personalInfo
  );
  const pricingData = pricing
    ? pricing
    : {
        subtotal: price,
        total: price,
        currency,
      };

  return {
    startDate: renderCustomFormatDate(from),
    from: renderCustomFormatDate(from),
    to: renderCustomFormatDate(to),
    endDate: renderCustomFormatDate(to),
    guestPrice: getGuestExchange(pricingData, version),
    subtotal: pricingData.subtotal,
    discount: pricingData?.discounts[0]?.amountDiscounted ?? null,
    hostPrice: pricingData.total,
    guestCurrency: getGuestCurrency(pricingData, pricingData?.version),
    hostCurrency: getCurrencyConfig(pricingData.currency),
    adminPrice: getOuttripExchange(pricingData, version),
    period: i18next.t(`common:per_${period}`),
    status,
    items,
    hostName: name,
    hostPhone: phone,
    hostSlug: slug,
    hostLocation: location,
    hostGeolocation: coordinates,
    hostLogo: logo,
    hostEmail: email,
    hostId,
    bookingId: _id,
    guestId,
    variants,
    openingConfig,
    openingHours,
    version,
    type: getTransactionType(items),
    balance,
    commissionFromHost,
    customId,
    assignments: assignment,
    bookingType: type,
  };
};

export const groupVariants = (items) => {
  const groupedVariants = groupBy(items, (item) => item.variant._id);
  const variants = Object.entries(groupedVariants).map(
    ([variantId, items]) => ({
      product: items[0].variant.product[0],
      variant: items[0].variant.variant,
      quantity: size(items),
    })
  );
  return variants;
};

export const getTransactionType = (items) => {
  const variant = first(items)?.variant;
  const product = first(variant);
  return product?.type;
};

export const getColorByStatus = (status) => {
  switch (status) {
    case transactionStatus.ACCEPTED:
    case transactionStatus.IN_PROGRESS:
    case transactionStatus.FINISHED:
      return "green";
    case transactionStatus.DECLINED:
    case transactionStatus.CANCELLED:
      return "red";
    case transactionStatus.PREAUTHORIZED:
    case transactionStatus.PENDING_APPROVAL:
      return "gold";
    case transactionStatus.RETURNED:
      return "grey";
    default:
      return "#444";
  }
};

export const getDescriptionStatusText = (status) => {
  if (!status)
    return {
      icon: null,
      text: null,
    };
  const configs = {
    [transactionStatus.PREAUTHORIZED]: {
      type: "warning",
      text: "bookingDetail_preauthorizedDescription",
    },
    [transactionStatus.ACCEPTED]: {
      type: "success",
      text: "bookingDetail_acceptedDescription",
    },
    [transactionStatus.PENDING_APPROVAL]: {
      type: "warning",
      text: "bookingDetail_pendingApprovalDescription",
    },
    [transactionStatus.IN_PROGRESS]: {
      type: "success",
      text: "bookingDetail_inProgressDescription",
    },
    [transactionStatus.DECLINED]: {
      type: "error",
      text: "bookingDetail_declinedDescription",
    },
    [transactionStatus.CANCELLED]: {
      type: "error",
      text: "bookingDetail_cancelledDescription",
    },
  };
  return configs[status];
};

// bookings params is an array of transactions
export const sortBookingsByUpcomingDay = (bookings) => {
  if (bookings && bookings.length > 0) {
    return bookings.sort((a, b) => {
      if (moment(a.booking.from).valueOf() < moment(b.booking.from).valueOf()) {
        return -1;
      }
      if (moment(a.booking.from).valueOf() > moment(b.booking.from).valueOf()) {
        return 1;
      }
      return 0;
    });
  }
  return bookings;
};

export const sortBookingsByFinishingDay = (bookings) => {
  if (bookings && bookings.length > 0) {
    return bookings.sort((a, b) => {
      if (moment(a.booking.to).valueOf() > moment(b.booking.to).valueOf()) {
        return 1;
      }
      if (moment(a.booking.to).valueOf() < moment(b.booking.to).valueOf()) {
        return -1;
      }
      return 0;
    });
  }
  return bookings;
};

export const sortBookingsByFinishedFirst = (bookings) => {
  if (bookings && bookings.length > 0) {
    return bookings.sort((a, b) => {
      if (moment(a.booking.to).valueOf() < moment(b.booking.to).valueOf()) {
        return 1;
      }
      if (moment(a.booking.to).valueOf() > moment(b.booking.to).valueOf()) {
        return -1;
      }
      return 0;
    });
  }
  return bookings;
};

export const calculateBalance = (statement, totalPrice) => {
  if (statement) {
    return statement?.reduce((acc, state) => {
      return acc - state.amount;
    }, totalPrice);
  }
  return totalPrice;
};

export const transactionStatus = Object.freeze({
  PREAUTHORIZED: "PREAUTHORIZED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  ACCEPTED: "ACCEPTED",
  DECLINED: "DECLINED",
  CANCELLED: "CANCELLED",
  IN_PROGRESS: "IN_PROGRESS",
  RETURNED: "RETURNED",
  FINISHED: "FINISHED",
});

export const bookingStatus = Object.freeze({
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  DECLINED: "DECLINED",
  CANCELLED: "CANCELLED",
});

export const paymentStatus = Object.freeze({
  PENDING: "PENDING",
  DECLINED: "DECLINED",
  ACCEPTED: "ACCEPTED",
  EXPIRED: "EXPIRED",
});

export const paymentMethods = {
  shopCash: {
    value: "shopCash",
    label: "shopCash",
  },
  shopCard: {
    value: "shopCard",
    label: "shopCard",
  },
  shopWireTransfer: {
    value: "shopWireTransfer",
    label: "shopWireTransfer",
  },
  onlinePlatform: {
    value: "onlinePlatform",
    label: "onlinePlatform",
  },
};

export const commonPaymentMethods = [
  paymentMethods.shopCash,
  paymentMethods.shopCard,
  paymentMethods.shopWireTransfer,
];

export const supplierPaymentMethods = [...commonPaymentMethods];

export const adminPaymentMethods = [
  ...commonPaymentMethods,
  paymentMethods.onlinePlatform,
];

export const generateTransactionErrorMessage = (errorMessage) => {
  //const defaultMessage = "transactionErrorDefaultMessage";
  const stockErrorMessage = "transactionErrorStockMessage";
  trackEvent(systemEvents.ERROR, { errorMessage });
  // if (
  //   errorMessage &&
  //   errorMessage.stack &&
  //   errorMessage?.stack?.includes("Error: Item stock unavailable")
  // ) {
  //   return stockErrorMessage;
  // }
  return stockErrorMessage;
};
