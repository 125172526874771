import PropTypes from "prop-types";
import Card from "antd/es/card";
import FooterButton from "./FooterButton";
import {
  BodyContainer,
  Description,
  StyledTitle,
} from "./RemainingPayment.style";

const RemainingPayment = ({
  title,
  description,
  onClick,
  buttonLabel,
  bodyStyle,
  ...restProps
}) => {
  return (
    <Card bordered={false} bodyStyle={{ ...bodyStyle }} {...restProps}>
      <BodyContainer>
        <StyledTitle level={3}>{title}</StyledTitle>
        <Description>{description}</Description>
        <FooterButton onClick={onClick} buttonLabel={buttonLabel} />
      </BodyContainer>
    </Card>
  );
};

RemainingPayment.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  bodyStyle: PropTypes.object,
};

export default RemainingPayment;
