import moment from "moment";
import { createUTCDate } from "../helpers/dates";

export const generateYearsArray = (currentYear, length = 1) => {
    const startingYear = currentYear ?? moment().year();

    return Array.from({ length }, (_, i) => startingYear - i);
};

export const generateMonths = () => {
    const monthsArray = moment.months();
    return monthsArray;
};

export const sanitizeDateOfBirth = ({ day, month, year }) => {
    const currentYear = moment().year();
    if (
        !day ||
        !month ||
        !year ||
        month > 12 ||
        day > 31 ||
        year < currentYear - 100
    ) {
        return null;
    }

    const dateOfBirth = createUTCDate(
        `${year}-${month}-${day}`,
        "YYYY-MM-DD",
        true
    );

    return dateOfBirth.isValid() ? dateOfBirth.format("YYYY-MM-DD") : null;
};