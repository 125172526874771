import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";
import { Select } from "antd";

export const StyledForm = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  @media ${mediaQueries.desktop} {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 10px;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 36.7px !important;
    display: flex;
    align-items: center;
    border-radius: 10px;
  }
`;
