import { useDispatch, useSelector } from "react-redux";
import {
  getPricingData,
  getPricingIsFetching,
  getSummary,
  getTicketsQuantity,
} from "../../../ShopPage/redux/selectors/ShopSelectors";
import {
  getPricingRequest,
  summaryAddTicket,
  summarySubtractTicket,
} from "../../../ShopPage/redux/actions/shopActions";
import { getExperienceDetail } from "../../redux/selectors/ExperiencesSelector";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { toISOFormatString } from "../../../../helpers/tools";
import { listTypes } from "../../../ShopPage/utils";
import { getAvailabilityByDate } from "../../redux/selectors/availabilitySelector";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PRICE_TYPES } from "../../list/helpers/constants";
import { RENTALS, isRental } from "../../../../helpers/rentals";
import { getExperiencePromotion } from "../../list/helpers/helpers";
import { spPromotionExperiencesList } from "../../config/spAventuasConfig";
import useWebsiteCurrency from "../../../../hooks/useWebsiteCurrency";
import useWebsiteRegion from "../../../../hooks/useWebsiteCountry";

const AddParticipantContainer = ({
  children,
  experienceId,
  period,
  supplierId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["experiences"]);
  const { currency } = useWebsiteCurrency();
  const pricingData = useSelector(getPricingData);
  const { region } = useWebsiteRegion();
  const isPricingFetching = useSelector(getPricingIsFetching);
  const { tickets, dates, startTimeId } = useSelector(getSummary);
  const [validationMessage, setValidationMessage] = useState([]);
  const [isSelectedDate, setIsSelectedDate] = useState(false);
  const availableDate = useSelector((state) =>
    getAvailabilityByDate(state, startTimeId, dates.from)
  );
  const ticketsQuantity = useSelector((state) => getTicketsQuantity(state));
  const spDiscount =
    isRental(supplierId, RENTALS.spAventuras) &&
    region === "argentina" &&
    getExperiencePromotion(experienceId, spPromotionExperiencesList);

  useEffect(() => {
    if (!tickets[PRICE_TYPES.adult]) {
      dispatch(summaryAddTicket(PRICE_TYPES.adult));
    }
  }, []);
  useEffect(() => {
    if (!isEmpty(tickets)) {
      const sanitizedVariants = Object.entries(tickets).map(
        ([rate, quantity]) => ({
          id: experienceId,
          rate,
          type: listTypes.EXPERIENCE,
          quantity,
        })
      );
      pricingRequest(sanitizedVariants);
    }
  }, [
    JSON.stringify(dates.to),
    JSON.stringify(dates.from),
    tickets,
    JSON.stringify(spDiscount),
  ]);

  const checkAvailability = () => {
    const errorMsg = [];
    if (!availableDate) return errorMsg;

    if (availableDate.available < ticketsQuantity)
      errorMsg.push(
        t("error.exceedAvailableQuota", { quantity: availableDate.available })
      );
    if (availableDate.minCapacity > ticketsQuantity)
      errorMsg.push(
        t("error.notMeetMinCapacity", { quantity: availableDate.minCapacity })
      );

    return errorMsg;
  };

  const checkDateAndTime = () => {
    const errorMsg = [];

    if (!isSelectedDate) errorMsg.push(t("error.selectDateAndTime"));
    if (!startTimeId && isSelectedDate) errorMsg.push(t("error.selectTime"));

    return errorMsg;
  };

  const checkMainMember = () =>
    tickets[PRICE_TYPES.adult] ? [] : [t("error.mainAdultIsRequired")];

  useEffect(() => {
    const availabilityValidation = checkAvailability();
    const dateAndTimeValidation = checkDateAndTime();
    const mainMember = checkMainMember();
    const validations = [
      dateAndTimeValidation,
      mainMember,
      availabilityValidation,
    ]
      .filter((validation) => !isEmpty(validation))
      .flat();
    setValidationMessage(validations);
  }, [isSelectedDate, startTimeId, tickets]);

  const pricingRequest = (sanitizedVariants) => {
    const allowDiscounts = true;
    dispatch(
      getPricingRequest(
        toISOFormatString(dates.from),
        toISOFormatString(dates.to),
        period,
        sanitizedVariants,
        {},
        currency,
        allowDiscounts,
        listTypes.EXPERIENCE,
        spDiscount?.discountCode ?? null
      )
    );
  };

  const experience = useSelector((state) =>
    getExperienceDetail(state, experienceId)
  );

  const participantOptions = Object.entries(experience?.price).map(
    ([key, value]) => {
      if (value !== undefined && value !== null) {
        const sanitizedOption = key.split("Price")[0];
        return sanitizedOption;
      }
      return value;
    }
  );

  const ageSettings = {
    child: { min: 1, max: experience?.childAgeSetting },
  };

  const handleOnAddParticipant = (rate) => {
    dispatch(summaryAddTicket(rate));
  };

  const handleOnRemoveParticipant = (rate) => {
    dispatch(summarySubtractTicket(rate));
  };

  return children({
    handleOnAddParticipant,
    handleOnRemoveParticipant,
    participantOptions: participantOptions.filter(Boolean),
    ageSettings,
    tickets,
    startTimeId,
    pricingData,
    isPricingFetching,
    validationMessage,
    isSelectedDate,
    setIsSelectedDate,
  });
};

export default AddParticipantContainer;
