import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: ${({ width }) => width}px;
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 15px;
`;
