export const SET_SUBCRIPTION_SUPPLIER = "SET_SUBCRIPTION_SUPPLIER";

export const setSupplierSubscription = (subscription) => {
  return {
    type: SET_SUBCRIPTION_SUPPLIER,
    payload: {
      subscription,
    },
  };
};
