import { Button, Modal } from "antd";
import React from "react";
import CalendarInputWrapper from "../CalendarInputWrapper";
import { PERIODS } from "../../../../../../helpers/tools";
import { useTranslation } from "react-i18next";

const PickUpAndDropOffModal = ({
  openModal,
  handleShowModal,
  period = PERIODS.day,
  onClick,
  pickUpTime,
  dropOffTime,
}) => {
  const { t } = useTranslation("common");
  return (
    <Modal
      centered
      onCancel={handleShowModal}
      open={openModal}
      footer={
        <Button
          size="large"
          shape="round"
          type="primary"
          style={{ width: "100%" }}
          onClick={handleShowModal}
          disabled={!pickUpTime || !dropOffTime}
        >
          {t("confirm")}
        </Button>
      }
    >
      <CalendarInputWrapper period={period} onClick={onClick} />
    </Modal>
  );
};

export default PickUpAndDropOffModal;
