export const SET_CHECKIN_SEARCH_FILTER = "SET_CHECKIN_SEARCH_FILTER";
export const SET_SUPPLIER_HOST = "SET_SUPPLIER_HOST";
export const RESET_CHECKIN_DATA = "RESET_CHECKIN_SEARCH_FILTER";
export const RESET_CHECKIN_SEARCH_FILTER = "RESET_CHECKIN_SEARCH_FILTER";
export const ADD_PASSENGER_DETAILS = "ADD_PASSENGER_DETAILS";
export const SET_DROP_OFF_BRANCH = "SET_DROP_OFF_BRANCH";

export const addPassengerDetails = (passengerDetails) => {
  return {
    type: ADD_PASSENGER_DETAILS,
    payload: passengerDetails,
  };
};

export const setCheckinSearchFilter = (searchValue) => {
  return {
    type: SET_CHECKIN_SEARCH_FILTER,
    payload: { searchValue },
  };
};

export const resetCheckinData = () => {
  return {
    type: RESET_CHECKIN_DATA,
  };
};

export const setSupplierHost = (host) => {
  return {
    type: SET_SUPPLIER_HOST,
    payload: {
      host,
    },
  };
};

export const setDropOffBranch = (branch) => {
  return {
    type: SET_DROP_OFF_BRANCH,
    payload: {
      branch,
    },
  };
};
