import { Avatar, Card } from "antd";
import React from "react";
import Text from "antd/es/typography/Text";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import styled from "styled-components";
import BootstrapIcon from "../../../../components/BootstrapIcon/BoostrapIcon";
import moment from "moment";
import useIsMobile from "../../../../hooks/useIsMobile";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
`;

const SupplierDetails = () => {
  const supplierData = useSelector(getSupplierData);
  const isMobile = useIsMobile();
  return (
    <Card bodyStyle={{ padding: 10 }}>
      <Container>
        <Col>
          <Text style={{ fontSize: 12 }}>{moment().format("DD/MM/YYYY")}</Text>
          <Text strong>
            <BootstrapIcon iconName="shop" style={{ marginRight: 10 }} />{" "}
            {supplierData.name}
          </Text>
          <Text>
            <BootstrapIcon iconName="telephone" style={{ marginRight: 10 }} />
            {supplierData.phone}
          </Text>
          <Text>
            <BootstrapIcon iconName="envelope-at" style={{ marginRight: 10 }} />
            {supplierData.email}
          </Text>
          <Text>
            <BootstrapIcon iconName="geo-alt" style={{ marginRight: 10 }} />
            {supplierData.location}
          </Text>
        </Col>
        {!isMobile && (
          <Col style={{ alignItems: "end" }}>
            <Avatar size={60} src={supplierData.logo} shape="square" />
          </Col>
        )}
      </Container>
    </Card>
  );
};

export default SupplierDetails;
