import { put, all, takeLatest, call } from "redux-saga/effects";
import environment from "../../../config/environment";
import { normalizeData } from "../../../Redux/Actions/normalizrActions";
import { paymentSchema } from "../../../Redux/Schemas/paymentSchema";
import { get, getConfigs } from "../../../services/httpService";
import {
  exchangeConverterFailure,
  exchangeConverterSucess,
  EXCHANGE_CONVERTER_REQUEST,
  getPaymentByIdFailure,
  getPaymentByIdSuccess,
  getPaymentMethodsFromSupplierFailure,
  getPaymentMethodsFromSupplierSuccess,
  GET_PAYMENT_BY_ID_REQUEST,
  GET_PAYMENT_METHODS_FROM_SUPPLIER_REQUEST,
} from "./paymentActions";

function* handleExchangeConverterRequest(action) {
  try {
    const { amount, currency, convertTo } = action.payload;
    const token = yield call(getConfigs);
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/v2/exchange/convert`,
      {
        ...token,
        params: {
          amount,
          currency,
          convertTo,
        },
      }
    );
    if (response.status === 1) {
      const { data } = response;
      yield put(exchangeConverterSucess(data));
    } else {
      yield put(exchangeConverterFailure());
    }
  } catch (err) {
    yield put(exchangeConverterFailure());
    console.log(err);
  }
}

function* handleGetPaymentById(action) {
  try {
    const { paymentId, supplierId } = action.payload;
    const token = yield call(getConfigs);
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/payments/${paymentId}?supplierId=${supplierId}`,
      {
        ...token,
      }
    );
    if (response.status === 1) {
      yield put(normalizeData(response.data, paymentSchema));
      yield put(getPaymentByIdSuccess(paymentId));
    }
  } catch (err) {
    yield put(getPaymentByIdFailure(err));
  }
}

function* handleGetPaymentMethods(action) {
  try {
    const { supplierId } = action.payload;
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/v2/suppliers/${supplierId}/integrations`,
      {
        params: {
          relatedTo: "supplier",
          type: "payment",
        },
      }
    );
    if (response.status === 1) {
      yield put(getPaymentMethodsFromSupplierSuccess(response.data));
    }
  } catch (err) {
    yield put(getPaymentMethodsFromSupplierFailure());
  }
}

export default function* exchangeConverterSaga() {
  yield all([
    takeLatest(EXCHANGE_CONVERTER_REQUEST, handleExchangeConverterRequest),
    takeLatest(GET_PAYMENT_BY_ID_REQUEST, handleGetPaymentById),
    takeLatest(
      GET_PAYMENT_METHODS_FROM_SUPPLIER_REQUEST,
      handleGetPaymentMethods
    ),
  ]);
}
