import {
  GET_EXPERIENCE_DETAIL_REQUEST,
  GET_EXPERIENCE_DETAIL_SUCCESS,
  GET_EXPERIENCE_DETAIL_FAILURE,
} from "../actions/ExperiencesActions.js";

const INITIAL_STATE = {
  data: null,
  isFetching: false,
  error: false,
};

const experienceDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EXPERIENCE_DETAIL_REQUEST:
      return {
        ...state,
        data: null,
        isFetching: true,
        error: false,
      };
    case GET_EXPERIENCE_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.payload.experienceId,
      };
    case GET_EXPERIENCE_DETAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: true,
      };

    default:
      return state;
  }
};

export default experienceDetailReducer;
