import { useDispatch, useSelector } from "react-redux";
import { quoteBuildAddPassengers } from "../../ShopPage/redux/actions/shopActions";
import { useLocation, useNavigate } from "react-router-dom";
import { buildSearchParams } from "../../../helpers/tools";
import moment from "moment";
import { addPassengerDetails } from "../redux/checkinActions";
import {
  generatePassengerDetails,
  generatePassengers,
} from "../helpers/helpers";
import { cloneDeep } from "lodash";
import { getSupplierSettings } from "../../../Redux/Selectors/appConfigSelector";
import { first } from "lodash";

const ParticipantsContainer = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const settings = useSelector(getSupplierSettings);
  const onSubmitPassengers = (values) => {
    const clonedNames = cloneDeep(values.names);
    clonedNames.shift();
    const passengers = generatePassengers(clonedNames);
    dispatch(quoteBuildAddPassengers(passengers));
    const passengerDetails = generatePassengerDetails(values.names);
    dispatch(addPassengerDetails(passengerDetails));
    navigate(
      `/checkin/checkout?${buildSearchParams({
        dates: {
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        },
        pickupTime: first(settings?.pickupTime) ?? "12:00",
        dropoffTime: first(settings?.dropoffTime) ?? "18:00",
        searchPath: location.search,
      })}`,
      {
        replace: true,
      }
    );
  };
  return children({ onSubmitPassengers });
};
export default ParticipantsContainer;
