import React from "react";
import {
  Container,
  Item,
  StyledText,
  StyledTitle,
} from "./SummaryDetails.styles";
import { useTranslation } from "react-i18next";
import { flatten } from "lodash";

const SummaryDetails = ({ assignmentsData = [] }) => {
  const { t } = useTranslation("shop");

  return (
    <Container>
      <StyledTitle>{t("summary")}</StyledTitle>
      {flatten(assignmentsData).map((assignment) => {
        return (
          <Item>
            <StyledText ellipsis={{ rows: 2 }}>
              {assignment.assigned[0].productId.title}
            </StyledText>
            <StyledText ellipsis={{ rows: 2 }} style={{ textAlign: "right" }}>
              {assignment.paxData.name}
            </StyledText>
          </Item>
        );
      })}
    </Container>
  );
};

export default SummaryDetails;
