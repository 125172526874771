import { schema } from "normalizr";

export const supplierGroupSchema = new schema.Entity(
  "supplierGroups",
  {},
  {
    idAttribute: "_id",
  }
);

export const supplierGroupsSchema = [supplierGroupSchema];
