import { PAYMENT_METHOD_LABELS } from "../../../../PaymentPage/PaymentMethods";

export const integrations = (supplierId) => [
  {
    label: [
      PAYMENT_METHOD_LABELS.mercadopago,
      PAYMENT_METHOD_LABELS.webpay,
      PAYMENT_METHOD_LABELS.other,
    ],
    id: "visa",
    country: ["Argentina", "Chile"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1692367407/paymentAssets/grayscale/fek48kvlynm1zuykj6q6.png",
  },
  {
    label: [PAYMENT_METHOD_LABELS.macro],
    id: "macro",
    country: ["Argentina"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1714723613/paymentAssets/yhl2h1btm7bt2er4zw10.png",
  },
  {
    label: [
      PAYMENT_METHOD_LABELS.mercadopago,
      PAYMENT_METHOD_LABELS.webpay,
      PAYMENT_METHOD_LABELS.other,
    ],
    id: "mastercard",
    country: ["Argentina", "Chile"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1692367406/paymentAssets/grayscale/hpojtcckhigsxuojq7vx.png",
  },
  {
    label: [
      PAYMENT_METHOD_LABELS.mercadopago,
      PAYMENT_METHOD_LABELS.webpay,
      PAYMENT_METHOD_LABELS.other,
    ],
    id: "americanExpress",
    country: ["Argentina", "Chile"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1692367406/paymentAssets/grayscale/dmocjxvqjxahpv83lqfw.png",
  },
  {
    label: [PAYMENT_METHOD_LABELS.mercadopago, PAYMENT_METHOD_LABELS.other],
    id: "tarjetaNaranja",
    country: ["Argentina"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1692367407/paymentAssets/grayscale/iygoydqpk3exrzzo1kva.png",
  },
  {
    label: [PAYMENT_METHOD_LABELS.mercadopago, PAYMENT_METHOD_LABELS.other],
    id: "cabal",
    country: ["Argentina"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1692367406/paymentAssets/grayscale/ncycvv4zt4sief6qgvmy.png",
  },
  {
    label: [PAYMENT_METHOD_LABELS.mercadopago],
    id: "mercadopago",
    country: ["Argentina"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1692367407/paymentAssets/grayscale/kgheh2heemfzxgtpt7u4.png",
  },
  {
    label: [PAYMENT_METHOD_LABELS.webpay],
    id: "webpay",
    country: ["Chile"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1692367406/paymentAssets/grayscale/udv32laicpz0eag8hvyz.png",
  },
  {
    label: [PAYMENT_METHOD_LABELS.wireTransfer],
    id: "bankTransfer",
    country: ["Argentina", "Chile"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1692367406/paymentAssets/grayscale/uoeb20rfynz7ed05618c.png",
  },
  {
    label: [PAYMENT_METHOD_LABELS.shopCash],
    id: "shopCash",
    country: ["Argentina", "Chile"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1692367406/paymentAssets/grayscale/mfuzkbfqkz5zep9vhkch.png",
  },
  {
    label: [PAYMENT_METHOD_LABELS.westernUnion],
    id: "westernUnion",
    country: ["Argentina", "Chile"],
    url: "https://res.cloudinary.com/dja5ahopr/image/upload/v1704219416/paymentAssets/wjjp0czueokkv6qmzlti.png",
  },
];
