import React from "react";
import PropTypes from "prop-types";
import { Wrapper, Title, TitleContainer, Text } from "./Section.styles";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

const Section = ({ children, title, dark, addonAfter, ...restProps }) => {
  return (
    <Wrapper dark={dark} {...restProps}>
      {title && (
        <TitleContainer>
          <Title dark={dark ? 1 : 0}>{title}</Title>
          {!isEmpty(addonAfter) && (
            <Link to={addonAfter.navigate}>
              <Text>{addonAfter.text}</Text>
            </Link>
          )}
        </TitleContainer>
      )}

      {children}
    </Wrapper>
  );
};

Section.defaultProps = {
  dark: false,
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  addonAfter: PropTypes.shape({
    text: PropTypes.string.isRequired,
    navigate: PropTypes.string.isRequired,
  }),
};

export default Section;
