import React from "react";
import PropTypes from "prop-types";
import { Video, Wrapper } from "./YoutubeViewer.styles";
import { getEmbedLink } from "./helpers/youtubeEmbed";

const YoutubeViewer = ({ url }) => {
  const embedUrl = getEmbedLink(url);

  if (!embedUrl) {
    return null;
  }

  return (
    <Wrapper>
      <Video
        src={embedUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        style={{ borderRadius: 20 }}
      />
    </Wrapper>
  );
};

YoutubeViewer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default YoutubeViewer;
