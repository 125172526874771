import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getProductsByPassenger } from "../../redux/QuoteSelectors";
import ProductDetailContainer from "../ProductDetailContainer/ProductDetailContainer";
import ProductsEmptyPlaceholder from "../../components/ProductsEmptyPlaceholder/ProductsEmptyPlaceholder";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SelectedProductsContainer = ({ id }) => {
  const passengerProducts = useSelector((state) =>
    getProductsByPassenger(state, id)
  );

  if (passengerProducts.length === 0) {
    return <ProductsEmptyPlaceholder />;
  }

  return (
    <Container>
      {passengerProducts.map(({ product, rowId }) => {
        return (
          <ProductDetailContainer
            product={product}
            passengerId={id}
            rowId={rowId}
            key={rowId}
          />
        );
      })}
    </Container>
  );
};

export default SelectedProductsContainer;
