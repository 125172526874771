import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Calendar,
  ReferenceContainer,
  ItemContainer,
  GreenCircle,
  YellowCircle,
  RedCircle,
  ReferenceText,
  WhiteCircle,
} from "./Calendar.styles.js";
import { addAvailabilityColor, getDates } from "./helpers.js";
import { createUTCDate } from "../../../../../helpers/dates.js";

const ExperienceCalendar = ({
  availability = [],
  onChangeMonth,
  onClickOnDate,
  onCalendarOpen,
  isLoading,
  initialDate,
}) => {
  const { t } = useTranslation("experiences");
  const filteredAvailability = availability.filter(({ date }) => {
    const today = createUTCDate();
    return date.isSameOrAfter(today, "day");
  });

  const mappedAvailability = filteredAvailability.map((availabilityItem) => {
    return {
      ...availabilityItem,
      date: availabilityItem.date.toDate(),
    };
  });

  const RenderReference = () => {
    return (
      <ReferenceContainer>
        <ItemContainer>
          <GreenCircle />
          <ReferenceText>{t("availability.availability")}</ReferenceText>
        </ItemContainer>

        <ItemContainer>
          <YellowCircle />
          <ReferenceText>{t("availability.lowAvailability")}</ReferenceText>
        </ItemContainer>

        <ItemContainer>
          <RedCircle />
          <ReferenceText>{t("availability.unavailable")}</ReferenceText>
        </ItemContainer>
        <ItemContainer>
          <WhiteCircle />
          <ReferenceText>{t("availability.withoutActivity")}</ReferenceText>
        </ItemContainer>
      </ReferenceContainer>
    );
  };

  return (
    <Calendar
      placeholder={t("selectDates")}
      availableDates={getDates(mappedAvailability)}
      onClickOnDate={onClickOnDate}
      onChangeMonth={onChangeMonth}
      highlightDates={addAvailabilityColor(mappedAvailability)}
      renderReference={<RenderReference/>}
      isLoading={isLoading}
      onCalendarOpen={onCalendarOpen}
      initialDate={initialDate}
      inline={true}
    />
  );
};

ExperienceCalendar.propTypes = {
  availability: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.object.isRequired,
      occupiedPercentage: PropTypes.number.isRequired,
    })
  ).isRequired,
  onClickOnDate: PropTypes.func.isRequired,
  onChangeMonth: PropTypes.func.isRequired,
  initialDate: PropTypes.instanceOf(Date()),
};

export default ExperienceCalendar;
