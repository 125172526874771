import styled from "styled-components";

export const DateAndTimePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: ${({ isMobile }) => (isMobile ? "wrap" : "nowrap")};
  margin-bottom: 30px;
  width: 100%;
`;

export const TimePickerContainer = styled.div`
  width: 350px;

`;
