import React from "react";
import { Container } from "./HeaderItinerary.styles";
import Text from "antd/es/typography/Text";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Divider } from "antd";

const HeaderItinerary = ({ from, to }) => {
  const { t } = useTranslation(["experiences"]);
  return (
    <>
      <Container>
        {from && (
          <Text>
            {t("tabsExperience.departure")}: <Text strong>{from}</Text>
          </Text>
        )}
        {to && (
          <Text>
            {t("tabsExperience.return")}: <Text strong>{to}</Text>
          </Text>
        )}
      </Container>
      <Divider />
    </>
  );
};

export default HeaderItinerary;

HeaderItinerary.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
};
