import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Card, Divider } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getPaymentMethodsFromSupplierRequest,
  transactionPaymentCreateRequest,
} from "../redux/paymentActions";
import { getPaymentCreationState } from "../redux/paymentSelectors";
import { getPaymentById } from "../../../Redux/Selectors/paymentSelector";
import Title from "antd/es/typography/Title";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import CustomBookingId from "../../../components/CustomBookingId/CustomBookingId";
import { StyledSummaryPaymentDetail } from "../components/PaymentMethodSelection.styles";
import Section from "../components/Section";
import { WarningOutlined } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import baseTheme from "../../../config/baseTheme";
import { PAYMENT_METHOD_LABELS } from "../PaymentMethods";
import RichTextRender from "../../../components/RichTextRender/RichTextRender";
import { isEmpty } from "lodash";
import PaymentDescription from "../../../components/PaymentsDescription/PaymentDescription";

const WesternUnionPaymentScreen = ({
  transactionId,
  transactionCustomId,
  amount,
  currency,
  onReady,
  summary,
  paymentInformation,
}) => {
  const { t } = useTranslation(["shop", "common"]);
  const dispatch = useDispatch();
  const {
    paymentId,
    loading: paymentCreationLoading,
    error: paymentCreationError,
    success: paymentCreationSuccess,
  } = useSelector(getPaymentCreationState, shallowEqual);
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const payment = useSelector((state) => getPaymentById(state, paymentId));

  useEffect(() => {
    dispatch(
      transactionPaymentCreateRequest({
        provider: PAYMENT_METHOD_LABELS.westernUnion,
        amount,
        currency,
        transactionId,
      })
    );
    dispatch(getPaymentMethodsFromSupplierRequest(supplierData._id));
  }, []);

  useEffect(() => {
    if (
      !payment ||
      paymentCreationLoading ||
      (!paymentCreationSuccess && !paymentCreationError)
    ) {
      return;
    }
    onReady();
  }, [payment, paymentCreationSuccess, paymentCreationError]);

  return (
    <Card>
      <CustomBookingId customId={transactionCustomId} />
      {summary && (
        <Section title={t("paymentSummary")}>
          <StyledSummaryPaymentDetail {...summary} />
        </Section>
      )}
      {!isEmpty(paymentInformation.description) ? (
        <RichTextRender
          style={{ fontSize: 18, lineHeight: 1.5 }}
          content={paymentInformation.description}
        />
      ) : (
        <PaymentDescription
          paymentInformation={paymentInformation}
          supplierData={supplierData}
          title="westernUnionPayment"
        />
      )}

      <Divider />
      <div
        style={{
          backgroundColor: baseTheme.background.highlightBackground,
          padding: 10,
          borderRadius: 5,
        }}
      >
        <Title level={4} style={{ marginTop: 0 }}>
          {t("common:important")} <WarningOutlined />
        </Title>
        <Paragraph>{t("westernUnionDisclaimer.title")}</Paragraph>
        <Paragraph>{t("westernUnionDisclaimer.step_1")}</Paragraph>
        <Paragraph>{t("westernUnionDisclaimer.step_2")}</Paragraph>
        <Paragraph>
          {t("westernUnionDisclaimer.step_3", {
            name: paymentInformation?.name,
            withdrawal: paymentInformation?.withdrawal,
            id: paymentInformation?.id,
          })}
        </Paragraph>
        <Paragraph>{t("westernUnionDisclaimer.step_4")}</Paragraph>
        <Paragraph>{t("westernUnionDisclaimer.step_5")}</Paragraph>
        <Paragraph>
          {t("transaction")}: <b>{transactionCustomId}</b>
        </Paragraph>
        <Paragraph strong>{supplierData?.email}</Paragraph>
      </div>
    </Card>
  );
};

WesternUnionPaymentScreen.propTypes = {
  onFinish: PropTypes.func,
};

export default WesternUnionPaymentScreen;
