import React from "react";
import Col from "antd/es/grid/col";

const PanelColumn = ({ children, spacing = 0, ...rest }) => {
  return (
    <Col {...rest}>
      {React.Children.map(children, (child) => (
        <div
          style={{
            marginBottom: spacing,
          }}
        >
          {child}
        </div>
      ))}
    </Col>
  );
};

export default PanelColumn;
