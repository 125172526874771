import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import environment from "../../../../config/environment";
import { get } from "../../../../services/Api";
import {
  CHECK_VARIANTS_STOCK_REQUEST,
  checkVariantsStockSuccess,
} from "../actions/AvailabiltyActions";
import {
  GET_LOCKED_DATES_REQUEST,
  getLockedDatesSuccess,
} from "../../../SpacesPage/redux/actions/spacesActions";

function* handleCheckVariantsStock(action) {
  const { variantIds, from, to, period } = action.payload;
  try {
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/v2/availability/stock`,
      {
        params: {
          variantIds,
          from,
          to,
          period,
        },
      }
    );
    if (response.status === 200) {
      yield put(checkVariantsStockSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleGetLockedDates(action) {
  const { variantId, from, to, period } = action.payload;
  try {
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/availability/variant/${variantId}/locked`,
      {
        params: {
          startDate: from,
          endDate: to,
          period,
          singleBooking: true,
        },
      }
    );
    if (response.status === 200) {
      yield put(getLockedDatesSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* availabilityShopSaga() {
  yield all([
    takeEvery(CHECK_VARIANTS_STOCK_REQUEST, handleCheckVariantsStock),
    takeLatest(GET_LOCKED_DATES_REQUEST, handleGetLockedDates),
  ]);
}
