import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Container from "../../../../components/Container/Container";
import { Button, Col, Row } from "antd";
import { highlightProductsRequest } from "../../redux/actions/highlightProductsActions";
import { getHighlightProducts } from "../../redux/selectors/highlightProductsSelector";
import ProductCard from "../../../../components/ProductCard/ProductCard";

const FeaturedProductsContainer = ({ productIds, onClick }) => {
  const { t } = useTranslation(["home"]);
  const dispatch = useDispatch();
  const products = useSelector(getHighlightProducts);

  useEffect(() => {
    dispatch(
      highlightProductsRequest({
        productIds,
      })
    );
  }, []);

  return (
    <Container>
      <Row justify="center" gutter={[8, 8]} direction="row">
        {products.map(
          ({
            _id,
            images,
            title,
            price,
            currency,
            subtitle,
            rentalPeriodType,
          }) => (
            <Col xs={24} sm={12} md={12} lg={6}>
              <ProductCard
                key={_id}
                id={_id}
                images={images}
                title={title}
                price={price}
                currency={currency}
                subtitle={subtitle}
                period={rentalPeriodType}
                onClick={onClick}
              />
            </Col>
          )
        )}
      </Row>
      <Col xs={24} style={{ marginTop: 60 }}>
        <Row justify="center">
          <Button type="primary" shape="round" onClick={onClick}>
            {t("preSale")}
          </Button>
        </Row>
      </Col>
    </Container>
  );
};

FeaturedProductsContainer.defaultProps = {
  loading: false,
  products: [],
};

FeaturedProductsContainer.propTypes = {
  loading: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      period: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
};
export default FeaturedProductsContainer;
