import styled from "styled-components";
import mediaQueries from "../../utils/mediaQueries";
import MenuItemComponent from "./MenuItem";
import { Divider } from "antd";

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0px;
  top: ${({ isMobile }) => (isMobile ? "74px" : "0")};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 999;
  padding-left: 20px;
  padding-right: 20px;

  @media ${mediaQueries.tablet} {
    display: inline-block;
    position: relative;
    flex-direction: row;
    background-color: none;
  }
`;

export const MenuItem = styled(MenuItemComponent)`
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
`;

export const CloseMenuWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  padding: 10px 20px;
`;

export const DividerList = styled(Divider)`
  margin: 0px;
`;
