import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getPreSaleIsFetching,
  getPresaleDetails,
} from "../features/PreSalePage/PreSaleSelectors";
import useUser from "./useUser";
import { getPreSaleByIdRequest } from "../features/PreSalePage/PreSaleActions";

/**
 * @param {Object} params
 * @param {string} params.preSaleId
 * @param {string} [params.customerId=null]
 */
const usePreSale = (params) => {
  const { preSaleId, customerId = null } = params;
  const loading = useSelector(getPreSaleIsFetching, shallowEqual);
  const preSale = useSelector(getPresaleDetails, shallowEqual);
  const dispatch = useDispatch();
  const { loggedIn } = useUser(customerId);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getPreSaleByIdRequest(preSaleId));
    }
  }, [preSaleId, loggedIn]);

  return {
    loading,
    preSale,
  };
};

export default usePreSale;
