import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClockCircleOutlined } from "@ant-design/icons";
import {
  DateAndTimePickerWrapper,
  TimePickerContainer,
} from "./DateAndTimePickerContainer.styles";
import { isEmpty } from "lodash";
import ExperienceCalendar from "../../components/Calendar/Calendar";
import {
  calculateAverageOccupancyPercentage,
  generateStartAndEndDate,
  groupDates,
  parseDatesAndTime,
  sortOptions,
} from "./helpers";
import { generatePickUpTimeOptions } from "./config";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { createUTCDate } from "../../../../../helpers/dates";
import {
  getAllStartTimesByExperience,
  getAvailabilityDatesByExperience,
  getAvailabilityDatesByExperienceFetching,
} from "../../../redux/selectors/availabilitySelector";
import {
  checkAvailableDatesRequest,
  getStartTimesByExperienceRequest,
  setAvailableDates,
} from "../../../redux/actions/availabilityActions";
import Dropdown from "../../../../../components/Dropdown/Dropdown";
import {
  setDates,
  setStartTime,
} from "../../../../ShopPage/redux/actions/shopActions";
import { getSummary } from "../../../../ShopPage/redux/selectors/ShopSelectors";
import { useTranslation } from "react-i18next";

const DateAndTimePickerContainer = ({
  experienceId,
  durationExperience,
  isSelectedDate,
  onSelectDate,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("experiences");
  const isMobile = useIsMobile();
  const [currentDate, setCurrentDate] = useState(createUTCDate());
  const { startTimeId } = useSelector(getSummary);
  const { startDate, endDate } = generateStartAndEndDate(currentDate);
  const { startTimes } = useSelector((state) =>
    getAllStartTimesByExperience(state)
  );
  const isLoadingAvailableDates = useSelector((state) =>
    getAvailabilityDatesByExperienceFetching(state)
  );
  const availableDates = useSelector((state) =>
    getAvailabilityDatesByExperience(state)
  );
  const startTimeIds = startTimes?.map((startTime) => startTime._id);
  const sanitizedAvailableDates = groupDates(availableDates);

  const matchingObject =
    sanitizedAvailableDates &&
    sanitizedAvailableDates.find((item) => {
      return item.date?.isSame(createUTCDate(currentDate), "day");
    });

  const options = generatePickUpTimeOptions(matchingObject, startTimes);
  useEffect(() => {
    dispatch(getStartTimesByExperienceRequest(experienceId, { active: true }));
    dispatch(setStartTime(null));
  }, [experienceId]);

  useEffect(() => {
    if (!isEmpty(startTimeIds)) {
      dispatch(checkAvailableDatesRequest(startTimeIds, startDate, endDate));
    }
  }, [startDate, endDate, startTimes]);

  const onClickOnDate = (val) => {
    setCurrentDate(createUTCDate(val));
    onSelectDate(true);
    dispatch(setStartTime(null));
  };

  const onChangeMonth = (val) => {
    const { startDate, endDate } = generateStartAndEndDate(val);
    dispatch(setAvailableDates({}));
    dispatch(checkAvailableDatesRequest(startTimeIds, startDate, endDate));
  };

  const onSelectTime = (val) => {
    dispatch(setStartTime(val));
    const time = startTimes?.find((item) => item._id === val).startTime;
    const newDate = parseDatesAndTime(
      time,
      durationExperience.value,
      durationExperience.unit,
      currentDate
    );
    dispatch(
      setDates({
        from: newDate.startDate,
        to: newDate.endDate,
      })
    );
  };

  return (
    <DateAndTimePickerWrapper isMobile={isMobile}>
      <ExperienceCalendar
        onChangeMonth={onChangeMonth}
        onClickOnDate={onClickOnDate}
        availability={calculateAverageOccupancyPercentage(
          sanitizedAvailableDates
        )}
        isLoading={isLoadingAvailableDates}
      />
      <TimePickerContainer>
        <Dropdown
          onChange={onSelectTime}
          value={startTimeId}
          options={sortOptions(options)}
          icon={<ClockCircleOutlined />}
          placeholder={!isSelectedDate ? "" : t("availability.selectTime")}
          disabled={!isSelectedDate}
          loading={!options}
        />
      </TimePickerContainer>
    </DateAndTimePickerWrapper>
  );
};

export default DateAndTimePickerContainer;
