import React from "react";
import PropTypes from "prop-types";
import RichTextRender from "../../../../../components/RichTextRender/RichTextRender";
import Details from "./Details/Details";
import Accordion from "../Accordion/Accordion";
import Includes from "../Includes/Includes";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import baseTheme from "../../../../../config/baseTheme";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

const ExperienceSummary = ({ description, details, accordion }) => {
  const { t } = useTranslation("experiences");
  return (
    <div
      style={{
        paddingTop: 10,
        paddingBottom: 50,
        display: "flex",
        flexDirection: "column",
        gap: 50,
      }}
    >
      <Details details={details} />
      <RichTextRender
        content={description}
        style={{ fontSize: 16, maxWidth: 600, lineHeight: 1.5 }}
      />
      <div>
        {!isEmpty(accordion.whatsIncluded) && (
          <Includes
            items={accordion.whatsIncluded}
            label={t("faqs.whatsIncluded")}
            icon={<CheckOutlined style={{ color: baseTheme.colors.green }} />}
          />
        )}
        {!isEmpty(accordion.whatsNotIncluded) && (
          <Includes
            items={accordion.whatsNotIncluded}
            label={t("faqs.whatsNotIncluded")}
            icon={<CloseOutlined style={{ color: baseTheme.colors.red }} />}
          />
        )}
      </div>
      <Accordion data={accordion} />
    </div>
  );
};

ExperienceSummary.propTypes = {
  description: PropTypes.string,
  details: Details.propTypes.details,
  video: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default ExperienceSummary;
