import React from "react";
import { PERIODS } from "../../../../../helpers/tools";
import DaysCalendarInput from "./PeriodCalendars/DaysCalendarInput";
import PickUpAndDropOffContainer from "../../../containers/PickUpAndDropOffContainer/PickUpAndDropOffContainer";
import HourCalendarInput from "./PeriodCalendars/HourCalendarInput";

const CalendarInputWrapper = ({ period = PERIODS.day, onClick }) => {
  return (
    <PickUpAndDropOffContainer period={period}>
      {({
        supplierSettings,
        fromHours,
        toHours,
        handleOnTimeChange,
        from,
        to,
        isMultibranch,
        supplierGroupsOptions,
        handleSetPickUpAndDropOffBranch,
        pickUpBranch,
        dropOffBranch,
        disabledPickUpBranch,
        pickUpTime,
        dropOffTime,
        quantityTime,
      }) => (
        <div>
          {period === PERIODS.day && (
            <DaysCalendarInput
              from={from}
              to={to}
              fromHours={fromHours}
              toHours={toHours}
              onClick={onClick}
              handleOnTimeChange={handleOnTimeChange}
              supplierGroupsOptions={supplierGroupsOptions}
              pickUpBranch={pickUpBranch}
              dropOffBranch={dropOffBranch}
              disabledPickUpBranch={disabledPickUpBranch}
              disabledDropOffBranch={!isMultibranch}
              handleSetPickUpAndDropOffBranch={handleSetPickUpAndDropOffBranch}
              pickUpTime={pickUpTime}
              dropOffTime={dropOffTime}
              supplierSettings={supplierSettings}
            />
          )}
          {period === PERIODS.hour && (
            <HourCalendarInput
              from={from}
              handleOnTimeChange={handleOnTimeChange}
              fromHours={fromHours}
              onClick={onClick}
              supplierGroupsOptions={supplierGroupsOptions}
              disabledPickUpBranch={disabledPickUpBranch}
              disabledDropOffBranch={!isMultibranch}
              pickUpBranch={pickUpBranch}
              dropOffBranch={dropOffBranch}
              handleSetPickUpAndDropOffBranch={handleSetPickUpAndDropOffBranch}
              pickUpTime={pickUpTime}
              quantityTime={quantityTime}
            />
          )}
        </div>
      )}
    </PickUpAndDropOffContainer>
  );
};

export default CalendarInputWrapper;
