import styled from "styled-components";
import TitleComponent from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import MapComponent from "../../../components/MapComponent/MapComponent";
import ContainerComponent from "../../../components/Container/Container";
import breakpoints from "../../../utils/breakpoints";
import mediaQueries from "../../../utils/mediaQueries";

export const ExperienceDetail = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled(TitleComponent)`
  margin: 0;
  margin-bottom: 0 !important;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1150px;
  width: 100%;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Container = styled(ContainerComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  padding-bottom: 100px;
  padding-top: ${({ removePadding }) => (removePadding ? "0px" : "40px")};
`;

export const Content = styled(ContainerComponent)`
  position: relative;
  display: grid;
  width: 100%;
  max-width: ${breakpoints.largeDesktop}px;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media ${mediaQueries.largeDesktop} {
    grid-template-columns: 2fr 1fr;
  }
`;
export const ContentTwo = styled(ContainerComponent)`
  width: 100%;
  max-width: ${breakpoints.largeDesktop}px;
  margin-top: 30px;
  @media ${mediaQueries.largeDesktop} {
    padding-left: 20px;
  }
`;

export const LocationName = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
`;

export const MapWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 300px;
  background-color: ${({ theme }) => theme.background.dark};
  overflow: hidden;

  @media ${mediaQueries.desktop} {
    border-radius: 30px;
  }
`;

export const Map = styled(MapComponent)`
  width: 100%;
  height: 100%;
`;
