import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentMethodsFromSupplierRequest } from "../../PaymentPage/redux/paymentActions";
import { getPaymentMethods } from "../../PaymentPage/redux/paymentSelectors";

const PaymentIntegrationsContainer = ({ supplierId, children }) => {
  const dispatch = useDispatch();
  const paymentMethods = useSelector(getPaymentMethods);

  useEffect(() => {
    dispatch(getPaymentMethodsFromSupplierRequest(supplierId));
  }, []);

  return children({
    paymentMethods,
  });
};

PaymentIntegrationsContainer.propTypes = {
  supplierId: PropTypes.string,
  children: PropTypes.func.isRequired,
};

export default PaymentIntegrationsContainer;
