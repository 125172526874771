import {
  EXCHANGE_CONVERTER_FAILURE,
  EXCHANGE_CONVERTER_REQUEST,
  EXCHANGE_CONVERTER_RESET,
  EXCHANGE_CONVERTER_SUCESSS,
} from "./paymentActions";

export const INITIAL_STATE = {
  fetching: false,
  error: null,
  success: false,
  convertedCurrency: null,
};

export const exchangeConverterReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case EXCHANGE_CONVERTER_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
        success: false,
        convertedCurrency: null,
      };
    case EXCHANGE_CONVERTER_SUCESSS:
      return {
        ...state,
        fetching: false,
        success: true,
        error: null,
        convertedCurrency: payload,
      };
    case EXCHANGE_CONVERTER_FAILURE:
      return {
        ...state,
        fetching: false,
        error: payload ? payload.message : true,
        success: false,
        convertedCurrency: null,
      };
    case EXCHANGE_CONVERTER_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default exchangeConverterReducer;
