import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../../services/mixpanel";
import LayoutWithDataContainer from "../../../containers/LayoutWithDataContainer";
import { ScrollRestoration } from "react-router-dom";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import { Container, SpacesWrapper, TitleSpaces } from "../list/styles/Styles";
import SpacesContent from "../list/containers/SpacesContent";
import useSpaces from "../../../hooks/useSpaces";
import Paragraph from "antd/es/typography/Paragraph";
import { resetSelectedDates } from "../../../Redux/Actions/searchActions";
import {
  resetAssignments,
  resetSummaryProducts,
} from "../../ShopPage/redux/actions/shopActions";
import {
  getAssigmentsData,
  getSummaryProducts,
} from "../../ShopPage/redux/selectors/ShopSelectors";

const SpacesListPageScreen = ({ isIframe }) => {
  const { t } = useTranslation(["common", "spaces"]);
  const dispatch = useDispatch();
  const supplier = useSelector(getSupplierData);
  const { spaces, loading } = useSpaces();
  const summaryProducts = useSelector(getSummaryProducts, shallowEqual);
  const assignments = useSelector(getAssigmentsData, shallowEqual);

  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "SpacesPage",
      supplier: supplier.name,
      supplierId: supplier._id,
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(summaryProducts)) {
      dispatch(resetSelectedDates());
      dispatch(resetSummaryProducts());
    }
    if (!isEmpty(assignments)) {
      dispatch(resetAssignments());
    }
  }, [summaryProducts, assignments]);

  return (
    <LayoutWithDataContainer isIframe={isIframe}>
      <Container>
        <SpacesWrapper>
          <TitleSpaces level={4}>{t("spaces:spaces_title")}</TitleSpaces>
          <Paragraph>
            Contamos con servicio de proveeduría, servicio de duchas, alquiler
            de carpa y luces de carga solar. Consultar disponibilidad.
          </Paragraph>
          <SpacesContent spaces={spaces} loading={loading} />
        </SpacesWrapper>
      </Container>
      <ScrollRestoration />
    </LayoutWithDataContainer>
  );
};

export default SpacesListPageScreen;
