import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getPrevDaysForBookingConfig,
  getSupplierData,
  getSupplierSettings,
} from "../../../../Redux/Selectors/appConfigSelector";
import {
  getPickUpAndDropOffTime,
  getQuantityTime,
  getSearchValueByKey,
} from "../../../../Redux/Selectors/searchSelectors";
import { RENTALS, isRental } from "../../../../helpers/rentals";
import { createUTCDate, PERIODS } from "../../../../helpers/dates";
import { modifyValueSearch } from "../../../../Redux/Actions/searchActions";
import { calculatePrevDaysForBooking } from "../../utils";
import { getSupplierGroupsRequest } from "../../../CheckInPage/redux/supplierGroups/supplierGroupsActions";
import { getAllSupplierGroups } from "../../../CheckInPage/redux/supplierGroups/supplierGroupsSelector";
import { branchOptionsGenerator } from "../../../CheckInPage/helpers/helpers";
import { setPickUpAndDropOffBranch } from "../../redux/actions/shopActions";
import { getBranchSelected } from "../../redux/selectors/ShopSelectors";
import { isEmpty } from "lodash";

const PickUpAndDropOffContainer = ({ children, period }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const dates = useSelector((state) => getSearchValueByKey(state, "dates"));
  const { from, to } = dates;
  const supplierData = useSelector(getSupplierData);
  const supplierSettings = useSelector(getSupplierSettings, shallowEqual);
  const prevDaysForBooking = useSelector((state) =>
    getPrevDaysForBookingConfig(state, shallowEqual)
  );
  const supplierGroups = useSelector(getAllSupplierGroups, shallowEqual);
  const isMultibranch = !isEmpty(supplierGroups);
  const { pickUpBranch, dropOffBranch } = useSelector(
    getBranchSelected,
    shallowEqual
  );
  const { pickUpTime, dropOffTime } = useSelector(
    getPickUpAndDropOffTime,
    shallowEqual
  );
  const quantityTime = useSelector(getQuantityTime, shallowEqual);
  const hostSupplierOption = [
    {
      value: supplierData._id,
      label: supplierData?.name,
    },
  ];
  const supplierGroupsOptions = !isMultibranch
    ? hostSupplierOption
    : branchOptionsGenerator(supplierGroups, supplierData);

  const initialFrom = calculatePrevDaysForBooking(
    from,
    prevDaysForBooking,
    PERIODS.day
  );
  const initialTo = calculatePrevDaysForBooking(
    to,
    prevDaysForBooking,
    PERIODS.day
  );
  useEffect(() => {
    dispatch(
      getSupplierGroupsRequest(
        { active: true },
        ["members", "admin"],
        supplierData?._id
      )
    );
  }, []);

  // High Mountain Config
  const isFromLimited =
    createUTCDate(from).isSameOrAfter(createUTCDate("2023-09-15"), "day") &&
    createUTCDate(from).isSameOrBefore(createUTCDate("2023-09-17"), "day");
  const isToLimited =
    createUTCDate(to).isSameOrAfter(createUTCDate("2023-09-15"), "day") &&
    createUTCDate(to).isSameOrBefore(createUTCDate("2023-09-17"), "day");

  const fromHours = JSON.parse(supplierData.openingHours)[
    `hours_${moment(from).format("dddd").toLocaleLowerCase()}`
  ];
  const toHours = JSON.parse(supplierData.openingHours)[
    `hours_${moment(to).format("dddd").toLocaleLowerCase()}`
  ];

  const calculateFromHours = () => {
    if (
      isFromLimited &&
      isRental(supplierData._id, RENTALS.highmountain_santiago)
    ) {
      return [
        {
          ...fromHours[0],
          from: "10:00",
          to: "14:00",
        },
      ];
    }
    return fromHours;
  };

  const calculateToHours = () => {
    if (
      isToLimited &&
      isRental(supplierData._id, RENTALS.highmountain_santiago)
    ) {
      return [
        {
          ...toHours[0],
          from: "10:00",
          to: "14:00",
        },
      ];
    }
    return toHours;
  };

  const handleOnTimeChange = (key, val) => {
    dispatch(modifyValueSearch(key, val));
  };

  const handleSetPickUpAndDropOffBranch = (branch, key, branchData) => {
    dispatch(setPickUpAndDropOffBranch(branch, key, branchData?.label));
  };

  const handleShowModal = () => {
    setOpenModal(!openModal);
  };
  return children({
    supplierSettings,
    from: initialFrom,
    to: initialTo,
    fromHours: calculateFromHours(),
    toHours: calculateToHours(),
    handleOnTimeChange,
    supplierGroupsOptions,
    handleSetPickUpAndDropOffBranch,
    pickUpBranch: pickUpBranch._id,
    dropOffBranch: dropOffBranch._id,
    disabledPickUpBranch: !isMultibranch, // Todo change with setting/subcription
    openModal,
    handleShowModal,
    pickUpTime,
    dropOffTime,
    quantityTime,
    isMultibranch,
  });
};

export default PickUpAndDropOffContainer;
