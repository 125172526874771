import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRegionLocalStorage } from "../helpers/tools";
import { setWebsiteRegion } from "../Redux/Actions/regionActions";
import { getWebsiteRegion } from "../Redux/Selectors/regionSelector";

const useWebsiteRegion = () => {
  const dispatch = useDispatch();
  const region = useSelector(getWebsiteRegion);

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query.region) {
      setRegion(query.region);
      localStorage.setItem("region", query.region);
      return;
    }
    setRegion(getRegionLocalStorage() ?? null);
  }, []);

  const setRegion = (region) => {
    if (region) {
      localStorage.setItem("region", region);
    } else {
      localStorage.removeItem("region");
    }
    dispatch(setWebsiteRegion(region));
  };

  return {
    region,
    setRegion,
  };
};

export default useWebsiteRegion;
