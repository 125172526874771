import MainMemberScreen from "./MainMemberScreen";
import { Route, Routes } from "react-router-dom";
import ParticipantsScreen from "./ParticipantsScreen";
import ProductsScreen from "./ProductsScreen";

const Router = ({ onSubmitMainMember }) => {
  return (
    <Routes>
      <Route
        element={<MainMemberScreen onSubmitMainMember={onSubmitMainMember} />}
        path="/"
      />
      <Route element={<ParticipantsScreen />} path="/participants" />
      <Route element={<ProductsScreen />} path="/checkout" />
    </Routes>
  );
};

export default Router;
