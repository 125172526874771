import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { exchangeConverterRequest } from "../features/PaymentPage/redux/paymentActions";
import {
  exchangeConverterIsFetching,
  getExchangeConverterResult,
} from "../features/PaymentPage/redux/paymentSelectors";

/**
 * @param {Object} params
 * @param {number} params.paymentAmount
 * @param {string} params.paymentCurrency
 * @param {string} [params.guestCurrency]
 * @returns {{
 *  loading: boolean,
 *  amount: number,
 *  currency: string,
 * }}
 */
const usePaymentExchange = ({
  paymentAmount,
  subtotalAmount,
  discountAmount,
  paymentCurrency,
  guestCurrency,
}) => {
  const dispatch = useDispatch();
  const fetchingExchange = useSelector(
    exchangeConverterIsFetching,
    shallowEqual
  );
  const exchangeConversion = useSelector(
    getExchangeConverterResult,
    shallowEqual
  );
  const [amount, setAmount] = useState(0);
  const [subtotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(null);
  const [currency, setCurrency] = useState();

  useEffect(() => {
    if (!paymentAmount || !paymentCurrency) return;

    const finalPaymentCurrency = getPaymentCurrency(
      paymentCurrency,
      guestCurrency
    );

    if (finalPaymentCurrency === paymentCurrency) {
      setAmount(paymentAmount);
      setCurrency(paymentCurrency);
      return;
    }
    dispatch(exchangeConverterRequest(1, paymentCurrency, guestCurrency));
  }, [paymentAmount]);

  useEffect(() => {
    if (fetchingExchange || isEmpty(exchangeConversion)) {
      return;
    }
    setAmount(exchangeConversion.amount * paymentAmount);
    setSubTotal(exchangeConversion.amount * subtotalAmount);
    discountAmount && setDiscount(exchangeConversion.amount * discountAmount);
    setCurrency(exchangeConversion.currency);
  }, [exchangeConversion, fetchingExchange]);

  /**
   * Returns the payment currency
   * @param {string} transactionCurrency
   * @param {string} [guestCurrency]
   * @returns {string}
   */
  const getPaymentCurrency = (transactionCurrency, guestCurrency) => {
    const hostCurrency = transactionCurrency.toUpperCase();
    const userCurrency = guestCurrency
      ? guestCurrency.toUpperCase()
      : hostCurrency;
    if (userCurrency === "ARS" && userCurrency === hostCurrency) {
      return userCurrency;
    } else if (userCurrency !== hostCurrency) {
      return userCurrency;
    } else if (hostCurrency === "ARS" && userCurrency !== hostCurrency) {
      return userCurrency;
    }
    return hostCurrency;
  };
  return {
    loading: fetchingExchange,
    amount: Number(amount),
    subtotal: Number(subtotal),
    currency,
    discount,
  };
};

export default usePaymentExchange;
