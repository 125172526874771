import { combineReducers } from "redux";
import highlightProductsReducer from "../../features/HomePage/redux/reducers/highlightProductsReducer";
import exchangeConverterReducer from "../../features/PaymentPage/redux/converterReducer";
import paymentActionReducer from "../../features/PaymentPage/redux/paymentActionReducer";
import paymentCreationReducer from "../../features/PaymentPage/redux/paymentCreationReducer";
import transactionPaymentReducer from "../../features/PaymentPage/redux/paymentReducer";
import PaymentScreenReducer from "../../features/PaymentPage/redux/paymentScreenReducer";
import buildShopReducer from "../../features/ShopPage/redux/reducers/BuildShopReducer";
import mainMemberReducer from "../../features/ShopPage/redux/reducers/MainMemberReducer";
import pricingReducer from "../../features/ShopPage/redux/reducers/PricingShopReducer";
import productModalReducer from "../../features/ShopPage/redux/reducers/ProductModalReducer";
import productsShopReducer from "../../features/ShopPage/redux/reducers/ProductsShopReducer";
import summaryShopReducer from "../../features/ShopPage/redux/reducers/SummaryShopReducer";
import transactionStatusReducer from "../../features/ShopPage/redux/reducers/transactionStatusReducer";
import variantsShopReducer from "../../features/ShopPage/redux/reducers/VariantsShopReducer";
import transactionReducer from "../../features/TransactionPage/transactionReducer";
import appConfigReducer from "./appConfigReducer";
import entitiesReducer from "./entitiesReducer";
import searchReducer from "./searchReducer";
import supplierSettingsReducer from "./supplierSettingsReducer";
import bundlesShopReducer from "../../features/ShopPage/redux/reducers/BundlesShopReducer";
import { quoteReducer } from "../../features/QuotePage/redux/QuoteReducer";
import experiencesListReducer from "../../features/ExperiencesPage/redux/reducers/ExperiencesListReducer";
import experienceDetailReducer from "../../features/ExperiencesPage/redux/reducers/ExperienceDetailReducer";
import availabilityReducer from "../../features/ExperiencesPage/redux/reducers/availabilityReducer";
import discountReducer from "../../features/ShopPage/redux/reducers/DiscountReducer";
import availabilityShopReducer from "../../features/ShopPage/redux/reducers/AvailabilityReducer";
import languageReducer from "./languageReducer";
import currencyReducer from "./currencyReducer";
import spacesListReducer from "../../features/SpacesPage/redux/reducers/spacesListReducer";
import spaceDetailReducer from "../../features/SpacesPage/redux/reducers/spaceDetailReducer";
import spaceAvailabilityReducer from "../../features/SpacesPage/redux/reducers/spaceAvailabilityReducer";
import preSaleReducer from "../../features/PreSalePage/PreSaleReducer";
import reviewsReducer from "../../features/HomePage/redux/reducers/reviewsReducer";
import regionReducer from "./regionReducer";
import checkinReducer from "../../features/CheckInPage/redux/checkinReducer";
import supplierGroupsReducer from "../../features/CheckInPage/redux/supplierGroups/supplierGroupsReducer";
import subscriptionReducer from "./subscriptionReducer";

export default combineReducers({
  appGlobal: combineReducers({
    config: appConfigReducer,
    settings: supplierSettingsReducer,
    language: languageReducer,
    currency: currencyReducer,
    region: regionReducer,
    subscription: subscriptionReducer,
  }),

  pages: combineReducers({
    homePage: combineReducers({
      highlightProducts: highlightProductsReducer,
      reviews: reviewsReducer,
    }),
    checkin: combineReducers({
      details: checkinReducer,
      supplierGroups: supplierGroupsReducer,
    }),
    experiences: combineReducers({
      list: experiencesListReducer,
      detail: experienceDetailReducer,
      availability: availabilityReducer,
    }),
    spaces: combineReducers({
      list: spacesListReducer,
      detail: spaceDetailReducer,
      availability: spaceAvailabilityReducer,
    }),
    shop: combineReducers({
      products: productsShopReducer,
      bundles: bundlesShopReducer,
      summary: summaryShopReducer,
      pricing: pricingReducer,
      build: buildShopReducer,
      variants: variantsShopReducer,
      productModal: productModalReducer,
      mainMember: mainMemberReducer,
      transactionStatus: transactionStatusReducer,
      discounts: discountReducer,
      availability: availabilityShopReducer,
    }),
    payment: combineReducers({
      paymentData: PaymentScreenReducer,
      payTransactionScreen: transactionPaymentReducer,
      createPayment: paymentCreationReducer,
      paymentAction: paymentActionReducer,
    }),
    quote: quoteReducer,
    preSale: preSaleReducer,
    transaction: transactionReducer,
    exchange: exchangeConverterReducer,
  }),
  context: combineReducers({
    search: searchReducer,
  }),
  entities: entitiesReducer,
});
