import React from "react";
import DaysItinerary from "./DaysItinerary/DaysItinerary";
import { Container } from "./DetailsExperience.styles";
import InfoExtraItinerary from "./InfoExtraItinerary/InfoExtraItinerary";
import { Divider } from "antd";
import useIsMobile from "../../../../../../../../hooks/useIsMobile";
import PropTypes from "prop-types";

const DetailsExperience = ({ experienceDetails }) => {
  const isMobile = useIsMobile();

  return (
    <Container removeWidth={isMobile}>
      <DaysItinerary
        title={experienceDetails?.title}
        infoHours={experienceDetails?.timeLine}
      />
      {experienceDetails?.extra && (
        <InfoExtraItinerary description={experienceDetails?.extra} />
      )}
      <Divider />
    </Container>
  );
};

export default DetailsExperience;

DetailsExperience.propTypes = {
  experecienceDetails: PropTypes.object,
};
