import React from "react";
import { Logo } from "../../../components/Navbar/Navbar.styles";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import { LanguageWrapper, NavbarContainer } from "./Navbar.styles";
import GoBack from "./GoBack";
import { useLocation } from "react-router-dom";
import ButtonLanguageContainer from "../../../components/ButtonLanguage/ButtonLanguageContainer";

const Navbar = () => {
  const { logo } = useSelector(getSupplierData);
  const location = useLocation();
  const shouldRenderGoBack = location.pathname !== "/checkin";
  return (
    <NavbarContainer>
      {shouldRenderGoBack && <GoBack />}
      <Logo src={logo} />
      <LanguageWrapper>
        <ButtonLanguageContainer />
      </LanguageWrapper>
    </NavbarContainer>
  );
};

export default Navbar;
