import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";

export const Image = styled.img`
  height: 80px;
  object-fit: contain;

  @media ${mediaQueries.desktop} {
    max-width: 200px;
    height: 120px;
    object-fit: contain;
  }
`;
