import React from "react";
import {
  ColumnWrapper,
  StyledRow,
  StyledText,
  StyledTitle,
} from "./PickUpAndDropOff.styles";
import BootstrapIcon from "../BootstrapIcon/BoostrapIcon";
import { createUTCDate } from "../../helpers/dates";
import { ShopOutlined } from "@ant-design/icons";

const PickUpAndDropOff = ({
  title,
  branchName,
  selectedDate,
  selectedTime,
}) => {
  return (
    <div>
      <StyledTitle>{title}</StyledTitle>
      <StyledRow>
        <ColumnWrapper>
          <ShopOutlined style={{ fontSize: 16 }} />
          <StyledText>{branchName}</StyledText>
        </ColumnWrapper>

        <ColumnWrapper>
          <BootstrapIcon iconName="calendar3" style={{ fontSize: 14 }} />
          <StyledText>
            {createUTCDate(selectedDate).format("DD MMM YY")}
          </StyledText>
        </ColumnWrapper>

        <ColumnWrapper>
          <BootstrapIcon iconName="clock" style={{ fontSize: 14 }} />
          <StyledText> {selectedTime ?? "00:00"} hs.</StyledText>
        </ColumnWrapper>
      </StyledRow>
    </div>
  );
};

export default PickUpAndDropOff;
