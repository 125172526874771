export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAILURE = "GET_REVIEWS_FAILURE";

export const getReviewRequest = (placeId, provider) => {
  return {
    type: GET_REVIEWS_REQUEST,
    payload: {
      placeId,
      provider
    },
  };
};

export const getReviewSuccess = (results) => {
  return {
    type: GET_REVIEWS_SUCCESS,
    payload: {
      results,
    },
  };
};

export const getReviewFailure = (error) => {
  return {
    type: GET_REVIEWS_FAILURE,
    payload: {
      error,
    },
  };
};
