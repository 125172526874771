import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import CalendarInputWrapper from "./CalendarInputWrapper";
import { PERIODS } from "../../../../../helpers/tools";
import { ServiceDetailsWrapper } from "./CalendarInput.styles";
import PickUpAndDropOffModal from "./PickUpAndDropOffModal/PickUpAndDropOffModal";
import { getPickUpAndDropOffTime } from "../../../../../Redux/Selectors/searchSelectors";

const CalendarInput = ({
  handleShowModal,
  period = PERIODS.day,
  showPickUpAndDropOffModal,
  handleShowPickUpAndDropOffModal,
}) => {
  const { pickUpTime, dropOffTime } = useSelector(
    getPickUpAndDropOffTime,
    shallowEqual
  );

  return (
    <div>
      <ServiceDetailsWrapper>
        <PickUpAndDropOffModal
          openModal={showPickUpAndDropOffModal}
          handleShowModal={handleShowPickUpAndDropOffModal}
          period={period}
          onClick={() => handleShowModal("calendar")}
          pickUpTime={pickUpTime}
          dropOffTime={dropOffTime}
        />

        {!showPickUpAndDropOffModal && (
          <CalendarInputWrapper
            onClick={() => handleShowModal("calendar")}
            period={period}
          />
        )}
      </ServiceDetailsWrapper>
    </div>
  );
};

export default CalendarInput;
