import {
  CHECK_VARIANTS_STOCK_REQUEST,
  CHECK_VARIANTS_STOCK_SUCCESS,
  RESET_CHECK_VARIANTS_STOCK,
} from "../actions/AvailabiltyActions";
import { setVariantsStock } from "../helpers/helpers";

export const INITIAL_STATE = {
  stock: {},
  fetching: false,
};

export const availabilityShopReducer = (state = INITIAL_STATE, action) => {
  const { payload: { results } = {} } = action;
  switch (action.type) {
    case CHECK_VARIANTS_STOCK_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case CHECK_VARIANTS_STOCK_SUCCESS:
      return {
        ...state,
        stock: setVariantsStock(state.stock, results),
        fetching: false,
      };
    case RESET_CHECK_VARIANTS_STOCK:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default availabilityShopReducer;
