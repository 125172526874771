export const getSearchValues = (state) => {
  const { search } = state.context;
  return search;
};

export const getSearchValueByKey = (state, key) => {
  const { search } = state.context;
  return search[key];
};

export const getPickUpAndDropOffTime = (state) => {
  const pickUpTime = state.context.search?.pickupTime ?? null;
  const dropOffTime = state.context.search?.dropoffTime ?? null;
  return {
    pickUpTime,
    dropOffTime,
  };
};

export const getQuantityTime = (state) => {
  const quantityTime = state.context.search?.quantityTime ?? null;
  return quantityTime;
};
