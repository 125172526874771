import React from "react";
import PropTypes from "prop-types";
import { Card } from "antd";
import { getStatusColor } from "../utils";
import HeaderCard from "./HeaderCard";
import FooterCard from "./FooterCard";
import BodyCard from "./BodyCard";

const PaymentResult = ({
  status,
  title,
  subtitle,
  onClick,
  buttonText,
  children,
  ...restProps
}) => {
  const bgColor = getStatusColor(status);
  return (
    <Card
      headStyle={{
        background: bgColor,
        height: "50px",
        display: "flex",
        justifyContent: "center",
      }}
      title={<HeaderCard status={status} />}
      bodyStyle={{ height: "400px" }}
      actions={[<FooterCard onClick={onClick} buttonText={buttonText} />]}
      {...restProps}
    >
      <BodyCard title={title} subtitle={subtitle}>
        {children}
      </BodyCard>
    </Card>
  );
};

PaymentResult.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default PaymentResult;
