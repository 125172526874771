import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Space from "antd/es/space";
import Button from "../Button/Button";
import MenuComponent from "../../components/Navbar/Menu";
import useBreakpoints from "../../hooks/useBreakpoints";
import BootstrapIcon from "../BootstrapIcon/BoostrapIcon";
import {
  Wrapper,
  Logo,
  HeaderGroup,
  Container,
  Menu,
  HamburgerButton,
} from "./Navbar.styles";
import { RENTALS, isAnyHighMountain, isRental } from "../../helpers/rentals";
import { useSelector } from "react-redux";
import {
  getButtonCustom,
  getSupplierData,
} from "../../Redux/Selectors/appConfigSelector";
import MultiBranchSelector from "../MultiBranchSelector/MultiBranchSelector";
import ButtonLanguageContainer from "../ButtonLanguage/ButtonLanguageContainer";
import MenuOverlay from "./MenuOverlay";

const Navbar = ({
  logo,
  showMenu,
  setShowMenu,
  onClickAction,
  menuItems,
  renderButtons,
  ...restProps
}) => {
  const supplierData = useSelector(getSupplierData);
  const { buttonText, linkButton, enabled, theme } = useSelector((state) =>
    getButtonCustom(state)
  );
  const [showOverlay, setShowOverlay] = useState(false);
  const { t } = useTranslation(["common"]);
  const { isBiggerThanTablet, isMobile } = useBreakpoints();

  const switchMenuState = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (!isBiggerThanTablet) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, [isBiggerThanTablet]);

  useEffect(() => {
    if (showMenu && !isBiggerThanTablet) {
      setShowOverlay(true);
    } else {
      setShowOverlay(false);
    }
  }, [showMenu, isBiggerThanTablet]);

  const renderLogo = () => {
    const showMultiBranch = isAnyHighMountain(supplierData._id);

    if (showMultiBranch) {
      return <MultiBranchSelector />;
    } else {
      return (
        <Link to="/">
          <Logo src={logo} preview={false} alt={supplierData?.name} />
        </Link>
      );
    }
  };

  const renderButton = () => {
    if (isRental(supplierData._id, RENTALS.ananda)) {
      return t("rentBike");
    }
    return t("rentGear");
  };

  return (
    <Wrapper {...restProps}>
      <MenuOverlay closeMenu={switchMenuState} isOpen={showOverlay} />
      <Container>
        <HeaderGroup>
          {renderLogo()}
          <ButtonLanguageContainer />
        </HeaderGroup>
        <HeaderGroup>
          <Space size="small">
            {isRental(RENTALS.campingLaIndia, supplierData._id) && (
              <Button
                type="primary"
                size={isBiggerThanTablet ? null : "large"}
                shape={"round"}
                onClick={onClickAction}
              >
                {t("bookIt")}
              </Button>
            )}
            {showMenu && (
              <Menu
                isMobile={isMobile}
                onClose={switchMenuState}
                items={menuItems}
              />
            )}
            {enabled && isBiggerThanTablet && (
              <a href={linkButton} target="_blank" rel="noreferrer">
                <Button
                  type="primary"
                  size={"medium"}
                  shape={"round"}
                  style={{
                    backgroundColor: theme?.colors?.primary,
                    border: "#FF8200",
                    color: "white",
                  }}
                >
                  {buttonText}
                </Button>
              </a>
            )}
            {renderButtons &&
              !isRental(RENTALS.highPeak, supplierData._id) &&
              !isRental(RENTALS.spAventuras, supplierData._id) &&
              !isRental(RENTALS.rumboNorte, supplierData._id) &&
              !isRental(RENTALS.campingLaIndia, supplierData._id) &&
              !isRental(RENTALS.intiAventura, supplierData._id) &&
              !isRental(RENTALS.bicitarte, supplierData._id) &&
              !isRental(RENTALS.villavicencio, supplierData._id) && (
                <Button
                  type="primary"
                  size={isBiggerThanTablet ? "large" : "medium"}
                  shape={"round"}
                  onClick={onClickAction}
                >
                  {renderButton()}
                </Button>
              )}
            {!isBiggerThanTablet && renderButtons && (
              <HamburgerButton
                type="text"
                icon={<BootstrapIcon iconName={showMenu ? "x-lg" : "list"} />}
                size="large"
                onClick={switchMenuState}
              />
            )}
          </Space>
        </HeaderGroup>
      </Container>
    </Wrapper>
  );
};

Navbar.defaultProps = {
  showMenu: false,
  setShowMenu: () => {},
  menuItems: null,
  renderButtons: true,
};

Navbar.propTypes = {
  logo: PropTypes.string.isRequired,
  onClickAction: PropTypes.func,
  menuItems: MenuComponent.propTypes.items,
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
  renderButtons: PropTypes.bool,
};

export default Navbar;
