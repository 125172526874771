import Container from "../../../../components/Container/Container";
import { ContainerImages } from "../PaymentIntegrations/PaymentIntegrations.styles";
import { Image } from "./SponsorImages.styles";

const SponsorImages = ({ sponsorImages = [] }) => {
  if (
    !sponsorImages ||
    !Array.isArray(sponsorImages) ||
    sponsorImages.length === 0
  )
    return null;

  const renderSponsorImages = sponsorImages.map((item, index) => {
    return (
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          gap: 5,
          width: "auto",
        }}
      >
        <Image key={index} src={item.url} alt={`${item.alt}`} />
      </div>
    );
  });

  return (
    <Container>
      <ContainerImages
        gap={sponsorImages.length > 2 ? "100px" : "20px"}
        justifyContent={sponsorImages.length > 2 ? "center" : "space-around"}
      >
        {renderSponsorImages}
      </ContainerImages>
    </Container>
  );
};

export default SponsorImages;
