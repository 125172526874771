import baseTheme from "../../config/baseTheme";
import { transactionStatus as transactionStatusConstants } from "../TransactionPage/helpers";
import { PAYMENT_METHOD_IDENTIFIERS } from "./PaymentMethods";

export const cardStatus = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  PENDING: "pending",
});

export const PAYMENT_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  AUTHORIZED: "AUTHORIZED",
};

/**
 * @param {string} status
 * @returns {string}
 */
export const getResultTitleLabel = (status) => {
  if (status === PAYMENT_STATUS.ACCEPTED) {
    return "paymentAcceptedTitle";
  } else if (status === PAYMENT_STATUS.AUTHORIZED) {
    return "paymentAuthorizedTitle";
  }
  return "paymentDeclinedTitle";
};

/**
 *
 * @param {string} transactionStatus
 * @param {string} paymentMethodIdentifier
 * @returns {string}
 */

export const getPaymentAction = (
  transactionStatus,
  paymentMethodIdentifier
) => {
  const isPaymentValid = transactionStatus && paymentMethodIdentifier;
  if (!isPaymentValid) return null;

  if (
    paymentMethodIdentifier === PAYMENT_METHOD_IDENTIFIERS.mpCreditCard &&
    (transactionStatus === transactionStatusConstants.PREAUTHORIZED ||
      transactionStatus === transactionStatusConstants.PENDING_APPROVAL)
  ) {
    return "authorize";
  }
  return "accept";
};

export const getStatusData = (status) => {
  switch (status) {
    case cardStatus.SUCCESS:
      return {
        isSuccess: true,
        titleLabel: "paymentSuccessTitle",
        buttonLabel: "viewMyBooking",
      };
    case cardStatus.PENDING:
      return {
        isSuccess: true,
        titleLabel: "paymentPendingTitle",
        buttonLabel: "viewMyBooking",
      };
    default:
      return {
        isSuccess: false,
        titleLabel: "paymentErrorTitle",
        buttonLabel: "retryPayment",
      };
  }
};
/**
 * @param {string} status
 * @returns {string}
 */
export const getDescriptionLabel = (status) => {
  if (status === PAYMENT_STATUS.ACCEPTED) {
    return "";
  } else if (status === PAYMENT_STATUS.AUTHORIZED) {
    return "paymentAuthorizedDescription";
  }
  return "paymentDeclinedDescription";
};

/**
 * @param {string} status
 * @returns {boolean}
 */
export const isPaymentError = (status) =>
  status !== PAYMENT_STATUS.ACCEPTED && status !== PAYMENT_STATUS.AUTHORIZED;

export const getStatusColor = (status) => {
  if (status === cardStatus.SUCCESS) return baseTheme.colors.success;
  if (status === cardStatus.ERROR) return baseTheme.colors.warning;
  if (status === cardStatus.PENDING) return baseTheme.colors.yellow;
};

export const calculateRateQuantities = (assignment, rate) => {
  return assignment.reduce(
    (total, participant) => (rate === participant?.rate ? total + 1 : total),
    0
  );
};
