export const GET_EXPERIENCES_REQUEST = "GET_EXPERIENCES_REQUEST";
export const GET_EXPERIENCES_SUCCESS = "GET_EXPERIENCES_SUCCESS";
export const GET_EXPERIENCES_FAILURE = "GET_EXPERIENCES_FAILURE";

export const getExperiencesRequest = (params, currency) => {
  return {
    type: GET_EXPERIENCES_REQUEST,
    payload: {
      params,
      currency,
    },
  };
};

export const getExperiencesSuccess = (results) => {
  return {
    type: GET_EXPERIENCES_SUCCESS,
    payload: {
      results,
    },
  };
};
export const getExperiencesFailure = (error) => {
  return {
    type: GET_EXPERIENCES_FAILURE,
    payload: {
      error,
    },
  };
};

export const GET_EXPERIENCE_DETAIL_REQUEST = "GET_EXPERIENCE_DETAIL_REQUEST";
export const GET_EXPERIENCE_DETAIL_SUCCESS = "GET_EXPERIENCE_DETAIL_SUCCESS";
export const GET_EXPERIENCE_DETAIL_FAILURE = "GET_EXPERIENCE_DETAIL_FAILURE";

export const getExperienceDetailRequest = (experienceId, currency) => {
  return {
    type: GET_EXPERIENCE_DETAIL_REQUEST,
    payload: {
      experienceId,
      currency,
    },
  };
};

export const getExperienceDetailSuccess = (experienceId) => {
  return {
    type: GET_EXPERIENCE_DETAIL_SUCCESS,
    payload: {
      experienceId,
    },
  };
};
export const getExperienceDetailFailure = (error) => {
  return {
    type: GET_EXPERIENCE_DETAIL_FAILURE,
    payload: {
      error,
    },
  };
};
