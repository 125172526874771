export const PRICE_TYPES = Object.freeze({
  adult: "adult",
  child: "child",
  senior: "senior",
});

export const EXPERIENCE_DURATION = Object.freeze({
  PER_DAY: "perDay",
  PER_ACTIVITY: "perActivity",
});
