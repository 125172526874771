export const SAVE_MAIN_MEMBER_INFO = "SAVE_MAIN_MEMBER_INFO";
export const CREATE_NEW_CUSTOMER_REQUEST = "CREATE_NEW_CUSTOMER_REQUEST";
export const CREATE_NEW_CUSTOMER_SUCCESS = "CREATE_NEW_CUSTOMER_SUCCESS";
export const SIGN_IN_CUSTOMER_REQUEST = "SIGN_IN_CUSTOMER_REQUEST";
export const SIGN_IN_CUSTOMER_SUCCESS = "SIGN_IN_CUSTOMER_SUCCESS";

export const saveMainMemberInfo = (userData) => {
  return {
    type: SAVE_MAIN_MEMBER_INFO,
    payload: {
      ...userData,
    },
  };
};

export const createNewCustomerRequest = (userData) => {
  return {
    type: CREATE_NEW_CUSTOMER_REQUEST,
    payload: {
      ...userData,
    },
  };
};

export const createNewCustomerSuccess = (userData) => {
  return {
    type: CREATE_NEW_CUSTOMER_SUCCESS,
    payload: {
      ...userData,
    },
  };
};

export const signInCustomerRequest = (customerId) => {
  return {
    type: SIGN_IN_CUSTOMER_REQUEST,
    payload: {
      customerId,
    },
  };
};

export const signInCustomerSuccess = () => {
  return {
    type: SIGN_IN_CUSTOMER_SUCCESS,
  };
};
