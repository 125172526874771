import styled, { css } from "styled-components";
import Space from "antd/es/space";
import Input from "antd/es/input";
import Col from "antd/es/col";
import ContainerComponent from "../../../../components/Container/Container";
import MapComponent from "../../../../components/MapComponent/MapComponent";
import mediaQueries from "../../../../utils/mediaQueries";
import { hexToRGB, rgbObjectToStringFormat } from "../../../../helpers/colors";

export const MapWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 251px;
  background-color: ${({ theme }) => theme.background.dark};
  overflow: hidden;

  @media ${mediaQueries.desktop} {
    height: 400px;
    border-radius: 30px;
  }
`;

export const Map = styled(MapComponent)`
  width: 100%;
  height: 100%;
`;

export const AddressLink = styled.a`
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Container = styled(ContainerComponent)`
  position: relative;
  overflow: hidden;
  ${({ noPadding }) =>
    noPadding
      ? css`
          padding: 0px !important;
        `
      : css`
          padding-bottom: 70px;
        `}

  @media ${mediaQueries.desktop} {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: auto;
      padding-bottom: 100%;
      bottom: 0;
      left: 50%;
      border-radius: 100%;
      background: linear-gradient(
        180deg,
        rgba(
            ${({ theme }) =>
              rgbObjectToStringFormat(hexToRGB(theme.colors.primary))},
            0.13
          )
          0%,
        rgba(
            ${({ theme }) =>
              rgbObjectToStringFormat(hexToRGB(theme.colors.primary))},
            0.05
          )
          20%,
        rgba(
            ${({ theme }) =>
              rgbObjectToStringFormat(hexToRGB(theme.colors.primary))},
            0
          )
          35%
      );
      transform: translate(-50%, 70%);
    }
  }
`;

export const PhoneSpace = styled(Space)`
  justify-content: center;
`;

export const TextArea = styled(Input.TextArea)`
  min-height: 132px !important;
  resize: vertical;
`;

export const FormColumn = styled(Col)`
  padding: 50px 30px;
  background-color: rgba(
    ${({ theme }) => rgbObjectToStringFormat(hexToRGB(theme.colors.primary))},
    0.13
  );

  @media ${mediaQueries.desktop} {
    padding: 0;
    background: none;
  }
`;
