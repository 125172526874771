export const APP_CONFIG_REQUEST = "APP_CONFIG_REQUEST";
export const APP_CONFIG_SUCCESS = "APP_CONFIG_SUCCESS";
export const APP_CONFIG_FAILURE = "APP_CONFIG_FAILURE";

export const appConfigRequest = (domain) => {
  return {
    type: APP_CONFIG_REQUEST,
    payload: {
      domain,
    },
  };
};

export const appConfigSuccess = (data) => {
  return {
    type: APP_CONFIG_SUCCESS,
    payload: {
      data,
    },
  };
};

export const appConfigFailure = (err) => {
  return {
    type: APP_CONFIG_FAILURE,
    payload: {
      err,
    },
  };
};
