import { denormalize } from "normalizr";
import { compact, isArray, isEmpty } from "lodash";

export const getEntitiesByIds = (state, schema, arrayOfIds = []) => {
  if (isArray(arrayOfIds)) {
    const denormalizeData = denormalize(arrayOfIds, schema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return compact(denormalizeData);
    }
    return [];
  }
  return [];
};

export const getEntityById = (state, schema, id) => {
  if (id) {
    const denormalizeData = denormalize(id, schema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};
