import { schema } from "normalizr";

export const referralSchema = new schema.Entity(
  "referrals",
  {},
  {
    idAttribute: "_id",
  }
);

export const referralsSchema = [referralSchema];
