import styled from "styled-components";
import ContainerComponent from "../Container/Container";
import MenuComponent from "./Menu";
import Button from "../Button/Button";
import mediaQueries from "../../utils/mediaQueries";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.borders.gray};
`;

export const Container = styled(ContainerComponent)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 1500px;
  padding: 10px 20px;
`;

export const HeaderGroup = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 10px;
`;

export const Logo = styled.img`
  max-height: 50px;
  object-fit: contain;

  @media ${mediaQueries.tablet} {
    max-width: 200px;
    width: auto;
    object-fit: contain;
  }
`;

export const Menu = styled(MenuComponent)`
  margin-right: 20px;
`;

export const HamburgerButton = styled(Button)`
  padding: 0px 5px !important;
  line-height: 100%;
  vertical-align: middle;
`;
