import { calculateSupplierDaysLength, createUTCDate } from "../helpers/tools";
import useCalculateDates from "./useCalculateDays";

const useDaysLength = (period, fromDate, toDate, fromTime, toTime) => {
  const { pricingFrom, pricingTo } = useCalculateDates({
    fromDate,
    toDate,
    fromTime,
    toTime,
    period: period,
  });
  const fromUTC = createUTCDate(pricingFrom);
  const toUTC = createUTCDate(pricingTo);
  return calculateSupplierDaysLength(fromUTC, toUTC);
};

export default useDaysLength;
