import React from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import Text from "antd/es/typography/Text";
import { parseLocation } from "../helpers/helpers";
import {
  ContainerCard,
  BodyCard,
  DetailsCard,
  ContainerItem,
  ContainerActivity,
  Price,
  ExperienceImage,
  TopCard,
  TitleCard,
  Location,
  ActivityText,
} from "../styles/styles";
import { ReactComponent as BarsIcon } from "../../../../components/Icons/bars.svg";
import { ReactComponent as HourGlassIcon } from "../../../../components/Icons/hourglass.svg";
import { renderHours } from "../../detail/components/ExperienceSummary/helpers/helpers";

const ExperienceCard = ({
  images,
  title,
  activity,
  location,
  price,
  duration,
  difficulty,
  currency,
  customPrice,
}) => {
  const { t } = useTranslation(["common", "experiences"]);

  return (
    <ContainerCard>
      <div>
        {!isEmpty(images) ? (
          <ExperienceImage alt="experienceImage" src={images[0]} />
        ) : (
          <ExperienceImage
            alt="defaultImage"
            src="https://camarasal.com/wp-content/uploads/2020/08/default-image-5-1.jpg"
          />
        )}
        <ContainerActivity>
          <ActivityText strong>
            {t(`activitiesList.${activity[0]}`)}
          </ActivityText>
        </ContainerActivity>
      </div>

      <BodyCard>
        <TopCard>
          <TitleCard ellipsis={{ rows: 2 }} level={5}>
            {title}
          </TitleCard>
          <Location ellipsis={{ rows: 1 }} strong>
            {parseLocation(location)}
          </Location>

          {customPrice ? (
            <div>
              <Price
                amount={price}
                currency={currency}
                deleteText={true}
                level={5}
              />
              <Price
                amount={customPrice}
                currency={currency}
                level={5}
                period={"ticket"}
              />
            </div>
          ) : (
            <Price
              amount={price}
              currency={currency}
              level={5}
              period={"ticket"}
            />
          )}
        </TopCard>

        <DetailsCard>
          <ContainerItem>
            <HourGlassIcon style={{ fontSize: 12 }} />
            <Text style={{ fontSize: 12 }}>
              {t("experiences:duration")}:{" "}
              {renderHours(duration?.unit, duration?.value)}
            </Text>
          </ContainerItem>

          <ContainerItem>
            <BarsIcon style={{ fontSize: 12 }} />
            <Text style={{ fontSize: 12 }}>
              {t("experiences:difficulty")}:{" "}
              {t(`experiences:difficultyList.${difficulty}`)}
            </Text>
          </ContainerItem>
        </DetailsCard>
      </BodyCard>
    </ContainerCard>
  );
};

export default ExperienceCard;
