import styled from "styled-components";
import Title from "antd/es/typography/Title";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.borders.gray};
  border-radius: 10px;
`;

export const Content = styled.div`
  width: 100%;
`;

export const ExtraContent = styled.div``;

export const CardHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 15px;
  align-items: center;
`;

export const CardTitle = styled(Title)`
  margin-bottom: 0px !important;
  margin-top: 0px !important;
`;

export const AfterTitle = styled.div``;

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
`;
