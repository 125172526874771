import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Container from "../Container/Container";
import SocialButtons from "../SocialButtons/SocialButtons";
import FooterMenu from "./FooterMenu";
import { Logo, PoweredBy } from "./PageFooter.styles";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../Redux/Selectors/appConfigSelector";

const PageFooter = ({ logo, socialLinks, footerMenu }) => {
  const { t } = useTranslation();
  const supplierData = useSelector(getSupplierData);

  return (
    <Container>
      <Row gutter={[0, 50]}>
        <Col span={24} md={12} lg={8}>
          <FooterMenu {...footerMenu} />
        </Col>
        <Col span={24} md={12} lg={8}>
          <Row justify="center" gutter={[0, 40]}>
            <Col span={24}>
              <Logo src={logo} alt={supplierData?.name} />
            </Col>
            <Col span={24}>
              <Row justify="center">
                <SocialButtons
                  iconSize="30px"
                  align="center"
                  links={socialLinks}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PoweredBy>
            {t("poweredBy")}{" "}
            <a
              href="https://outtripmanager.com"
              target="_blank"
              rel="noreferrer"
            >
              Outtrip Manager
            </a>
          </PoweredBy>
        </Col>
      </Row>
    </Container>
  );
};

PageFooter.propTypes = {
  logo: PropTypes.string.isRequired,
  footerMenu: PropTypes.shape(FooterMenu.propTypes),
  socialLinks: SocialButtons.propTypes.links,
};

export default PageFooter;
