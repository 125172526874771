import PropTypes from "prop-types";
import { ClockCircleOutlined } from "@ant-design/icons";
import { ContainerHeader } from "./PaymentResult.styles";
import { cardStatus, getStatusColor } from "../utils";
import BootstrapIcon from "../../../components/BootstrapIcon/BoostrapIcon";

const HeaderCard = ({ status }) => {
  const bgColor = getStatusColor(status);
  const style = {
    width: "45px",
    height: "45px",
    color: "white",
    padding: "5.5px",
  };

  return (
    <ContainerHeader bgColor={bgColor}>
      {status === cardStatus.SUCCESS && (
        <BootstrapIcon iconName="check-lg" style={{ ...style }} />
      )}
      {status === cardStatus.ERROR && (
        <BootstrapIcon iconName="x-lg" style={{ ...style }} />
      )}
      {status === cardStatus.PENDING && (
        <ClockCircleOutlined style={{ ...style, fontSize: "35px" }} />
      )}
    </ContainerHeader>
  );
};
HeaderCard.propTypes = {
  status: PropTypes.string.isRequired,
};
export default HeaderCard;
