export const SHOP_APPLY_DISCOUNT = "SHOP_APPLY_DISCOUNT";
export const SHOP_REMOVE_DISCOUNT = "SHOP_REMOVE_DISCOUNT";
export const SHOP_RESET_DISCOUNT = "SHOP_RESET_DISCOUNT";

export const shopApplyDiscount = ({ discountCode }) => {
  return {
    type: SHOP_APPLY_DISCOUNT,
    payload: {
      discountCode,
    },
  };
};

export const shopResetDiscount = () => {
  return {
    type: SHOP_RESET_DISCOUNT,
  };
};

export const shopRemoveDiscount = () => {
  return {
    type: SHOP_REMOVE_DISCOUNT,
  };
};
