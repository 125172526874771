import { Descriptions } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const PaymentDescription = ({ paymentInformation, country, title }) => {
  const { t } = useTranslation(["shop", "common"]);

  const fieldLabels = {
    bank: t("form.bankName"),
    name: t("form.name"),
    id: country === "Chile" ? "RUT" : "CUIT",
    accountType: t("accountType.type"),
    accountNumber: t("form.accountNumber"),
    cbu: t("form.cbu"),
    alias: t("form.alias"),
    whithdrawal: t("form.whithdrawal"),
  };

  const getValue = (key, value) => {
    if (key === "accountType") {
      return value ? t(`accountType.${value}`) : null;
    }
    return value;
  };

  return (
    <Descriptions bordered title={t(title)} column={1}>
      {Object.entries(paymentInformation).map(([key, value]) => {
        const label = fieldLabels[key];
        const processedValue = getValue(key, value);

        return (
          label &&
          processedValue && (
            <Descriptions.Item key={key} label={label}>
              {processedValue}
            </Descriptions.Item>
          )
        );
      })}
    </Descriptions>
  );
};

export default PaymentDescription;

PaymentDescription.propTypes = {
  country: PropTypes.string,
  paymentInformation: PropTypes.objectOf({
    bank: PropTypes.string,
    accountNumber: PropTypes.string,
    accountType: PropTypes.string,
    alias: PropTypes.string,
    cbu: PropTypes.string,
    name: PropTypes.string,
    whithdrawal: PropTypes.string,
    id: PropTypes.string,
  }),
};
