import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { createEditor } from "slate";
import { Editable, Slate, withReact } from "slate-react";

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const RichTextRender = ({ content, ...restProps }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  const sanitizeValue = (val) => {
    if (typeof val === "string" && val[0] === "[" && val[1] === "{") {
      return JSON.parse(val);
    } else if (typeof val === "string") {
      return [
        {
          children: [{ text: val }],
        },
      ];
    } else {
      return val;
    }
  };

  return (
    <Slate editor={editor} value={sanitizeValue(content)}>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
        {...restProps}
      />
    </Slate>
  );
};

RichTextRender.propTypes = {
  content: PropTypes.string,
};

export default RichTextRender;
