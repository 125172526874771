import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Skeleton from "antd/es/skeleton";
import useIsMobile from "../../../../../hooks/useIsMobile";
import breakpoints from "../../../../../utils/breakpoints";
import Collage from "./Collage/Collage";
import Gallery from "./Gallery/Gallery";
import { Container } from "./BannerImage.styles";
import { isEmpty } from "lodash";

const BannerImage = ({ images = [], alt }) => {
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(true);
  const height = isMobile ? "100%" : "45vh";
  const maxWidth = breakpoints.largeDesktop;
  const [visibleGallery, setVisibleGallery] = useState(false);
  const openGallery = () => {
    setVisibleGallery(!visibleGallery);
  };

  useEffect(() => {
    if (!isEmpty(images)) setLoading(false);
  }, [images]);

  return (
    <Container height={height} width={maxWidth}>
      {loading ? (
        <Skeleton.Image active={true} style={{ height, width: maxWidth }} />
      ) : (
        <>
          <Collage
            images={images}
            heightConfig={height}
            mobileImages={images}
            alt={alt}
            openGallery={openGallery}
          />

          <Gallery
            images={images}
            visible={visibleGallery}
            setVisible={setVisibleGallery}
          />
        </>
      )}
    </Container>
  );
};

BannerImage.defaultProps = {
  images: [],
};

BannerImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
};

export default BannerImage;
