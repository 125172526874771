import { cardStatus } from "../../../../PaymentPage/utils";

export const validateDateOfBirth = (dateOfBirth, t) => {
    if (dateOfBirth?.day && dateOfBirth?.month && dateOfBirth?.year) {
        return { validateStatus: cardStatus.SUCCESS };
    }
    return {
        validateStatus: cardStatus.ERROR,
        errorMsg: t("form.error", { field: t("form.dateOfBirth") }),
    };
};
