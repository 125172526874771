import styled from "styled-components";
import Button from "../Button/Button";

export const ButtonLink = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  padding-bottom: 0 !important;
  text-decoration: underline;
  font-weight: 500;
`;
