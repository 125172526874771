export const CHECK_VARIANTS_STOCK_REQUEST = "CHECK_VARIANTS_STOCK_REQUEST";
export const CHECK_VARIANTS_STOCK_SUCCESS = "CHECK_VARIANTS_STOCK_SUCCESS";
export const RESET_CHECK_VARIANTS_STOCK = "RESET_CHECK_VARIANTS_STOCK";
export const GET_ALL_ITEMS_REQUEST = "GET_ALL_ITEMS_REQUEST";
export const GET_ALL_ITEMS_SUCCESS = "GET_ALL_ITEMS_SUCCESS";

export const resetCheckVariantsStock = () => ({
  type: RESET_CHECK_VARIANTS_STOCK,
});

export const checkVariantsStockRequest = (variantIds, from, to, period) => {
  return {
    type: CHECK_VARIANTS_STOCK_REQUEST,
    payload: {
      variantIds,
      from,
      to,
      period,
    },
  };
};

export const checkVariantsStockSuccess = (results) => {
  return {
    type: CHECK_VARIANTS_STOCK_SUCCESS,
    payload: {
      results,
    },
  };
};
