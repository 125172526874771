import QuoteBuilderRow from "../../ShopPage/components/QuoteBuilderRow/QuoteBuilderRow";
import ProductsContainer from "../containers/ProductsContainer";
import { Button } from "../../../components/Button/Button.styles";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  PricingSection,
  SelectBranchContainer,
  ShopIcon,
  NotFoundProductContainer,
  SpinContainer,
} from "../CheckinScreen.styles";
import { Divider, Spin } from "antd";
import { isEmpty } from "lodash";
import Paragraph from "antd/es/typography/Paragraph";
import usePeriod from "../../../hooks/usePeriod";
import AssignmentModal from "../components/AssignmentModal";
import SummaryDetails from "../components/SummaryDetails";
import BannerRenderContainer from "../../../components/BannerComponent/BannerRenderContainer";
import { isCircuitoChico, isVertical } from "../../../helpers/rentals";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import Counter from "../../../components/Counter/Counter";
import { CounterContainer } from "../../../components/Counter/styled";
import SelectBranch from "../components/SelectBranch/SelectBranch";
import Text from "antd/es/typography/Text";
import { SearchInput } from "../../../components/SearchInput/SearchInput.styles";
import { getCheckoutSummary } from "../../ShopPage/redux/selectors/ShopSelectors";
import { getSupplierPlan } from "../../Subscription/redux/selectorSubscription";

const ProductsScreen = () => {
  const { t } = useTranslation();
  const supplierData = useSelector(getSupplierData);
  usePeriod();
  const assignments = useSelector(getCheckoutSummary);
  const features = useSelector((state) => getSupplierPlan(state));
  const showCounter =
    isVertical(supplierData?._id) || isCircuitoChico(supplierData?._id);
  const showSelectBranch = isVertical(supplierData._id);
  return (
    <FormContainer>
      <ProductsContainer>
        {({
          products,
          quoteSummary,
          handleSubmitTransaction,
          transactionStatus,
          handleOnSubstractProduct,
          handleOnAddProduct,
          showAssignmentModal,
          handleAssignmentModalOnClose,
          onAssignMember,
          assignmentsData,
          handleOnSubtract,
          handleOnAdd,
          quantityDays,
          onSearchProduct,
          productsFetching,
          searchValue,
          searchLoading,
          handleSelectBranch,
          supplierGroupsOptions,
          loadingSupplierGroups,
          supplierHost,
          isHourPeriod,
          dropOffBranch,
          handleDropOffBranch,
        }) => {
          if (transactionStatus.fetching || loadingSupplierGroups) {
            return (
              <SpinContainer>
                <Spin />
              </SpinContainer>
            );
          } else {
            return (
              <>
                {showAssignmentModal.visible && (
                  <AssignmentModal
                    onClose={handleAssignmentModalOnClose}
                    onAssignMember={onAssignMember}
                    action={showAssignmentModal.action}
                    productId={showAssignmentModal.productId}
                    assignmentsData={assignmentsData}
                  />
                )}
                <BannerRenderContainer screen="checkInPageToProducts" />
                {!showCounter && (
                  <CounterContainer>
                    <Paragraph style={{ margin: 0 }}>
                      {isHourPeriod
                        ? t("shop:hours_to_pay")
                        : t("numberOfDays")}
                      :{" "}
                    </Paragraph>
                    <Counter
                      value={quantityDays}
                      onAdd={handleOnAdd}
                      onSubtract={handleOnSubtract}
                      min={1}
                      max={99}
                    />
                  </CounterContainer>
                )}

                {showSelectBranch && (
                  <SelectBranchContainer>
                    <Text>{t("shop:withdrawalBranch")}</Text>
                    <SelectBranch
                      placeholder={t("shop:withdrawalBranch")}
                      icon={<ShopIcon />}
                      handleSelectBranch={handleSelectBranch}
                      options={supplierGroupsOptions}
                      value={supplierHost ?? supplierData._id}
                      loading={loadingSupplierGroups}
                      style={{ marginTop: 10 }}
                    />
                  </SelectBranchContainer>
                )}
                {features.tools.transferItems && (
                  <SelectBranchContainer>
                    <Text>{t("shop:dropoffBranch")}</Text>
                    <SelectBranch
                      placeholder={t("shop:dropoffBranch")}
                      icon={<ShopIcon />}
                      handleSelectBranch={handleDropOffBranch}
                      options={supplierGroupsOptions}
                      value={dropOffBranch ?? supplierData._id}
                      loading={loadingSupplierGroups}
                      style={{ marginTop: 10 }}
                    />
                  </SelectBranchContainer>
                )}
                <Paragraph style={{ fontSize: 14 }} type="secondary">
                  {t("shop:checkin.products.selectProducts")}
                </Paragraph>
                <div style={{ margin: "20px 0" }}>
                  <SearchInput
                    size="large"
                    placeholder={t("shop:searchProduct")}
                    onChange={(e) => onSearchProduct(e.target.value)}
                    value={searchValue}
                  />
                </div>
                {searchLoading || productsFetching ? (
                  <SpinContainer>
                    <Spin />
                  </SpinContainer>
                ) : (
                  products?.map((item, index) => (
                    <QuoteBuilderRow
                      index={index}
                      key={item._id}
                      productId={item._id}
                      title={item.title}
                      price={item.convertedPrice}
                      currency={item.convertedCurrency}
                      quantity={quoteSummary.products[item._id] || 0}
                      isClosed={false}
                      supplierName={item.owner?.name}
                      type={item?.type}
                      minimumPeriodQuantity={item.minimumPeriodQuantity}
                      rentalPeriodType={item.rentalPeriodType}
                      category={item.category?.value}
                      showSnackbar={false}
                      handleOnSubstractProduct={handleOnSubstractProduct}
                      handleOnAddProduct={handleOnAddProduct}
                      hidePrice
                      searchLoading={searchLoading}
                    />
                  ))
                )}

                {isEmpty(products) && !searchLoading && (
                  <NotFoundProductContainer>
                    <Text>
                      {t("shop:notFoundProductByName", { searchValue })}
                    </Text>
                  </NotFoundProductContainer>
                )}
                {!isEmpty(assignments) && (
                  <>
                    <Divider />
                    <PricingSection>
                      <SummaryDetails assignmentsData={assignments} />
                      <Divider />
                    </PricingSection>
                  </>
                )}

                <Button
                  type="primary"
                  block
                  size="large"
                  disabled={isEmpty(quoteSummary.products)}
                  onClick={handleSubmitTransaction}
                >
                  {t("common:confirm")}
                </Button>
              </>
            );
          }
        }}
      </ProductsContainer>
    </FormContainer>
  );
};
export default ProductsScreen;
