import { Image } from "antd";
import { downsizeImage } from "../../utils/addHttps";

export const PAYMENT_METHOD_LABELS = {
  mercadopago: "mercadopago",
  stripe: "stripe",
  webpay: "webpay",
  wireTransfer: "wireTransfer",
  other: "other",
  cash: "cash",
  westernUnion: "westernUnion",
  macro: "macro",
  shopCard: "shopCard",
  shopCash: "shopCash",
};

export const PAYMENT_METHOD_IDENTIFIERS = {
  mpCheckout: "mp_checkout",
  mpCreditCard: "mp_credit_card",
  mpDebitCard: "mp_debit_card",
  stripe: "stripe",
  webpay: "webpay",
  wireTransfer: "wireTransfer",
  other: "other",
  cash: "cash",
  shopCash: "shopCash",
  westernUnion: "westernUnion",
  macro: "macro",
};

export const methodsByCurrency = {
  international: {
    [PAYMENT_METHOD_LABELS.westernUnion]: {
      paymentMethod: PAYMENT_METHOD_LABELS.westernUnion,
      paymentIdentifier: PAYMENT_METHOD_IDENTIFIERS.westernUnion,
      title: "shop:westernUnion",
      afterTitleContent: (
        <Image
          preview={false}
          src={downsizeImage(
            "https://res.cloudinary.com/dja5ahopr/image/upload/v1702386579/paymentAssets/my7wtzmyv4s6q2zh9gqu.svg",
            100
          )}
          height={22}
          alt="Western Union"
        />
      ),
    },

    [PAYMENT_METHOD_LABELS.stripe]: {
      paymentMethod: PAYMENT_METHOD_LABELS.stripe,
      paymentIdentifier: PAYMENT_METHOD_IDENTIFIERS.stripe,
      title: "shop:shopCard",
      afterTitleContent: (
        <Image
          preview={false}
          src={downsizeImage(
            "https://res.cloudinary.com/dja5ahopr/image/upload/v1682330412/paymentAssets/xtpeyxov1hziaoyg5ks1.png",
            100
          )}
          height={50}
          alt="Stripe"
        />
      ),
    },

    [PAYMENT_METHOD_LABELS.webpay]: {
      paymentMethod: PAYMENT_METHOD_LABELS.webpay,
      paymentIdentifier: PAYMENT_METHOD_IDENTIFIERS.webpay,
      title: "shop:shopCard",
      afterTitleContent: (
        <Image
          preview={false}
          src={downsizeImage(
            "https://res.cloudinary.com/dja5ahopr/image/upload/v1682330235/paymentAssets/xojj2xdjzrpvdeglcecb.png",
            100
          )}
          height={50}
          alt="Webpay"
        />
      ),
    },
  },

  argentina: {
    [PAYMENT_METHOD_LABELS.mercadopago]: [
      {
        paymentMethod: PAYMENT_METHOD_LABELS.mercadopago,
        paymentIdentifier: PAYMENT_METHOD_IDENTIFIERS.mpCheckout,
        title: "shop:cardAndMercadoPago",
        afterTitleContent: (
          <Image
            preview={false}
            src={downsizeImage(
              "https://res.cloudinary.com/dja5ahopr/image/upload/v1671661271/paymentAssets/oki9k4k5q2kb4xvxq5hc.png",
              100
            )}
            height={22}
            alt="Mercado Pago"
          />
        ),
      },
    ],
    [PAYMENT_METHOD_LABELS.macro]: [
      {
        paymentMethod: PAYMENT_METHOD_LABELS.macro,
        paymentIdentifier: PAYMENT_METHOD_IDENTIFIERS.macro,
        title: "Macro",
        afterTitleContent: (
          <Image
            preview={false}
            src={downsizeImage(
              "https://res.cloudinary.com/dja5ahopr/image/upload/v1714723613/paymentAssets/yhl2h1btm7bt2er4zw10.jpg",
              100
            )}
            height={30}
            alt="Macro"
          />
        ),
      },
    ],
  },

  common: {
    [PAYMENT_METHOD_LABELS.wireTransfer]: {
      paymentMethod: PAYMENT_METHOD_LABELS.wireTransfer,
      paymentIdentifier: PAYMENT_METHOD_IDENTIFIERS.wireTransfer,
      title: "shop:wireTransfer",
      afterTitleContent: (
        <Image
          preview={false}
          src={downsizeImage(
            "https://res.cloudinary.com/dja5ahopr/image/upload/v1684771203/paymentAssets/igtolvhuut3mb8gfsnbl.png",
            100
          )}
          height={22}
          alt="Transferencia bancaria"
        />
      ),
    },
    [PAYMENT_METHOD_LABELS.shopCash]: {
      paymentMethod: PAYMENT_METHOD_LABELS.shopCash,
      paymentIdentifier: PAYMENT_METHOD_IDENTIFIERS.shopCash,
      title: "shop:cash",
      afterTitleContent: (
        <Image
          preview={false}
          src={downsizeImage(
            "https://res.cloudinary.com/dja5ahopr/image/upload/v1689370034/paymentAssets/yvsbxxntxiqayfpq3lwh.png",
            100
          )}
          height={50}
          alt="Efectivo"
        />
      ),
    },
    [PAYMENT_METHOD_LABELS.westernUnion]: {
      paymentMethod: PAYMENT_METHOD_LABELS.westernUnion,
      paymentIdentifier: PAYMENT_METHOD_IDENTIFIERS.westernUnion,
      title: "shop:westernUnion",
      afterTitleContent: (
        <Image
          preview={false}
          src={downsizeImage(
            "https://res.cloudinary.com/dja5ahopr/image/upload/v1704219416/paymentAssets/wjjp0czueokkv6qmzlti.jpg",
            100
          )}
          height={50}
          alt="Efectivo"
        />
      ),
    },
  },
};

export const methods = {
  ...methodsByCurrency.international,
  ...methodsByCurrency.common,
  ...methodsByCurrency.argentina,
};

/**
 * @param {string} [transactionCurrency]
 * @param {string} [guestCurrency=null]
 */
export const paymentMethodsByCurrency = (guestCurrency = null) => {
  if (guestCurrency && guestCurrency.toLowerCase() === "ars") {
    return { ...methodsByCurrency.argentina, ...methodsByCurrency.common };
  }
  return { ...methodsByCurrency.international, ...methodsByCurrency.common };
};

export const URL_FROM_DASHBOARD = {
  DASHBOARD: "dataSource=dashboard",
};
