import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import TabController from "../components/TabController/TabController";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivePassenger,
  getInitalPassengerData,
} from "../redux/QuoteSelectors";
import {
  addPassengerQuote,
  getProducsQuoteRequest,
  removePassengerQuote,
} from "../redux/QuoteActions";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import PanelColumn from "../../../components/PanelColumn/PanelColumn";
import WinterMemberCard from "../components/WinterMemberCard/WinterMemberCard";
import { Row } from "antd";
import DateContainer from "../containers/DateContainer/DateContainer";
import WinterMemberCardMobile from "../components/WinterMemberCard/WinterMemberCardMobile";
import useIsMobile from "../../../hooks/useIsMobile";
import BannerRenderContainer from "../../../components/BannerComponent/BannerRenderContainer";

const Container = styled.div`
  padding: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const WinterQuote = ({ isPresale }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const supplier = useSelector(getSupplierData);
  const activePassenger = useSelector(getActivePassenger);
  const initialPassenger = useSelector((state) =>
    getInitalPassengerData(state)
  );

  const handleOnRemoveTab = (id) => {
    dispatch(removePassengerQuote(id));
  };

  const handleOnAddTab = (id) => {
    dispatch(addPassengerQuote(id, "", "", []));
  };

  useEffect(() => {
    dispatch(
      getProducsQuoteRequest({
        active: true,
        owner: supplier._id,
      })
    );
  }, []);

  return (
    <Container>
      <Title level={3}>{t("shop:quoter")}</Title>
      <BannerRenderContainer screen="productsScreen" />
      <Row justify="center" gutter={[8, 8]}>
        <PanelColumn xs={0} lg={7} spacing={8}>
          <WinterMemberCard id={activePassenger} />
        </PanelColumn>
        <PanelColumn xs={24} lg={17} spacing={isMobile ? 8 : 30}>
          <DateContainer isPresale={isPresale} />
          <PanelColumn xs={24} lg={0} spacing={10}>
            <WinterMemberCardMobile id={activePassenger} />
          </PanelColumn>
          <TabController
            initialValues={initialPassenger}
            onAddTab={handleOnAddTab}
            onRemoveTab={handleOnRemoveTab}
          />
        </PanelColumn>
      </Row>
    </Container>
  );
};

export default WinterQuote;
