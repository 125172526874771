import React from "react";
import {
  DropdownContainer,
  IconWrapper,
} from "../../../../components/Dropdown/Dropdown.styles";
import { SelectStyled } from "../../CheckinScreen.styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const SelectBranch = ({
  icon,
  placeholder,
  size = "large",
  handleSelectBranch,
  options,
  value,
  loading,
  disabled = false,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <DropdownContainer {...restProps}>
      <IconWrapper>{icon}</IconWrapper>
      <SelectStyled
        placeholder={placeholder ?? t("shop:selectBranch")}
        size={size}
        onChange={handleSelectBranch}
        options={options}
        value={value}
        loading={loading}
        disabled={disabled}
      />
    </DropdownContainer>
  );
};

SelectBranch.propTypes = {
  icon: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  handleSelectBranch: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        label: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        group: PropTypes.string,
        key: PropTypes.string,
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
              .isRequired,
            label: PropTypes.string.isRequired,
            group: PropTypes.string,
            key: PropTypes.string,
          })
        ).isRequired,
      }),
    ])
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
};

export default SelectBranch;
