import PersonalForm from "../../../../ShopPage/components/MainMemberForm/PersonalForm";
import { Collapse } from "antd";
import ParticipantsForm from "./ParticipantForm/ParticipantForm";
import { HeaderContainer, StyledCollapse } from "./ParticipantGroupForm.styles";
import { FormContainer } from "../AddParticipant/AddParticipants.styles";
import ParticipantGroupContainer from "../../containers/ParticipantContainer/ParticipantGroupContainer";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import Typography from "antd/es/typography/Typography";
import baseTheme from "../../../../../config/baseTheme";
import { useTranslation } from "react-i18next";
import { CUSTOMER_TYPE } from "../../../../../components/FormInputs/constants";
import { DateOfBirthProvider } from "../../../../../components/DateOfBirthSelector/context/DateOfBirthContext";
const { Text } = Typography;
const { Panel } = Collapse;

const ParticipantGroupForm = ({ experienceId }) => {
  const { t } = useTranslation("experiences");

  const renderHeader = (label, isComplete, active) => {
    return (
      <HeaderContainer>
        <Text>{label}</Text>
        {isComplete ? (
          <CheckCircleFilled
            style={{ color: baseTheme.colors.green, fontSize: "25px" }}
          />
        ) : (
          !active && (
            <ExclamationCircleFilled
              style={{ color: baseTheme.colors.yellow, fontSize: "25px" }}
            />
          )
        )}
      </HeaderContainer>
    );
  };

  return (
    <FormContainer>
      <DateOfBirthProvider>
        <ParticipantGroupContainer>
          {({
            participants,
            onClickParticipant,
            onSubmitMember,
            createCustomer,
            supplierData,
          }) => {
            return (
              <>
                {Object.entries(participants)?.map(([id, value], index) => {
                  const { rate, active, complete, isMainMember, addonLabel } =
                    value;
                  const label = `${t(`booking.${rate}`, {
                    count: "",
                  })} ${addonLabel}`;
                  return (
                    <StyledCollapse
                      ghost
                      activeKey={active ? id : []}
                      onChange={() => onClickParticipant(id)}
                    >
                      <Panel
                        header={renderHeader(label, complete, active)}
                        key={id}
                      >
                        {isMainMember ? (
                          <PersonalForm
                            onSubmit={(values) =>
                              onSubmitMember({
                                isMainMember,
                                passenger: `${index}`,
                                rate,
                                fieldkey: `${rate}-${index}`,
                                experienceId,
                                personalInfo: {
                                  ...values,
                                  id: `${index}`,
                                  label,
                                  rate,
                                },
                              })
                            }
                            initialValues={{
                              phonePrefix: createCustomer.phonePrefixByRegion,
                              type: createCustomer.personalIdType,
                              nationality: createCustomer.nationality,
                              createdBy: supplierData._id,
                              customerType: CUSTOMER_TYPE.PERSONAL,
                            }}
                            showSubmitButton={true}
                          />
                        ) : (
                          <ParticipantsForm
                            participantRate={rate}
                            id={id}
                            fieldKey={`${rate}-${index}`}
                            onSubmit={(values) =>
                              onSubmitMember({
                                passenger: id,
                                rate,
                                fieldkey: `${rate}-${index}`,
                                experienceId,
                                personalInfo: {
                                  [id]: { ...values, id, label, rate },
                                },
                              })
                            }
                          />
                        )}
                      </Panel>
                    </StyledCollapse>
                  );
                })}
              </>
            );
          }}
        </ParticipantGroupContainer>
      </DateOfBirthProvider>
    </FormContainer>
  );
};

export default ParticipantGroupForm;
