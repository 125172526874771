import { denormalize } from "normalizr";
import { isEmpty } from "lodash";
import { categorySchema, categoriesSchema } from "../Schemas/categorySchema";

export const getCategoryById = (state, id) => {
  if (id) {
    const denormalizeData = denormalize(id, categorySchema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};

export const getCategories = (state, arrayOfIds) => {
  if (arrayOfIds) {
    const denormalizeData = denormalize(
      arrayOfIds,
      categoriesSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return [];
  }
  return [];
};
