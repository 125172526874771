import React from "react";
import { isEmpty } from "lodash";
import Text from "antd/es/typography/Text";
import {
  ContainerCard,
  BodyCard,
  DetailsCard,
  ExperienceImage,
  TopCard,
  TitleCard,
} from "./ProductCard.styles";
import Price from "../../features/ExperiencesPage/components/Price/Price";

const ProductCard = ({
  images,
  title,
  subtitle,
  currency,
  price,
  period,
  onClick,
}) => {
  return (
    <ContainerCard onClick={onClick}>
      <div>
        {!isEmpty(images) ? (
          <ExperienceImage alt="productImage" src={images[0]} />
        ) : (
          <ExperienceImage
            alt="defaultImage"
            src="https://camarasal.com/wp-content/uploads/2020/08/default-image-5-1.jpg"
          />
        )}
      </div>

      <BodyCard>
        <TopCard>
          <TitleCard ellipsis={{ rows: 2 }} level={5}>
            {title}
          </TitleCard>
          <Text type="secondary" style={{ fontSize: 14 }}>
            {subtitle}
          </Text>
        </TopCard>
        <DetailsCard>
          <Price
            amount={price}
            currency={currency}
            level={5}
            showTotal
            period={period}
          />
        </DetailsCard>
      </BodyCard>
    </ContainerCard>
  );
};

export default ProductCard;
