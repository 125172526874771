import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Text from "antd/es/typography/Text";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  getAssigmentByFieldKey,
  getPassengersByKey,
} from "../../redux/selectors/ShopSelectors";
import AssignVariantAndPeopleDataForm from "../AssignVariantForm/AssignVariantForm";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import {
  removeSelectedProduct,
  unassignVariantToPassenger,
} from "../../redux/actions/shopActions";
import baseTheme from "../../../../config/baseTheme";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
  width: 100%;
`;

const AssignProductsForm = ({ initialFormData, handleOnSubmitAssign }) => {
  const [showModal, setShoModal] = useState({
    visible: false,
    data: null,
    isBundle: false,
  });

  const handleAssignProduct = (product, fieldKey, assigned) => {
    let productIds;
    let bundleId;
    if (product && !isEmpty(product.products)) {
      productIds = product.products.map(({ productId }) => productId);
      bundleId = product._id;
    }
    const id = !isEmpty(productIds) ? productIds : product?._id;
    const isBundle = !isEmpty(productIds);
    setShoModal({
      visible: true,
      data: {
        assigned,
        fieldKey,
        id,
        bundleId,
      },
      isBundle,
    });
  };

  const handleCloseModal = () => {
    setShoModal({
      visible: false,
      data: null,
      isBundle: false,
    });
  };

  return (
    <>
      {initialFormData.productsDetail.map((product) => (
        <ItemWrapper
          handleAssignProduct={handleAssignProduct}
          fieldKey={product?.fieldKey}
          value={{ ...product }}
        />
      ))}

      <AssignVariantAndPeopleDataForm
        visible={showModal.visible}
        data={showModal.data}
        isBundle={showModal.isBundle}
        people={Object.values(initialFormData.passengers)}
        onCancel={handleCloseModal}
        handleOnSubmit={handleOnSubmitAssign}
      />
    </>
  );
};

const ItemWrapper = ({ value, handleAssignProduct, fieldKey }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assigned = useSelector((state) =>
    getAssigmentByFieldKey(state, fieldKey)
  );
  const passenger = useSelector((state) =>
    getPassengersByKey(state, assigned?.passenger)
  );
  const generateStyle = () => {
    if (assigned) {
      return {
        backgroundColor: "#4BB543",
        borderColor: "#4BB543",
        color: "white",
      };
    }
    return {};
  };
  const handleSubstractProduct = (productId, fieldKey) => {
    dispatch(unassignVariantToPassenger({ index: fieldKey, productId }));
    dispatch(removeSelectedProduct(fieldKey));
  };

  return (
    <Row>
      <div>
        <Button
          type="link"
          icon={
            <CloseOutlined
              style={{ fontSize: 14, color: baseTheme.colors.red }}
            />
          }
          onClick={() => handleSubstractProduct(value._id, fieldKey)}
        />
        <Text>{value.title}</Text>
      </div>
      <Button
        style={generateStyle()}
        type="primary"
        onClick={() => handleAssignProduct(value, fieldKey, assigned)}
        icon={assigned ? <CheckOutlined /> : null}
        data-testid="quote-assign-passenger-button"
      >
        {assigned ? passenger?.name : t("shop:assign.assign")}
      </Button>
    </Row>
  );
};

export default AssignProductsForm;
