import {
  GET_BUNDLES_SHOP_REQUEST,
  GET_BUNDLES_SHOP_SUCCESS,
} from "../actions/shopActions";

const INITIAL_STATE = {
  results: [],
  isFetching: false,
  error: false,
};

const bundlesShopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BUNDLES_SHOP_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case GET_BUNDLES_SHOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        results: action.payload.results,
      };

    default:
      return state;
  }
};

export default bundlesShopReducer;
