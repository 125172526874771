import styled from "styled-components";
import CollapseComponent from "../../../../components/Collapse/Collapse";

export const Collapse = styled(CollapseComponent)`
  width: 100%;
  margin-bottom: 60px;

  .ant-collapse-item {
    border: ${({ theme }) => `2px solid ${theme.borders.gray} !important`};
  }
`;
