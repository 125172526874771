import React from "react";
import PropTypes from "prop-types";
import Images from "./Images";
import { StyledRows } from "../Collage.styles";

const Columns = ({ colsImages, style, alt }) => {
  return (
    <>
      {colsImages?.map((colImgs, index) => (
        <StyledRows rows={colImgs.length} key={index}>
          <Images images={colImgs} style={style} alt={alt} />
        </StyledRows>
      ))}
    </>
  );
};

Columns.propTypes = {
  colsImages: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default Columns;
