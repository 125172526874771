import { takeLatest, all, put, call } from "redux-saga/effects";
import { get } from "../../../../services/httpService";
import {
  GET_REVIEWS_REQUEST,
  getReviewFailure,
  getReviewSuccess,
} from "../actions/reviewsActions";
import environment from "../../../../config/environment";

function* handleGetReviews(action) {
  try {
    const { placeId, provider } = action.payload;
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/ratings?provider=${provider}&placeId=${placeId}`
    );
    if (response.success) {
      yield put(getReviewSuccess(response.message.reviews));
    } else {
      yield put(getReviewFailure());
    }
  } catch (err) {
    yield put(getReviewFailure(err));
    console.log(err);
  }
}

export default function* reviewsSaga() {
  yield all([takeLatest(GET_REVIEWS_REQUEST, handleGetReviews)]);
}
