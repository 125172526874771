import styled from "styled-components";

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color:  ${({ theme }) => theme.colors.white}};
`;

export const ContainerCategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
