import {
  GET_EXPERIENCES_REQUEST,
  GET_EXPERIENCES_SUCCESS,
  GET_EXPERIENCES_FAILURE,
} from "../actions/ExperiencesActions.js";

const INITIAL_STATE = {
  results: [],
  isFetching: false,
  error: false,
};

const experienceListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EXPERIENCES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case GET_EXPERIENCES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        results: action.payload.results,
      };
    case GET_EXPERIENCES_FAILURE:
      return {
        ...state,
        isFetching: false,
        results: [],
        error: true,
      };

    default:
      return state;
  }
};

export default experienceListReducer;
