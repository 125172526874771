import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import RichTextRender from "../../../../../components/RichTextRender/RichTextRender";
import { Collapse } from "./Accordion.styles";

const Accordion = ({ data }) => {
  const { t } = useTranslation("experiences");

  return (
    <Collapse defaultActiveKey="1">
      {Object.entries(data).map(([title, description], index) => {
        if (
          title === "whatsIncluded" ||
          title === "whatsNotIncluded" ||
          !description
        ) {
          return null;
        }
        return (
          <Collapse.Panel
            header={t(`faqs.${title}`)}
            key={[index.toString()]}
            style={{ fontSize: 16 }}
          >
            <RichTextRender content={description} style={{ fontSize: 16 }} />
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

Accordion.defaultProps = {
  data: [],
};

Accordion.propTypes = {
  data: PropTypes.object,
};

export default Accordion;
