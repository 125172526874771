import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { CUSTOMER_TYPE } from "../../../../components/FormInputs/constants";
import CountrySelectForm from "../../../../components/FormInputs/CountrySelectForm";
import PersonalIdInput from "../../../../components/FormInputs/PersonalIdInput";
import PhoneInput from "../../../../components/FormInputs/PhoneInput";
import useIsMobile from "../../../../hooks/useIsMobile";
import styled from "styled-components";
import useGlobalUtils from "../../../../hooks/useGlobalUtils";
import { generateMessage } from "../../utils";
import { getPricingData } from "../../redux/selectors/ShopSelectors";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BusinessForm = ({
  initialValues,
  onChange,
  form,
  onSubmit,
  formEnabled = true,
}) => {
  const pricingData = useSelector(getPricingData);
  const { handleSendWhatsApp } = useGlobalUtils();
  const { t } = useTranslation("shop");
  const isMobile = useIsMobile();
  const spacing = isMobile ? [24] : [12, 12];

  if (formEnabled) {
    return (
      <Form
        onFinish={onSubmit}
        onChange={onChange}
        layout="vertical"
        form={form}
        initialValues={initialValues}
      >
        <Form.Item
          noStyle
          name="customerType"
          label={t("form.name")}
          value="business"
          hidden
        >
          <Input size="large" hidden />
        </Form.Item>
        <Form.Item noStyle name="createdBy" hidden>
          <Input size="large" hidden />
        </Form.Item>
        <Form.Item
          name="name"
          label={t("form.fantasy_name")}
          rules={[
            {
              required: true,
              message: t("form.error", { field: t("form.fantasy_name") }),
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          name="companyName"
          label={t("form.companyName")}
          rules={[
            {
              required: true,
              message: t("form.error", { field: t("form.companyName") }),
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <PersonalIdInput
          typeWidth={180}
          customerType={CUSTOMER_TYPE.BUSINESS}
          required
        />

        <Form.Item
          name="email"
          label={t("form.email")}
          rules={[
            {
              required: true,
              message: t("form.error", { field: t("form.email") }),
            },
            {
              type: "email",
              message: t("form.error", { field: t("form.email") }),
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Row gutter={spacing}>
          <Col span={spacing[0]}>
            <Form.Item
              name="industry"
              label={t("form.industry")}
              rules={[
                {
                  required: true,
                  message: t("form.error", { field: t("form.industry") }),
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={spacing[0]}>
            <CountrySelectForm
              name="nationality"
              required={true}
              size={"large"}
            />
          </Col>
        </Row>

        <PhoneInput prefixWidth={180} required />

        <Form.Item
          name="address"
          label={t("form.customerAddress")}
          rules={[
            {
              required: true,
              message: t("form.error", { field: t("form.address") }),
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Form>
    );
  }

  const handleOnClick = () => {
    if (pricingData) {
      const message = generateMessage(
        pricingData.items,
        pricingData.currency,
        pricingData.total,
        t
      );
      return handleSendWhatsApp(message);
    }
  };

  return (
    <Container>
      <Button onClick={() => handleOnClick()} type="primary" size="large">
        {t("businessGetInTouch")}
      </Button>
    </Container>
  );
};

export default BusinessForm;
