import React from "react";
import { Typography } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const StyledTitle = styled(Text)`
  font-size: 16px !important;
  margin-top: 5px;
`;

const Container = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  margin-bottom: 5px;
  height: ${({ expanded }) => (expanded ? "100%" : "0px")};
  transition: height 0.5s ease, opacity 0.3s ease-in;
  opacity: ${({ expanded }) => (expanded ? "1" : "0")};
  padding-top: 10px;
`;
const ProductList = styled.ul`
  list-style: none;
  margin-left: 0;
  padding: 0;
`;
const Item = styled.li`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProductsSummary = ({ products = [], expanded }) => {
  const { t } = useTranslation("shop");
  return (
    <Container expanded={expanded}>
      <StyledTitle level={5}>{t("products")}:</StyledTitle>
      <ProductList>
        {products.map((item, index) => (
          <Item key={index}>
            <div>{item.title}</div>{" "}
            <div style={{ width: 50, textAlign: "right" }}>
              x {item.quantity}
            </div>
          </Item>
        ))}
      </ProductList>
    </Container>
  );
};

export default ProductsSummary;
