/**
 * Converts a hex color to an object {r, g, b}
 *
 * @param {string} hex - hex color in "#RRGGBB".
 * @returns {Object.<{
 *  r: number,
 *  g: number,
 *  b: number
 * }>}
 */
export const hexToRGB = (hex) => {
  const int = parseInt(hex.replace("#", ""), 16);

  return {
    r: (int >> 16) & 255,
    g: (int >> 8) & 255,
    b: int & 255,
  };
};

/**
 * Converts a RGB object to r, g, b format por styles
 *
 * @param {Object} rgbObject
 * @param {number} r
 * @param {number} g
 * @param {number} b
 * @returns {string}
 */
export const rgbObjectToStringFormat = ({ r, g, b }) => {
  return `${r}, ${g}, ${b}`;
};
