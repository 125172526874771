import { Avatar, Button, Card, Modal, Switch } from "antd";
import Text from "antd/es/typography/Text";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import {
  PERIODS,
  getCurrencyConfig,
  renderPrice,
  toISOFormatString,
} from "../../../../helpers/tools";
import { useDispatch, useSelector } from "react-redux";
import { getSearchValueByKey } from "../../../../Redux/Selectors/searchSelectors";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import {
  getQuotePricingRequest,
  quoteSubstractItem,
  resetIndividualDates,
  setIndividualDates,
  selectVariant,
} from "../../redux/QuoteActions";
import {
  getIndividualDatesByRow,
  getQuotePricingByRow,
  isPricingFetchingByRow,
} from "../../redux/QuoteSelectors";
import useIsMobile from "../../../../hooks/useIsMobile";
import DateProductPicker from "../../components/DateProductPicker/DateProductPicker";
import { CloseOutlined } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import { clickEvents, trackEvent } from "../../../../services/mixpanel";
import BundleDetails from "../../components/BundleDetails/BundleDetails";
import PickQuoteVariant from "../../components/PickQuoteVariant/PickQuoteVariant";
import { size } from "lodash";
import { removeRequiredInfo } from "../../redux/QuoteActions";
import { RENTALS, isRental } from "../../../../helpers/rentals";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? "2fr 1fr" : "1fr 2fr 1fr"};
  flex-direction: row;
  padding-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
`;

const ProductDetailContainer = ({ product, passengerId, rowId }) => {
  const supplierData = useSelector(getSupplierData);
  const isMobile = useIsMobile();
  const { t } = useTranslation(["shop", "common"]);
  const [dateDisabled, setDateDisabled] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dates = useSelector((state) => getSearchValueByKey(state, "dates"));
  const individualDates = useSelector((state) =>
    getIndividualDatesByRow(state, passengerId, rowId)
  );
  const isFetching = useSelector((state) =>
    isPricingFetchingByRow(state, passengerId, rowId)
  );
  const pricing = useSelector((state) =>
    getQuotePricingByRow(state, passengerId, rowId)
  );
  const dispatch = useDispatch();

  const sanitisedVariants = [
    {
      quantity: 1,
      type: product.type === "product" ? "product" : "bundle",
      id: product._id,
      variants: [],
    },
  ];

  useEffect(() => {
    if (dates.to && dates.from && dateDisabled) {
      dispatch(
        getQuotePricingRequest(
          {
            from: toISOFormatString(dates.from),
            to: toISOFormatString(dates.to),
            period: PERIODS.day,
            listing: sanitisedVariants,
            customPricing: {},
            byProduct: true,
            currency: product?.currency,
            allowDiscounts: true,
            rowId,
          },
          passengerId
        )
      );
    }
  }, [JSON.stringify(dates.to), JSON.stringify(dates.from), dateDisabled]);

  useEffect(() => {
    if (individualDates.from && individualDates.to) {
      dispatch(
        getQuotePricingRequest(
          {
            from: toISOFormatString(individualDates.from),
            to: toISOFormatString(individualDates.to),
            period: PERIODS.day,
            listing: sanitisedVariants,
            customPricing: {},
            byProduct: true,
            currency: product?.currency,
            allowDiscounts: true,
            rowId,
          },
          passengerId
        )
      );
    }
  }, [individualDates]);

  const handleOnSwitchChanged = () => {
    trackEvent(clickEvents.PICK_INDIVIDUAL_DATES, {
      page: "Quote",
      itemId: product._id,
      itemTitle: product.title,
      category: product?.category?.value,
      supplierId: supplierData._id,
      supplierName: supplierData.name,
    });
    setDateDisabled(!dateDisabled);
    dispatch(resetIndividualDates(passengerId, rowId));
  };

  const handleOnPickDate = (key, val) => {
    dispatch(setIndividualDates(key, val, passengerId, rowId));
  };

  const handleOnDelete = () => {
    setShowDeleteModal(true);
  };
  const handleSelectedVariant = (variantId, uniqueKey, autoSelect) => {
    dispatch(
      selectVariant(variantId, uniqueKey, passengerId, rowId, autoSelect)
    );
  };

  const handleOnDeleteConfirmation = () => {
    trackEvent(clickEvents.REMOVE_PRODUCT_FROM_BASKET, {
      page: "Quote",
      itemId: product?._id,
      itemTitle: product?.title,
      category: product?.category?.value,
      supplierId: supplierData._id,
      supplierName: supplierData.name,
    });
    dispatch(quoteSubstractItem(passengerId, rowId));
    dispatch(removeRequiredInfo(`${product._id}-${rowId}`, passengerId));
    setShowDeleteModal(false);
  };

  const renderPickVariants = () => {
    let productVariantsSelection = [];
    if (product?.type === "product") {
      productVariantsSelection = [
        {
          id: product?._id,
          quantity: 1,
        },
      ];
    }
    if (product.type !== "product") {
      productVariantsSelection = product?.products.map((item) => ({
        id: item.productId,
        quantity: item.quantity,
      }));
    }

    return productVariantsSelection.map(({ id, quantity }, index) =>
      Array.from({ length: quantity }).map((_, idx) => {
        const uniqueKey = `${id}-${index}-${idx}`;
        return (
          <PickQuoteVariant
            key={uniqueKey}
            productId={id}
            showTitle={size(productVariantsSelection) > 1}
            handleSelectedVariant={(variantId, autoSelect) =>
              handleSelectedVariant(variantId, uniqueKey, autoSelect)
            }
          />
        );
      })
    );
  };

  return (
    <Card>
      <Wrapper isMobile={isMobile}>
        {!isMobile && (
          <img
            src={product.images[0]}
            size="large"
            shape="square"
            alt={`Alquilar ${product.title} - ${supplierData?.name}`}
            style={{ width: 120, height: 120, objectFit: "contain" }}
          />
        )}
        <Content>
          <Text style={{ fontSize: 14 }} strong>
            {isMobile && (
              <Avatar
                src={product.images[0]}
                size="large"
                shape="square"
                style={{ marginRight: 10 }}
              />
            )}
            {product.title}
          </Text>

          <Paragraph style={{ fontSize: 14 }} type="secondary">
            {product.subtitle}
          </Paragraph>
          {(isRental(supplierData._id, RENTALS.travesiaCatedral) ||
            isRental(supplierData._id, RENTALS.extremoOutdoor) ||
            isRental(supplierData._id, RENTALS.windfly) ||
            isRental(supplierData._id, RENTALS.fronteraSur) ||
            isRental(supplierData._id, RENTALS.staging)) &&
            renderPickVariants()}
          {!(
            isRental(supplierData._id, RENTALS.travesiaCatedral) ||
            isRental(supplierData._id, RENTALS.extremoOutdoor) ||
            isRental(supplierData._id, RENTALS.windfly) ||
            isRental(supplierData._id, RENTALS.fronteraSur) ||
            isRental(supplierData._id, RENTALS.staging)
          ) &&
            product.type !== "product" && (
              <BundleDetails products={product.products} />
            )}
          {isRental(supplierData._id, RENTALS.alborde) && (
            <div>
              <Text style={{ fontSize: 14, marginRight: 10 }}>
                Seleccionar fecha individual
              </Text>
              <Switch size="small" onChange={handleOnSwitchChanged} />
            </div>
          )}

          {!dateDisabled && (
            <DateProductPicker
              onChange={handleOnPickDate}
              from={individualDates.from}
              to={individualDates.to}
            />
          )}
        </Content>
        <PriceContainer>
          {!isFetching && (
            <Text strong>
              {getCurrencyConfig(pricing.currency).symbol}{" "}
              {renderPrice(pricing.total, pricing.currency)}
            </Text>
          )}
          {!isFetching && pricing.timeQuantity > 1 && (
            <div>
              <Text type="secondary" style={{ fontSize: 12 }}>
                {getCurrencyConfig(pricing.currency).symbol}{" "}
                {renderPrice(
                  pricing.total / pricing.timeQuantity,
                  pricing.currency
                )}{" "}
                {t(`common:byPeriods.per_${product.rentalPeriodType}`)}
              </Text>
            </div>
          )}
          {isFetching && (
            <Text style={{ fontSize: isMobile ? 12 : 14 }}>
              Obteniendo precio..
            </Text>
          )}
        </PriceContainer>
        <Button
          onClick={handleOnDelete}
          icon={<CloseOutlined />}
          style={{ position: "absolute", top: 10, right: 10, border: "none" }}
        />
        <Modal
          open={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          centered
          title="Atención"
          onOk={handleOnDeleteConfirmation}
          bodyStyle={{ paddingTop: 20, paddingBottom: 20 }}
        >
          <Text>
            ¿Está seguro que desea quitar <Text strong>{product.title}</Text> de
            la cotización?
          </Text>
        </Modal>
      </Wrapper>
    </Card>
  );
};

export default ProductDetailContainer;
