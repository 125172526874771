import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getUserLoggedIn } from "../features/ShopPage/redux/selectors/MainMemberSelectors";
import { signInCustomerRequest } from "../features/ShopPage/redux/actions/MainMemberActions";

const useUser = (customerId = null) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(getUserLoggedIn, shallowEqual);

  useEffect(() => {
    if (!loggedIn && customerId) {
      dispatch(signInCustomerRequest(customerId));
    }
  }, [loggedIn]);

  return {
    loggedIn,
    error: false,
  };
};

export default useUser;
