import { Card } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import Text from "antd/es/typography/Text";
import styled from "styled-components";
import {
  getFinalPriceFetching,
  getSubtotalByPassenger,
} from "../../redux/QuoteSelectors";
import { getCurrencyConfig, renderPrice } from "../../../../helpers/tools";
import { getSupplierSettings } from "../../../../Redux/Selectors/appConfigSelector";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  flex-direction: row;
`;

const SubtotalFooter = ({ id }) => {
  const subtotal = useSelector((state) => getSubtotalByPassenger(state, id));
  const settings = useSelector(getSupplierSettings);
  const isFetching = useSelector(getFinalPriceFetching);

  return (
    <Card>
      <Container>
        {!isFetching && (
          <Text type="secondary">
            Subtotal: {getCurrencyConfig(settings.currency).symbol}{" "}
            {renderPrice(subtotal, settings.currency)}
          </Text>
        )}
        {isFetching && <Text>Calculando subtotal..</Text>}
      </Container>
    </Card>
  );
};

export default SubtotalFooter;
