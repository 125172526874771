import { SUPPLIER_SETTINGS_SUCCESS } from "../Actions/supplierSettingsActions";

const INITIAL_STATE = {
  data: null,
};

export const supplierSettingsReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SUPPLIER_SETTINGS_SUCCESS: {
      return {
        ...payload.data,
      };
    }
    default:
      return state;
  }
};

export default supplierSettingsReducer;
