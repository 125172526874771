import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { I18nextProvider } from "react-i18next";
import i18n from "../config/i18n";

const LanguageProvider = ({ locale, children }) => {
  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.node,
};

export default LanguageProvider;
