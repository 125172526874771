import styled from "styled-components";
import CallToActionButtonComponent from "../CallToActionButton/CallToActionButton";

export const Container = styled.div`
  padding: 10px;
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  border-radius: 10px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.borders.gray};
`;

export const CallToActionButton = styled(CallToActionButtonComponent)`
  width: 100%;
`;
