import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Result,
  Row,
} from "antd";
import html2canvas from "html2canvas";
import React, { useEffect, useRef, useContext } from "react";
import Text from "antd/es/typography/Text";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsProcessFinished,
  getPassengersDataWithProducts,
  getQuoteTotalPrice,
} from "../../redux/QuoteSelectors";
import useIsMobile from "../../../../hooks/useIsMobile";
import PassengerCard from "../../components/PassengerCard/PassengerCard";
import { BookOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  getCurrencyConfig,
  renderPrice,
  sanitizePhonePrefix,
} from "../../../../helpers/tools";
import {
  getSupplierData,
  getSupplierSettings,
} from "../../../../Redux/Selectors/appConfigSelector";
import SupplierDetails from "../../components/SupplierDetails/SupplierDetails";
import { sendEmailRequest } from "../../redux/QuoteActions";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import { getSearchValueByKey } from "../../../../Redux/Selectors/searchSelectors";
import moment from "moment";
import { clickEvents, trackEvent } from "../../../../services/mixpanel";
import { performTransactionRequest } from "../../../TransactionPage/TransactionActions";
import { normaliseListing, normalisePassengers } from "../../helpers/helpers";
import { saveMainMemberInfo } from "../../../ShopPage/redux/actions/MainMemberActions";
import PhoneInput from "../../../../components/FormInputs/PhoneInput";
import PersonalIdInput from "../../../../components/FormInputs/PersonalIdInput";
import { CUSTOMER_TYPE } from "../../../../components/FormInputs/constants";
import CountrySelectForm from "../../../../components/FormInputs/CountrySelectForm";
import { useSupplierConfig } from "../../../../hooks/useSupplierConfig";
import { getTransactionStatus } from "../../../ShopPage/redux/selectors/TransactionStatusSelectors";
import OverlaySpiner from "../../../../components/OverlaySpinner/OverlaySpinner";
import { isEmpty } from "lodash";
import BannerRenderContainer from "../../../../components/BannerComponent/BannerRenderContainer";
import { provinces } from "../../../../config/provinces";
import ProvinceSelector from "../../../../components/ProvinceSelector/ProvinceSelector";
import { findProvinceIdByName } from "../../../ShopPage/utils";
import CitySelectorContainer from "../../../../components/CitySelector/CitySelectorContainer";
import DateOfBirthSelector from "../../../../components/DateOfBirthSelector/DateOfBirthSelector";
import { cardStatus } from "../../../PaymentPage/utils";
import { validateDateOfBirth } from "../../../ShopPage/components/MainMemberForm/helpers/helpers";
import { DateOfBirthContext } from "../../../../components/DateOfBirthSelector/context/DateOfBirthContext";
import { sanitizeDateOfBirth } from "../../../../tools/dateTools";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 24px 10px;
  }
`;

const ShareModalContainer = ({ showModal, onClose }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation(["shop", "result"]);
  const ref = useRef();
  const [form] = useForm();
  const dispatch = useDispatch();
  const settings = useSelector(getSupplierSettings);
  const { fetching } = useSelector(getTransactionStatus);
  const supplierData = useSelector(getSupplierData);
  const totalPrice = useSelector(getQuoteTotalPrice);
  const quoteData = useSelector(getPassengersDataWithProducts);
  const dates = useSelector((state) => getSearchValueByKey(state, "dates"));
  const isProcessFinished = useSelector(getIsProcessFinished);
  const { createCustomer } = useSupplierConfig();
  const spacing = isMobile ? [24] : [12, 12];
  const { dateOfBirth } = useContext(DateOfBirthContext);

  const handleOnPrint = async () => {
    trackEvent(clickEvents.DOWNLOAD_QUOTE, {
      page: "Quote",
      supplierId: supplierData._id,
      supplierName: supplierData.name,
      dateFrom: moment(dates.from).format("DD-MM-YYYY"),
      dateTo: moment(dates.to).format("DD-MM-YYYY"),
      currency: getCurrencyConfig(settings.currency).symbol,
      quoteData,
    });
    const canvas = await html2canvas(ref.current, {
      useCORS: true,
      scale: 1,
    });
    const a = document.createElement("a");
    const image = canvas.toDataURL("image/png", 1.0);
    a.href = image;
    a.download = `${supplierData.name}-cotizacion.png`;
    a.click();
  };

  const handleOnRequestBooking = () => {
    const values = form.getFieldsValue();
    const phonePrefix =
      values?.phonePrefix && sanitizePhonePrefix(values?.phonePrefix);
    if (settings.onlinePaymentsEnabled) {
      const provinceId = findProvinceIdByName(
        values.nationality?.toLowerCase(),
        values.province
      );
      dispatch(
        saveMainMemberInfo({
          ...values,
          province: provinceId,
          dateOfBirth: sanitizeDateOfBirth(dateOfBirth),
          phonePrefix,
        })
      );
      const listing = normaliseListing(quoteData);
      const startDate = dates.from;
      const endDate = dates.to;
      const period = "day";
      const currency = settings.currency;
      const passengers = normalisePassengers(quoteData);
      const pickAndDrop = {
        pickup: {
          date: dates.from,
        },
        dropoff: {
          date: dates.to,
        },
      };
      trackEvent(clickEvents.REQUEST_BOOKING, {
        page: "Quote",
        supplierId: supplierData._id,
        supplierName: supplierData.name,
        dateFrom: moment(dates.from).format("DD-MM-YYYY"),
        dateTo: moment(dates.to).format("DD-MM-YYYY"),
        currency: getCurrencyConfig(settings.currency).symbol,
        passengers,
      });
      dispatch(
        performTransactionRequest(
          listing,
          startDate,
          endDate,
          period,
          currency,
          { passengers },
          pickAndDrop
        )
      );
    } else {
      const metadata = {
        quoteData,
        total: totalPrice,
        dates: {
          from: moment(dates.from).format("DD-MM-YYYY"),
          to: moment(dates.to).format("DD-MM-YYYY"),
        },
        currency: getCurrencyConfig(settings.currency).symbol,
        supplier: {
          name: supplierData.name,
          logo: supplierData.logo,
          email: supplierData.email,
          telephone: supplierData.telephone,
        },
        customer: {
          name: values.name,
          email: values.email,
          telephone: `${phonePrefix}${values.phoneNumber}`,
        },
      };
      trackEvent(clickEvents.REQUEST_QUOTE, {
        page: "Quote",
        supplierId: supplierData._id,
        supplierName: supplierData.name,
        dateFrom: moment(dates.from).format("DD-MM-YYYY"),
        dateTo: moment(dates.to).format("DD-MM-YYYY"),
        currency: getCurrencyConfig(settings.currency).symbol,
        quoteData,
      });
      dispatch(sendEmailRequest(supplierData.email, metadata));
    }
  };

  useEffect(() => {
    if (quoteData[0].name) {
      form.setFieldsValue({
        name: quoteData[0].name,
        email: quoteData[0].email,
        telephone: quoteData[0].phone,
      });
    }
  }, [JSON.stringify(quoteData[0])]);

  const handleOnReset = () => {
    window.location.reload();
  };

  return (
    <StyledModal
      open={showModal}
      closable={!isProcessFinished}
      style={{ padding: 0 }}
      footer={
        isProcessFinished ? null : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={handleOnPrint}
              icon={<DownloadOutlined />}
              block={isMobile}
              size="large"
            >
              Descargar
            </Button>

            <Form.Item style={{ width: isMobile ? "100%" : "auto" }}>
              <Button
                block={isMobile}
                type="primary"
                icon={<BookOutlined />}
                htmlType="submit"
                size="large"
                onClick={form.submit}
              >
                {settings.onlinePaymentsEnabled
                  ? "Continuar"
                  : "Consultar disponibilidad"}
              </Button>
            </Form.Item>
          </div>
        )
      }
      onOk={onClose}
      onCancel={isProcessFinished ? null : onClose}
      width={isMobile ? "100%" : 800}
    >
      {!isProcessFinished && (
        <Container ref={ref}>
          <SupplierDetails />
          {quoteData.map((passenger) => {
            if (isEmpty(passenger.products)) {
              return null;
            }
            return (
              <PassengerCard
                key={passenger.id}
                passenger={passenger}
                supplierId={supplierData._id}
              />
            );
          })}
          <BannerRenderContainer screen="formScreen" />
          <Card style={{ borderWidth: 4 }} bodyStyle={{ padding: 10 }}>
            <TotalWrapper>
              <Text strong style={{ fontSize: 18 }}>
                Total:
              </Text>
              <Text strong style={{ fontSize: 18 }}>
                {getCurrencyConfig(settings.currency).symbol}{" "}
                {renderPrice(totalPrice, settings.currency)}
              </Text>
            </TotalWrapper>
          </Card>
        </Container>
      )}
      {!isProcessFinished && (
        <Container>
          {settings.rentalRules && (
            <Alert message={settings.rentalRules} type="warning" showIcon />
          )}
          <Card title="Datos de contacto">
            <Form
              form={form}
              onFinish={handleOnRequestBooking}
              layout="vertical"
              initialValues={{
                phonePrefix: createCustomer.phonePrefixByRegion,
                type: createCustomer.personalIdType,
                nationality: createCustomer.nationality,
                createdBy: supplierData._id,
                customerType: CUSTOMER_TYPE.PERSONAL,
              }}
            >
              <Row gutter={spacing}>
                <Col span={spacing[0]}>
                  <Form.Item
                    name="name"
                    label={t("shop:form.name")}
                    rules={[
                      {
                        required: true,
                        message: t("shop:form.error", {
                          field: t("shop:form.name"),
                        }),
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={spacing[0]}>
                  <Form.Item
                    name="surname"
                    label={t("shop:form.surname")}
                    rules={[
                      {
                        required: true,
                        message: t("shop:form.error", {
                          field: t("shop:form.surname"),
                        }),
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              {settings.onlinePaymentsEnabled && (
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.nationality !== currentValues.nationality
                  }
                  noStyle
                >
                  {({ getFieldValue, setFieldValue }) => {
                    const nationality =
                      getFieldValue("nationality")?.toLowerCase();
                    return (
                      <>
                        <CountrySelectForm
                          name="nationality"
                          required
                          size={"large"}
                        />
                        <Form.Item
                          name="dateOfBirth"
                          label={t("form.dateOfBirth")}
                          rules={[
                            {
                              required: true,
                              validator: (_) => {
                                const { validateStatus, errorMsg } =
                                  validateDateOfBirth(dateOfBirth, t);
                                if (validateStatus === cardStatus.ERROR) {
                                  return Promise.reject(errorMsg);
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <DateOfBirthSelector />
                        </Form.Item>
                        {provinces[nationality] && (
                          <Row gutter={spacing}>
                            <Col span={spacing[0]}>
                              <Form.Item
                                name="province"
                                label={t("form.province")}
                                rules={[
                                  {
                                    required: true,
                                    message: t("form.error", {
                                      field: t("form.province"),
                                    }),
                                  },
                                ]}
                              >
                                <ProvinceSelector
                                  placeholder={t("form.province")}
                                  country={nationality}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={spacing[1]}>
                              <Form.Item
                                name="city"
                                label={t("form.city")}
                                rules={[
                                  {
                                    required: true,
                                    message: t("form.error", {
                                      field: t("form.city"),
                                    }),
                                  },
                                ]}
                              >
                                <CitySelectorContainer
                                  placeholder={t("form.city")}
                                  name={"city"}
                                  onSelect={(city) =>
                                    setFieldValue("city", city)
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              )}
              {settings.onlinePaymentsEnabled && (
                <PersonalIdInput
                  typeWidth={180}
                  customerType={CUSTOMER_TYPE.PERSONAL}
                  required
                />
              )}
              <Form.Item
                name="email"
                label={t("shop:form.email")}
                rules={[
                  {
                    required: true,
                    message: t("shop:form.error", {
                      field: t("shop:form.email"),
                    }),
                  },
                  {
                    type: "email",
                    message: t("shop:form.error", {
                      field: t("shop:form.email"),
                    }),
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <PhoneInput prefixWidth={180} required />
              {settings.onlinePaymentsEnabled && (
                <Form.Item
                  name="address"
                  label={t("shop:form.customerAddress")}
                  rules={[
                    {
                      required: true,
                      message: t("shop:form.error", {
                        field: t("shop:form.customerAddress"),
                      }),
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              )}
            </Form>
          </Card>
        </Container>
      )}
      {isProcessFinished && (
        <Container>
          <Result
            status="success"
            title={t("result:successfullQuoteTitle")}
            subTitle={t("result:successfullQuoteSubtitle")}
            extra={
              <Button type="primary" onClick={handleOnReset}>
                {t("result:backToQuote")}
              </Button>
            }
          />
        </Container>
      )}
      <OverlaySpiner isLoading={fetching} />
    </StyledModal>
  );
};

export default ShareModalContainer;
