import styled, { css } from "styled-components";
import ButtonAntd from "antd/es/button/button";
import { lighten } from "polished";

export const Button = styled(ButtonAntd)`
  ${({ type }) =>
    type === "primary"
      ? css`
          font-weight: 600;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
        `
      : null}

  ${({ type, theme }) =>
    type === "link"
      ? css`
          height: auto !important;
          padding: 0 0 5px !important;
          vertical-align: middle !important;
          border-bottom: 2px solid ${theme.colors.primary};
          border-radius: 0 !important;
        `
      : null}

  ${({ type, theme }) =>
    type === "light-primary"
      ? css`
          background-color: ${lighten(0.1, theme.colors.primary)};
          color: #fff;
          font-weight: 700;
          &:hover {
            background-color: ${lighten(0.15, theme.colors.primary)};
          }
        `
      : null}
`;

Button.propTypes = {
  ...ButtonAntd.propTypes,
};
