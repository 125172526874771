import { Button } from "antd";
import PropTypes from "prop-types";
import useIsMobile from "../../../hooks/useIsMobile";
import { ContainerButton } from "./RemainingPayment.style";

const FooterButton = ({ buttonLabel, onClick }) => {
  const isMobile = useIsMobile();

  return (
    <ContainerButton border={isMobile}>
      <Button type="primary" shape="round" onClick={onClick}>
        {buttonLabel}
      </Button>
    </ContainerButton>
  );
};

FooterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonName: PropTypes.string.isRequired,
};

export default FooterButton;
