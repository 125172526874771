import styled from "styled-components";
import Section from "../../../components/Section/Section";
import RichTextRender from "../../../components/RichTextRender/RichTextRender";
import mediaQueries from "../../../utils/mediaQueries";

export const LightParagraph = styled(RichTextRender)`
  color: ${({ theme }) => theme.colors.white};

  p {
    margin: 10px;
  }
`;

export const ContactUsSection = styled(Section)`
  position: relative;
  padding-bottom: 50px;
`;

export const FeatureContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;

  @media ${mediaQueries.desktop} {
    padding-bottom: 100px;
  }
`;

export const StyledMapImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 10px;
`;

export const SectionBanner = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
