import { useNavigate } from "react-router-dom";
import { removeSpaces } from "../../ShopPage/utils";
import { createUTCDate, sanitizePhonePrefix } from "../../../helpers/tools";
import { useDispatch } from "react-redux";
import { saveMainMemberInfo } from "../../ShopPage/redux/actions/MainMemberActions";
import { quoteBuildAddPassengers } from "../../ShopPage/redux/actions/shopActions";
import { referralIdentifier } from "../helpers/helpers";

const CheckinContainer = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmitMainMember = (values) => {
    const data = {
      ...values,
      name: removeSpaces(values.name),
      surname: removeSpaces(values.surname),
      dateOfBirth: createUTCDate(values.dateOfBirth).format("YYYY-MM-DD"), //CHECK correct format
      phonePrefix:
        values.phonePrefix && sanitizePhonePrefix(values.phonePrefix),
      referral: referralIdentifier(values.referralCategory, values.referral),
      housingInfo: { hotel: values.housing, roomNumber: "" },
    };
    dispatch(saveMainMemberInfo(data));
    dispatch(
      quoteBuildAddPassengers([
        { name: `${data.name} ${data.surname}`, deletable: false, id: 0 },
      ])
    );
    navigate("participants");
  };
  return children({ onSubmitMainMember });
};

export default CheckinContainer;
