import { Card } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Text from "antd/es/typography/Text";
import PickProductModalContainer from "../../containers/PickProductModalContainer/PickProductModalContainer";
import CategoriesList from "../CategoriesList/CategoriesList";
import { clickEvents, trackEvent } from "../../../../services/mixpanel";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import { RENTALS, isRental } from "../../../../helpers/rentals";
import SnowCategoriesList from "../CategoriesList/SnowCategoriesList";
import {
  getBundlesWithOutCategory,
  getCategoriesQuote,
} from "../../redux/QuoteSelectors";
import { sortCategorizedProducts } from "../../tools/helpers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  min-height: 400px;
`;

const WinterMemberCard = ({ id }) => {
  const { t } = useTranslation();
  const supplierData = useSelector(getSupplierData);
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: null,
    selectedBundle: null,
  });
  const categories = useSelector((state) => getCategoriesQuote(state, t));
  const bundlesWithOutCategory = useSelector(getBundlesWithOutCategory);

  const handleOnClick = (category, selectedBundle) => {
    trackEvent(clickEvents.SELECT_CATEGORY, {
      page: "Quote",
      category: category,
      supplierId: supplierData._id,
      supplierName: supplierData.name,
      selectedBundle,
    });
    setModalVisible({ visible: true, type: category, selectedBundle });
  };

  const renderCategoriesList = () => {
    if (isRental(supplierData._id, RENTALS.alborde)) {
      return <CategoriesList onClick={handleOnClick} />;
    } else {
      return (
        <SnowCategoriesList
          onClick={handleOnClick}
          categories={sortCategorizedProducts(categories, t)}
          bundlesWithOutCategory={bundlesWithOutCategory}
        />
      );
    }
  };

  return (
    <Card>
      <Container>
        <Text strong>{t("shop:choose_equipment")}:</Text>
        {renderCategoriesList()}
        <PickProductModalContainer
          open={modalVisible.visible}
          type={modalVisible.type}
          selectedBundle={modalVisible.selectedBundle}
          closeModal={() => setModalVisible(false)}
          passengerId={id}
        />
      </Container>
    </Card>
  );
};

export default WinterMemberCard;
