export const SET_WEBISTE_CURRENCY = "SET_WEBSITE_CURRENCY";

export const setWebsiteCurrency = (currency) => {
  return {
    type: SET_WEBISTE_CURRENCY,
    payload: {
      currency,
    },
  };
};
