import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getWebsiteCurrency } from "../Redux/Selectors/currencySelector";
import { setWebsiteCurrency } from "../Redux/Actions/currencyActions";

const useWebsiteCurrency = () => {
  const dispatch = useDispatch();
  const currency = useSelector(getWebsiteCurrency, shallowEqual);

  const setCurrency = (currency) => {
    if (currency) {
      localStorage.setItem("currency", currency);
    } else {
      localStorage.removeItem("currency");
    }
    dispatch(setWebsiteCurrency(currency));
  };

  return { currency, setCurrency };
};

export default useWebsiteCurrency;
