import { Form, Input, Modal, Select } from "antd";
import React, { useEffect } from "react";
import Paragraph from "antd/es/typography/Paragraph";
import { useTranslation } from "react-i18next";

const GroupDetailsModal = ({
  visible,
  passenger,
  handleOnSubmit,
  handleOnCancel,
  config = {
    height: {
      required: true,
      visible: true,
    },
    weight: {
      required: true,
      visible: true,
    },
    footLength: {
      required: false,
      visible: true,
    },
    experience: {
      required: true,
      visible: true,
    },
  },
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(["common", "shop"]);
  const onCancel = () => {
    form.resetFields();
    handleOnCancel();
  };

  const handleOnOk = () => {
    form.validateFields().then((values) => {
      form.resetFields();
      handleOnSubmit({
        ...values,
        id: passenger?.id,
      });
      onCancel();
    });
  };

  useEffect(() => {
    form.resetFields();
    if (passenger) {
      form.setFieldsValue({
        height: passenger?.height,
        weight: passenger?.weight,
        experience: passenger?.experience,
        footLength: passenger?.footLength,
      });
    }
  }, [JSON.stringify(passenger)]);

  return (
    <Modal
      open={visible}
      title={passenger?.name}
      onOk={handleOnOk}
      onCancel={onCancel}
      centered
      cancelText={t("back")}
      okText={t("continue")}
    >
      <Paragraph type="secondary">
        {t("shop:checkout.modalDescription", { user: passenger?.name })}
      </Paragraph>
      <Form form={form} layout="vertical">
        {config.height.visible && (
          <Form.Item
            name="height"
            label={t("shop:checkout.modalHeight")}
            rules={[
              {
                required: config.height.required,
                message:
                  "Necesitamos saber la altura para darte el mejor equipo.",
              },
            ]}
          >
            <Input
              type="number"
              min={0}
              autoComplete="off"
              data-testid="quote-passenger-height-input"
            />
          </Form.Item>
        )}
        {config.weight.visible && (
          <Form.Item
            name="weight"
            label={t("shop:checkout.modalWeight")}
            rules={[
              {
                required: config.weight.required,
                message:
                  "Necesitamos saber el peso para darte el mejor equipo.",
              },
            ]}
          >
            <Input
              type="number"
              min={0}
              autoComplete="off"
              data-testid="quote-passenger-weight-input"
            />
          </Form.Item>
        )}
        {config.footLength.visible && (
          <Form.Item
            name="footLength"
            label={t("shop:checkout.modalFootLength")}
            rules={[{ required: config.footLength.required }]}
          >
            <Input
              type="number"
              min={0}
              autoComplete="off"
              data-testid="quote-passenger-footlength-input"
            />
          </Form.Item>
        )}
        {config.experience.visible && (
          <Form.Item
            name="experience"
            label={t("shop:checkout.modalExperience")}
            rules={[
              {
                required: config.experience.required,
                message: "Necesitamos saber tu nivel de experiencia.",
              },
            ]}
          >
            <Select data-testid="quote-passenger-experience-select">
              <Select.Option value="beginner">
                {t("shop:checkout.beginner")}
              </Select.Option>
              <Select.Option value="intermediate">
                {t("shop:checkout.intermediate")}
              </Select.Option>
              <Select.Option value="expert">
                {t("shop:checkout.expert")}
              </Select.Option>
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default GroupDetailsModal;
