import { Affix, Button } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import ShareModalContainer from "../../containers/ShareModalContainer/ShareModalContainer";
import {
  getFinalPriceFetching,
  getInfoValidation,
  getQuoteTotalPrice,
} from "../../redux/QuoteSelectors";
import { getSupplierSettings } from "../../../../Redux/Selectors/appConfigSelector";
import { useSelector } from "react-redux";
import { getCurrencyConfig, renderPrice } from "../../../../helpers/tools";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { DateOfBirthProvider } from "../../../../components/DateOfBirthSelector/context/DateOfBirthContext";

const Container = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.borders.gray};
  height: 80px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;
const Footer = () => {
  const totalPrice = useSelector(getQuoteTotalPrice);
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const isFetching = useSelector(getFinalPriceFetching);
  const settings = useSelector(getSupplierSettings);
  const [showModal, setShowModal] = useState(false);
  const isValidForm = useSelector(getInfoValidation);
  const handleOnClick = () => {
    setShowModal(!showModal);
  };
  return (
    <Affix offsetBottom={0}>
      <div>
        <Container>
          <Button
            type="primary"
            shape="round"
            block={isMobile}
            size="large"
            disabled={totalPrice === 0 || !isValidForm}
            onClick={handleOnClick}
          >
            {isFetching
              ? t("shop:summaryLoader")
              : `${t("continue")} | ${getCurrencyConfig(settings.currency).symbol
              } ${renderPrice(totalPrice, settings.currency)}`}
          </Button>
        </Container>
        <DateOfBirthProvider>
          <ShareModalContainer showModal={showModal} onClose={handleOnClick} />
        </DateOfBirthProvider>
      </div>
    </Affix>
  );
};
export default Footer;