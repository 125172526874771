import React, { useRef, useState } from "react";
import {
  MobileCarousel,
  MobileImage,
  SlideWrapper,
} from "../../../../../HomePage/components/FeaturedExperiences/styles/CarouselFeaturedExperiences.styles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ArrowContainer, ImageCounter } from "./Collage.styles";
import BootstrapIcon from "../../../../../../components/BootstrapIcon/BoostrapIcon";

const Slider = ({ images, title }) => {
  const carouselRef = useRef(null);
  const [counter, setCounter] = useState(1);
  const renderArrowLeft = () => {
    if (images.length === 1) return null;
    return (
      <ArrowContainer
        oriented="left"
        onClick={() => {
          setCounter(counter <= 1 ? images.length : counter - 1);
          carouselRef.current.prev();
        }}
      >
        <LeftOutlined style={{ fontSize: 40, color: "white" }} />
      </ArrowContainer>
    );
  };

  const renderArrowRight = () => {
    if (images.length === 1) return null;
    return (
      <ArrowContainer
        oriented="right"
        onClick={() => {
          setCounter(counter >= images.length ? 1 : counter + 1);
          carouselRef.current.next();
        }}
      >
        <RightOutlined style={{ fontSize: 40, color: "white" }} />
      </ArrowContainer>
    );
  };

  const renderCounter = () => {
    return (
      <ImageCounter>
        <BootstrapIcon iconName={"camera"} /> {counter}/{images.length}
      </ImageCounter>
    );
  };

  return (
    <div style={{ position: "relative", width: "100vw" }}>
      <MobileCarousel draggable dots={false} ref={carouselRef}>
        {images.map((img, index) => {
          return (
            <SlideWrapper key={index}>
              <MobileImage src={img} alt={title} />
            </SlideWrapper>
          );
        })}
      </MobileCarousel>
      {renderArrowLeft()}
      {renderArrowRight()}
      {renderCounter()}
    </div>
  );
};

export default Slider;
