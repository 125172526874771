import { isMobileSafari, isSafari } from "react-device-detect";

const getFileExtension = () => {
  if (isMobileSafari || isSafari) return ".png";
  return ".webp";
};

export const downsizeImage = (src = "", quality, width) => {
  if (src === "") return null;
  if (!quality || !width) return src;
  const transformation = `c_scale,q_${quality},w_${width}`;
  const transformedImage = src?.split("/upload/");
  let image = null;
  if (transformedImage?.length > 0) {
    image =
      transformedImage[0] + `/upload/${transformation}/` + transformedImage[1];
  } else {
    image = src;
  }

  const newImage = image?.replace(
    /\.(gif|jpg|jpeg|tiff?|png|webp|bmp)$/i,
    getFileExtension()
  );
  if (!newImage) return src;
  return addHttps(newImage);
};

const addHttps = (uri) => {
  if (uri) {
    const newUri = uri.replace("http://", "https://");
    return newUri;
  } else {
    return null;
  }
};

export default addHttps;
