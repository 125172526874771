import React from "react";
import PropTypes from "prop-types";
import Row from "antd/es/row";
import CollapseComponent from "../../../../components/Collapse/Collapse";
import Container from "../../../../components/Container/Container";
import { Collapse } from "./FaqContent.styles";

const FaqContent = ({ faqs, onClickButton, ...restProps }) => {
  return (
    <Container {...restProps}>
      <Row justify="start">
        <Collapse panels={faqs} accordion />
      </Row>
    </Container>
  );
};

FaqContent.propTypes = {
  faqs: CollapseComponent.propTypes.panels,
  onClickButton: PropTypes.func,
};

export default FaqContent;
