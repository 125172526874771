import { Card, Modal } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getProductsByCategory } from "../../redux/QuoteSelectors";
import Text from "antd/es/typography/Text";
import styled from "styled-components";
import useIsMobile from "../../../../hooks/useIsMobile";
import ProductContentContainer from "../ProductContentContainer/ProductContentContainer";
import { useTranslation } from "react-i18next";
import BundleContentContainer from "../BundleContentContainer/BundleContentContainer";
import PriceContainer from "../PriceContainer/PriceContainer";
import { isEmpty } from "lodash";
import { COMBO } from "../../../ShopPage/constants";
import { listTypes } from "../../../ShopPage/utils";

const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const PickProductModalContainer = ({
  open,
  type,
  passengerId,
  closeModal,
  selectedBundle,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const products = useSelector((state) => getProductsByCategory(state, type));

  return (
    <Modal
      open={open}
      width={isMobile ? "100vw" : 800}
      bodyStyle={{ minHeight: isMobile ? "90vh" : "100%" }}
      onCancel={closeModal}
      centered={isMobile}
      onOk={closeModal}
      footer={null}
      title={
        <Text style={{ fontWeight: "400" }}>{t("shop:choose_equipment")}:</Text>
      }
    >
      <Container>
        {!isEmpty(selectedBundle) && (
          <Card
            key={selectedBundle._id}
            extra={<PriceContainer product={selectedBundle} />}
            title={selectedBundle?.title}
          >
            <BundleContentContainer
              productId={selectedBundle._id}
              image={selectedBundle.images[0]}
              products={selectedBundle.products}
              description={selectedBundle.description}
              subtitle={selectedBundle.subtitle}
              title={selectedBundle.title}
              passengerId={passengerId}
              closeModal={closeModal}
              promo={selectedBundle.promo}
              price={selectedBundle.price}
              category={listTypes.BUNDLE}
              productsId={selectedBundle.products.map(
                (product) => product.productId
              )}
            />
          </Card>
        )}
        {isEmpty(selectedBundle) &&
          type !== COMBO &&
          products?.map((product) => {
            if (product.type !== listTypes.PRODUCT) {
              return (
                <Card
                  key={product._id}
                  extra={<PriceContainer product={product} />}
                  title={product?.title}
                >
                  <BundleContentContainer
                    productId={product._id}
                    image={product.images[0]}
                    products={product.products}
                    description={product.description}
                    subtitle={product.subtitle}
                    title={product.title}
                    passengerId={passengerId}
                    closeModal={closeModal}
                    promo={product.promo}
                    price={product.price}
                    category={listTypes.BUNDLE}
                    productsId={product.products.map(
                      (productItem) => productItem.productId
                    )}
                  />
                </Card>
              );
            } else {
              return (
                <Card
                  key={product._id}
                  extra={<PriceContainer product={product} />}
                  title={product?.title}
                >
                  <ProductContentContainer
                    id={product?._id}
                    image={product?.images[0]}
                    description={product?.description}
                    subtitle={product?.subtitle}
                    title={product?.title}
                    passengerId={passengerId}
                    closeModal={closeModal}
                    promo={product?.promo}
                    price={product?.price}
                    category={product?.category?.value}
                    periodType={product?.rentalPeriodType}
                    currency={product?.currency}
                  />
                </Card>
              );
            }
          })}
      </Container>
    </Modal>
  );
};

export default PickProductModalContainer;
