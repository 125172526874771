import { intersectionWith } from "lodash";

export const getProductsAssigned = (passengers, assignments, productId) => {
  return intersectionWith(
    passengers,
    Object.values(assignments).filter(
      (assignments) => assignments._id === productId
    ),
    (passenger, assignment) => {
      return passenger.id === assignment.passenger;
    }
  );
};
