import styled, { css } from "styled-components";
import Text from "antd/es/typography/Text";
import { lighten } from "polished";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin-top: ${({ addMargin }) => (addMargin ? "10px" : "0px")};
`;

export const TextTime = styled(Text)`
  background-color: ${({ theme }) => theme.colors.primary} !important;
  ${({ type, theme }) =>
    type === "light-primary"
      ? css`
          background-color: ${lighten(0.6, theme.colors.primary)};
          font-weight: 700;
          &:hover {
            background-color: ${lighten(0.15, theme.colors.primary)};
          }
        `
      : null}
  border-radius: 5px;
  width: 80px;
  height: 31px;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
`;

export const TextDescription = styled(Text)`
  flex: 1;
  max-width: 900px;
`;
