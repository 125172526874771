import React from "react";
import { CityProvider } from "./Context/CityContext";
import CitySelector from "./components/CitySelector";

const CitySelectorContainer = ({
  placeholder = "city",
  width = "100%",
  size = "large",
  onSelect,
}) => {
  return (
    <CityProvider>
      <CitySelector
        onSelect={onSelect}
        placeholder={placeholder}
        width={width}
        size={size}
      />
    </CityProvider>
  );
};

export default CitySelectorContainer;
