import { flatten } from "lodash";
import { paymentMethodsByCurrency, methods } from "../PaymentMethods";

export const exchangeConverterIsFetching = (state) => {
  const { fetching } = state.pages.exchange;
  return fetching;
};

export const getExchangeConverterResult = (state) => {
  const { convertedCurrency } = state.pages.exchange;
  return convertedCurrency;
};

export const getPaymentMethodIdentifier = (state) => {
  const {
    pages: {
      payment: {
        payTransactionScreen: { paymentMethodIdentifier },
      },
    },
  } = state;
  return paymentMethodIdentifier;
};

export const getPaymentCreationState = (state) => {
  const {
    pages: {
      payment: { createPayment },
    },
  } = state;
  return createPayment;
};

export const getPaymentActionState = (state, action) => {
  try {
    const {
      pages: {
        payment: { paymentAction },
      },
    } = state;
    return paymentAction[action] || {};
  } catch {
    return {};
  }
};

export const getPaymentDataState = (state) => {
  return state.pages.payment.paymentData.payment;
};

export const getPaymentMethods = (state) => {
  const {
    pages: {
      payment: {
        paymentData: { paymentMethods },
      },
    },
  } = state;
  if (paymentMethods && paymentMethods.length > 0) {
    const methodsData = paymentMethods.map((method) => {
      return methods[method.paymentMethod];
    });
    return methodsData;
  }
  return [];
};

export const getPaymentMethodsByCurrency = (state) => {
  const { currency } = state.appGlobal.currency;
  const {
    pages: {
      payment: {
        paymentData: { paymentMethods },
      },
    },
  } = state;
  if (paymentMethods && paymentMethods.length > 0) {
    const methodsByCurrency = paymentMethodsByCurrency(currency);
    const validPaymentMethods = paymentMethods.filter(
      (method) => methodsByCurrency[method.paymentMethod]
    );
    const methodsData = validPaymentMethods.map((method) => {
      return methodsByCurrency[method.paymentMethod];
    });
    return flatten(methodsData);
  }

  return [];
};

export const getPaymentMethodsState = (state) => {
  const {
    pages: {
      payment: {
        paymentData: { paymentMethods },
      },
    },
  } = state;
  return paymentMethods;
};

export const getPaymentMethodsStateByPaymentIdentifier = (
  state,
  paymentMethodIdentifier
) => {
  const paymentMethods = getPaymentMethodsState(state);
  return paymentMethods.find(
    (method) => method.paymentMethod === paymentMethodIdentifier
  );
};
