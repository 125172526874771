import { denormalize } from "normalizr";
import { isEmpty } from "lodash";
import {
  supplierGroupSchema,
  supplierGroupsSchema,
} from "../Schemas/supplierGroupsSchema";

export const getSupplierGroupById = (state, id) => {
  if (id) {
    const denormalizeData = denormalize(
      id,
      supplierGroupSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};

export const getSupplierGroups = (state, arrayOfIds) => {
  if (arrayOfIds) {
    const denormalizeData = denormalize(
      arrayOfIds,
      supplierGroupsSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return [];
  }
  return [];
};
