import { schema } from "normalizr";

export const preSaleSchema = new schema.Entity(
  "presales",
  {},
  {
    idAttribute: "_id",
  }
);

export const preSalesSchema = [preSaleSchema];
