import { parseApiDate } from "../../../../../helpers/dates";
import { createUTCDate } from "../../../../../helpers/tools";


export const generateStartAndEndDate = (date) => {
  const parsedDate = createUTCDate(date);
  const startDate = parsedDate.startOf("month").format("YYYY-MM-DD");
  const endDate = parsedDate.endOf("month").format("YYYY-MM-DD");

  return { startDate, endDate };
};

/**
 * @param {Number} hour
 * @param {Number} minute
 * @returns {string} HH:mm
 */
export const formatHourToString = (hour, minute) => {
  const hourMoment = createUTCDate({ hour, minute });
  return hourMoment.format("HH:mm");
};

/**
 * @param {Object} time
 * @param {string} time.hour
 * @param {string} time.minute
 * @param {number} valueAdded
 * @param {string} unit
 * @param {Moment} currentDate
 * @returns {Object}
 */
export const parseDatesAndTime = (time, valueAdded, unit, currentDate) => {
  const startDate = createUTCDate(currentDate).set({
    hour: time.hour,
    minute: time.minute,
  });
  const endDate = startDate.clone().add(valueAdded, unit);
  return { startDate, endDate };
};

/**
 * @param {Number} value
 * @param {Number} total
 * @returns {Number}
 */
export const calculatePercentage = (value, total) => {
  if (typeof value !== "number" || typeof total !== "number") {
    return null;
  }
  const percentage = (value / total) * 100;
  return percentage > 100 ? 100 : percentage;
};

export const sortOptions = (options) => {
  return options?.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });
};

/**
 * @param {string} stringDate YYYYMMDD
 * @returns {string} YYYY-MM-DD
 */
export const formatAvailabilityDates = (stringDate) => {
  const [year, month, day] = [
    stringDate.slice(0, 4),
    stringDate.slice(4, 6),
    stringDate.slice(6, 8),
  ];
  return `${year}-${month}-${day}`;
};

/**

 * @param {Array} parsedDates 
 * @param {Moment} parsedDates[].date 
 * @param {Array} parsedDates[].entries 
 * @param {string} parsedDates[].entries[].startTimeId 
 * @param {Number} parsedDates[].entries[].maxCapacity 
 * @param {Number} parsedDates[].entries[].occupied 
 * @param {Number} parsedDates[].entries[].available 
 * @returns {Array} 
 */

export const calculateAverageOccupancyPercentage = (parsedDates = []) => {
  return parsedDates?.map((dateEntry) => {
    const { entries, date } = dateEntry;

    const totalOccupiedPercentage = entries?.reduce((sum, entry) => {
      return sum + entry.occupiedPercentage;
    }, 0);

    const averageOccupiedPercentage = totalOccupiedPercentage / entries.length;

    return {
      date,
      occupiedPercentage: averageOccupiedPercentage,
    };
  });
};

/**
 *
 * @param {Object} availableDates
 * @returns {Array}
 */
export const groupDates = (availableDates) => {
  const dateGroups = {};
  Object.entries(availableDates).forEach(([startTimeId, objectDate]) => {
    Object.entries(objectDate).forEach(([dateStr, entry]) => {
      const dateString = parseApiDate(formatAvailabilityDates(dateStr));

      if (!dateGroups[dateString]) {
        dateGroups[dateString] = {
          date: dateString,
          entries: [],
        };
      }

      dateGroups[dateString].entries.push({
        startTimeId: startTimeId,
        available: entry.available,
        occupiedPercentage: calculatePercentage(
          entry.occupied,
          entry.maxCapacity
        ),
      });
    });
  });

  const result = Object.values(dateGroups);
  return result;
};
