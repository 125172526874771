import { schema } from "normalizr";

export const paymentSchema = new schema.Entity(
  "payments",
  {},
  {
    idAttribute: "_id",
  }
);

export const paymentsSchema = [paymentSchema];
