import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import {
  CHECK_AVAILABLE_DATES_REQUEST,
  GET_START_TIMES_BY_EXPERIENCE_REQUEST,
  getStartTimesByExperienceSuccess,
  checkAvailableDatesSuccess,
  getStartTimesByExperienceFailure,
  checkAvailableDatesFailure,
} from "../actions/availabilityActions";
import { get, getConfigs } from "../../../../services/httpService";
import environment from "../../../../config/environment";
import { normalizeParams } from "../../../../helpers/tools";

function* handleGetStartTimesByExperience(action) {
  const { experienceId, params } = action.payload;
  const normalizedParams = normalizeParams({ ...params });
  try {
    const token = yield call(getConfigs);
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/experiences/${experienceId}/starttimes`,
      {
        token,
        params: {
          ...normalizedParams,
        },
      }
    );
    if (response.status === 1) {
      const startTimes = response.data.docs;
      yield put(getStartTimesByExperienceSuccess(startTimes));
    }
  } catch (error) {
    yield put(getStartTimesByExperienceFailure(error));
  }
}

function* handleCheckAvailableDates(action) {
  const { startTimeIds, startDate, endDate } = action.payload;

  try {
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/experiences/availability`,
      {
        params: {
          startTimeIds: startTimeIds,
          startDate: startDate,
          endDate: endDate,
        },
      }
    );

    if (response.status === 1) {
      const availableDates = response.data;
      yield put(checkAvailableDatesSuccess(availableDates));
    } else {
      checkAvailableDatesFailure("error");
    }
  } catch (error) {
    yield put(checkAvailableDatesFailure(error));
  }
}

export default function* availabilitySaga() {
  yield all([
    takeLatest(
      GET_START_TIMES_BY_EXPERIENCE_REQUEST,
      handleGetStartTimesByExperience
    ),
    takeLatest(CHECK_AVAILABLE_DATES_REQUEST, handleCheckAvailableDates),
  ]);
}
