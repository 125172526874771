import React from "react";
import useIsMobile from "../../../../hooks/useIsMobile";
import FloatingSummary from "../FloatingSummary/FloatingSummary";

const ShopWrapper = ({
  children,
  renderExtra,
  buttonLabel,
  isButtonEnabled,
  discounts,
  deleteText = false,
  showPickUpAndDropOffModal,
  handleShowPickUpAndDropOffModal,
  ...props
}) => {
  const isMobile = useIsMobile();

  return (
    <div style={{ position: "relative" }}>
      <div>{children}</div>
      {renderExtra()}
      <FloatingSummary
        visible={isMobile}
        itemsLength={props?.itemsLength}
        pricingData={props.pricingData}
        period={props.quoteSummary?.period || props?.period}
        selectedProducts={props?.selectedProducts}
        isPricingFetching={props?.isPricingFetching}
        expandedDefault
        onActionClick={props?.handleOnSummaryClick}
        buttonLabel={buttonLabel}
        isButtonEnabled={isButtonEnabled}
        dates={props?.dates}
        from={props?.from}
        to={props?.to}
        bookingType={props?.bookingType}
        showTotal={props?.showTotal}
        title={props?.title}
        validationMessage={props?.validationMessage}
        discounts={discounts}
        deleteText={deleteText}
        handleShowPickUpAndDropOffModal={handleShowPickUpAndDropOffModal}
      />
    </div>
  );
};

ShopWrapper.defaultProps = {
  renderExtra: () => {},
};

export default ShopWrapper;
