import useWindowSize from "./useWindowSize";
import breakpoints from "../utils/breakpoints";

const useBreakpoints = () => {
  const { width } = useWindowSize();
  const { mobile, tablet, desktop, largeDesktop, xLargeDesktop } = breakpoints;

  return {
    isBiggerThanMobile: width > mobile,
    isBiggerThanTablet: width > tablet,
    isSmallerThanDesktop: width < desktop,
    isMobile: width <= tablet,
    isTablet: width > tablet && width < desktop,
    isDesktop: width > desktop && width < largeDesktop,
    isLargeDesktop: width > largeDesktop && width < xLargeDesktop,
    isXLargeDesktop: width > xLargeDesktop,
  };
};

export default useBreakpoints;
