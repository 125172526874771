import {
  getExperienceById,
  getExperiences,
} from "../../../../Redux/Selectors/experiencesSelectors";

export const getAllExperiences = (state) => {
  const { results } = state.pages.experiences.list;
  const experiences = getExperiences(state, results);
  return experiences;
};

export const getAllExperiencesIsFetching = (state) => {
  const { isFetching } = state.pages.experiences.list;
  return isFetching;
};

export const getExperienceDetail = (state) => {
  const { data } = state.pages.experiences.detail;
  const experience = getExperienceById(state, data);
  return experience;
};

export const getExperienceDetailIsFetching = (state) => {
  const { isFetching } = state.pages.experiences.detail;
  return isFetching;
};
