import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { EnvironmentOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../../services/mixpanel";
import Skeleton from "antd/es/skeleton";
import LayoutWithDataContainer from "../../../containers/LayoutWithDataContainer";
import BannerImage from "../detail/components/BannerImage/BannerImage";
import ExperienceSummary from "../detail/components/ExperienceSummary/ExperienceSummary";
import ExperienceDetailContainer from "../detail/containers/ExperienceDetailContainer";
import Sider from "../detail/components/FloatingFooter/Sider";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import Price from "../components/Price/Price";
import {
  ExperienceDetail,
  Content,
  Details,
  Container,
  Title,
  LocationName,
  MapWrapper,
  Map,
  ContentTwo,
} from "../detail/ExperienceDetailScreen.styles";
import SkeletonContainer from "../../../components/Skeleton/SkeletonContainer";
import YoutubeViewer from "../detail/components/YoutubeViewer/YoutubeViewer";
import ExperienceTabs from "../detail/components/ExperienceTabs/ExperienceTabs";
import { Helmet } from "react-helmet-async";
import { getImageUrl } from "../../../helpers/cloudinary";
import { sanitizeRichText } from "../../../helpers/supplier";
import useWebsiteDomain from "../../../hooks/useWebsiteDomain";

const ExperienceDetailScreen = ({ isIframe }) => {
  const { id } = useParams();
  const isMobile = useIsMobile();
  const { domain } = useWebsiteDomain();

  const supplierData = useSelector(getSupplierData, shallowEqual);

  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "ExperienceDetailScreen",
      supplier: supplierData.name,
      supplierId: supplierData._id,
    });
  }, []);

  const handleClickInCTA = ({
    supplierName,
    experienceTitle,
    experienceId,
  }) => {
    trackEvent(clickEvents.CLICK_REQUEST_EXPERIENCE_INFO, {
      supplier: supplierName,
      experience: experienceTitle,
      experienceId: experienceId,
    });
  };

  return (
    <LayoutWithDataContainer isIframe={isIframe}>
      <ExperienceDetailContainer
        experienceId={id}
        supplierId={supplierData?._id}
      >
        {({ experience, customPrice }) => {
          if (!experience) {
            return (
              <Container>
                <SkeletonContainer>
                  <Skeleton active={true} />
                  <Skeleton active={true} />
                </SkeletonContainer>
              </Container>
            );
          }

          const actionPanelsProps = {
            ctaData: {
              experienceTitle: experience.title,
              phoneNumber: experience.owner.phone,
            },
            onClickInCTA: () =>
              handleClickInCTA({
                experienceId: experience._id,
                experienceTitle: experience.title,
                supplierName: experience.owner.name,
              }),
          };

          return (
            <ExperienceDetail>
              <Helmet prioritizeSeoTags>
                <title>
                  {supplierData?.name} - {experience?.title} -{" "}
                  {experience?.location}
                </title>
                <link rel="canonical" href={`https://${domain}`} />
                <meta
                  name="description"
                  content={sanitizeRichText(
                    JSON.parse(experience?.description)
                  )}
                />
                <meta property="og:type" content="website" />
                <meta
                  property="og:url"
                  content={`https://${domain}/experiences/${experience?._id}`}
                />
                <meta
                  property="og:title"
                  content={`${supplierData?.name} - ${experience?.title} - ${experience?.location}`}
                />
                <meta
                  property="og:description"
                  content={sanitizeRichText(
                    JSON.parse(experience?.description)
                  )}
                />
                <meta
                  property="og:image"
                  content={getImageUrl(experience?.images[0], {
                    width: 192,
                    height: 192,
                    format: "png",
                    crop: "pad",
                  })}
                />
                <link
                  rel="icon"
                  href={getImageUrl(experience?.images[0], {
                    width: 32,
                    height: 32,
                    format: "ico",
                    crop: "pad",
                  })}
                />
                <link
                  rel="apple-touch-icon"
                  href={getImageUrl(experience?.images[0], {
                    width: 192,
                    height: 192,
                    format: "png",
                    crop: "pad",
                  })}
                />
              </Helmet>
              <Container removePadding={isMobile}>
                {!isEmpty(experience?.images) && (
                  <BannerImage
                    images={experience?.images}
                    alt={experience?.title}
                  />
                )}
                <Content>
                  <Details>
                    <div>
                      <Title level={4} ellipsis={{ rows: 2 }}>
                        {experience?.title}
                      </Title>
                      <LocationName strong>
                        <EnvironmentOutlined style={{ fontSize: 14 }} />{" "}
                        {experience.location}
                      </LocationName>
                    </div>
                    {customPrice ? (
                      <div>
                        <Price
                          amount={experience.convertedPrice.adultPrice}
                          level={5}
                          currency={experience.convertedCurrency}
                          deleteText={true}
                        />
                        <Price
                          amount={customPrice}
                          currency={experience.convertedCurrency}
                          level={4}
                          period={"ticket"}
                        />
                      </div>
                    ) : (
                      <Price
                        amount={experience.convertedPrice.adultPrice}
                        currency={experience.convertedCurrency}
                        level={4}
                        period={"ticket"}
                      />
                    )}

                    <ExperienceSummary
                      description={experience?.description}
                      accordion={experience?.accordion}
                      video={{
                        url: experience?.videoUrl,
                      }}
                      details={{
                        age: experience.age,
                        capacity: experience.amountPeople.max,
                        difficulty: experience.difficulty,
                        duration: experience.duration,
                        languages: experience.languages,
                        activity: experience.activity,
                      }}
                    />
                    {experience?.videoUrl && (
                      <YoutubeViewer url={experience?.videoUrl} />
                    )}
                  </Details>
                  <Sider
                    experienceId={id}
                    supplierId={supplierData._id}
                    durationExperience={experience.duration}
                    {...actionPanelsProps}
                  />
                </Content>
                <ContentTwo addPadding={isMobile}>
                  <ExperienceTabs
                    experienceId={id}
                    itinerary={experience.itinerary}
                  />
                  <div style={{ paddingTop: 40 }}>
                    <MapWrapper>
                      <Map
                        coordinates={experience?.geolocation?.coordinates}
                        zoom={4}
                        radius={1000}
                      />
                    </MapWrapper>
                  </div>
                </ContentTwo>
              </Container>
            </ExperienceDetail>
          );
        }}
      </ExperienceDetailContainer>
    </LayoutWithDataContainer>
  );
};

export default ExperienceDetailScreen;
