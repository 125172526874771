import {
  MODIFY_VALUE_SEARCH,
  SET_SEARCH_PRESETS,
  SEARCH_BY_NAME,
  RESET_PICKUP_AND_DROPOF_TIME,
  RESET_SELECTED_DATES,
} from "../Actions/searchActions";

const INITIAL_STATE = {
  city: {
    name: null,
    value: null,
  },
  activities: null,
  passengers: {
    adults: 1,
    children: 0,
  },
  dates: {
    from: new Date(),
    to: new Date(),
  },
  searchByName: null,
};

const searchReducer = (state = INITIAL_STATE, action) => {
  const {
    payload: {
      key,
      value,
      city,
      activities,
      dates,
      passengers,
      searchByName,
      time,
    } = {},
  } = action;
  switch (action.type) {
    case MODIFY_VALUE_SEARCH: {
      return {
        ...state,
        [key]: value,
      };
    }
    case RESET_PICKUP_AND_DROPOF_TIME: {
      if (time && state.hasOwnProperty(time)) {
        const { [time]: _, ...newState } = state;
        return newState;
      }
      return state;
    }
    case SET_SEARCH_PRESETS: {
      return {
        city,
        activities,
        passengers,
        dates: {
          from: new Date(dates.from),
          to: new Date(dates.to),
        },
      };
    }
    case SEARCH_BY_NAME: {
      return {
        ...state,
        searchByName,
      };
    }
    case RESET_SELECTED_DATES: {
      return {
        ...state,
        dates: {
          ...INITIAL_STATE.dates,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default searchReducer;
