import { schema } from "normalizr";
import { supplierSchema } from "./supplierSchema";

export const userSchema = new schema.Entity(
  "users",
  { supplierData: supplierSchema },
  {
    idAttribute: "_id",
  }
);

export const usersSchema = [userSchema];
