import styled from "styled-components";
import InputComponent from "antd/es/input";
import Button from "../../../../components/Button/Button";

export const Wrapper = styled.div`
  width: 100%;
`;

export const GroupedInput = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.borders.gray};
  border-radius: 100px;
`;

export const Input = styled(InputComponent)`
  width: 100%;

  input {
    font-weight: bold;
    padding-left: 2px !important;
  }
`;

export const RemoveButton = styled(Button)`
  padding: 0 !important;
  line-height: 100%;
  vertical-align: middle;
  border: none;
`;

export const ErrorMessage = styled.p`
  margin-top: 5px;
  margin-left: 20px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.red};
`;

export const SuccessMessage = styled.p`
  margin-top: 5px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.green};
  text-align: right;
  font-weight: bold;
`;
