import styled from "styled-components";

export const AssignmentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.borders.gray}`};
`;
