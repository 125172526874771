import React from "react";
import { Typography } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyleText = styled.span`
  color: #4ab2bb;
`;
const { Title } = Typography;
const CustomBookingId = ({ customId }) => {
  const { t } = useTranslation("shop");
  return (
    <Title level={5} style={{ marginTop: "20px" }}>
      {t("transaction")} {<StyleText>#{customId}</StyleText>}
    </Title>
  );
};

export default CustomBookingId;
