import { denormalize } from "normalizr";
import { isEmpty } from "lodash";
import { bundleSchema, bundlesSchema } from "../Schemas/bundlesSchema";

export const getBundleById = (state, id) => {
  if (id) {
    const denormalizeData = denormalize(id, bundleSchema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};

export const getBundles = (state, arrayOfIds) => {
  if (arrayOfIds) {
    const denormalizeData = denormalize(
      arrayOfIds,
      bundlesSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return [];
  }
  return [];
};
