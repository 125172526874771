import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import Paragraph from "antd/es/typography/Paragraph";
import { cardStatus } from "../utils";

const AlternativePaymentMethodScreen = ({ onFinish }) => {
  const { t } = useTranslation();
  useEffect(() => {
    onFinish({
      type: cardStatus.PENDING,
      content: (
        <Trans
          t={t}
          i18nKey="alternativePaymentSuccess"
          components={{
            paragraph: <Paragraph />,
            bold: <b />,
          }}
        />
      ),
    });
  }, []);

  return <></>;
};

AlternativePaymentMethodScreen.propTypes = {
  onFinish: PropTypes.func,
};

export default AlternativePaymentMethodScreen;
