import React from "react";
import { useSelector } from "react-redux";
import { isProductsFetching } from "../../redux/QuoteSelectors";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import { CategoryButton, Section } from "./CategoriesList.styles";
import { findBundleById } from "../../tools/helpers";

const SnowCategoriesList = ({
  onClick,
  categories,
  bundlesWithOutCategory = [],
}) => {
  const { t } = useTranslation();
  const isFetching = useSelector(isProductsFetching);

  if (isFetching) {
    return <Skeleton active title paragraph={{ rows: 10 }} />;
  }
  return (
    <div>
      {Object.keys(categories).map((sectionKey) => (
        <React.Fragment key={sectionKey}>
          <Section>{t(`shop:${sectionKey}`)}</Section>
          {categories[sectionKey].map((category, index) => (
            <CategoryButton
              key={`${category.category}-${index}`}
              type="link"
              size="regular"
              onClick={() =>
                onClick(
                  category.category,
                  findBundleById(category?._id, bundlesWithOutCategory)
                )
              }
            >
              {category.label}
            </CategoryButton>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SnowCategoriesList;
