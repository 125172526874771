import { bundlesSchema } from "../../../../Redux/Schemas/bundlesSchema";
import { productsSchema } from "../../../../Redux/Schemas/productSchema";
import { getEntitiesByIds } from "../../../../Redux/Selectors/entitiesSelectors";

export const getHighlightProducts = (state) => {
  const { products } = state.pages.homePage.highlightProducts;

  const productsArray = getEntitiesByIds(state, productsSchema, products);
  const bundlesArray = getEntitiesByIds(state, bundlesSchema, products);

  return productsArray.concat(bundlesArray).filter(Boolean);
};

export const getHighlightCampingSites = (state) => {
  const products = getHighlightProducts(state);
  return products.filter((product) => product.activity[0] === "campingSites");
};

export const getHighlightProductsState = (state) => {
  const { loading, success, error } = state.pages.homePage.highlightProducts;
  return {
    loading,
    success,
    error,
  };
};
