import { SET_DATE_OF_BIRTH } from "./constants";

const DEFAULT_VALUES = {
    day: null,
    month: null,
    year: null,
};

const dateOfBirthReducer = (state, action) => {
    switch (action.type) {
        case SET_DATE_OF_BIRTH:
            return { ...state, [action.payload.key]: action.payload.value };

        default:
            return state;
    }
};

export { DEFAULT_VALUES, dateOfBirthReducer };