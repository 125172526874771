import React from "react";
import {
  Container,
  Description,
  StyledParagraph,
  StyledTitle,
} from "./ReviewCard.styles";
import Avatar from "antd/es/avatar/avatar";
import Rate from "antd/es/rate";
import { Col, Row } from "antd/es/grid";

const ReviewCard = ({ author, avatar, time, description, rating }) => {
  return (
    <Container>
      <Row gutter={[10, 10]}>
        <Col>
          <Avatar size="large" src={avatar} />
        </Col>

        <Col>
          <StyledTitle strong ellipsis>
            {author}
          </StyledTitle>
          <StyledParagraph type="secondary">{time}</StyledParagraph>
        </Col>
      </Row>
      <Row align="middle" gutter={[0, 0]}>
        <Avatar
          style={{ marginRight: 10 }}
          size="small"
          src="https://res.cloudinary.com/dja5ahopr/image/upload/v1705274559/Websites/dybjfajbi9ytcoh8kahy.png"
        />
        <Rate value={rating} allowHalf disabled />
      </Row>

      <Description ellipsis={{ rows: 6, expandable: false }}>
        {description}
      </Description>
    </Container>
  );
};

export default ReviewCard;
