import RichTextRender from "../../../../../components/RichTextRender/RichTextRender";
import FaqContent from "../../../../HomePage/components/FaqContent/FaqContent";
import Itinerary from "./Itinerary/Itinerary";
import { isEmpty } from "lodash";

//TO DO: En este switch, se van a ir incorporando mas componentes, dependiendo de cada caso
export const generateChildren = (item) => {
  switch (item.children) {
    case "itinerary":
      return <Itinerary infoExperience={item} />;
    case "faqs":
      return (
        <FaqContent
          faqs={item.metadata.map(({ title, answer }, key) => ({
            key,
            title,
            content: <RichTextRender content={answer} />,
          }))}
        />
      );
    case "requiredEquipment":
      return <RichTextRender content={item.metadata} />;
    case "considerations":
      return <RichTextRender content={item.metadata} />;
    case "food":
      return <RichTextRender content={item.metadata} />;
    case "difficulty":
      return <RichTextRender content={item.metadata} />;
    case "campsite":
      return <RichTextRender content={item.metadata} />;
    case "servicesIncluded":
      return <RichTextRender content={item.metadata} />;
    default:
      return null;
  }
};

export const generateTab = (tab = {}, key) => {
  if (isEmpty(tab)) return {};
  const [tabName, data] = Object.entries(tab)[0];
  return {
    label: `tabsExperience.${tabName}`,
    key,
    children: tabName,
    metadata: data,
  };
};
