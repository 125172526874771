import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const firebaseDevConfig = {
  apiKey: "AIzaSyC1p5HTyoPeb1IjMSXI55SBr_3t7f2XrII",
  authDomain: "outtrip-dev.firebaseapp.com",
  databaseURL: "https://outtrip-dev.firebaseio.com",
  projectId: "outtrip-dev",
  storageBucket: "outtrip-dev.appspot.com",
  messagingSenderId: "640789236071",
  appId: "1:640789236071:web:02d41042df27f05c6fc7c2",
  measurementId: "G-H4DL0JV34X",
};

const firebaseProdConfig = {
  apiKey: "AIzaSyAm_TkGqYhAEzFKpI_VBuJBqyWuYP-LF8w",
  authDomain: "outtrip-ce5d5.firebaseapp.com",
  databaseURL: "https://outtrip-ce5d5.firebaseio.com",
  projectId: "outtrip-ce5d5",
  storageBucket: "outtrip-ce5d5.appspot.com",
  messagingSenderId: "1039709596867",
  appId: "1:1039709596867:web:bbb1ade55aa836a03d0676",
  measurementId: "G-ES0B3J6GBL",
};

const handleEnv = () => {
  switch (process.env.REACT_APP_HOST_TYPE) {
    case "staging":
      return firebaseDevConfig;
    case "production":
      return firebaseProdConfig;
    case "dev":
      return firebaseDevConfig;
    default:
      return firebaseDevConfig;
  }
};

const app = initializeApp(handleEnv());
// firebase.analytics();

// export const viewItem = firebase.analytics.EventName.VIEW_ITEM_LIST;
// export const openItem = firebase.analytics.EventName.VIEW_ITEM;

const providerGoogle = new GoogleAuthProvider();
providerGoogle.addScope("https://www.googleapis.com/auth/contacts.readonly");
export const provider = providerGoogle;

export const auth = getAuth(app);

const signUpApp = initializeApp(handleEnv(), "signUpApp");
export const signUpAuth = getAuth(signUpApp);
