import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
`;

export const SkeletonContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 60px;
  max-width: 600px;
  width: 100%;
`;

export const NoProductsContainer = styled.div`
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`} !important;
  border-radius: 10px;
  padding: 15px;
  margin-top: 30px;
`;
