import styled from "styled-components";
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";

export const Container = styled.div`
  padding-bottom: 10px;
`;

export const Item = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
`;

export const StyledText = styled(Text)`
  font-size: 14px;
`;

export const StyledTitle = styled(Title)`
  font-size: 18px !important;
  margin: 0px;
  padding: 0px;
`;
