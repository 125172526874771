import { Button, Image, List, Modal } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import Text from "antd/es/typography/Text";
import { useTranslation } from "react-i18next";
import { getProductModalData } from "../../redux/selectors/ProductModalSelectors";
import PromoItem from "../PromoItem/PromoItem";
import Disclaimer from "../../../../components/Disclaimer/Disclaimer";
import RichTextRender from "../../../../components/RichTextRender/RichTextRender";
import styled from "styled-components";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";

const ImageContainer = styled.div`
  text-align: center;
`;

const ProductModal = ({ visible, onClose }) => {
  const { t } = useTranslation(["common", "shop"]);
  const supplierData = useSelector(getSupplierData);
  const { product, optionType } = useSelector(getProductModalData);
  if (!product) {
    return null;
  }

  const renderPromos = () => {
    return (
      <List
        header={<Text strong>{t("shop:promotions.listHeader")}</Text>}
        footer={
          <Text type="secondary" style={{ fontSize: 12 }}>
            {t("shop:promotions.listFooter")}
          </Text>
        }
        dataSource={product.promo}
        renderItem={(item) => (
          <List.Item>
            <PromoItem
              key={product._id}
              amount={item.amount}
              type={item.type}
              periodAmount={item.periodQuantity}
              operation={item.operation}
              periodType={product.rentalPeriodType}
              currency={product?.currency}
              price={product.price}
            />
          </List.Item>
        )}
      />
    );
  };

  const renderProduct = () => {
    return (
      <ImageContainer>
        <Image
          src={product.images[0]}
          height="250px"
          alt={`Alquilar ${product.title} - ${supplierData.name}`}
        />
        <Disclaimer text={t("shop:disclaimerImageProduct")} bordered={false} />
        <RichTextRender content={product.description} />
      </ImageContainer>
    );
  };

  const renderModalByType = () => {
    switch (optionType) {
      case "promos":
        return renderPromos();
      case "details":
        return renderProduct();
      default:
        return null;
    }
  };

  return (
    <Modal
      title={product?.title}
      open={visible}
      onCancel={onClose}
      onOk={onClose}
      centered
      footer={[
        <Button key="back" type="primary" onClick={onClose}>
          {t("back")}
        </Button>,
      ]}
    >
      {renderModalByType()}
    </Modal>
  );
};

export default ProductModal;
