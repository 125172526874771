import {
  GET_PRICING_REQUEST,
  GET_PRICING_SUCCESS,
} from "../actions/shopActions";

const INITIAL_STATE = {
  isFetching: false,
  pricing: null,
  error: false,
};

const pricingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRICING_REQUEST:
      return {
        ...state,
        isFetching: true,
        pricing: null,
      };
    case GET_PRICING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        pricing: action.payload.results,
      };
    default:
      return state;
  }
};

export default pricingReducer;
