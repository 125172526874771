import React from "react";
import baseTheme from "../../config/baseTheme";
import styled from "styled-components";
import Text from "antd/es/typography/Text";

const ItemContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${baseTheme.borders.gray};
  display: flex;
  gap: 10px;
`;

const StyledLink = styled.a`
  color: ${baseTheme.text.gray};
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`;

const Item = ({ label, url, icon }) => {
  return (
    <StyledLink href={`https://${url}`}>
      <ItemContainer>
        {icon && icon()}
        <Text>{label}</Text>
      </ItemContainer>
    </StyledLink>
  );
};

export default Item;
