import React from "react";
import { useSelector } from "react-redux";
import Text from "antd/es/typography/Text";
import { getProductById } from "../../../../Redux/Selectors/productSelectors";

const ProductTitle = ({ productId }) => {
  const product = useSelector((state) => getProductById(state, productId));
  return <Text style={{ fontSize: 12 }}>{product?.title}</Text>;
};

export default ProductTitle;
