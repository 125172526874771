import {
  GET_START_TIMES_BY_EXPERIENCE_REQUEST,
  GET_START_TIMES_BY_EXPERIENCE_SUCCESS,
  GET_START_TIMES_BY_EXPERIENCE_FAILURE,
  CHECK_AVAILABLE_DATES_REQUEST,
  CHECK_AVAILABLE_DATES_SUCCESS,
  CHECK_AVAILABLE_DATES_FAILURE,
  SET_AVAILABLE_DATES,
  RESET_AVAILABLE_DATA,
} from "../actions/availabilityActions";

const INITIAL_STATE = {
  startTimes: [],
  availableDates: {},
  isFetching: false,
  error: false,
};

const availabilityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_START_TIMES_BY_EXPERIENCE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case GET_START_TIMES_BY_EXPERIENCE_SUCCESS:
      return {
        ...state,
        startTimes: action.payload.results,
        isFetching: false,
        error: false,
      };
    case GET_START_TIMES_BY_EXPERIENCE_FAILURE:
      return {
        ...state,
        startTimes: [],
        isFetching: false,
        error: true,
      };
    case CHECK_AVAILABLE_DATES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case CHECK_AVAILABLE_DATES_SUCCESS:
      return {
        ...state,
        availableDates: action.payload.results,
        isFetching: false,
        error: false,
      };
    case CHECK_AVAILABLE_DATES_FAILURE:
      return {
        ...state,
        availableDates: {},
        isFetching: false,
        error: true,
      };
    case SET_AVAILABLE_DATES:
      return {
        ...state,
        availableDates: action.payload.dates,
      };
    case RESET_AVAILABLE_DATA:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default availabilityReducer;
