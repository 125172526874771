import React, { useRef } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { HorizontalScroll } from "../../../../components/HorizontalScroll/HorizontalScroll";

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding-left: 10px;
`;

const Container = styled.div`
  position: relative;
  padding-bottom: 10px;
  padding-top: 20px;
`;

const Scroller = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-right: 10px;
  gap: 10px;
  -ms-overflow-style: none; /* IE */
  overflow: -moz-scrollbars-none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome and Safari */
  }
`;

const StyledButton = styled(Button)`
  -webkit-box-shadow: 1px 2px 5px rgb(0 0 0 / 0.1);
  -moz-box-shadow: 1px 2px 5px rgb(0 0 0 / 0.1);
  box-shadow: 1px 2px 5px rgb(0 0 0 / 0.1);
  background-color: ${({ $isActive, theme }) => {
    return $isActive ? theme.colors.primary : "white";
  }};
  color: ${({ $isActive, theme }) => {
    return $isActive ? "white" : theme.text.gray;
  }};
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
`;

const QuoteBuilderCategory = ({ categories, onClick, activedCategory }) => {
  const { t } = useTranslation("common");
  const scrollerRef = useRef();

  return (
    <Container>
      <ButtonsContainer>
        <HorizontalScroll
          config={{
            content: categories,
            element: scrollerRef?.current,
            minLength: 9,
            scrollPerView: 4,
          }}
        >
          <Scroller ref={scrollerRef}>
            <StyledButton
              shape="round"
              $isActive={activedCategory === "all"}
              onClick={() => onClick("all")}
              type="primary"
            >
              {t("categoriesList.all")}
            </StyledButton>
            {categories.map((category) => (
              <StyledButton
                key={category}
                shape="round"
                $isActive={activedCategory === category}
                onClick={() => onClick(category)}
                type="primary"
              >
                {t(`categoriesList.${category}`)}
              </StyledButton>
            ))}
          </Scroller>
        </HorizontalScroll>
      </ButtonsContainer>
    </Container>
  );
};

export default QuoteBuilderCategory;
