export const GET_START_TIMES_BY_EXPERIENCE_REQUEST =
  "GET_START_TIMES_BY_EXPERIENCE_REQUEST";
export const GET_START_TIMES_BY_EXPERIENCE_SUCCESS =
  "GET_START_TIMES_BY_EXPERIENCE_SUCCESS";
export const GET_START_TIMES_BY_EXPERIENCE_FAILURE =
  "GET_START_TIMES_BY_EXPERIENCE_FAILURE";

export const CHECK_AVAILABLE_DATES_REQUEST = "CHECK_AVAILABLE_DATES_REQUEST";
export const CHECK_AVAILABLE_DATES_SUCCESS = "CHECK_AVAILABLE_DATES_SUCCESS";
export const CHECK_AVAILABLE_DATES_FAILURE = "CHECK_AVAILABLE_DATES_FAILURE";
export const SET_AVAILABLE_DATES = "SET_AVAILABLE_DATES";
export const RESET_AVAILABLE_DATA = "RESET_AVAILABLE_DATA";

export const getStartTimesByExperienceRequest = (experienceId, params) => {
  return {
    type: GET_START_TIMES_BY_EXPERIENCE_REQUEST,
    payload: {
      experienceId,
      params,
    },
  };
};

export const getStartTimesByExperienceSuccess = (results) => {
  return {
    type: GET_START_TIMES_BY_EXPERIENCE_SUCCESS,
    payload: {
      results,
    },
  };
};

export const getStartTimesByExperienceFailure = (error) => {
  return {
    type: GET_START_TIMES_BY_EXPERIENCE_FAILURE,
    payload: {
      error,
    },
  };
};

export const checkAvailableDatesRequest = (
  startTimeIds,
  startDate,
  endDate
) => {
  return {
    type: CHECK_AVAILABLE_DATES_REQUEST,
    payload: {
      startTimeIds,
      startDate,
      endDate,
    },
  };
};

export const checkAvailableDatesSuccess = (results) => {
  return {
    type: CHECK_AVAILABLE_DATES_SUCCESS,
    payload: {
      results,
    },
  };
};

export const checkAvailableDatesFailure = (error) => {
  return {
    type: CHECK_AVAILABLE_DATES_FAILURE,
    payload: {
      error,
    },
  };
};

export const setAvailableDates = (dates) => {
  return {
    type: SET_AVAILABLE_DATES,
    payload: {
      dates,
    },
  };
};
export const resetAvailableData = () => {
  return {
    type: RESET_AVAILABLE_DATA,
  };
};
