import React from "react";
import { isRental, RENTALS } from "../../helpers/rentals";
import { regionConstants } from "../../helpers/tools";
import useWebsiteRegion from "../../hooks/useWebsiteCountry";
import { ButtonCustom, ModalCustom } from "./SelectRegion.styles";

export const SelectRegion = ({ idSupplier }) => {
  const { region, setRegion } = useWebsiteRegion();

  return (
    <>
      <ModalCustom
        width={600}
        closable
        centered
        open={!region && isRental(idSupplier, RENTALS.spAventuras)}
        footer={null}
        title={"Where are you visiting from?"}
        bodyStyle={{
          display: "flex",
          gap: 30,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        <ButtonCustom onClick={() => setRegion(regionConstants.ARGENTINA)}>
          Soy Argentino
        </ButtonCustom>
        <ButtonCustom onClick={() => setRegion(regionConstants.FOREIGN)}>
          Rest of the world
        </ButtonCustom>
      </ModalCustom>
    </>
  );
};

export default SelectRegion;
