import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getTransactionByIdRequest } from "../features/TransactionPage/TransactionActions";
import {
  getTransactionDetails,
  getTransactionIsFetching,
} from "../features/TransactionPage/TransactionSelectors";
import useUser from "./useUser";

/**
 * @param {Object} params
 * @param {string} params.transactionId
 * @param {string} [params.customerId=null]
 */
const useTransaction = (params) => {
  const { transactionId, customerId = null } = params;
  const loading = useSelector(getTransactionIsFetching, shallowEqual);
  const transaction = useSelector(
    (state) => getTransactionDetails(state, transactionId),
    shallowEqual
  );
  const dispatch = useDispatch();
  const { loggedIn } = useUser(customerId);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getTransactionByIdRequest(transactionId));
    }
  }, [transactionId, loggedIn]);

  return {
    loading,
    transaction,
  };
};

export default useTransaction;
