import mixpanel from "mixpanel-browser";

const config = {
  production: "745b097b15da04bfd3b8e615c2ab505a",
  dev: "f9358428699f3fb1602a139cd5aebee4",
};

const init = () => {
  const token =
    process.env.REACT_APP_HOST_TYPE === "dev" ? config.dev : config.production;
  mixpanel.init(token, { api_host: "https://api-eu.mixpanel.com" }, "");
};

export const trackEvent = (key, params) => {
  mixpanel.track(key, params);
};

export const identifyMixpanelUser = (userId) => {
  mixpanel.identify(userId);
};

export const assignMixpanelAlias = (userId) => {
  mixpanel.alias(userId);
};

export const registerSuperProperties = (properties) => {
  mixpanel.register(properties);
};

export const clickEvents = Object.freeze({
  OPEN_PAGE: "Open page",
  SELECT_CATEGORY: "Select category",
  NAVIGATE_ADD_MEMBERS: "Navigate add members",
  REMOVE_PRODUCT_FROM_BASKET: "Remove product from basket",
  ADD_PRODUCT_TO_BASKET: "Add product to basket",
  ADD_BUNDLE_TO_BASKET: "Add bundle to basket",
  DOWNLOAD_QUOTE: "Download quote",
  REQUEST_QUOTE: "Request quote",
  PICK_INDIVIDUAL_DATES: "Pick individual dates",
  REQUEST_BOOKING: "Request booking",
  SELECT_PAYMENT_METHOD: "Select payment method",
  CLICK_REQUEST_EXPERIENCE_INFO: "Click on request experience",
  CLICK_WHATSAPP: "Click on whatsapp",
  SEND_MESSAGE: "Send message",
});

export const systemEvents = Object.freeze({
  ERROR: "error",
});

export default init;
