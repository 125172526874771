export const GET_SPACES_REQUEST = "GET_SPACES_REQUEST";
export const GET_SPACES_SUCCESS = "GET_SPACES_SUCCESS";
export const GET_SPACES_FAILURE = "GET_SPACES_FAILURE";

export const getSpacesRequest = (params) => {
  return {
    type: GET_SPACES_REQUEST,
    payload: {
      params,
    },
  };
};

export const getSpacesSuccess = (results) => {
  return {
    type: GET_SPACES_SUCCESS,
    payload: {
      results,
    },
  };
};
export const getSpacesFailure = (error) => {
  return {
    type: GET_SPACES_FAILURE,
    payload: {
      error,
    },
  };
};

export const GET_SPACE_DETAIL_REQUEST = "GET_SPACE_DETAIL_REQUEST";
export const GET_SPACE_DETAIL_SUCCESS = "GET_SPACE_DETAIL_SUCCESS";
export const GET_SPACE_DETAIL_FAILURE = "GET_SPACE_DETAIL_FAILURE";

export const getSpaceDetailRequest = (spaceId) => {
  return {
    type: GET_SPACE_DETAIL_REQUEST,
    payload: {
      spaceId,
    },
  };
};

export const getSpaceDetailSuccess = (spaceId) => {
  return {
    type: GET_SPACE_DETAIL_SUCCESS,
    payload: {
      spaceId,
    },
  };
};
export const getSpaceDetailFailure = (error) => {
  return {
    type: GET_SPACE_DETAIL_FAILURE,
    payload: {
      error,
    },
  };
};

export const GET_LOCKED_DATES_REQUEST = "GET_LOCKED_DATES_REQUEST";
export const GET_LOCKED_DATES_SUCCESS = "GET_LOCKED_DATES_SUCCESS";
export const GET_LOCKED_DATES_FAILURE = "GET_LOCKED_DATES_FAILURE";

export const getLockedDatesRequest = (variantId, from, to, period) => {
  return {
    type: GET_LOCKED_DATES_REQUEST,
    payload: {
      variantId,
      from,
      to,
      period,
    },
  };
};

export const getLockedDatesSuccess = (lockedDates) => {
  return {
    type: GET_LOCKED_DATES_SUCCESS,
    payload: {
      lockedDates,
    },
  };
};
