import styled from "styled-components";

export const ContainerDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

export const WrapperDate = styled.div`
  width: ${({ isMobile }) => (isMobile ? "100%" : "65%")};
`;
