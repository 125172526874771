import { map } from "lodash";
import { takeLatest, all, put, call, select } from "redux-saga/effects";
import environment from "../../../../config/environment";
import { normalizeData } from "../../../../Redux/Actions/normalizrActions";
import { productsSchema } from "../../../../Redux/Schemas/productSchema";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import { get } from "../../../../services/httpService";
import {
  highlightProductsFailure,
  highlightProductsSuccess,
  HIGHLIGHT_PRODUCTS_REQUEST,
} from "../actions/highlightProductsActions";
import { bundlesSchema } from "../../../../Redux/Schemas/bundlesSchema";

function* handleHighlightProductsRequest(action) {
  try {
    const { productIds } = action.payload;
    const supplier = yield select(getSupplierData);
    const [productsResponse, bundlesResponse] = yield all([
      call(get, `${environment.api.apiBaseUrl}/api/v2/products`, {
        params: {
          filters: {
            _id: productIds,
            owner: supplier._id,
          },
          limit: 4,
        },
      }),
      call(get, `${environment.api.apiBaseUrl}/api/bundles`, {
        params: {
          filters: {
            _id: productIds,
            owner: supplier._id,
          },
          limit: 4,
        },
      }),
    ]);
    const products = productsResponse.data.docs;
    const bundles = bundlesResponse.data.docs;
    const resultProductIds = map(products.concat(bundles), "_id");
    yield put(normalizeData(products, productsSchema));
    yield put(normalizeData(bundles, bundlesSchema));
    yield put(highlightProductsSuccess(resultProductIds));
  } catch (err) {
    yield put(highlightProductsFailure(err));
    console.log(err);
  }
}

export default function* highlightProductsSaga() {
  yield all([
    takeLatest(HIGHLIGHT_PRODUCTS_REQUEST, handleHighlightProductsRequest),
  ]);
}
