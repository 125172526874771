import { Col, Row } from "antd";
import Container from "../../../../components/Container/Container";
import { ReactComponent as MapMarker } from "../../../../components/Icons/mapMarker.svg";
import { StyledMapImage } from "../HomePageScreen.styles";
import { Button } from "../../../../components/Button/Button.styles";

const MapCroquis = ({ src }) => {
  return (
    <Container>
      <Row justify="center" style={{ textAlign: "center" }}>
        <Col xs={24} xl={12}>
          <a
            href="https://res.cloudinary.com/dja5ahopr/image/upload/v1703202941/Websites/ybdabgpbrp5tqcx9f8pr.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <StyledMapImage alt="Mapa cómo llegar" src={src} />
            <Button type="primary" icon={<MapMarker className="anticon" />}>
              Descargar
            </Button>
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default MapCroquis;
