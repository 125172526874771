import React from "react";
import { useSelector } from "react-redux";
import Text from "antd/es/typography/Text";
import {
  getInfoValidationByPassenger,
  getPassengerDataById,
} from "../../redux/QuoteSelectors";
import BootstrapIcon from "../../../../components/BootstrapIcon/BoostrapIcon";
import { Tooltip } from "antd";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";

const TabLabel = ({ id }) => {
  const data = useSelector((state) => getPassengerDataById(state, id));
  const supplierData = useSelector(getSupplierData);
  const isValid = useSelector((state) =>
    getInfoValidationByPassenger(state, id, supplierData._id)
  );
  return (
    <div style={{ maxWidth: 150 }}>
      <Tooltip title={!isValid && "Falta información"}>
        <BootstrapIcon
          iconName={isValid ? "person" : "exclamation-triangle-fill"}
        />{" "}
        <Text style={{ color: "inherit" }} ellipsis>
          {data?.name}
        </Text>
      </Tooltip>
    </div>
  );
};

export default TabLabel;
