import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getCategories,
  getProductsByCategory,
  isProductsFetching,
} from "../../redux/QuoteSelectors";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Input, Skeleton } from "antd";
import Text from "antd/es/typography/Text";
import { isEmpty } from "lodash";
import { sortCategories } from "../../tools/helpers";
import { Section, CategoryButton } from "./CategoriesList.styles";

const CategoriesList = ({ onClick }) => {
  const { t } = useTranslation();
  const categories = useSelector(getCategories);
  const isFetching = useSelector(isProductsFetching);
  const bundles = useSelector((state) => getProductsByCategory(state, "combo"));
  const [filteredCategories, setFilteredCategories] = useState([]);

  useEffect(() => {
    const categoriesFiltered = categories.filter(
      (category) => category !== "combo"
    );
    setFilteredCategories(sortCategories([...bundles, ...categoriesFiltered]));
  }, [categories]);

  const handleOnChange = (e) => {
    const { value } = e.target;
    const categoriesFiltered = categories.filter(
      (category) => category !== "combo"
    );
    if (isEmpty(value)) {
      return setFilteredCategories(
        sortCategories([...bundles, ...categoriesFiltered])
      );
    }

    const filteredCats = categoriesFiltered.filter((cat) =>
      t(`categoriesList.${cat}`)?.toLowerCase().includes(value.toLowerCase())
    );

    const filteredBundles = bundles.filter((bundle) =>
      bundle.title.toLowerCase().includes(value.toLowerCase())
    );

    const combinedResults = [...filteredCats, ...filteredBundles];

    return setFilteredCategories(combinedResults);
  };

  const renderEmpty = () => {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          padding: 20,
          gap: 10,
        }}
      >
        <SearchOutlined /> <Text>{t("notResults")}</Text>
      </div>
    );
  };

  if (isFetching) {
    return <Skeleton active title paragraph={{ rows: 10 }} />;
  }

  return (
    <div>
      <Input.Search onChange={handleOnChange} style={{ marginBottom: 20 }} />
      <Section>{t("teams")}</Section>
      {filteredCategories.length === 0 && renderEmpty()}
      {filteredCategories.map((category) => {
        return (
          <>
            <CategoryButton
              type="link"
              size="regular"
              onClick={() =>
                onClick(
                  category?._id ? "combo" : category,
                  category?._id ? category : null
                )
              }
            >
              {category?.title || t(`categoriesList.${category}`)}
            </CategoryButton>
          </>
        );
      })}
    </div>
  );
};

export default CategoriesList;
