import {
  GET_PAYMENT_BY_ID_FAILURE,
  GET_PAYMENT_BY_ID_REQUEST,
  GET_PAYMENT_BY_ID_SUCCESS,
  GET_PAYMENT_METHODS_FROM_SUPPLIER_SUCCESS,
} from "./paymentActions";

const INITIAL_STATE = {
  payment: {
    success: false,
    fetching: false,
    error: false,
  },
  phone: {
    success: false,
    fetching: false,
    error: false,
  },
  invoice: {
    fetching: false,
    error: false,
    success: false,
  },
  paymentMethods: [],
};

const PaymentScreenReducer = (state = INITIAL_STATE, action) => {
  const { type } = action;
  switch (type) {
    case GET_PAYMENT_BY_ID_REQUEST: {
      return {
        ...state,
        payment: {
          success: false,
          error: false,
          fetching: true,
        },
      };
    }
    case GET_PAYMENT_BY_ID_SUCCESS: {
      return {
        ...state,
        payment: {
          success: true,
          error: false,
          fetching: false,
        },
      };
    }
    case GET_PAYMENT_BY_ID_FAILURE: {
      return {
        ...state,
        payment: {
          success: false,
          error: true,
          fetching: false,
        },
      };
    }
    case GET_PAYMENT_METHODS_FROM_SUPPLIER_SUCCESS: {
      return {
        ...state,
        paymentMethods: action.payload.methods,
      };
    }
    default:
      return state;
  }
};

export default PaymentScreenReducer;
