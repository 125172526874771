import React from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import Text from "antd/es/typography/Text";
import {
  ContainerCard,
  BodyCard,
  DetailsCard,
  ExperienceImage,
  TopCard,
  TitleCard,
} from "./HostingCard.styles";
import { Button } from "../Button/Button.styles";
import Price from "../../features/ExperiencesPage/components/Price/Price";
import { useNavigate } from "react-router-dom";
import { sanitiseProduct } from "../../features/HomePage/helpers/helpers";

const HostingCard = ({ images, title, subtitle, currency, price, id }) => {
  const { t } = useTranslation(["common", "spaces"]);
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/spaces/${id}/${sanitiseProduct(`Camping ${title}`)}`);
  };
  return (
    <ContainerCard>
      <div>
        {!isEmpty(images) ? (
          <ExperienceImage alt="experienceImage" src={images[0]} />
        ) : (
          <ExperienceImage
            alt="defaultImage"
            src="https://camarasal.com/wp-content/uploads/2020/08/default-image-5-1.jpg"
          />
        )}
      </div>

      <BodyCard>
        <TopCard>
          <TitleCard ellipsis={{ rows: 2 }} level={5}>
            {title}
          </TitleCard>
          <Text type="secondary" style={{ fontSize: 14 }}>
            {subtitle}
          </Text>
        </TopCard>
        <DetailsCard>
          <Price amount={price} currency={currency} level={5} showTotal />
          <Button type="primary" onClick={handleOnClick}>
            {t("bookIt")}
          </Button>
        </DetailsCard>
      </BodyCard>
    </ContainerCard>
  );
};

export default HostingCard;
