import styled from "styled-components";

export const OverlayWrapper = styled.div`
  position: fixed;
  top: 74px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color:  ${({ theme }) => theme.colors.transparent}};
  backdrop-filter: blur(5px);
  `;
