import { all, call, put, takeLatest } from "redux-saga/effects";
import environment from "../../../../config/environment";
import { normalizeParams } from "../../../../helpers/tools";
import { normalizeData } from "../../../../Redux/Actions/normalizrActions";
import {
  experienceSchema,
  experiencesSchema,
} from "../../../../Redux/Schemas/experiencesSchema";
import { get } from "../../../../services/Api";
import {
  getExperiencesSuccess,
  GET_EXPERIENCES_REQUEST,
  getExperiencesFailure,
  GET_EXPERIENCE_DETAIL_REQUEST,
  getExperienceDetailSuccess,
  getExperienceDetailFailure,
} from "../actions/ExperiencesActions.js";

function* handleGetExperiences(action) {
  try {
    const { params, currency } = action.payload;
    const normalizedParams = normalizeParams({ ...params });
    const endpoint = `${environment.api.apiBaseUrl}/api/experiences`;

    const response = yield call(get, endpoint, {
      params: {
        pagination: false,
        ...normalizedParams,
        currency,
      },
    });

    if (response.status === 200) {
      yield put(normalizeData(response.data.data.docs, experiencesSchema));
      yield put(
        getExperiencesSuccess(response.data.data.docs.map((doc) => doc._id))
      );
    }
  } catch (error) {
    yield put(getExperiencesFailure(error));
  }
}

function* handleGetExperienceDetail(action) {
  try {
    const { experienceId, currency } = action.payload;
    const endpoint = `${environment.api.apiBaseUrl}/api/experiences/${experienceId}`;

    const response = yield call(get, endpoint, {
      params: {
        currency,
      },
    });

    if (response.status === 200) {
      yield put(normalizeData(response.data.data, experienceSchema));
      yield put(getExperienceDetailSuccess(response.data.data._id));
    }
  } catch (error) {
    yield put(getExperienceDetailFailure(error));
  }
}

export default function* experiencesSaga() {
  yield all([
    takeLatest(GET_EXPERIENCES_REQUEST, handleGetExperiences),
    takeLatest(GET_EXPERIENCE_DETAIL_REQUEST, handleGetExperienceDetail),
  ]);
}
