import React from "react";
import PropTypes from "prop-types";
import { Button } from "./MenuItem.styles";

const MenuItem = ({ title, onClick, ...restProps }) => {
  return (
    <Button type="text" shape="default" onClick={onClick} {...restProps}>
      {title}
    </Button>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default MenuItem;
