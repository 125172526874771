import { useDispatch, useSelector } from "react-redux";
import { getWebsiteReviewsConfig } from "../../../../Redux/Selectors/appConfigSelector";
import { useEffect } from "react";
import { getReviewRequest } from "../../redux/actions/reviewsActions";
import { getReviews } from "../../redux/selectors/reviewsSelector";

const ReviewsContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { config, provider } = useSelector(getWebsiteReviewsConfig);
  const reviews = useSelector(getReviews);

  useEffect(() => {
    dispatch(getReviewRequest(config?.id, provider));
  }, []);

  return children({
    reviews: reviews?.filter((review) => review?.rating >= 3).slice(0, 4),
  });
};

export default ReviewsContainer;
