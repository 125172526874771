import React from "react";
import { languageConfig } from "../../helpers/tools";
import useBreakpoints from "../../hooks/useBreakpoints";
import { Container } from "./ButtonLanguage.style";
import PropTypes from "prop-types";

const ButtonLanguage = ({ onClick, language }) => {
  const { isDesktop } = useBreakpoints();
  const selectedLanguage = languageConfig[language]?.name;
  const selectedLanguageFlag = languageConfig[language]?.flagIcon({
    width: 18,
  });
  return (
    <Container onClick={onClick}>
      {isDesktop && selectedLanguage}
      {selectedLanguageFlag}
    </Container>
  );
};

ButtonLanguage.propTypes = {
  language: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonLanguage;
