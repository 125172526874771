import { isEmpty } from "lodash";
import { roundNumber } from "../../helpers/numbers";
import { createUTCDate, PERIODS } from "../../helpers/tools";
import { provinces } from "../../config/provinces";

export const generateMessage = (items, currency, total, t) => {
  return t("common:whatsAppBookingMessage", {
    products: items.map((item) => {
      return `* ${item.title} x${item.quantity}\n`;
    }),
  }).replace(",", "");
};

const amountConfig = {
  percentage: (amount) => `${roundNumber(amount, 0)}%`,
  amountFixed: (amount, currency) => `${currency} ${roundNumber(amount, 2)}`,
};

export const formatAmount = (amount, type, currency) => {
  const formatter = amountConfig[type];
  if (formatter) {
    return formatter(amount, currency);
  } else {
    return null;
  }
};

export const sanitiseVariants = (variants) => {
  let sanitisedVariants = variants.map((variant) => {
    const {
      variant: { value, type, unit },
      stock,
      colors,
      _id,
    } = variant;
    return {
      value,
      type,
      unit,
      stock,
      colors,
      id: _id,
    };
  });
  return sanitisedVariants;
};

export const listTypes = Object.freeze({
  BUNDLE: "bundle",
  VARIANT: "variant",
  PRODUCT: "product",
  EXPERIENCE: "experience",
  ITEM: "item",
  SPACE: "space",
});

export const sanitizeVariantListing = (
  assignments,
  bookingType,
  experienceId,
  startTimeId = null
) => {
  const listing = {};
  const getProductItems = (product) => {
    if (product.type === listTypes.BUNDLE) {
      return Object.values(product.variants).map(({ reference, itemId }) => ({
        variantId: reference,
        item: itemId,
      }));
    } else if (product.type === listTypes.VARIANT) {
      return { variantId: product.reference, item: product.itemId };
    }
    return [];
  };

  Object.values(assignments).forEach(({ _id, type }) => {
    const products = Object.values(assignments).filter(
      (assignment) => assignment._id === _id
    );
    const items = products.flatMap(getProductItems);

    if (bookingType === listTypes.EXPERIENCE) {
      listing[_id] = {
        quantity: (listing[_id]?.quantity || 0) + 1,
        type,
        id: experienceId,
        rate: _id,
        startTime: startTimeId,
      };
    } else {
      listing[_id] = {
        quantity: (listing[_id]?.quantity || 0) + 1,
        type: type === listTypes.VARIANT ? listTypes.PRODUCT : type,
        id: _id,
        variants: items,
      };
    }
  });

  return Object.values(listing);
};

export const normalizeListingParam = (params, paramName = "filters") => {
  return params.reduce((normalizedObject, param) => {
    const { key, quantity } = normalizeParam(param);
    return {
      ...normalizedObject,
      [`${paramName}[${key}]`]: quantity,
    };
  }, {});
};

const normalizeParam = ({ type, id, quantity, rate }) => {
  if (type === listTypes.EXPERIENCE) {
    return {
      key: `${type},${id},${rate}`,
      quantity,
    };
  } else {
    return {
      key: `${type},${id}`,
      quantity,
    };
  }
};

export const removeSpaces = (string) => {
  const sanitizeString = string.replace(/\s+/g, " ").trim();
  return sanitizeString;
};

export const calculatePrevDaysForBooking = (
  dateRange,
  minPrevDaysForBooking = 0,
  period = PERIODS.day
) => {
  if (isEmpty(minPrevDaysForBooking)) {
    if (createUTCDate().isSame(createUTCDate(dateRange), "day")) {
      return calculateDates(minPrevDaysForBooking, period, dateRange);
    }
  }
  return createUTCDate(dateRange);
};

const calculateDates = (
  minPrevDaysForBooking = 0,
  period = PERIODS.day,
  dateRange
) => {
  dateRange = isEmpty(dateRange) ? dateRange : createUTCDate();

  if (minPrevDaysForBooking <= 0) {
    return createUTCDate(dateRange);
  } else {
    return createUTCDate(dateRange).clone().add(minPrevDaysForBooking, period);
  }
};

export function findProvinceIdByName(country, provinceName) {
  const countryProvinces = provinces[country];
  if (!provinceName || !countryProvinces) return null;
  const province = countryProvinces.find(
    (prov) => prov.name.toLowerCase() === provinceName.toLowerCase()
  );
  return province ? province.id : null;
}

export const getStockAvailabilityTitle = (stock) => {
  switch (true) {
    case stock === 0:
      return { title: "outOfStock", color: "danger" };
    case stock === 1:
      return { title: "lastAvailable", color: "warning" };
    case stock >= 2 && stock <= 5:
      return { title: "lastAvailableCount", color: "warning" };
    case isEmpty(stock) || stock < 0 || stock > 5:
      return null;
    default:
      return null;
  }
};
