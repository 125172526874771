import {
  GET_SUPPLIER_GROUPS_FAILURE,
  GET_SUPPLIER_GROUPS_REQUEST,
  GET_SUPPLIER_GROUPS_SUCCESS,
} from "./supplierGroupsActions";

export const INITIAL_STATE = {
  results: [],
  fetching: false,
  error: false,
  sort: "desc",
  sortBy: "createdAt",
};

export const supplierGroupsReducer = (state = INITIAL_STATE, action) => {
  const { payload: { data } = {} } = action;
  switch (action.type) {
    case GET_SUPPLIER_GROUPS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case GET_SUPPLIER_GROUPS_SUCCESS:
      return {
        ...state,
        results: data,
        fetching: false,
      };
    case GET_SUPPLIER_GROUPS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};

export default supplierGroupsReducer;
