import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Badge from "antd/es/badge";
import ButtonAntd from "antd/es/button";
import styled from "styled-components";
import React, { useCallback } from "react";
import {
  summaryAddItem,
  summarySubstractItem,
} from "../../redux/actions/shopActions";
import { useDispatch, useSelector } from "react-redux";
import { clickEvents, trackEvent } from "../../../../services/mixpanel";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import { getProductById } from "../../../../Redux/Selectors/productSelectors";
import { getBundleById } from "../../../../Redux/Selectors/bundlesSelectors";
import { listTypes } from "../../utils";

const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Button = styled(ButtonAntd)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductAddCounter = ({
  quantity,
  itemId,
  disabledAdd = false,
  type,
  disableSubtract = false,
  onSubstractProduct = null,
  onAddProduct = null,
}) => {
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => getProductById(state, itemId));
  const bundleDetails = useSelector((state) => getBundleById(state, itemId));
  const supplierData = useSelector(getSupplierData);

  const getItemTitle = () => {
    if (type === listTypes.PRODUCT) return productDetails.title;
    if (type === listTypes.BUNDLE) return bundleDetails.title;
  };

  const getCategoryItem = () => {
    if (type === listTypes.PRODUCT) return productDetails.category.value;
    return null;
  };

  const substractProduct = useCallback(() => {
    trackEvent(clickEvents.REMOVE_PRODUCT_FROM_BASKET, {
      page: "Shop",
      itemId: itemId,
      itemTitle: getItemTitle(),
      category: getCategoryItem(),
      supplierId: supplierData._id,
      supplierName: supplierData.name,
      type,
    });
    if (onSubstractProduct) {
      return onSubstractProduct(itemId);
    } else {
      return dispatch(summarySubstractItem(itemId));
    }
  }, [itemId]);

  const addProduct = useCallback(() => {
    trackEvent(clickEvents.ADD_PRODUCT_TO_BASKET, {
      page: "Shop",
      itemId: itemId,
      itemTitle: getItemTitle(),
      category: getCategoryItem(),
      supplierId: supplierData._id,
      supplierName: supplierData.name,
      type,
    });
    if (onAddProduct) {
      return onAddProduct(itemId);
    } else {
      dispatch(summaryAddItem(itemId));
    }
  }, [itemId]);

  return (
    <Container>
      <Button
        size="small"
        shape="circle"
        disabled={disableSubtract || quantity === 0}
        icon={<MinusOutlined />}
        onClick={() => substractProduct(itemId)}
        data-testid="product-button-subtract"
      />
      <Badge
        count={quantity}
        showZero
        style={{
          backgroundColor: quantity > 0 ? "#52c41a" : "#f3f3f3",
          fontSize: 16,
        }}
      />
      <Button
        shape="circle"
        type="primary"
        disabled={disabledAdd}
        icon={<PlusOutlined />}
        onClick={() => addProduct(itemId)}
        data-testid="product-button-add"
      />
    </Container>
  );
};
export default React.memo(ProductAddCounter);
