import React from "react";
import styled from "styled-components";
import baseTheme from "../../config/baseTheme";
import Item from "./Item";

const Container = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  min-height: 300px;
  padding: 20px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
`;

const Header = styled.div`
  padding-bottom: 20px;
  border-bottom: 2px solid ${baseTheme.borders.gray};
`;

const ContextMenu = ({ renderHeader, items = [] }) => {
  return (
    <Container>
      <Header>{renderHeader()}</Header>
      {items.map((item) => (
        <Item label={item.label} url={item.url} icon={item.icon} />
      ))}
    </Container>
  );
};

export default ContextMenu;
