import LayoutWithDataContainer from "../../../containers/LayoutWithDataContainer";
import BannerCollapsed from "../../ShopPage/components/BannerCollapsed/BannerCollapsed";
import { Container } from "../detail/ExperienceDetailScreen.styles";
import { useParams } from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";
import { Body } from "../detail/components/AddParticipant/AddParticipants.styles";
import useIsMobile from "../../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { Col } from "antd";
import ParticipantGroupForm from "../detail/components/ParticipantGroupForm/ParticipantGroupForm";
import FloatingSummary from "../../ShopPage/components/FloatingSummary/FloatingSummary";
import { listTypes } from "../../ShopPage/utils";
import { isEmpty } from "lodash";
import ParticipantContainer from "../detail/containers/ParticipantContainer/ParticipantContainer";
import ShopWrapper from "../../ShopPage/components/ShopWrapper/ShopWrapper";
import DetailCheckoutModal from "../../ShopPage/components/DetailCheckoutModal/DetailCheckoutModal";

const ExperienceParticipantsScreen = ({ isIframe }) => {
  const { t } = useTranslation("experiences");
  const { id } = useParams();
  const isMobile = useIsMobile();

  return (
    <ParticipantContainer id={id}>
      {({
        content,
        experienceData,
        pricingData,
        isFetching,
        dates,
        handleOnContinue,
        showSummaryModal,
        setShowSummaryModal,
        isBookingFetching,
        handleOnRequestPayment,
        passengers,
        validationMessage,
        deleteText,
      }) => {
        const floatingSummaryParams = {
          from: dates.from,
          pricingData: pricingData,
          period: experienceData?.duration.unit,
          isPricingFetching: isFetching,
          visible: true,
          expandable: false,
          expandedDefault: true,
          handleOnSummaryClick: handleOnContinue,
          bookingType: listTypes.EXPERIENCE,
          showTotal: !isEmpty(pricingData),
          title: experienceData?.title,
          validationMessage: validationMessage,
        };

        return (
          <LayoutWithDataContainer isIframe={isIframe}>
            {!isIframe && (
              <BannerCollapsed bgImage={content?.headerBackgroundImage} />
            )}
            <ShopWrapper
              {...floatingSummaryParams}
              offset={{
                offsetBottom: 0,
              }}
              handleOnSummaryClick={handleOnContinue}
              deleteText={deleteText}
            >
              <Container>
                <Body $isMobile={isMobile}>
                  <Col>
                    <Paragraph strong>
                      {t("booking.completeMembersInfo")}
                    </Paragraph>
                    <ParticipantGroupForm experienceId={id} />
                  </Col>
                  {!isMobile && (
                    <Col
                      style={{
                        paddingTop: 30,
                        paddingLeft: 40,
                        marginRight: 15,
                      }}
                    >
                      <FloatingSummary
                        {...floatingSummaryParams}
                        onActionClick={handleOnContinue}
                        offset={{
                          offsetTop: 120,
                        }}
                        deleteText={deleteText}
                      />
                    </Col>
                  )}
                </Body>
              </Container>
            </ShopWrapper>
            <DetailCheckoutModal
              open={showSummaryModal}
              isLoading={isBookingFetching}
              onContinue={handleOnRequestPayment}
              onCancel={() => setShowSummaryModal(false)}
              bookingType={listTypes.EXPERIENCE}
              passengers={passengers}
              description={t("booking.detailModalDescription")}
            />
          </LayoutWithDataContainer>
        );
      }}
    </ParticipantContainer>
  );
};

export default ExperienceParticipantsScreen;
