import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const Container = styled.div`
  padding: 10px;
  max-width: 400px;
  width: 100%;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const LabelContainer = styled.div`
  width: 200px;
`;

export const StyledLabel = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

export const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Item = styled(Text)`
  font-size: 14px;
`;
