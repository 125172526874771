import { Button, Spin } from "antd";
import React from "react";
import styled from "styled-components";
import Text from "antd/es/typography/Text";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";

const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const SpinContainer = styled.div`
  width: 350px;
  height: 250px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  gap: 25px;
  padding: 10px;
`;

const LabelContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  text-align: center;
`;

const OverlaySpiner = ({
  isLoading,
  label = "pleaseMoment",
  description = null,
  isError = false,
  errorMessage = null,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "shop"]);
  if (!isLoading && !isError) return null;
  return (
    <OverlayContainer>
      {isError && (
        <SpinContainer>
          <LabelContainer>
            <Paragraph strong style={{ fontWeight: "200" }}>
              {t("common:problemMessage")}
            </Paragraph>
            <Text style={{ fontWeight: "200" }}>
              {t(`common:transacionErrors.${errorMessage}`)}
            </Text>
            <Button
              type="primary"
              shape="round"
              block
              size="large"
              onClick={() => navigate("/")}
              style={{ marginTop: "30px", width: "80%" }}
            >
              {t("common:continue")}
            </Button>
          </LabelContainer>
        </SpinContainer>
      )}
      {!isError && (
        <SpinContainer>
          <Spin size="large" />
          {label && (
            <LabelContainer>
              <Text strong>{t(`shop:checkout.${label}`)}</Text>
              {description && (
                <Text style={{ fontWeight: "200" }}>{description}</Text>
              )}
            </LabelContainer>
          )}
        </SpinContainer>
      )}
    </OverlayContainer>
  );
};

export default OverlaySpiner;
