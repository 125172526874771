import { schema } from "normalizr";
import { supplierSchema } from "./supplierSchema";

export const experienceSchema = new schema.Entity(
  "experiences",
  {
    owner: supplierSchema,
  },
  {
    idAttribute: "_id",
  }
);

export const experiencesSchema = [experienceSchema];
