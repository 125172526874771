import { Button } from "antd";
import React from "react";

const ListLanguage = ({ label, icon, onClick }) => {
  return (
    <div>
      <Button
        block
        style={{
          fontSize: 16,
          border: "none",
          padding: 0,
          boxShadow: "none",
          margin: 10,
          textAlign: "left",
        }}
        icon={icon}
        onClick={onClick}
      >
        {label}
      </Button>
    </div>
  );
};

export default ListLanguage;
