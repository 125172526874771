import i18next from "i18next";
import { isEmpty } from "lodash";
import moment from "moment";

/*
TODO Hacer test
*/
const getDuration = (period, value) => {
  const duration = moment.duration({ [period]: value });
  return duration._data;
};

/*
  TODO Hacer test
  */
export const renderHours = (period, value) => {
  let periodType = isEmpty(period) ? "hour" : period;
  let result = "";
  const duration = getDuration(periodType, value);
  const arrayDuration = Object.entries(duration).reverse();
  arrayDuration.forEach(([period, value]) => {
    if (value >= 1) {
      result += `${i18next.t(`periodsWithCount.${period.slice(0, -1)}`, {
        count: value,
      })} `;
    }
  });
  return result;
};
