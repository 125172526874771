import React from "react";
import Select from "antd/es/select/index";
import countryCodes from "../../config/countries";
import { shallowEqual, useSelector } from "react-redux";
import { getWebsiteLanguage } from "../../Redux/Selectors/languageSelector";
import { languageConstants } from "../../helpers/tools";

const CountrySelector = ({ width = "100%", size, ...props }) => {
  const language = useSelector(getWebsiteLanguage, shallowEqual);

  return (
    <Select
      showSearch={true}
      defaultValue={language === languageConstants.esAR && "Argentina"}
      style={{ width }}
      {...props}
      size={size}
    >
      {countryCodes().map((code) => {
        const countryName =
          language === languageConstants.esAR ? code.name_es : code.name_en;
        return (
          <Select.Option key={code.code} value={countryName}>
            {countryName}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default CountrySelector;
