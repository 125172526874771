import React, { useState } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import { map } from "lodash";
import { getImageUrl } from "../helpers/cloudinary";
import {
  getSupplierData,
  getTypeVisualization,
} from "../Redux/Selectors/appConfigSelector";
import Layout from "../components/Layout/Layout";
import useGlobalUtils from "../hooks/useGlobalUtils";
import useMenuLinks from "../hooks/useMenuLinks";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { RENTALS, isRental } from "../helpers/rentals";
import useBreakpoints from "../hooks/useBreakpoints";
import { matchVisualization } from "../utils/typeVisualization";

const LayoutWithDataContainer = ({ children, isIframe, isCheckin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const displayType = useSelector(getTypeVisualization, shallowEqual);
  const { getSocialMediaLinksData } = useGlobalUtils();
  const { handleNavigate, links } = useMenuLinks();
  const [showMenu, setShowMenu] = useState(false);
  const { isBiggerThanTablet } = useBreakpoints();
  const handleClickMenu = (e, index) => {
    if (!isBiggerThanTablet) {
      setShowMenu(false);
    }
    handleNavigate(e, index);
  };

  const handleNavigation = () => {
    if (isRental(supplierData?._id, RENTALS.campingLaIndia)) {
      navigate("/spaces" + location.search);
    } else {
      navigate(matchVisualization(displayType) + location.search);
    }
  };

  return (
    <Layout
      logo={getImageUrl(supplierData?.logo, { height: 150 })}
      alternativeLogo={getImageUrl(
        supplierData?.alternativeLogo || supplierData?.logo,
        { height: 150 }
      )}
      socialLinks={getSocialMediaLinksData()}
      onClickNavbarAction={handleNavigation}
      menuItems={map(links, ({ label, style }, index) => ({
        title: t(`menu.${label}`),
        onClick: (e) => handleClickMenu(e, index),
        style,
      }))}
      showMenu={showMenu}
      setShowMenu={setShowMenu}
      navbar={!isIframe && !isCheckin}
      showWhatsappIcon={!isCheckin}
    >
      {children}
    </Layout>
  );
};

LayoutWithDataContainer.propTypes = {
  children: PropTypes.node,
};

export default LayoutWithDataContainer;
