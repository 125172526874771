import { useDispatch, useSelector } from "react-redux";
import { getSummary } from "../../../../ShopPage/redux/selectors/ShopSelectors";
import { useState, useContext } from "react";
import {
  assignVariantToPassenger,
  quoteBuildAddPassengers,
} from "../../../../ShopPage/redux/actions/shopActions";
import { findProvinceIdByName, listTypes } from "../../../../ShopPage/utils";
import { saveMainMemberInfo } from "../../../../ShopPage/redux/actions/MainMemberActions";
import { useSupplierConfig } from "../../../../../hooks/useSupplierConfig";
import { getSupplierData } from "../../../../../Redux/Selectors/appConfigSelector";
import { defaultParticipants } from "../../../list/helpers/helpers";
import { sanitizeDateOfBirth } from "../../../../../tools/dateTools";
import { DateOfBirthContext } from "../../../../../components/DateOfBirthSelector/context/DateOfBirthContext";
import { sanitizePhonePrefix } from "../../../../../helpers/tools";

const ParticipantGroupContainer = ({ children }) => {
  const dispatch = useDispatch();
  const supplierData = useSelector(getSupplierData);
  const { createCustomer, createCompany } = useSupplierConfig();
  const { tickets } = useSelector(getSummary);
  const { dateOfBirth } = useContext(DateOfBirthContext);
  const [participants, setParticipants] = useState(
    defaultParticipants(tickets) || []
  );
  const onClickParticipant = (id) => {
    setParticipants({
      ...participants,
      [id]: {
        ...participants[id],
        active: !participants[id].active,
      },
    });
  };

  const onSubmitMember = ({
    rate,
    fieldkey,
    experienceId,
    passenger,
    personalInfo,
    isMainMember = false,
  }) => {
    if (isMainMember) {
      const provinceId = findProvinceIdByName(
        personalInfo.nationality?.toLowerCase(),
        personalInfo.province
      );
      const phonePrefix =
        personalInfo?.phonePrefix &&
        sanitizePhonePrefix(personalInfo?.phonePrefix);
      const payload = {
        ...personalInfo,
        dateOfBirth: sanitizeDateOfBirth(dateOfBirth),
        province: provinceId,
        phonePrefix,
      };
      dispatch(saveMainMemberInfo(payload));
      personalInfo = { [personalInfo.id]: payload };
    }
    dispatch(
      assignVariantToPassenger({
        index: fieldkey,
        passenger,
        id: rate,
        type: listTypes.EXPERIENCE,
        fieldkey,
        experienceId,
      })
    );
    // Attach personal info to passengers
    personalInfo = {
      [passenger]: {
        ...personalInfo[passenger],
        dateOfBirth: sanitizeDateOfBirth(dateOfBirth),
      },
    };
    dispatch(quoteBuildAddPassengers(personalInfo));
    setParticipants({
      ...participants,
      [passenger]: {
        ...participants[passenger],
        complete: true,
        active: false,
      },
    });
  };

  return children({
    participants,
    onClickParticipant,
    onSubmitMember,
    createCustomer,
    createCompany,
    supplierData,
  });
};

export default ParticipantGroupContainer;
