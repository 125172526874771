import ReactToPrint from "react-to-print";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import LayoutWithDataContainer from "../../containers/LayoutWithDataContainer";
import BannerCollapsed from "../ShopPage/components/BannerCollapsed/BannerCollapsed";
import OverlaySpiner from "../../components/OverlaySpinner/OverlaySpinner";
import { Button } from "antd";
import { useParams } from "react-router";
import {
  getPaymentByIdRequest,
  transactionPaymentActionRequest,
} from "../PaymentPage/redux/paymentActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getPaymentById } from "../../Redux/Selectors/paymentSelector";
import { isEmpty } from "lodash";
import { getPaymentActionState } from "../PaymentPage/redux/paymentSelectors";
import { PrinterFilled } from "@ant-design/icons";
import { getTransactionByIdRequest } from "./TransactionActions";
import {
  getTransactionById,
  getTransactionIsFetching,
} from "./TransactionSelectors";
import Success from "./Status/Success";
import Failed from "./Status/Failed";
import { getSupplierData } from "../../Redux/Selectors/appConfigSelector";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../services/mixpanel";
import { createUTCDate } from "../../helpers/tools";
import { getPaymentDataState } from "../PaymentPage/redux/paymentSelectors";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 120px;
`;
const TransactionResultScreen = ({ isIframe }) => {
  const { t } = useTranslation("result");
  const { paymentId } = useParams();
  const dispatch = useDispatch();
  const detailsView = useRef();
  const supplierData = useSelector(getSupplierData);
  const paymentData = useSelector((state) => getPaymentById(state, paymentId));
  const transactionData = useSelector((state) =>
    getTransactionById(state, paymentData?.transaction)
  );
  const isFetchingTransaction = useSelector(
    getTransactionIsFetching,
    shallowEqual
  );
  const paymentDataState = useSelector(getPaymentDataState, shallowEqual);
  const isFetchingPaymentData = paymentDataState.fetching;
  const status = paymentData?.status?.toLowerCase();
  const paymentStatus = useSelector((state) =>
    getPaymentActionState(state, status)
  );
  useEffect(() => {
    dispatch(getPaymentByIdRequest(paymentId, supplierData._id));
  }, []);
  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "TransactionResultPage",
      supplier: supplierData.name,
      supplierId: supplierData._id,
    });
  }, []);
  useEffect(() => {
    if (!isEmpty(paymentData)) {
      dispatch(getTransactionByIdRequest(paymentData.transaction));
    }
    if (
      !isEmpty(paymentData) &&
      paymentData.provider === "webpay" &&
      paymentData.data.status === "INITIALIZED"
    ) {
      dispatch(
        transactionPaymentActionRequest({
          paymentId,
          action: "accept",
        })
      );
    }
    if (
      !isEmpty(paymentData) &&
      paymentData.provider === "webpay" &&
      paymentData.data.status === "FAILED"
    ) {
      dispatch(
        transactionPaymentActionRequest({
          paymentId,
          action: "decline",
        })
      );
    }
  }, [JSON.stringify(paymentData)]);
  if (paymentStatus?.loading && !paymentData) {
    return <OverlaySpiner isLoading={true} />;
  }
  return (
    <LayoutWithDataContainer isIframe={isIframe}>
      {!isIframe && <BannerCollapsed />}
      <Container>
        {!isFetchingTransaction && !isFetchingPaymentData ? (
          <ReactToPrint
            trigger={() => (
              <Button icon={<PrinterFilled />}>{t("print")}</Button>
            )}
            content={() => detailsView.current}
            documentTitle={transactionData?.customId}
          />
        ) : null}
        <div ref={detailsView} style={{ paddingLeft: 10, paddingRight: 10 }}>
          {paymentData?.status === "ACCEPTED" && (
            <Success
              customId={transactionData?.customId}
              buyOrder={paymentData?.data?.buy_order}
              transactionDate={createUTCDate(
                paymentData?.data?.transaction_date
              ).format("DD-MM-YYYY")}
              authorizationCode={paymentData?.data?.authorization_code}
              installmentsNumber={paymentData?.data?.installments_number}
              cardNumber={paymentData?.data?.card_detail?.card_number}
              isLoading={isFetchingTransaction || isFetchingPaymentData}
            />
          )}
          {paymentData?.status === "DECLINED" && (
            <Failed transactionId={transactionData?._id} />
          )}
        </div>
      </Container>
    </LayoutWithDataContainer>
  );
};
export default TransactionResultScreen;
