import { useParams } from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";
import { useTranslation } from "react-i18next";
import { Col } from "antd";
import { isEmpty } from "lodash";
import ParticipantContainer from "../checkout/containers/ParticipantContainer";
import useIsMobile from "../../../hooks/useIsMobile";
import LayoutWithDataContainer from "../../../containers/LayoutWithDataContainer";
import BannerCollapsed from "../../ShopPage/components/BannerCollapsed/BannerCollapsed";
import ShopWrapper from "../../ShopPage/components/ShopWrapper/ShopWrapper";
import { Container } from "../detail/styles/SpaceDetailScreen.styles";
import { Body } from "../../ExperiencesPage/detail/components/AddParticipant/AddParticipants.styles";
import FloatingSummary from "../../ShopPage/components/FloatingSummary/FloatingSummary";
import DetailCheckoutModal from "../../ShopPage/components/DetailCheckoutModal/DetailCheckoutModal";
import { listTypes } from "../../ShopPage/utils";
import MainMemberForm from "../../ShopPage/components/MainMemberForm/MainMemberForm";
import { PERIODS } from "../../../helpers/dates";
import { DateOfBirthProvider } from "../../../components/DateOfBirthSelector/context/DateOfBirthContext";

const SpaceParticipantsScreen = ({ isIframe }) => {
  const { t } = useTranslation("experiences");
  const { id } = useParams();
  const isMobile = useIsMobile();

  return (
    <DateOfBirthProvider>
      <ParticipantContainer id={id}>
        {({
          content,
          spaceData,
          pricingData,
          isFetching,
          dates,
          handleOnContinue,
          showSummaryModal,
          setShowSummaryModal,
          isBookingFetching,
          handleOnRequestPayment,
          checkoutSummary,
          buttonIsEnabled,
          handleOnFormIsValid,
          saveMainMember,
        }) => {
          const floatingSummaryParams = {
            from: dates.from,
            to: dates.to,
            pricingData: pricingData,
            period: PERIODS.day,
            isPricingFetching: isFetching,
            visible: true,
            expandable: false,
            expandedDefault: true,
            handleOnSummaryClick: handleOnContinue,
            bookingType: listTypes.SPACE,
            showTotal: !isEmpty(pricingData),
            title: spaceData?.title,
            isButtonEnabled: buttonIsEnabled,
          };

          return (
            <LayoutWithDataContainer isIframe={isIframe}>
              {!isIframe && (
                <BannerCollapsed bgImage={content?.headerBackgroundImage} />
              )}
              <ShopWrapper
                {...floatingSummaryParams}
                offset={{
                  offsetBottom: 0,
                }}
                handleOnSummaryClick={handleOnContinue}
              >
                <Container>
                  <Body $isMobile={isMobile}>
                    <Col>
                      <Paragraph strong>
                        {t("booking.completeMembersInfo")}
                      </Paragraph>
                      <MainMemberForm
                        onFormIsValid={handleOnFormIsValid}
                        handleOnContinue={saveMainMember}
                      />
                    </Col>
                    {!isMobile && (
                      <Col
                        style={{
                          paddingTop: 30,
                          paddingLeft: 40,
                          marginRight: 15,
                        }}
                      >
                        <FloatingSummary
                          {...floatingSummaryParams}
                          onActionClick={handleOnContinue}
                          offset={{
                            offsetTop: 120,
                          }}
                        />
                      </Col>
                    )}
                  </Body>
                </Container>
              </ShopWrapper>
              <DetailCheckoutModal
                open={showSummaryModal}
                isLoading={isBookingFetching}
                onContinue={handleOnRequestPayment}
                onCancel={() => setShowSummaryModal(false)}
                bookingType={listTypes.SPACE}
                passengers={checkoutSummary}
                description={t("booking.detailModalDescription")}
              />
            </LayoutWithDataContainer>
          );
        }}
      </ParticipantContainer>
    </DateOfBirthProvider>
  );
};

export default SpaceParticipantsScreen;
