import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import Form from "antd/es/form/Form";
import Modal from "antd/es/modal";
import Radio from "antd/es/radio";
import Space from "antd/es/space";
import Spin from "antd/es/spin";
import Search from "antd/es/input/Search";
import {
  getInitialReferral,
  groupReferrals,
  sortCategories,
} from "../helpers/helpers";
import { isCircuitoChico } from "../../../helpers/rentals";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import { shallowEqual, useSelector } from "react-redux";

const ReferralForm = ({ referrals = [], isReferralsFetching }) => {
  const { t } = useTranslation("shop");
  const [filteredResults, setFilteredResults] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const groupedReferrals = groupReferrals(referrals);
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const initialReferral = getInitialReferral(
    groupedReferrals,
    selectedCategory,
    "Otro"
  );

  useEffect(() => {
    if (!isEmpty(referrals)) {
      setFilteredResults(referrals);
    }
  }, [JSON.stringify(referrals)]);

  const handleOnSearch = (e) => {
    const filter = referrals.filter(
      (referral) =>
        referral.category === selectedCategory &&
        referral.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredResults(filter);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);

    if (groupedReferrals[category]?.length > 1) {
      setShowModal(true);
    }
  };

  return (
    <>
      <Form.Item
        name="referralCategory"
        rules={[
          {
            required: isCircuitoChico(supplierData._id),
            message: t("form.error", { field: t("form.referral") }),
          },
        ]}
      >
        <Radio.Group>
          {sortCategories(groupedReferrals).map((category, index) => (
            <div>
              <Radio
                key={index}
                value={
                  groupedReferrals[category].length === 1
                    ? groupedReferrals[category][0]?._id
                    : category
                }
                onClick={() => handleCategorySelect(category)}
              >
                {t(`referralCategory.${category}`)}
              </Radio>
            </div>
          ))}
        </Radio.Group>
      </Form.Item>

      <Modal
        open={showModal}
        style={{ height: "100vh" }}
        onCancel={() => setShowModal(false)}
        closable
        centered
        footer={null}
        title={t("referralModalTitle")}
        bodyStyle={{
          display: "flex",
          gap: 30,
          flexDirection: "column",
          paddingTop: 20,
        }}
      >
        {selectedCategory && (
          <>
            <Search onChange={handleOnSearch} allowClear />
            {isReferralsFetching && <Spin />}
            <Form.Item
              name="referral"
              initialValue={initialReferral?._id ?? null}
            >
              <Radio.Group defaultValue={initialReferral?._id ?? null}>
                <Space direction="vertical">
                  {filteredResults
                    .filter(
                      (referral) => referral.category === selectedCategory
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((referral, index) => (
                      <Radio
                        key={index}
                        value={referral._id}
                        onClick={() => setShowModal(false)}
                      >
                        {referral.name}
                      </Radio>
                    ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </>
        )}
      </Modal>
    </>
  );
};

export default ReferralForm;
