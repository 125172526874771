import React from "react";
import RichTextRender from "../../../../components/RichTextRender/RichTextRender";

const SpaceDetails = ({ description }) => {
  return (
    <RichTextRender
      content={description}
      style={{ fontSize: 16, maxWidth: 600, lineHeight: 1.5 }}
    />
  );
};

export default SpaceDetails;
