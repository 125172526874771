import React from "react";
import PropTypes from "prop-types";
import { MenuWrapper, MenuItem, DividerList } from "./Menu.styles";

const Menu = ({ isMobile, items, onClose, ...restProps }) => {
  return (
    <MenuWrapper {...restProps} isMobile={isMobile}>
      {items?.map((item, index) => (
        <>
          <MenuItem key={index} {...item} />
          {isMobile ? <DividerList /> : null}
        </>
      ))}
    </MenuWrapper>
  );
};

Menu.defaultProps = {
  isMobile: false,
  items: [],
};

Menu.propTypes = {
  isMobile: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({ ...MenuItem.propTypes })),
  onClose: PropTypes.func,
};

export default Menu;
