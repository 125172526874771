import React from "react";
import PropTypes from "prop-types";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import Columns from "./Columns/Columns";
import { columnsCreator } from "./helpers";
import { GalleryButton, StyledColumns } from "./Collage.styles";
import { size } from "lodash";
import Slider from "./Slider";
import { FileImageOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Collage = ({ images, heightConfig, mobileImages, alt, openGallery }) => {
  const isMobile = useIsMobile();
  const colImages = columnsCreator(images);
  const { t } = useTranslation();

  const imageStyle = {
    objectFit: "cover",
    height: heightConfig,
    width: "100%",
  };

  return (
    <>
      {isMobile ? (
        <Slider images={mobileImages} alt={alt} />
      ) : (
        <StyledColumns numColumns={size(colImages)}>
          <Columns colsImages={colImages} style={imageStyle} alt={alt} />
          <GalleryButton
            shape="round"
            icon={<FileImageOutlined />}
            onClick={openGallery}
          >
            {t("gallery")}
          </GalleryButton>
        </StyledColumns>
      )}
    </>
  );
};

Collage.defaultProps = {
  images: [],
};

Collage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  heightConfig: PropTypes.string.isRequired,
};

export default Collage;
