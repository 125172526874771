import { Collapse } from "antd";
import Form from "antd/es/form/Form";
import styled from "styled-components";

export const ContainerForm = styled(Form)`
  border: 2px solid red;
  
`;
export const StyledCollapse = styled(Collapse)`
  margin-bottom: 20px;
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.colors.borders} !important;

  .ant-collapse-item {
    border-bottom: none !important;
  }
  .ant-collapse-expand-icon {
    display: none;
  }
  .ant-collapse-header-text {
    padding-left: 10px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

