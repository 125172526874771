import React from "react";
import { getPassengerDataById } from "../../redux/QuoteSelectors";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import Text from "antd/es/typography/Text";
import BootstrapIcon from "../../../../components/BootstrapIcon/BoostrapIcon";

const DeletePassengerTabModal = ({ visible, id, onCancel, onOK }) => {
  const passenger = useSelector((state) => getPassengerDataById(state, id));
  return (
    <Modal
      centered
      open={visible}
      onCancel={onCancel}
      onOk={onOK}
      title="Atención"
      bodyStyle={{ paddingTop: 20, paddingBottom: 20 }}
      okText={
        <Text style={{ color: "white" }}>
          <BootstrapIcon iconName="trash3" style={{ marginRight: 10 }} />
          Quitar
        </Text>
      }
      cancelText="Volver"
    >
      <Text>
        ¿Está seguro de quitar a <Text strong>{passenger?.name}</Text>?
      </Text>
    </Modal>
  );
};

export default DeletePassengerTabModal;
