import Search from "antd/lib/input/Search";
import styled from "styled-components";

export const SearchInput = styled(Search)`
  .ant-input-wrapper.ant-input-group {
    border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
    border-radius: 20px;
    transition: border-color 0.3s;
  }

  .ant-input-group-addon {
    border-radius: 20px;
  }
  .ant-input {
    border-radius: 20px 0 0 20px;
    border: none;
    padding-left: 2.5%;
    &:focus {
      box-shadow: none;
    }
  }
  .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
    border-radius: 0 20px 20px 0;
    border-left: none;
  }

  .ant-input-search-button {
    border: none;
    background-color: transparent;
  }

  .anticon.anticon-search {
    font-size: 20px;
  }

  [ant-click-animating-without-extra-node="true"]::after {
    display: none;
  }
`;
