import Typography from "antd/es/typography/Typography";
import styled from "styled-components";

const { Text, Title } = Typography;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: ${({ isMobile }) => (isMobile ? "wrap" : "noWrap")};
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
export const TextStyled = styled(Text)`
  white-space: nowrap;
  text-align: right;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Items = styled.div`
  margin: 5px 0px 0px 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;
export const PriceDetail = styled(Text)`
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
`;


export const StyledTitle = styled(Title)`
  font-size: 16px !important;
  margin-top: 10px;
  align-self: flex-start;
`;