import React from "react";
import styled from "styled-components";
import Text from "antd/es/typography/Text";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Container = styled.div``;
const Date = styled.div``;
const StyledText = styled(Text)`
  font-size: 14px;
`;

const ExperienceDates = ({
  from,
  format = "DD/MM/YY - HH:mm",
  period,
  daysQuantity,
}) => {
  const { t } = useTranslation(["shop", "experiences"]);

  return (
    <Container>
      <Date>
        <StyledText>{t("startDate")}:</StyledText>{" "}
        <StyledText strong>{moment(from).format(format)}</StyledText>
      </Date>
      <StyledText>
        <StyledText>{t("experiences:duration")}:</StyledText>{" "}
        <StyledText strong>{t(period, { count: daysQuantity })}</StyledText>{" "}
      </StyledText>
    </Container>
  );
};

export default ExperienceDates;
