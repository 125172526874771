import React, { useEffect, useState } from "react";
import { DateRange, Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useSelector } from "react-redux";
import { addDays, addYears } from "date-fns";
import { getSearchValueByKey } from "../../../../Redux/Selectors/searchSelectors";
import { PERIODS, getDateLocal } from "../../../../helpers/tools";
import useIsMobile from "../../../../hooks/useIsMobile";
import { getAppConfigState } from "../../../../Redux/Selectors/appConfigSelector";
import { isEmpty } from "lodash";
import { calculatePrevDaysForBooking } from "../../utils";
import { Container } from "./CalendarContainer.styles";
import {
  getNextAvailableDate,
  isRestrictedDate,
} from "../../../../helpers/dates";

const CalendarContainer = ({
  handleOnSelect,
  direction = "horizontal",
  restrictedDates,
  period = PERIODS.day,
  hoursToOpen,
  opennedDaysOfWeek = [],
  prevDaysForBooking = 0,
}) => {
  const isMobile = useIsMobile();
  const {
    data: { theme },
  } = useSelector(getAppConfigState);
  const { from: defaultFrom, to: defaultTo } = useSelector((state) =>
    getSearchValueByKey(state, "dates")
  );
  const [dates, setDates] = useState([
    {
      startDate: getNextAvailableDate(
        calculatePrevDaysForBooking(
          defaultFrom,
          prevDaysForBooking,
          PERIODS.day
        ).toDate(),
        restrictedDates,
        opennedDaysOfWeek
      ),
      endDate: getNextAvailableDate(
        calculatePrevDaysForBooking(
          defaultTo,
          prevDaysForBooking,
          PERIODS.day
        ).toDate(),
        restrictedDates,
        opennedDaysOfWeek
      ),
      key: "selection",
    },
  ]);

  useEffect(() => {
    handleOnSelect("dates", {
      from: dates[0].startDate,
      to: dates[0].endDate,
    });
  }, [dates]);

  const handleSelect = (ranges) => {
    setDates([
      {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: "selection",
      },
    ]);
  };

  const handleDaySelect = (value) => {
    setDates([
      {
        startDate: value,
        endDate: value,
        key: "selection",
      },
    ]);
  };

  const isDayClosed = (current) => {
    const isClosedDay =
      !isEmpty(opennedDaysOfWeek) && !opennedDaysOfWeek[current.getDay()];
    return isClosedDay || isRestrictedDate(current, restrictedDates);
  };
  return (
    <Container isMobile={isMobile}>
      {period === PERIODS.day && (
        <>
          <DateRange
            showDateDisplay={false}
            className="calendar-wrapper"
            locale={getDateLocal()}
            disabledDay={isDayClosed}
            direction={direction}
            showMonthAndYearPickers={false}
            maxDate={addYears(new Date(), 1)}
            months={isMobile ? 1 : 2}
            onChange={handleSelect}
            ranges={dates}
            moveRangeOnFirstSelection={false}
            minDate={addDays(new Date(), prevDaysForBooking)}
            rangeColors={[theme.colors.primary]}
            style={{ width: "100%" }}
          />
        </>
      )}
      {period === PERIODS.hour && (
        <Calendar
          showDateDisplay={false}
          className="calendar-wrapper"
          locale={getDateLocal()}
          disabledDay={isDayClosed}
          direction={direction}
          showMonthAndYearPickers={false}
          maxDate={addYears(new Date(), 1)}
          months={isMobile ? 1 : 2}
          onChange={handleDaySelect}
          date={dates[0].startDate}
          minDate={hoursToOpen <= 0 ? addDays(new Date(), 1) : new Date()}
          color={theme.colors.primary}
          style={{ width: "100%" }}
        />
      )}
    </Container>
  );
};

export default CalendarContainer;
