import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "antd/es/typography/Text";
import { getQuotePricingByProductRequest } from "../../redux/QuoteActions";
import { getSearchValueByKey } from "../../../../Redux/Selectors/searchSelectors";
import {
  PERIODS,
  getCurrencyConfig,
  renderPrice,
  toISOFormatString,
} from "../../../../helpers/tools";
import { useTranslation } from "react-i18next";
import { getPricingDataByProduct } from "../../redux/QuoteSelectors";
import { isEmpty } from "lodash";
import { Skeleton } from "antd";

const PriceContainer = ({ product }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productType = product?.type === "product" ? "product" : "bundle";
  const pricingData = useSelector((state) =>
    getPricingDataByProduct(state, product?._id, productType)
  );
  const dates = useSelector((state) => getSearchValueByKey(state, "dates"));
  const sanitisedVariants = [
    {
      quantity: 1,
      type: productType,
      id: product._id,
      variants: [],
    },
  ];

  useEffect(() => {
    if (dates.to && dates.from) {
      dispatch(
        getQuotePricingByProductRequest(
          {
            from: toISOFormatString(dates.from),
            to: toISOFormatString(dates.to),
            period: PERIODS.day,
            listing: sanitisedVariants,
            customPricing: {},
            byProduct: true,
            currency: product?.currency,
            allowDiscounts: true,
          },
          product?._id
        )
      );
    }
  }, [JSON.stringify(dates.to)]);

  if (isEmpty(pricingData)) {
    return <Skeleton.Input active size="small" />;
  }
  return (
    <Text strong>
      {getCurrencyConfig(product.currency).symbol}{" "}
      {renderPrice(
        pricingData?.lineTotal / pricingData?.timeQuantity,
        product.currency
      )}{" "}
      {t(`common:byPeriods.per_${product.rentalPeriodType}`)}
    </Text>
  );
};

export default PriceContainer;
