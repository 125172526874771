import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import Text from "antd/es/typography/Text";
import { parseLocation } from "../../../../ExperiencesPage/list/helpers/helpers";
import { ReactComponent as BarsIcon } from "../../../../../components/Icons/bars.svg";
import { ReactComponent as HourGlassIcon } from "../../../../../components/Icons/hourglass.svg";
import Price from "../../../../ExperiencesPage/components/Price/Price";

import {
  ContainerCard,
  HeaderCard,
  ExperienceImage,
  ContainerActivity,
  ActivityText,
  TitleCard,
  Location,
  BodyCard,
  BottomCard,
  ContainerItem,
} from "../styles/FeaturedExperiences.styles";

const FeaturedExperienceCard = ({
  images,
  title,
  activity,
  location,
  price,
  duration,
  difficulty,
  currency,
  customPrice,
}) => {
  const { t } = useTranslation(["common", "experiences"]);

  return (
    <ContainerCard>
      <HeaderCard>
        {!isEmpty(images) ? (
          <ExperienceImage alt="experienceImage" src={images[0]} />
        ) : (
          <ExperienceImage
            alt="defaultImage"
            src="https://camarasal.com/wp-content/uploads/2020/08/default-image-5-1.jpg"
          />
        )}
        <ContainerActivity>
          <ActivityText strong>
            {t(`activitiesList.${activity[0]}`)}
          </ActivityText>
        </ContainerActivity>
      </HeaderCard>

      <BodyCard>
        <div>
          <TitleCard ellipsis={{ rows: 2 }} level={5}>
            {title}
          </TitleCard>
          <Location ellipsis={{ rows: 1 }} strong>
            {parseLocation(location)}
          </Location>
        </div>

        <BottomCard>
          {customPrice ? (
            <div>
              <Price
                amount={price}
                currency={currency}
                deleteText={true}
                level={5}
              />
              <Price
                amount={customPrice}
                currency={currency}
                level={5}
                period={"ticket"}
              />
            </div>
          ) : (
            <Price
              amount={price}
              currency={currency}
              level={5}
              period={"ticket"}
            />
          )}

          <div>
            <ContainerItem>
              <HourGlassIcon />
              <Text style={{ fontSize: "12px" }}>
                {t("experiences:duration")}:{" "}
                {t(`periodsWithCount.${duration?.unit}`, {
                  count: duration?.value,
                })}
              </Text>
            </ContainerItem>

            <ContainerItem>
              <BarsIcon />
              <Text style={{ fontSize: "12px" }}>
                {t("experiences:difficulty")}:{" "}
                {t(`experiences:difficultyList.${difficulty}`)}
              </Text>
            </ContainerItem>
          </div>
        </BottomCard>
      </BodyCard>
    </ContainerCard>
  );
};

FeaturedExperienceCard.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  activity: PropTypes.arrayOf(PropTypes.string).isRequired,
  location: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  duration: PropTypes.shape({
    unit: PropTypes.string,
    value: PropTypes.number,
  }),
  difficulty: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

export default FeaturedExperienceCard;
