import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Skeleton from "antd/es/skeleton";
import Container from "../../../../components/Container/Container";
import { ProductsList } from "./HighlightSpacesContent.styles";
import HostingCard from "../../../../components/HostingCard/HostingCard";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "../FeaturedExperiences/styles/FeaturedExperiences.styles";
import { useDispatch, useSelector } from "react-redux";
import { getHighlightCampingSites } from "../../redux/selectors/highlightProductsSelector";
import { highlightProductsRequest } from "../../redux/actions/highlightProductsActions";

const HighlightSpacesContainer = ({ loading, productIds }) => {
  const { t } = useTranslation(["home"]);
  const dispatch = useDispatch();
  const spaces = useSelector(getHighlightCampingSites);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      highlightProductsRequest({
        productIds,
      })
    );
  }, []);

  if (loading) {
    return (
      <Container>
        <Skeleton active={true} />
      </Container>
    );
  }

  return (
    <Wrapper>
      <Container fullsize>
        <ProductsList>
          {spaces.map(({ _id, images, title, price, currency, subtitle }) => (
            <HostingCard
              key={_id}
              id={_id}
              images={images}
              title={title}
              price={price}
              currency={currency}
              subtitle={subtitle}
            />
          ))}
        </ProductsList>
      </Container>
      <Button type="primary" shape="round" onClick={() => navigate("/spaces")}>
        {t("seeMoreSpaces")}
      </Button>
    </Wrapper>
  );
};

HighlightSpacesContainer.defaultProps = {
  loading: false,
  products: [],
};

HighlightSpacesContainer.propTypes = {
  loading: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      period: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
};

export default HighlightSpacesContainer;
