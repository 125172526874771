import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  GET_PRODUCTS_QUOTE_REQUEST,
  GET_QUOTE_PRICING_BY_PRODUCT_REQUEST,
  GET_QUOTE_PRICING_REQUEST,
  SEND_EMAIL_REQUEST,
  getBundlesQuoteSuccess,
  getCategoriesSuccess,
  getProducsQuoteSuccess,
  getQuotePricingByProductSuccess,
  getQuotePricingSuccess,
  sendEmailSuccess,
} from "./QuoteActions";
import { normalizeParams } from "../../../helpers/tools";
import { get, post } from "../../../services/httpService";
import environment from "../../../config/environment";
import { normalizeData } from "../../../Redux/Actions/normalizrActions";
import { productsSchema } from "../../../Redux/Schemas/productSchema";
import { uniq } from "lodash";
import { bundlesSchema } from "../../../Redux/Schemas/bundlesSchema";
import { normalizeListingParam } from "../../ShopPage/utils";
import { filterByPublished } from "../helpers/helpers";

function* handleGetProducts(action) {
  try {
    const { params, currency } = action.payload;
    const { visibleOnWeb, ...restParams } = params;
    const normalizedParams = normalizeParams({ ...params });
    const normalizedBundleParams = normalizeParams({ ...restParams });
    //CHECK FOR SUPPLIER SETTINGS
    const [productsResponse, bundlesResponse] = yield all([
      call(get, `${environment.api.apiBaseUrl}/api/v2/products`, {
        params: {
          pagination: false,
          relationships: ["category", "owner"],
          ...normalizedParams,
          currency,
        },
      }),
      call(get, `${environment.api.apiBaseUrl}/api/bundles`, {
        params: {
          pagination: false,
          relationships: ["owner", "category"],
          ...normalizedBundleParams,
          currency,
        },
      }),
    ]);
    const publishedProducts = filterByPublished(productsResponse.data.docs);
    const publishedBundles = filterByPublished(bundlesResponse.data.docs);
    if (productsResponse.status === 1) {
      yield put(normalizeData(productsResponse.data.docs, productsSchema));
      yield put(getProducsQuoteSuccess(publishedProducts));
      if (bundlesResponse.status === 1) {
        yield put(normalizeData(bundlesResponse.data.docs, bundlesSchema));
        yield put(getBundlesQuoteSuccess(publishedBundles));
      }
      const mappedCategories = [];
      publishedProducts.forEach((product) => {
        if (product.visibleOnWeb) {
          mappedCategories.push(product.category.value);
        }
      });
      let categories = uniq(mappedCategories);
      if (publishedBundles.length > 0) {
        categories = ["combo"].concat(categories);
      }
      yield put(getCategoriesSuccess(categories));
    }
  } catch (e) {
    console.log(e);
  }
}

function* handleGetProductPricing(action) {
  const {
    from,
    to,
    period,
    listing,
    customPricing = {},
    currency,
    allowDiscounts,
    chargeLastDay,
    productId,
  } = action.payload;
  const normalizedVariants = normalizeListingParam(listing, "listing");
  const normalizedCustomPricing = normalizeParams(
    customPricing,
    "customPricing"
  );
  try {
    const endpoint = `${environment.api.apiBaseUrl}/api/v3/pricing/transaction`;
    const [response, responseOriginalCurrency] = yield all([
      call(get, endpoint, {
        params: {
          ...normalizedVariants,
          ...normalizedCustomPricing,
          from,
          to,
          period,
          currency,
          allowDiscounts,
          chargeLastDay,
        },
      }),
      call(get, endpoint, {
        params: {
          ...normalizedVariants,
          ...normalizedCustomPricing,
          from,
          to,
          period,
          allowDiscounts,
          chargeLastDay,
        },
      }),
    ]);
    yield put(
      getQuotePricingByProductSuccess(
        {
          ...response.data,
          hostCurrency: responseOriginalCurrency.data.currency,
          hostTotal: responseOriginalCurrency.data.total,
        },
        productId
      )
    );
  } catch (error) {
    console.log(error);
  }
}

function* handleGetPricing(action) {
  const {
    from,
    to,
    period,
    listing,
    customPricing = {},
    currency,
    allowDiscounts,
    chargeLastDay,
    rowId,
    id,
  } = action.payload;
  const normalizedVariants = normalizeListingParam(listing, "listing");
  const normalizedCustomPricing = normalizeParams(
    customPricing,
    "customPricing"
  );
  try {
    const endpoint = `${environment.api.apiBaseUrl}/api/v3/pricing/transaction`;
    const [response, responseOriginalCurrency] = yield all([
      call(get, endpoint, {
        params: {
          ...normalizedVariants,
          ...normalizedCustomPricing,
          from,
          to,
          period,
          currency,
          allowDiscounts,
          chargeLastDay,
        },
      }),
      call(get, endpoint, {
        params: {
          ...normalizedVariants,
          ...normalizedCustomPricing,
          from,
          to,
          period,
          allowDiscounts,
          chargeLastDay,
        },
      }),
    ]);
    yield put(
      getQuotePricingSuccess(
        {
          ...response.data,
          hostCurrency: responseOriginalCurrency.data.currency,
          hostTotal: responseOriginalCurrency.data.total,
          timeQuantity: responseOriginalCurrency.data.detail[0].timeQuantity,
        },
        id,
        rowId
      )
    );
  } catch (error) {
    console.log(error);
  }
}

function* handleSendEmail(action) {
  try {
    const { email, metadata } = action.payload;
    const response = yield call(
      post,
      `${environment.api.apiBaseUrl}/api/mailer/public`,
      {
        email,
        action: "quote_request_supplier",
        metadata,
      }
    );
    if (response.success) {
      yield put(sendEmailSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* quoteSaga() {
  yield all([
    takeLatest(GET_PRODUCTS_QUOTE_REQUEST, handleGetProducts),
    takeEvery(GET_QUOTE_PRICING_REQUEST, handleGetPricing),
    takeEvery(GET_QUOTE_PRICING_BY_PRODUCT_REQUEST, handleGetProductPricing),
    takeLatest(SEND_EMAIL_REQUEST, handleSendEmail),
  ]);
}
