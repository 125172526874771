import styled from "styled-components";
import mediaQueries from "../../../../../../utils/mediaQueries";

export const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  @media ${mediaQueries.desktop} {
    padding-left: 20px;
  }
  @media ${mediaQueries.largeDesktop} {
    padding-left: 0px;
  }
`;
