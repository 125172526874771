import { CheckCircleFilled } from "@ant-design/icons";
import { Descriptions } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BootstrapIcon from "../../../components/BootstrapIcon/BoostrapIcon";
import { Spinner } from "../../../components/AvailabilityCalendar/AvailabilityCalendar.styles";
const DescriptionContainer = styled.div`
  width: 100%;
  max-width: 600px;
`;
const Success = ({
  customId,
  transactionDate,
  buyOrder,
  authorizationCode,
  installmentsNumber,
  cardNumber,
  isLoading,
}) => {
  const { t } = useTranslation("result");

  if (isLoading) {
    return <Spinner style={{ marginLeft: "50%" }} />;
  }
  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Title>
        <CheckCircleFilled style={{ color: "green" }} />{" "}
        {t("paymentSuccessTitle")}
      </Title>
      <Paragraph>{t("descriptionSuccessful")}</Paragraph>
      <BootstrapIcon iconName="bi-check-circle-fill" />
      <DescriptionContainer style={{ marginTop: 20 }}>
        <Descriptions layout="horizontal" bordered>
          {customId && (
            <Descriptions.Item label={t("reservationCode")} span={3}>
              {customId}
            </Descriptions.Item>
          )}
          {transactionDate && (
            <Descriptions.Item label={t("transactionDate")} span={3}>
              {transactionDate}
            </Descriptions.Item>
          )}
          {buyOrder && (
            <Descriptions.Item label={t("orderNumber")} span={3}>
              <span style={{ fontSize: 14 }}>{buyOrder}</span>
            </Descriptions.Item>
          )}
          {authorizationCode && (
            <Descriptions.Item
              label={t("transactionAuthorizationCode")}
              span={3}
            >
              {authorizationCode}
            </Descriptions.Item>
          )}
          {installmentsNumber && (
            <Descriptions.Item label={t("amountFees")} span={3}>
              {installmentsNumber}
            </Descriptions.Item>
          )}
          {cardNumber && (
            <Descriptions.Item label={t("fourLastDigitsOfAccount")} span={3}>
              {cardNumber}
            </Descriptions.Item>
          )}
        </Descriptions>
      </DescriptionContainer>
    </div>
  );
};
export default Success;
