import React from "react";
import styled from "styled-components";
import BootstrapIcon from "../../../../../components/BootstrapIcon/BoostrapIcon";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { getAppConfigState } from "../../../../../Redux/Selectors/appConfigSelector";
import { useSelector } from "react-redux";
import { PRINT_FORMATS, createUTCDate } from "../../../../../helpers/dates";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  width: 100%;
  cursor: pointer;
  padding: 8px 0px 8px 15px;
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  border-radius: 20px;
`;

const Date = styled.span`
  font-size: 16px;
`;

const DateInput = ({ value, onClick }) => {
  const {
    data: { theme },
  } = useSelector(getAppConfigState);
  const isMobile = useIsMobile();
  return (
    <Container $isMobile={isMobile} onClick={onClick}>
      <BootstrapIcon
        iconName="calendar3"
        style={{ fontSize: 16, color: theme.colors.primary }}
      />

      <Date>{createUTCDate(value).format(PRINT_FORMATS.day.short)}</Date>
    </Container>
  );
};

export default DateInput;
