import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Divider from "antd/es/divider";
import Descriptions from "antd/es/descriptions";
import { first } from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Wrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  border-radius: 10px;
  padding: 12px 20px;
`;

const CardTitle = styled.h5`
  font-size: 16px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;

const SummarySpaceCard = ({ passenger, ...restProps }) => {
  const { t } = useTranslation(["spaces"]);
  const {
    paxData: { name, quantity, from, to },
    assigned: [product],
  } = first(passenger);
  return (
    <Wrapper {...restProps}>
      <CardTitle level={6}>{name}</CardTitle>
      <Descriptions
        column={1}
        size="small"
        style={{
          padding: "0 17px",
        }}
        labelStyle={{
          fontWeight: "bold",
        }}
      >
        <Descriptions.Item
          style={{
            paddingBottom: 2,
            fontSize: "12px",
          }}
          label={`${product.productId.title}  x${quantity} Autos`}
        />
        <Descriptions.Item
          style={{
            paddingBottom: 2,
            fontSize: "12px",
          }}
          label={t("spaces:checkin")}
        >
          {from} - 10:00hs AM
        </Descriptions.Item>
        <Descriptions.Item
          style={{
            paddingBottom: 2,
            fontSize: "12px",
          }}
          label={t("spaces:checkout")}
        >
          {moment(to, "DD-MM-YYYY")
            .utc(true)
            .add(1, "day")
            .format("DD-MM-YYYY")}{" "}
          - 11:00hs AM
        </Descriptions.Item>
      </Descriptions>
      <StyledDivider />
    </Wrapper>
  );
};

SummarySpaceCard.defaultProps = {
  personalInfo: [],
  gear: [],
};

SummarySpaceCard.propTypes = {
  name: PropTypes.string.isRequired,
  personalInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

export default SummarySpaceCard;
