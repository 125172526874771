import React from "react";
import styled from "styled-components";
import Text from "antd/es/typography/Text";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Container = styled.div``;
const Date = styled.div``;
const StyledText = styled(Text)`
  font-size: 14px;
`;

const SpaceDates = ({
  from,
  to,
  format = "DD/MM/YY",
  period,
  daysQuantity,
}) => {
  const { t } = useTranslation(["shop", "spaces"]);

  return (
    <Container>
      <Date>
        <StyledText>{t("spaces:checkin")}:</StyledText>{" "}
        <StyledText strong>
          {moment(from).format(format)} 10:00hs. AM
        </StyledText>
      </Date>
      <Date>
        <StyledText>{t("spaces:checkout")}:</StyledText>{" "}
        <StyledText strong>
          {moment(to).add(1, "day").format(format)} 11:00hs. AM
        </StyledText>
      </Date>
      <StyledText>
        <StyledText>{t("experiences:duration")}:</StyledText>{" "}
        <StyledText strong>{t(period, { count: daysQuantity })}</StyledText>{" "}
      </StyledText>
    </Container>
  );
};

export default SpaceDates;
