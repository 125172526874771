import { cloneDeep, keyBy, merge } from "lodash";
import {
  ADD_PASSENGER_REQUIRED_INFO,
  ASSIGN_DEFAULT_VARIANT_TO_PASSENGER_FAILURE,
  ASSIGN_VARIANT_TO_PASSENGER,
  BUILD_ADD_PASSENGERS,
  BUILD_REMOVE_PASSENGERS,
  GET_REFERRALS_REQUEST,
  GET_REFERRALS_SUCCESS,
  RESET_ASSIGNMENTS,
  UNASSIGN_VARIANT_TO_PASSENGER,
} from "../actions/shopActions";
import { ITEM_TYPE } from "../../constants";
import { setFieldKey } from "../helpers/helpers";

export const INITIAL_STATE = {
  passengers: {
    0: {
      id: 0,
      name: "Cliente",
    },
  },
  assignments: {},
};

const removePassenger = (passengers, id) => {
  const clonedPassengers = cloneDeep(passengers);
  if (clonedPassengers[id]) {
    delete clonedPassengers[id];
  }
  return clonedPassengers;
};

const removeAssignment = ({ index }, assignments) => {
  // Search the first index that matches the criteria to be deleted.
  const clonedAssignments = { ...assignments };
  const assignmentsKeys = Object.keys(clonedAssignments);
  const findKey = assignmentsKeys.find((key) => key.includes(index));
  delete clonedAssignments[findKey];
  return clonedAssignments;
};

const buildShopReducer = (state = INITIAL_STATE, action) => {
  const {
    payload: {
      passengers,
      id,
      passenger,
      variantId,
      index,
      type,
      data,
      productId,
      bundleId,
      experienceId,
      fieldkey,
    } = {},
  } = action;
  switch (action.type) {
    case BUILD_ADD_PASSENGERS:
      return {
        ...state,
        passengers: merge(
          keyBy(state.passengers, "id"),
          keyBy(passengers, "id")
        ),
      };
    case BUILD_REMOVE_PASSENGERS:
      return {
        ...state,
        passengers: removePassenger(state.passengers, id),
      };
    case UNASSIGN_VARIANT_TO_PASSENGER:
      return {
        ...state,
        assignments: removeAssignment(action.payload, state.assignments),
      };
    case ASSIGN_VARIANT_TO_PASSENGER:
      return {
        ...state,
        assignmentFailure: false,
        assignments: {
          ...state.assignments,
          [index]: {
            passenger,
            _id: bundleId || productId || id,
            reference: type === ITEM_TYPE.BUNDLE ? null : variantId,
            fieldKey:
              fieldkey ||
              setFieldKey({
                productId,
                type,
                bundleId,
                state: state.assignments,
                fieldKey: state.assignments[index]?.fieldKey,
              }).rowFieldKey,
            type,
            experienceId,
            variants:
              type === ITEM_TYPE.BUNDLE
                ? variantId.map((variant, i) => {
                    return {
                      reference: variant.reference,
                      type: variant.type,
                      _id: bundleId,
                      fieldKey: setFieldKey({
                        productId: variant.productId,
                        bundleId,
                        state: state.assignments,
                        fieldKey:
                          state.assignments[index]?.variants[i].fieldKey,
                        type,
                      }).bundleVariantFieldKey,
                    };
                  })
                : [],
          },
        },
      };
    case ADD_PASSENGER_REQUIRED_INFO:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [data.id]: {
            ...state.passengers[data.id],
            weight: data.weight,
            height: data.height,
            experience: data.experience,
            footLength: data.footLength,
            requiredInfo: true,
          },
        },
      };
    case GET_REFERRALS_REQUEST:
      return {
        ...state,
        referralsFetching: true,
      };
    case GET_REFERRALS_SUCCESS:
      return {
        ...state,
        referralsFetching: false,
        referrals: action.payload.results,
      };
    case ASSIGN_DEFAULT_VARIANT_TO_PASSENGER_FAILURE: {
      return {
        ...state,
        assignmentFailure: true,
      };
    }
    case RESET_ASSIGNMENTS: {
      return {
        ...state,
        assignments: {},
      };
    }
    default:
      return state;
  }
};

export default buildShopReducer;
