import React from "react";
import PropTypes from "prop-types";
import LayoutAntd from "antd/es/layout";
import Navbar from "../Navbar/Navbar";
import PageFooter from "../PageFooter/PageFooter";
import { Content, Footer, Header } from "./Layout.styles";
import WhatsAppFloat from "../WhatsAppFloat/WhatsAppFloat";

const Layout = ({
  logo,
  alternativeLogo,
  navbar,
  children,
  socialLinks,
  onClickNavbarAction,
  menuItems,
  showMenu,
  setShowMenu,
  showWhatsappIcon,
}) => {
  return (
    <LayoutAntd style={{ minHeight: "100vh" }}>
      {navbar && (
        <Header>
          <Navbar
            logo={logo}
            menuItems={menuItems}
            onClickAction={onClickNavbarAction}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
        </Header>
      )}
      <Content>
        {children}
        {showWhatsappIcon && <WhatsAppFloat />}
      </Content>
      {navbar && (
        <Footer>
          <PageFooter
            logo={alternativeLogo}
            footerMenu={{
              links: menuItems,
            }}
            socialLinks={socialLinks}
          />
        </Footer>
      )}
    </LayoutAntd>
  );
};

Layout.defaultProps = {
  navbar: true,
  socialLinks: [],
  showMenu: false,
};

Layout.propTypes = {
  logo: PropTypes.string.isRequired,
  alternativeLogo: PropTypes.string.isRequired,
  navbar: PropTypes.bool,
  socialLinks: PageFooter.propTypes.socialLinks,
  onClickNavbarAction: PropTypes.func,
  children: PropTypes.node,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
  infoLocalStorage: PropTypes.bool,
  setLocalStorageArgentino: PropTypes.func,
  setLocalStorageForeign: PropTypes.func,
};

export default Layout;
