import moment from "moment";

export const getAllStartTimesByExperience = (state) => {
  const { startTimes, isFetching } = state.pages.experiences.availability;
  return { startTimes, isFetching };
};

export const getAvailabilityDatesByExperience = (state) => {
  const { availableDates } = state.pages.experiences.availability;
  return availableDates;
};

export const getAvailabilityByDate = (state, startTimeId, from) => {
  const availableDates = getAvailabilityDatesByExperience(state);
  const startDate = moment(from).format("YYYYMMDD");
  if (availableDates?.[startTimeId]?.[startDate]) {
    return availableDates[startTimeId][startDate];
  }
  return null;
};

export const getAvailabilityDatesByExperienceFetching = (state) => {
  const { isFetching } = state.pages.experiences.availability;
  return isFetching;
};
