import React from "react";
import styled from "styled-components";
import Text from "antd/es/typography/Text";
import ProductTitle from "../../containers/BundleContentContainer/ProductTitle";
import { useTranslation } from "react-i18next";

const StyledUl = styled.ul`
  margin-top: 15px;
`;
const StyledLi = styled.li``;

const BundleDetails = ({ products, showVariants = false, variants = [] }) => {
  const { t } = useTranslation("shop");
  return (
    <StyledUl>
      {products.map((product, index) => {
        const variant = variants.filter(
          (variant) => variant?.product?._id === product?.productId
        );
        return (
          <StyledLi key={`${product.productId}${index}`}>
            <ProductTitle productId={product.productId} />{" "}
            {showVariants && (
              <Text strong style={{ fontSize: 14 }}>
                - {t(variant?.[0]?.variant?.unit)}{" "}
                {variant?.[0]?.variant?.value}
              </Text>
            )}
          </StyledLi>
        );
      })}
    </StyledUl>
  );
};

export default BundleDetails;
