import {
  ADD_PASSENGER_DETAILS,
  SET_CHECKIN_SEARCH_FILTER,
  RESET_CHECKIN_DATA,
  SET_SUPPLIER_HOST,
  SET_DROP_OFF_BRANCH,
} from "./checkinActions";

const INITIAL_STATE = {
  passengers: [],
  productSearch: null,
  supplierHost: null,
  dropOffBranch: null,
};

const checkinReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CHECKIN_SEARCH_FILTER:
      return {
        ...state,
        productSearch: action.payload.searchValue,
      };
    case SET_SUPPLIER_HOST:
      return {
        ...state,
        supplierHost: action.payload.host,
      };
    case SET_DROP_OFF_BRANCH:
      return {
        ...state,
        dropOffBranch: action.payload.branch,
      };
    case RESET_CHECKIN_DATA:
      return {
        ...state,
        productSearch: null,
        supplierHost: null,
      };

    case ADD_PASSENGER_DETAILS:
      return {
        ...state,
        passengers: action.payload,
      };
    default:
      return state;
  }
};

export default checkinReducer;
