import { first, groupBy, isEmpty, size, uniqueId } from "lodash";
import { setFieldKey } from "../../ShopPage/redux/helpers/helpers";
import { CUSTOMER_SIZE } from "../../../helpers/tools";
import { listTypes } from "../../ShopPage/utils";

export const normalizeVariants = (products) => {
  if (!products) return [];
  const variantList = products.reduce((acc, product) => {
    return acc.concat(product.variants);
  }, []);
  const variants = variantList.map((variant) => ({
    variantId: variant._id || variant.id,
  }));
  return variants;
};

export const normaliseListing = (passengers) => {
  const productList = passengers.reduce((acc, passenger) => {
    return acc.concat(passenger.products);
  }, []);
  const groupedProducts = groupBy(productList, (product) => [
    product.productId,
    product.product.type === listTypes.PRODUCT
      ? listTypes.PRODUCT
      : listTypes.BUNDLE,
  ]);
  const normalizedListing = Object.values(groupedProducts).map((products) => {
    const referralProduct = first(products);
    const quantity = size(products);
    const variants = normalizeVariants(products);
    return {
      type:
        referralProduct.product.type === listTypes.PRODUCT
          ? listTypes.PRODUCT
          : listTypes.BUNDLE,
      quantity,
      id: referralProduct.productId,
      variants,
    };
  });
  return normalizedListing;
};

// todo: refactor
export const normalisePassengers = (passengers) => {
  return passengers.map((passenger, index) => {
    const { name, weight, height, footLength, experience } = passenger;
    return {
      personalInfo: {
        name,
        deletable: true,
        id: uniqueId(Date.now()),
        weight: { value: weight || 0, unit: "kgs" },
        height: { value: height || 0, unit: "cms" },
        footLength: { value: footLength || 0, unit: "cms" },
        experience: { value: experience },
      },
      items: passenger.products.map((item, prodIndex) => ({
        type:
          item.product.type === listTypes.PRODUCT
            ? listTypes.VARIANT
            : listTypes.BUNDLE,
        reference:
          item.product.type === listTypes.PRODUCT
            ? item.variants[0]?._id
            : item.productId,
        title: item.product?.title,
        fieldKey: setFieldKey({
          productId: item.productId,
          type:
            item.product.type === listTypes.PRODUCT
              ? listTypes.PRODUCT
              : listTypes.BUNDLE,
          bundleId: item.productId,
          state: passengers,
          fieldKey: passengers[index]?.fieldKey,
        }).rowFieldKey,
        image: item.product.images[0],
        _id: item.productId,
        item: null,
        variants: !isEmpty(item.variants)
          ? item.variants.map((variant, i) => {
              return {
                type: listTypes.VARIANT,
                reference: variant._id,
                fieldKey: setFieldKey({
                  productId: variant?.product?._id || item.productId,
                  bundleId: item.productId,
                  state: passengers,
                  fieldKey:
                    passengers[index]?.products[prodIndex].variants[i].fieldKey,
                  type: listTypes.VARIANT,
                }).bundleVariantFieldKey,
                _id: variant._id || variant.id,
                item: null,
              };
            })
          : [],
      })),
    };
  });
};

export const mapCategoriesId = (products = []) => {
  const categoriesIds = products?.map((product) => product?.category?._id);
  return categoriesIds;
};

export const mapCategoryValues = (categories = []) => {
  const categoryValues = categories?.map((category) => category?.value);
  return categoryValues;
};

export const getFootOptions = () => {
  return Array.from({ length: 29 }, (_, i) => i + 22);
};

export const getPassengerInfo = (require, passenger, t) => {
  const label = require === CUSTOMER_SIZE.HEIGHT ? t(require) : t("shoeSize");
  const value =
    require === CUSTOMER_SIZE.HEIGHT
      ? `${passenger[require]} Cm.`
      : passenger[require];
  return { label, value };
};

export const filterByPublished = (data = []) => {
  const publishedData = data.filter((value) => value.published === true);
  return publishedData;
};
