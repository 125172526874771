import { denormalize } from "normalizr";
import { generateTheme } from "../../helpers/theme";
import { sanitizeScript } from "../../helpers/tools";
import { displayType } from "../../utils/typeVisualization";
import { supplierSchema } from "../Schemas/supplierSchema";
import { websiteConfigSchema } from "../Schemas/websiteConfigSchema";

export const getAppConfigState = (state) => {
  const config = state.appGlobal?.config;
  return {
    ...config,
    data: denormalize(config?.data, websiteConfigSchema, state.entities),
  };
};

export const getAppThemeConfig = (state) => {
  return generateTheme(getAppConfigState(state)?.data?.theme);
};

export const getWebsiteContentConfig = (state) => {
  return getAppConfigState(state)?.data?.content;
};

export const getBanners = (state) => {
  return getAppConfigState(state)?.data?.banners;
};

export const getWebsiteReviewsConfig = (state) => {
  return getAppConfigState(state)?.data?.reviews;
};

export const getAgreementConfig = (state) => {
  return getAppConfigState(state)?.data?.agreement || {};
};

export const getScripts = (state) => {
  const scripts = getAppConfigState(state)?.data?.scripts || "";
  return sanitizeScript(scripts);
};
export const getButtonCustom = (state) => {
  const button = getAppConfigState(state)?.data?.buttonCustom || {};
  return button;
};
export const getPrevDaysForBookingConfig = (state) => {
  return getAppConfigState(state)?.data?.prevDaysForBookingRequire || 0;
};
export const getTypeVisualization = (state) => {
  return getAppConfigState(state)?.data?.displayType || displayType.UNIVERSAL;
};

export const getSupplierData = (state) => {
  return denormalize(
    getAppConfigState(state)?.data?.supplier,
    supplierSchema,
    state.entities
  );
};

export const getSupplierSettings = (state) => {
  const settings = state.appGlobal?.settings;
  return settings;
};
