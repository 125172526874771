import { getAssignedItems } from "../../../ShopPage/redux/selectors/ShopSelectors";
import { uniqueId } from "lodash";

export const createSizeMap = (passengersSize) => {
  return new Map(passengersSize.map((size) => [size.id, size]));
};

export const mapPassengerData = (passenger, sizeMap, state) => {
  const size = sizeMap.get(passenger.id) || {};

  const weight = size.weight
    ? {
        weight: {
          value: parseInt(size.weight, 10),
          unit: "kgs",
        },
      }
    : {};

  const height = size.height
    ? {
        height: {
          value: parseInt(size.height, 10),
          unit: "cms",
        },
      }
    : {};

  const footLength = size.footLength
    ? {
        footLength: {
          value: parseInt(size.footLength, 10),
          unit: "cms",
        },
      }
    : {};

  const experience = size.experience
    ? {
        experience: {
          value: size.experience,
        },
      }
    : {};

  const items = getAssignedItems(state, passenger.id);

  return {
    personalInfo: {
      name: passenger.name,
      deletable: passenger.deletable ?? true,
      id: uniqueId(Date.now()),
      ...weight,
      ...height,
      ...footLength,
      ...experience,
    },
    items: items ?? [],
  };
};

export const parseCheckinData = (checkInDetails) => {
  return checkInDetails.reduce((map, detail) => {
    map[detail.id] = detail;
    return map;
  }, {});
};

export const mergePassengerAndChekinData = (passengersData, checkInMap) => {
  return passengersData.map((data) => ({
    ...data,
    ...(checkInMap[data.id] || {}),
  }));
};
