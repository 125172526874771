import { Button, Col, Form, Input, Row } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CUSTOMER_TYPE } from "../../../../components/FormInputs/constants";
import CountrySelectForm from "../../../../components/FormInputs/CountrySelectForm";
import PersonalIdInput from "../../../../components/FormInputs/PersonalIdInput";
import PhoneInput from "../../../../components/FormInputs/PhoneInput";
import useIsMobile from "../../../../hooks/useIsMobile";
import { provinces } from "../../../../config/provinces";
import ProvinceSelector from "../../../../components/ProvinceSelector/ProvinceSelector";
import CitySelectorContainer from "../../../../components/CitySelector/CitySelectorContainer";
import DateOfBirthSelector from "../../../../components/DateOfBirthSelector/DateOfBirthSelector";
import { DateOfBirthContext } from "../../../../components/DateOfBirthSelector/context/DateOfBirthContext";
import { validateDateOfBirth } from "./helpers/helpers";
import { cardStatus } from "../../../PaymentPage/utils";

const PersonalForm = ({
  onChange,
  form,
  initialValues,
  onSubmit,
  showSubmitButton = false,
}) => {
  const { t } = useTranslation(["experiences", "shop"]);
  const isMobile = useIsMobile();
  const spacing = isMobile ? [24] : [12, 12];
  const { dateOfBirth } = useContext(DateOfBirthContext);
  return (
    <Form
      onFinish={onSubmit}
      onChange={onChange}
      layout="vertical"
      form={form}
      initialValues={initialValues}
    >
      <Form.Item noStyle name="customerType" hidden>
        <Input size="large" hidden />
      </Form.Item>
      <Form.Item noStyle name="createdBy" hidden>
        <Input size="large" hidden />
      </Form.Item>
      <Row gutter={spacing}>
        <Col span={spacing[0]}>
          <Form.Item
            name="name"
            label={t("shop:form.name")}
            rules={[
              {
                required: true,
                message: t("shop:form.error", { field: t("shop:form.name") }),
              },
            ]}
          >
            <Input size="large" placeholder={t("shop:form.name")} />
          </Form.Item>
        </Col>
        <Col span={spacing[0]}>
          <Form.Item
            name="surname"
            label={t("shop:form.surname")}
            rules={[
              {
                required: true,
                message: t("shop:form.error", {
                  field: t("shop:form.surname"),
                }),
              },
            ]}
          >
            <Input size="large" placeholder={t("shop:form.surname")} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="dateOfBirth"
        label={t("shop:form.dateOfBirth")}
        rules={[
          {
            required: true,
            message: t("shop:form.error", {
              field: t("shop:form.dateOfBirth"),
            }),
            validator: (_) => {
              const { validateStatus, errorMsg } = validateDateOfBirth(
                dateOfBirth,
                t
              );
              if (validateStatus === cardStatus.ERROR) {
                return Promise.reject(errorMsg);
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DateOfBirthSelector />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.nationality !== currentValues.nationality
        }
        noStyle
      >
        {({ getFieldValue, setFieldValue }) => {
          const nationality = getFieldValue("nationality")?.toLowerCase();
          return (
            <>
              <CountrySelectForm name="nationality" required size={"large"} />
              {provinces[nationality] && (
                <Row gutter={spacing}>
                  <Col span={spacing[0]}>
                    <Form.Item
                      name="province"
                      label={t("shop:form.province")}
                      rules={[
                        {
                          required: true,
                          message: t("shop:form.error", {
                            field: t("shop:form.province"),
                          }),
                        },
                      ]}
                    >
                      <ProvinceSelector
                        placeholder={t("shop:form.province")}
                        country={nationality}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={spacing[1]}>
                    <Form.Item
                      name="city"
                      label={t("shop:form.city")}
                      rules={[
                        {
                          required: true,
                          message: t("shop:form.error", {
                            field: t("shop:form.city"),
                          }),
                        },
                      ]}
                    >
                      <CitySelectorContainer
                        placeholder={t("shop:form.city")}
                        name={"city"}
                        onSelect={(city) => setFieldValue("city", city)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          );
        }}
      </Form.Item>
      <PersonalIdInput
        typeWidth={isMobile ? 130 : 180}
        customerType={CUSTOMER_TYPE.PERSONAL}
        required
      />
      <Form.Item
        name="email"
        label={t("shop:form.email")}
        rules={[
          {
            required: true,
            message: t("shop:form.error", { field: t("shop:form.email") }),
          },
          {
            type: "email",
            message: t("shop:form.error", { field: t("shop:form.email") }),
          },
        ]}
      >
        <Input size="large" placeholder={t("shop:form.email")} />
      </Form.Item>

      <PhoneInput prefixWidth={isMobile ? 130 : 180} required />

      <Form.Item
        name="address"
        label={t("shop:form.customerAddress")}
        rules={[
          {
            required: true,
            message: t("shop:form.error", {
              field: t("shop:form.customerAddress"),
            }),
          },
        ]}
      >
        <Input size="large" placeholder={t("shop:form.customerAddress")} />
      </Form.Item>
      {showSubmitButton && (
        <Form.Item>
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            style={{ width: 150, borderRadius: 20 }}
          >
            {t("save")}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};
export default PersonalForm;
