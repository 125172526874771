export const SET_WEBISTE_REGION = "SET_WEBSITE_REGION";

export const setWebsiteRegion = (region) => {
  return {
    type: SET_WEBISTE_REGION,
    payload: {
      region,
    },
  };
};
