import {
  TRANSACTION_PAMYENT_RESET_IDENTIFIER,
  TRANSACTION_PAYMENT_SET_PAYMENT_IDENTIFIER,
} from "./paymentActions";

const INITIAL_STATE = {
  paymentMethodIdentifier: null,
};

const transactionPaymentReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case TRANSACTION_PAMYENT_RESET_IDENTIFIER: {
      return INITIAL_STATE;
    }
    case TRANSACTION_PAYMENT_SET_PAYMENT_IDENTIFIER: {
      return {
        ...state,
        paymentMethodIdentifier: payload.paymentMethodIdentifier,
      };
    }
    default:
      return state;
  }
};

export default transactionPaymentReducer;
