import { useDispatch, useSelector } from "react-redux";
import {
  shopApplyDiscount,
  shopResetDiscount,
} from "../../../redux/actions/DiscountActions";
import { isEmpty } from "lodash";
import { getDiscountData } from "../../../redux/selectors/DiscountSelectors";

const useDiscounts = () => {
  const dispatch = useDispatch();
  const { discountCode, firstApplicationIntent } = useSelector(getDiscountData);

  const handleApplyDiscount = (discountCode) => {
    if (isEmpty(discountCode)) {
      return false;
    }
    dispatch(shopApplyDiscount({ discountCode }));
  };

  const handleRemoveDiscount = () => {
    dispatch(shopResetDiscount());
  };

  return {
    handleApplyDiscount,
    discountCode,
    firstApplicationIntent,
    handleRemoveDiscount,
  };
};

export default useDiscounts;
