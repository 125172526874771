import { isString } from "lodash";
import { Node } from "slate";
import { OPENING_CONFIG } from "../utils/supplier";
import { PERIODS } from "./tools";

export const isDayOpen = (day = {}) => !!(day.open || day.delivery);

export const getOpeningDays = (openingConfig, openingHours) => {
  const openingHoursObj = isString(openingHours)
    ? JSON.parse(openingHours)
    : openingHours;
  let openingDays = new Array(7).fill(false);
  if (openingConfig === OPENING_CONFIG.alwaysOpen) {
    openingDays = new Array(7).fill(true);
  } else if (openingConfig === OPENING_CONFIG.selectedHours) {
    const {
      hours_sunday = [],
      hours_monday = [],
      hours_tuesday = [],
      hours_wednesday = [],
      hours_thursday = [],
      hours_friday = [],
      hours_saturday = [],
    } = openingHoursObj;
    openingDays = [
      isDayOpen(hours_sunday[0]),
      isDayOpen(hours_monday[0]),
      isDayOpen(hours_tuesday[0]),
      isDayOpen(hours_wednesday[0]),
      isDayOpen(hours_thursday[0]),
      isDayOpen(hours_friday[0]),
      isDayOpen(hours_saturday[0]),
    ];
  }
  return openingDays;
};

export const sanitizeRichText = (richText) => {
  return richText.map((n) => Node.string(n));
};

/**
 * Determine the appropriate reservation period based on the rental settings
 *
 * @param {("day" | "hour")[]} bookingPeriodTypes - An array of available booking period types.
 *                                                   Possible values: ["day"], ["hour"], ["day", "hour"]
 * @returns {string} - The selected booking period. If the array contains only one type,
 *                     that type is returned. If multiple types are present, "day" is returned by default.
 */
export const getSanitizedBookingPeriod = (bookingPeriodTypes = []) => {
  // If there's only one period type in the array, return it.
  if (bookingPeriodTypes.length === 1) {
    return bookingPeriodTypes[0];
  }

  // If there are multiple period types, return "day" as the default.
  return PERIODS.day;
};
