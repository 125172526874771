import { getProductById } from "../../../../Redux/Selectors/productSelectors";
import { getBundleById } from "../../../../Redux/Selectors/bundlesSelectors";
import { PRODUCT_TYPE } from "../../constants";

export const getProductModalVisibility = (state) => {
  const { visible } = state.pages.shop.productModal;
  return visible;
};

export const getProductModalData = (state) => {
  const { productId, optionType, productType } = state.pages.shop.productModal;
  const product =
    productType === PRODUCT_TYPE.PRODUCT ||
    productType === PRODUCT_TYPE.EXPERIENCE
      ? getProductById(state, productId)
      : getBundleById(state, productId);
  return { product, optionType };
};
