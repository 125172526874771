import { CheckCircleFilled } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import BootstrapIcon from "../../../components/BootstrapIcon/BoostrapIcon";
import LayoutWithDataContainer from "../../../containers/LayoutWithDataContainer";
import Container from "../../../components/Container/Container";
import { Card } from "antd";

const SuccessScreen = ({ isCheckin }) => {
  const { t } = useTranslation("result");

  return (
    <LayoutWithDataContainer isCheckin={isCheckin}>
      <Container>
        <Card>
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              textAlign: "center",
              paddingTop: 60,
              paddingBottom: 60,
            }}
          >
            <CheckCircleFilled style={{ color: "green", fontSize: 60 }} />
            <Title level={5}>{t("checkin.success")}</Title>
            <Paragraph>{t("checkin.description")}</Paragraph>
            <BootstrapIcon iconName="bi-check-circle-fill" />
          </div>
        </Card>
      </Container>
    </LayoutWithDataContainer>
  );
};

export default SuccessScreen;
