import { Row, Typography } from "antd";
import PropTypes from "prop-types";
import { ContainerBody } from "./PaymentResult.styles";
const { Title, Text } = Typography;

const BodyCard = ({ title, children, subtitle }) => {
  return (
    <ContainerBody>
      <Title level={4}>{title}</Title>
      <Row gutter={[12, 12]}>
        <Text>{subtitle}</Text>
        {children}
      </Row>
    </ContainerBody>
  );
};

BodyCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  subtitle: PropTypes.string,
};

export default BodyCard;
