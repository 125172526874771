import styled from "styled-components";
import Text from "antd/es/typography/Text";
import Avatar from "antd/es/avatar/avatar";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 5px;
`;

export const Logo = styled(Avatar)`
  flex-shrink: 0;
`;

export const RentalName = styled(Text)``;
