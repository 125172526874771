import { schema } from "normalizr";
import { bookingSchema } from "./bookingSchema";
import { referralSchema } from "./referralSchema";
import { supplierSchema } from "./supplierSchema";
import { userSchema } from "./userSchema";

export const transactionSchema = new schema.Entity(
  "transactions",
  {
    guest: userSchema,
    host: supplierSchema,
    booking: bookingSchema,
    referral: referralSchema,
  },
  {
    idAttribute: "_id",
  }
);

export const transactionsSchema = [transactionSchema];
