export const HIGHLIGHT_PRODUCTS_REQUEST = "HIGHLIGHT_PRODUCTS_REQUEST";
export const HIGHLIGHT_PRODUCTS_SUCCESS = "HIGHLIGHT_PRODUCTS_SUCCESS";
export const HIGHLIGHT_PRODUCTS_FAILURE = "HIGHLIGHT_PRODUCTS_FAILURE";

/**
 * @param {Object} params
 * @param {string[]} params.productIds
 * @returns {object}
 */
export const highlightProductsRequest = ({ productIds }) => {
  return {
    type: HIGHLIGHT_PRODUCTS_REQUEST,
    payload: {
      productIds,
    },
  };
};

export const highlightProductsSuccess = (productIds) => {
  return {
    type: HIGHLIGHT_PRODUCTS_SUCCESS,
    payload: {
      productIds,
    },
  };
};

export const highlightProductsFailure = (error) => {
  return {
    type: HIGHLIGHT_PRODUCTS_SUCCESS,
    payload: {
      error,
    },
  };
};
