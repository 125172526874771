import React from "react";
import { useSelector } from "react-redux";
import { getSearchValueByKey } from "../../../../Redux/Selectors/searchSelectors";
import DateInput from "../../../ShopPage/forms/AddPeopleForm/CalendarInput/DateInput";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/es/typography/Paragraph";
import useIsMobile from "../../../../hooks/useIsMobile";
import { Container, WrapperRight, WrapperLeft } from "./DatesInput.styles";
import { calculatePrevDaysForBooking } from "../../../ShopPage/utils";
import { PERIODS } from "../../../../helpers/tools";

const DatesInput = ({ handleShowModal, bodyStyle, prevDaysForBooking }) => {
  const { t } = useTranslation("shop");
  const isMobile = useIsMobile();
  const dates = useSelector((state) => getSearchValueByKey(state, "dates"));
  const { from, to } = dates;
  return (
    <div>
      <Container
        onClick={() => handleShowModal("calendar")}
        style={{ ...bodyStyle }}
      >
        <WrapperRight>
          <DateInput
            label={t("startDate")}
            value={calculatePrevDaysForBooking(
              from,
              prevDaysForBooking,
              PERIODS.day
            )}
            align="left"
          />
        </WrapperRight>
        <WrapperLeft isMobile={isMobile}>
          <DateInput
            label={t("dropOffDate")}
            value={calculatePrevDaysForBooking(
              to,
              prevDaysForBooking,
              PERIODS.day
            )}
            align="left"
          />
        </WrapperLeft>
      </Container>
      <Paragraph
        type="secondary"
        style={{ fontSize: 12, marginTop: 10, marginBottom: 0 }}
      >
        Las fechas que seleccionas pueden afectar los precios.
      </Paragraph>
    </div>
  );
};

export default DatesInput;
