import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { getPaymentCreationState } from "../redux/paymentSelectors";
import { getPaymentById } from "../../../Redux/Selectors/paymentSelector";
import { transactionPaymentCreateRequest } from "../redux/paymentActions";

const WebpayPaymentScreen = ({ transactionId, amount, currency }) => {
  const dispatch = useDispatch();
  const {
    paymentId,
    loading: paymentCreationLoading,
    error: paymentCreationError,
    success: paymentCreationSuccess,
  } = useSelector(getPaymentCreationState, shallowEqual);
  const payment = useSelector((state) => getPaymentById(state, paymentId));

  useEffect(() => {
    dispatch(
      transactionPaymentCreateRequest({
        provider: "webpay",
        amount,
        currency,
        transactionId,
      })
    );
  }, []);

  useEffect(() => {
    if (
      !payment ||
      paymentCreationLoading ||
      (!paymentCreationSuccess && !paymentCreationError)
    ) {
      return;
    }
    if (paymentCreationSuccess) {
      window.location.href = payment.checkoutUrl;
    }
    if (paymentCreationError) {
      // TODO: error feedback
      console.log("creation error");
    }
  }, [
    payment,
    paymentCreationLoading,
    paymentCreationSuccess,
    paymentCreationError,
  ]);

  return <></>;
};

WebpayPaymentScreen.propTypes = {
  transactionId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export default WebpayPaymentScreen;
