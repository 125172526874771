import { Button, Card, Modal } from "antd";
import React, { useState } from "react";
import PickProductModalContainer from "../../containers/PickProductModalContainer/PickProductModalContainer";
import CategoriesList from "../CategoriesList/CategoriesList";
import { useTranslation } from "react-i18next";
import { clickEvents, trackEvent } from "../../../../services/mixpanel";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import SnowCategoriesList from "../CategoriesList/SnowCategoriesList";
import { RENTALS, isRental } from "../../../../helpers/rentals";
import {
  CategoriesContainer,
  ContainerCategoriesList,
} from "./WinterMemberCardMobile.styles";
import { sortCategorizedProducts } from "../../tools/helpers";
import {
  getBundlesWithOutCategory,
  getCategoriesQuote,
} from "../../redux/QuoteSelectors";

const WinterMemberCardMobile = ({ id }) => {
  const { t } = useTranslation("shop");
  const supplierData = useSelector(getSupplierData);
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: null,
    selectedBundle: null,
  });
  const [categoriesVisible, setCategoriesVisible] = useState(false);
  const categories = useSelector((state) => getCategoriesQuote(state, t));
  const bundlesWithOutCategory = useSelector(getBundlesWithOutCategory);

  const handleOnClick = (category, selectedBundle) => {
    trackEvent(clickEvents.SELECT_CATEGORY, {
      page: "Quote",
      category: category,
      supplierId: supplierData._id,
      supplierName: supplierData.name,
      selectedBundle,
    });
    setModalVisible({ visible: true, type: category, selectedBundle });
  };

  const handleCategoriesClick = () => {
    setCategoriesVisible(!categoriesVisible);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setCategoriesVisible(false);
  };

  const renderCategories = () => {
    if (isRental(supplierData._id, RENTALS.alborde)) {
      return <CategoriesList onClick={handleOnClick} />;
    }
    return (
      <SnowCategoriesList
        onClick={handleOnClick}
        categories={sortCategorizedProducts(categories, t)}
        bundlesWithOutCategory={bundlesWithOutCategory}
      />
    );
  };

  return (
    <Card>
      <CategoriesContainer>
        <Button type="primary" shape="round" onClick={handleCategoriesClick}>
          {t("choose_equipment")}:
        </Button>
        <PickProductModalContainer
          open={modalVisible.visible}
          type={modalVisible.type}
          closeModal={handleCloseModal}
          passengerId={id}
          selectedBundle={modalVisible.selectedBundle}
        />
        <Modal
          onCancel={handleCategoriesClick}
          open={categoriesVisible}
          width={"100%"}
          centered
          bodyStyle={{ minHeight: "90vh" }}
          title={t("categories")}
          footer={null}
        >
          <ContainerCategoriesList>
            {renderCategories()}
          </ContainerCategoriesList>
        </Modal>
      </CategoriesContainer>
    </Card>
  );
};

export default WinterMemberCardMobile;
