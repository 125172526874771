/**
 * @param {object} theme
 * @returns {object}
 */
export const getAntdThemeConfig = (theme) => {
  return {
    token: {
      fontFamily: '"Proxima Outtrip", sans-serif',
      colorPrimary: theme.colors.primary,
      colorLink: theme.colors.primary,
      colorLinkActive: theme.colors.primary,
      colorLinkHover: theme.colors.primary,
      fontSize: theme.size.fontSize,
      borders: theme.borders.gray,
    },
    components: {
      Input: {
        colorBorder: theme.borders.gray,
        borderRadius: 10,
        controlHeight: 40,
      },
    },
  };
};
