import { inRange, isEmpty, isNumber, uniqueId } from "lodash";
import { toISOFormatString } from "../../../../helpers/tools";
import { DISCOUNT_TYPES } from "../../../ShopPage/constants";

export const parseLocation = (locationString) => {
  const entities = locationString.split(",").map((entity) => entity.trim());

  if (entities.length >= 3) {
    return entities.slice(-3).join(", ");
  }
  return entities.join(", ");
};

export const defaultParticipants = (tickets) => {
  const labelCounts = {};
  return Object.entries(tickets)
    .sort()
    .reduce((acc, [rate, quantity], index) => {
      for (let i = 0; i < quantity; i++) {
        const id = uniqueId(Date.now());
        const reservationMainMember = i === 0 && index === 0;
        labelCounts[rate] = (labelCounts[rate] || 0) + 1;
        const participant = {
          active: reservationMainMember,
          rate,
          id: reservationMainMember ? "0" : id,
          complete: false,
          isMainMember: reservationMainMember,
          addonLabel: reservationMainMember
            ? "titular"
            : `${labelCounts[rate]}`,
        };
        acc[participant.id] = participant;
      }
      return acc;
    }, {});
};

export const normalizeExperiencePassengers = (passengers) => {
  return passengers?.map((passenger) => {
    return {
      personalInfo: {
        ...passenger,
        dateOfBirth: passenger?.dateOfBirth
          ? toISOFormatString(passenger?.dateOfBirth.toString())
          : null,
      },
    };
  });
};

export const getConvertedPrice = (
  experienceId,
  price,
  experiencePromotionList = {}
) => {
  if (
    !experienceId ||
    !isNumber(price) ||
    !inRange(price, 1, Infinity) ||
    isEmpty(experiencePromotionList)
  )
    return null;
  const discount = getExperiencePromotion(
    experienceId,
    experiencePromotionList
  );
  const convertedPrice = calculateDiscount(
    discount?.discountType,
    discount?.value,
    price
  );
  return convertedPrice;
};

export const getExperiencePromotion = (
  experienceId,
  experiencePromotionList
) => {
  if (experiencePromotionList[experienceId]) {
    const experience = experiencePromotionList[experienceId];
    return {
      ...experience,
    };
  }
  return null;
};

export const calculateDiscount = (
  discountType = DISCOUNT_TYPES.PERCENTAGE,
  discountValue,
  price
) => {
  if (
    !isNumber(price) ||
    !inRange(price, 1, Infinity) ||
    !isNumber(discountValue)
  ) {
    return null;
  }
  if (discountType === DISCOUNT_TYPES.PERCENTAGE) {
    return price - (price * discountValue) / 100;
  } else if (discountType === DISCOUNT_TYPES.AMOUNT_FIXED) {
    return price - discountValue;
  }
  return null;
};
