import React from "react";
import styled from "styled-components";
import { uniqueId } from "lodash";
import RichTextRender from "../../../../components/RichTextRender/RichTextRender";
import { useDispatch, useSelector } from "react-redux";
import { Button, Collapse, List } from "antd";
import { quoteAddItem, setRequiredInfoMissing } from "../../redux/QuoteActions";
import { PlusOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { useTranslation } from "react-i18next";
import PromoItem from "../../../ShopPage/components/PromoItem/PromoItem";
import useIsMobile from "../../../../hooks/useIsMobile";
import { requiresInfo } from "../../tools/helpers";
import { clickEvents, trackEvent } from "../../../../services/mixpanel";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import Disclaimer from "../../../../components/Disclaimer/Disclaimer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  justify-content: start;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ProductContentContainer = ({
  image,
  title,
  description,
  passengerId,
  closeModal,
  subtitle,
  promo,
  category,
  price,
  periodType,
  currency,
  id,
}) => {
  const supplierData = useSelector(getSupplierData);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const rowId = uniqueId();
  const renderPromos = () => {
    return (
      <List
        header={<Text strong>{t("shop:promotions.listHeader")}</Text>}
        footer={
          <Text type="secondary" style={{ fontSize: 12 }}>
            {t("shop:promotions.listFooter")}
          </Text>
        }
        dataSource={promo}
        renderItem={(item) => (
          <List.Item>
            <PromoItem
              key={id}
              amount={item.amount}
              type={item.type}
              periodAmount={item.periodQuantity}
              operation={item.operation}
              periodType={periodType}
              currency={currency}
              price={price}
            />
          </List.Item>
        )}
      />
    );
  };

  return (
    <Container>
      <Content>
        <Wrapper>
          <img
            src={image}
            alt={`Alquilar ${title} - ${supplierData?.name}`}
            size="large"
            style={{
              height: isMobile ? 60 : 120,
              width: isMobile ? 60 : 120,
              minWidth: isMobile ? 60 : 120,
              objectFit: "contain",
            }}
            shape="square"
          />
          <div>
            <Title
              type="secondary"
              level={5}
              style={{ marginTop: 0, fontSize: 14 }}
            >
              {subtitle}
            </Title>
            {description !== "null" && (
              <RichTextRender content={description} style={{ fontSize: 12 }} />
            )}
          </div>
        </Wrapper>
      </Content>
      <Button
        type="primary"
        style={{ alignSelf: "end", justifySelf: "end" }}
        icon={<PlusOutlined />}
        onClick={() => {
          closeModal();
          trackEvent(clickEvents.ADD_PRODUCT_TO_BASKET, {
            page: "Quote",
            itemId: id,
            itemTitle: title,
            category: category,
            supplierId: supplierData._id,
            supplierName: supplierData.name,
          });
          dispatch(quoteAddItem(id, passengerId, rowId));
          const requiresExtraInfo = requiresInfo(category, supplierData._id);
          if (requiresExtraInfo.length > 0) {
            dispatch(
              setRequiredInfoMissing(requiresExtraInfo, passengerId, rowId)
            );
          }
        }}
      >
        Agregar
      </Button>
      <Disclaimer
        text={t("shop:disclaimerImageProduct")}
        bordered={false}
        size="small"
      />
      {promo.length > 0 && (
        <Collapse style={{ width: "100%" }}>
          <Collapse.Panel header={t("shop:promotions.pickPromo")}>
            {renderPromos()}
          </Collapse.Panel>
        </Collapse>
      )}
    </Container>
  );
};

export default ProductContentContainer;
