export const GET_SUPPLIER_GROUPS_REQUEST = "GET_SUPPLIER_GROUPS_REQUEST";
export const GET_SUPPLIER_GROUPS_SUCCESS = "GET_SUPPLIER_GROUPS_SUCCESS";
export const GET_SUPPLIER_GROUPS_FAILURE = "GET_SUPPLIER_GROUPS_FAILURE";

export const getSupplierGroupsRequest = (
  params,
  relationships,
  supplierId,
  sort = "desc",
  sortBy = "createdAt"
) => {
  return {
    type: GET_SUPPLIER_GROUPS_REQUEST,
    payload: {
      params,
      relationships,
      supplierId,
      sort,
      sortBy,
    },
  };
};

export const getSupplierGroupsSuccess = (data) => {
  return {
    type: GET_SUPPLIER_GROUPS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getSupplierGroupsFailure = (error) => {
  return {
    type: GET_SUPPLIER_GROUPS_FAILURE,
    payload: {
      error,
    },
  };
};
