import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Text from "antd/es/typography/Text";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../Redux/Selectors/appConfigSelector";
import mediaQueries from "../../utils/mediaQueries";
import baseTheme from "../../config/baseTheme";
import BootstrapIcon from "../BootstrapIcon/BoostrapIcon";
import { createPortal } from "react-dom";
import BranchSelectorModal from "./BranchSelectorModal";

const Container = styled.div`
  border: 1px solid ${baseTheme.borders.gray};
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  background-color: ${baseTheme.borders.light};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const Logo = styled.img`
  height: 44px;
  object-fit: contain;

  @media ${mediaQueries.smallMobile} {
    width: 60px !important;
    max-height: 30px;
  }

  @media ${mediaQueries.mobile} {
    width: 60px !important;
    max-height: 30px;
  }
`;

const branchSelectorEl = document.getElementById("context-menu");

const MultiBranchSelector = () => {
  const [isVisible, setVisible] = useState(false);
  const supplierData = useSelector(getSupplierData);

  const handleOnClick = () => {
    setVisible(!isVisible);
  };

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isVisible]);

  return (
    <Container onClick={handleOnClick}>
      <Logo src={supplierData?.logo} alt={supplierData?.name} />
      <Text strong>{supplierData?.branch}</Text>
      <BootstrapIcon iconName="chevron-down" style={{ paddingTop: 3 }} />
      {isVisible &&
        createPortal(
          <BranchSelectorModal
            onClose={handleOnClick}
            logo={supplierData?.logo}
          />,
          branchSelectorEl
        )}
    </Container>
  );
};

export default MultiBranchSelector;
