import { isString } from "lodash";
import breakpoints from "../../../utils/breakpoints";

export const getSlicedExperiences = (experiences, screenWidth) => {
  if (screenWidth < breakpoints.tablet) {
    return experiences.slice(0, 4);
  }
  if (screenWidth < breakpoints.desktop) {
    return experiences.slice(0, 2);
  }
  if (screenWidth < breakpoints.largeDesktop) {
    return experiences.slice(0, 3);
  }

  return experiences.slice(0, 4);
};

export const navigateExperience = (id, title, location) => {
  const findExperienceId = id === "6585af8a22446d1fcbcfaace";
  return findExperienceId
    ? "/shop?" + location
    : `/experiences/${id}/${sanitiseProduct(title)}`;
};

/**
 * @param {string} title
 * @returns {string}
 */
export const sanitiseProduct = (title) => {
  if (!isString(title)) {
    return "";
  }
  const sanitizedTitle = title.replace(/\//g, "-").replace(/,*\s/g, "-");
  return removeAccents(sanitizedTitle).toLowerCase();
};

/**
 * @param {string} title
 * @returns {string}
 */
export const removeAccents = (str) => {
  return isString(str)
    ? str
        .toLowerCase()
        .normalize("NFKD")
        .replace(/[\u0300-\u036f]/g, "")
    : null;
};
