import React from "react";
import { Alert } from "antd";
import { shallowEqual, useSelector } from "react-redux";
import { getBanners } from "../../Redux/Selectors/appConfigSelector";
import { isEmpty } from "lodash";

const BannerRenderContainer = ({ screen, ...restProps }) => {
  const banners = useSelector(getBanners, shallowEqual);
  if (isEmpty(screen)) {
    return null;
  }
  return (
    banners &&
    banners
      .filter((banner) => banner.pages.includes(screen))
      .map((filteredBanner, index) => (
        <Alert
          key={index}
          message={filteredBanner.message}
          description={filteredBanner.description}
          type={filteredBanner.type}
          closable={filteredBanner.closable}
          {...restProps}
        />
      ))
  );
};

export default BannerRenderContainer;
