import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import Typography from "antd/es/typography/Typography";
import {
  FeatureWrapper,
  FeatureItem,
  IconWrapper,
  RightArrow,
  Text,
} from "./FeaturesContent.styles";
import useIsMobile from "../../../../hooks/useIsMobile";

const FeaturesContent = ({ features }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <FeatureWrapper>
      {features.map(
        ({ icon, description, rightArrow, mobileDescription }, index) => (
          <>
            <FeatureItem key={index}>
              <IconWrapper>{icon}</IconWrapper>
              <Typography align="center">
                <Text color={theme.text.gray}>
                  {isMobile ? mobileDescription : description}
                </Text>
              </Typography>
            </FeatureItem>
            <RightArrow>{rightArrow}</RightArrow>
          </>
        )
      )}
    </FeatureWrapper>
  );
};

FeaturesContent.defaultProps = {
  features: [],
};

FeaturesContent.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
};

export default FeaturesContent;
