import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { SET_NORMALIZED_DATA } from "../Actions/normalizrActions";

const INITIAL_STATE = {};

export const applyReducer = (entity, entities = {}) => {
  const obj = Immutable(entity);
  if (obj) {
    return {
      ...entities,
      ...Immutable.merge(entities, obj, { deep: true }),
    };
  }
  return { ...entities };
};

const setNormalizedData = (state, action) => {
  const {
    data: { entities },
  } = action.payload;

  return {
    ...applyReducer(entities, state),
  };
};

export default createReducer(INITIAL_STATE, {
  [SET_NORMALIZED_DATA]: setNormalizedData,
});
