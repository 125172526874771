import { Card } from "antd";
import React from "react";
import Title from "antd/es/typography/Title";
import styled from "styled-components";
import Paragraph from "antd/es/typography/Paragraph";
import BootstrapIcon from "../../../../components/BootstrapIcon/BoostrapIcon";
import baseTheme from "../../../../config/baseTheme";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProductsEmptyPlaceholder = () => {
  return (
    <Card>
      <Content>
        <BootstrapIcon
          iconName="snow2"
          style={{ fontSize: 48, color: baseTheme.text.gray }}
        />
        <Title level={5} style={{ fontSize: 16, marginTop: 10 }}>
          Comienza agregando productos
        </Title>
        <Paragraph style={{textAlign: 'center'}}>
          Puedes seleccionar tus productos favoritos seleccionando las distintas
          categorías.
        </Paragraph>
      </Content>
    </Card>
  );
};

export default ProductsEmptyPlaceholder;
