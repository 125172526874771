import React from "react";
import { useTranslation } from "react-i18next";
import Form from "antd/es/form/Form";
import CountrySelector from "../CountrySelector/CountrySelector";
import useWebsiteRegion from "../../hooks/useWebsiteCountry";
import { RENTALS, isRental } from "../../helpers/rentals";
import { shallowEqual, useSelector } from "react-redux";
import { getSupplierData } from "../../Redux/Selectors/appConfigSelector";

const CountrySelectForm = ({ name, size, required = true }) => {
  const { t } = useTranslation("shop");
  const { region } = useWebsiteRegion();
  const supplierData = useSelector(getSupplierData, shallowEqual);

  return (
    <Form.Item
      name={name}
      label={t("form.country")}
      rules={[
        {
          required,
        },
      ]}
    >
      <CountrySelector
        size={size}
        disabled={
          isRental(supplierData._id, RENTALS.spAventuras) &&
          region === "argentina"
        }
      />
    </Form.Item>
  );
};
export default CountrySelectForm;
