import React from "react";
import { useTranslation } from "react-i18next";
import jsonExperiences from "../../../../../config/spConfig.json";
import { TabsCustomer } from "./ExperienceTabs.styles";
import { generateChildren, generateTab } from "./helpers";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

// TODO: Refactor when jsonExperience.experiences is deleted
const ExperienceTabs = ({ experienceId, itinerary }) => {
  const { t } = useTranslation(["experiences"]);
  const itineraryTab = generateTab({ itinerary: [...itinerary] }, 1);
  let tabs = jsonExperiences.experiences[experienceId]?.tabs || [];

  if (!isEmpty(itinerary)) {
    tabs = isEmpty(tabs)
      ? [itineraryTab]
      : tabs.map((tab) => (tab.children === "itinerary" ? itineraryTab : tab));
  }

  if (isEmpty(tabs)) return null;

  const items = tabs.map((item) => {
    return { ...item, label: t(item.label), children: generateChildren(item) };
  });
  return (
    <TabsCustomer type="light-primary" defaultActiveKey="1" items={items} />
  );
};

export default ExperienceTabs;

ExperienceTabs.propTypes = {
  experienceId: PropTypes.string,
};
