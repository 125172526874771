import { schema } from "normalizr";
import { supplierSchema } from "./supplierSchema";

export const websiteConfigSchema = new schema.Entity(
  "websiteConfig",
  {
    supplier: supplierSchema,
  },
  {
    idAttribute: "_id",
  }
);

export const websiteConfigsSchema = [websiteConfigSchema];
