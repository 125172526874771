import { schema } from "normalizr";

export const supplierSchema = new schema.Entity(
  "suppliers",
  {},
  {
    idAttribute: "_id",
  }
);

export const suppliersSchema = [supplierSchema];
