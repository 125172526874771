import React, { useState } from "react";
import ModalSwitchLanguage from "../LanguageModal/ModalSwitchLanguage";
import ButtonLanguage from "./ButtonLanguage";
import useWebsiteLanguage from "../../hooks/useWebsiteLanguage";

const ButtonLanguageContainer = () => {
  const [modalVisibleLanguage, setModalVisibleLanguage] = useState(false);
  const { language, setLanguage } = useWebsiteLanguage();

  const handleCloseModal = () => {
    setModalVisibleLanguage(!modalVisibleLanguage);
  };

  const handleClickLanguage = (val) => {
    const selectedLanguage = val.key;
    setLanguage(selectedLanguage);
    handleCloseModal();
  };

  return (
    <>
      <ButtonLanguage onClick={handleCloseModal} language={language} />
      <ModalSwitchLanguage
        visible={modalVisibleLanguage}
        onCancel={handleCloseModal}
        onChangeLanguage={handleClickLanguage}
      />
    </>
  );
};

export default ButtonLanguageContainer;
