import {
  SAVE_MAIN_MEMBER_INFO,
  SIGN_IN_CUSTOMER_SUCCESS,
} from "../actions/MainMemberActions";

const INITIAL_STATE = {
  data: null,
  loggedIn: false,
};

const mainMemberReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_MAIN_MEMBER_INFO:
      return {
        ...state,
        data: action.payload,
      };
    case SIGN_IN_CUSTOMER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    default:
      return state;
  }
};

export default mainMemberReducer;
