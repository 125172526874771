import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Divider from "antd/es/divider";
import Descriptions from "antd/es/descriptions";
import GearItem from "../GearItem/GearItem";

const Wrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  border-radius: 10px;
  padding: 12px 20px;
`;

const CardTitle = styled.h5`
  font-size: 16px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;

const SummaryCard = ({ name, personalInfo, gear, ...restProps }) => {
  const renderDescriptionItem = (key, label, value) => (
    <Descriptions.Item
      key={key}
      style={{
        paddingBottom: 2,
        fontSize: "14px",
      }}
      label={label}
    >
      {value}
    </Descriptions.Item>
  );

  return (
    <Wrapper {...restProps}>
      <CardTitle level={6}>{name}</CardTitle>
      <Descriptions
        column={2}
        size="small"
        style={{
          padding: "0 17px",
        }}
        labelStyle={{
          fontWeight: "bold",
        }}
      >
        {personalInfo.map(({ label, value }, index) =>
          renderDescriptionItem(index, label, value)
        )}
      </Descriptions>
      <StyledDivider />
      {gear.map((gearProps, index) => (
        <GearItem key={index} {...gearProps} />
      ))}
    </Wrapper>
  );
};

SummaryCard.defaultProps = {
  personalInfo: [],
  gear: [],
};

SummaryCard.propTypes = {
  name: PropTypes.string.isRequired,
  personalInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  gear: PropTypes.arrayOf(PropTypes.shape(GearItem.propTypes)),
};

export default SummaryCard;
