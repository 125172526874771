import { denormalize } from "normalizr";
import { isEmpty } from "lodash";
import {
  experienceSchema,
  experiencesSchema,
} from "../Schemas/experiencesSchema";

export const getExperienceById = (state, id) => {
  if (id) {
    const denormalizeData = denormalize(id, experienceSchema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};

export const getExperiences = (state, arrayOfIds) => {
  if (arrayOfIds) {
    const denormalizeData = denormalize(
      arrayOfIds,
      experiencesSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return [];
  }
  return [];
};
