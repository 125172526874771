import React from "react";
import PropTypes from "prop-types";
import Image from "antd/es/image";
import { useTranslation } from "react-i18next";

const Images = ({ images, style }) => {
  const { t } = useTranslation();
  const { height } = { ...style };

  return (
    <>
      {images?.map((imgSrc, index) => (
        <Image
          style={{
            ...style,
            height: `calc(${height}/${images?.length})`,
          }}
          src={imgSrc}
          alt={t("experience")}
          key={index}
        />
      ))}
    </>
  );
};

Images.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
};

export default Images;
