import React from "react";
import CollapseAntd from "antd/es/collapse";
import PropTypes from "prop-types";
import { Panel, PanelTitle } from "./Collapse.styles";
import { UpOutlined } from "@ant-design/icons";

const Collapse = ({ panels, ...restProps }) => {
  return (
    <CollapseAntd
      ghost
      bordered={false}
      expandIconPosition="end"
      expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} />}
      {...restProps}
    >
      {panels.map(({ key, title, content }) => (
        <Panel
          key={key}
          header={
            <PanelTitle strong level={5}>
              {title}
            </PanelTitle>
          }
        >
          {content}
        </Panel>
      ))}
    </CollapseAntd>
  );
};

Collapse.defaultProps = {
  panels: [],
};

Collapse.propTypes = {
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.node,
    })
  ),
};

export default Collapse;
