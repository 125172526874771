import React from "react";
import {
  Container,
  Content,
  Item,
  ItemsList,
  LabelContainer,
  StyledLabel,
} from "./Includes.styles";
import { useTranslation } from "react-i18next";

const Includes = ({ label, items, icon }) => {
  const { t } = useTranslation("experiences");
  return (
    <Container>
      <Content>
        <LabelContainer>
          <StyledLabel>{label}</StyledLabel>
        </LabelContainer>
        <ItemsList>
          {items.map((item) => {
            return (
              <Item>
                {icon} {t(`extras.${item}`)}
              </Item>
            );
          })}
        </ItemsList>
      </Content>
    </Container>
  );
};

export default Includes;
