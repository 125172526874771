const environment = {
  demoHostname: process.env.REACT_APP_HOSTNAME_ENDPOINT || "localhost",
  api: {
    apiBaseUrl: process.env.REACT_APP_BACKEND_ENDPOINT,
  },
  googleMaps: {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  },
};

export default environment;
