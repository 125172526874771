import React from "react";
import Select from "antd/es/select/index";
import countryCodes from "../../config/countries";
import { shallowEqual, useSelector } from "react-redux";
import { getWebsiteLanguage } from "../../Redux/Selectors/languageSelector";
import { languageConstants } from "../../helpers/tools";

const PhoneCountryCodeSelect = ({
  width = "100%",
  showCountryName = true,
  ...props
}) => {
  const language = useSelector(getWebsiteLanguage, shallowEqual);
  const getCountryName = (code) => {
    return language === languageConstants.esAR ? code.name_es : code.name_en;
  };

  const filterOption = (input, option) => {
    return (
      option.label?.toLowerCase().includes(input.toLowerCase()) ||
      option.value?.includes(input)
    );
  };
  return (
    <Select
      filterOption={filterOption}
      showSearch={true}
      style={{ width }}
      {...props}
    >
      {countryCodes().map((code) => {
        return (
          <Select.Option
            key={`${code.dial_code}-${code.code}`}
            value={`${code.dial_code}-${code.code}`}
            label={getCountryName(code)}
          >
            {showCountryName && getCountryName(code)} {code.dial_code}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default PhoneCountryCodeSelect;
