import styled from "styled-components";

export const Logo = styled.img`
  display: block;
  max-width: 100%;
  max-height: 142px;
  margin: auto;
`;

export const PoweredBy = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text.gray};
  text-align: center;
  margin-top: 30px;

  a {
    color: inherit;
  }
`;
