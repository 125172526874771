import { denormalize } from "normalizr";
import { isEmpty, sortBy } from "lodash";
import { variantSchema, variantsSchema } from "../Schemas/variantSchema";

export const getDefaultVariant = (productId, state) => {
  const { variants } = state.entities;
  const variant = Object.values(variants).filter((variant) => {
    return variant.product === productId;
  });
  if (variant && variant.length > 0) {
    return getVariantById(state, sortBy(variant, "_id")[0]._id);
  }
  return null;
};

export const getVariantById = (state, id) => {
  if (id) {
    const denormalizeData = denormalize(id, variantSchema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};

export const getVariants = (state, arrayOfIds) => {
  if (arrayOfIds) {
    const denormalizeData = denormalize(
      arrayOfIds,
      variantsSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return [];
  }
  return [];
};
