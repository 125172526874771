import { Form } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  calculateItemsLength,
  normalizeProducts,
} from "../../../../helpers/tools";
import { getProducts } from "../../../../Redux/Selectors/productSelectors";
import { getSearchValues } from "../../../../Redux/Selectors/searchSelectors";
import { getSupplierProfileData } from "../../../../Redux/Selectors/supplierSelectors";
import {
  quoteBuildAddPassengers,
  quoteBuildRemovePassenger,
} from "../../redux/actions/shopActions";
import {
  getPassengersData,
  getSummary,
} from "../../redux/selectors/ShopSelectors";

const PassengersContainer = ({ children }) => {
  const [footerIsVisible, setFooterVisible] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchValues = useSelector(getSearchValues);
  const quoteSummary = useSelector(getSummary);
  const passengersData = useSelector(getPassengersData);
  const supplierData = useSelector(getSupplierProfileData);
  const selectedProducts = useSelector((state) =>
    getProducts(state, normalizeProducts(quoteSummary?.products))
  );
  const [addPeopleForm] = Form.useForm();

  const handleOnFinish = (values) => {
    const passengers = values.names.reduce((acc, value, i) => {
      acc[i] = {
        id: i,
        name: value,
      };
      return acc;
    }, {});
    dispatch(quoteBuildAddPassengers(passengers));
  };

  const handleOnRemovePassenger = (field) => {
    dispatch(quoteBuildRemovePassenger(field.name));
  };

  const handleOnAddPeopleContinue = async () => {
    addPeopleForm
      .validateFields()
      .then(() => {
        addPeopleForm.submit();
        navigate(`/shop/assignments${window.location.search}`);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const handleOnBlur = () => {
    setFooterVisible(true);
  };

  const handleOnFocus = () => {
    setFooterVisible(false);
  };

  return children({
    quoteSummary,
    addPeopleForm,
    handleOnFinish,
    handleOnRemovePassenger,
    passengersData,
    handleOnAddPeopleContinue,
    itemsLength: calculateItemsLength(quoteSummary?.products),
    handleOnBlur,
    handleOnFocus,
    footerIsVisible,
    supplierData,
    selectedProducts,
    searchValues,
  });
};

export default PassengersContainer;
