import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Text from "antd/es/typography/Text";
import { isEmpty } from "lodash";
import {
  getProductsByPassenger,
  getSubtotalByPassenger,
} from "../../redux/QuoteSelectors";
import { getCurrencyConfig, renderPrice } from "../../../../helpers/tools";
import { getSupplierSettings } from "../../../../Redux/Selectors/appConfigSelector";
import { getSearchValueByKey } from "../../../../Redux/Selectors/searchSelectors";
import BundleDetails from "../BundleDetails/BundleDetails";
import { getPassengerInfo } from "../../helpers/helpers";
import {
  CardHeader,
  StyledLi,
  StyledText,
  StyledUl,
} from "./PassengerCard.styles";

const PassengerCard = ({ passenger, supplierId }) => {
  const settings = useSelector(getSupplierSettings);
  const { t } = useTranslation("shop");
  const generalDates = useSelector((state) =>
    getSearchValueByKey(state, "dates")
  );
  const subtotal = useSelector((state) =>
    getSubtotalByPassenger(state, passenger.id)
  );
  const products = useSelector((state) =>
    getProductsByPassenger(state, passenger.id)
  );

  const renderDates = (dates) => {
    if (dates.from || dates.to) {
      return (
        <StyledText fontSize={12}>
          {moment(dates.from).format("DD/MM")} -{" "}
          {moment(dates.to).format("DD/MM")}
        </StyledText>
      );
    }
    return (
      <StyledText fontSize={12}>
        {moment(generalDates.from).format("DD/MM")} -{" "}
        {moment(generalDates.to).format("DD/MM")}
      </StyledText>
    );
  };

  const renderRequiredItems = () => {
    if (!isEmpty(passenger.required)) {
      return (
        <StyledUl>
          {passenger.required?.map((require) => {
            const { label, value } = getPassengerInfo(require, passenger, t);
            return (
              <li key={require} style={{ paddingLeft: 10, marginTop: 0 }}>
                <StyledText fontSize={12}>
                  - {label}:{" "}
                  <StyledText strong fontSize={12}>
                    {value}
                  </StyledText>
                </StyledText>
              </li>
            );
          })}
        </StyledUl>
      );
    }
    return null;
  };

  const renderTitle = (product, index) => {
    const variant = passenger.products[index]?.variants.filter(
      (variant) => product._id === variant?.product?._id
    );
    return (
      <Text strong style={{ fontSize: 14 }}>
        {product.title} -{" "}
        <Text strong style={{ fontSize: 14 }}>
          {t(variant?.[0]?.variant?.unit)} {variant?.[0]?.variant?.value}
        </Text>
      </Text>
    );
  };

  return (
    <Card bodyStyle={{ padding: 10 }}>
      {!isEmpty(passenger.products) && (
        <CardHeader>
          <StyledText strong>{passenger.name}</StyledText>
          <StyledText strong>
            {getCurrencyConfig(settings.currency).symbol}{" "}
            {renderPrice(subtotal, settings.currency)}
          </StyledText>
        </CardHeader>
      )}
      {renderRequiredItems()}

      <StyledUl>
        {products.map((data, index) => {
          const isBundle = !isEmpty(data.product?.products);
          return (
            <StyledLi key={`${data.product._id}-${index}`}>
              <div
                style={{
                  whiteSpace: "break-spaces",
                  maxWidth: "100%",
                }}
              >
                {renderTitle(data.product, index)}
                {isBundle && (
                  <BundleDetails
                    products={data.product.products}
                    showVariants
                    variants={passenger.products[index]?.variants}
                  />
                )}
                <ol style={{ paddingLeft: 10, paddingTop: 20 }}>
                  {renderDates(data.dates)}
                </ol>
              </div>
              <StyledText fontSize={14}>
                {getCurrencyConfig(settings.currency).symbol}{" "}
                {renderPrice(data.total, settings.currency)}
              </StyledText>
            </StyledLi>
          );
        })}
      </StyledUl>
    </Card>
  );
};

export default PassengerCard;
