import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import environment from "../../config/environment";
import {
  getVariantsFromProductSuccess,
  GET_VARIANTS_FROM_PRODUCT_REQUEST,
  setProductsVariants,
} from "../../features/ShopPage/redux/actions/shopActions";
import { get } from "../../services/Api";
import { normalizeData } from "../Actions/normalizrActions";
import { variantsSchema } from "../Schemas/variantSchema";

function* handleGetProducts(action) {
  const { productId } = action.payload;
  try {
    //Migrate to baseDomain from env
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/product/${productId}/variants`
    );
    const { data } = response.data;
    yield put(normalizeData(data, variantsSchema));
    const variants = data.map((variants) => variants._id);
    yield put(getVariantsFromProductSuccess(variants));
    yield put(setProductsVariants(variants, productId));
  } catch (err) {
    console.log(err);
  }
}

export default function* variantsSaga() {
  yield all([takeEvery(GET_VARIANTS_FROM_PRODUCT_REQUEST, handleGetProducts)]);
}
