import { size } from "lodash";

export const columnsCreator = (images) => {
  if (!images) return [];
  const columnsMaxChildrenQuantity = [1, 1, 2, 1];
  const columns = [];
  let currentIndex = 0;
  for (let col = 0; col < size(columnsMaxChildrenQuantity); col++) {
    for (let i = 0; i < columnsMaxChildrenQuantity[col]; i++) {
      if (currentIndex >= size(images)) {
        return columns;
      }
      if (!columns[col]) {
        columns[col] = [];
      }
      columns[col].push(images[currentIndex]);
      currentIndex++;
    }
  }
  return columns;
};
