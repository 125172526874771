import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ContainerImages = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap};
  margin-top: 50px;
  flex-wrap: wrap;
  max-width: 1150px;
`;

export const Image = styled.img`
  height: 60px;
  object-fit: contain;

  @media ${mediaQueries.desktop} {
    max-width: 200px;
    height: 80px;
    object-fit: contain;
  }
`;
