import React, { useRef } from "react";
import PropTypes from "prop-types";
import { isEmpty, isFunction } from "lodash";
import Tooltip from "antd/es/tooltip";
import { ReactComponent as DiscountIcon } from "../../../../assets/discount.svg";
import Button from "../../../../components/Button/Button";
import {
  Wrapper,
  GroupedInput,
  Input,
  RemoveButton,
  ErrorMessage,
  SuccessMessage,
} from "./DiscountInput.styles";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const DiscountInput = ({
  discount,
  onApplyDiscount,
  loading,
  onRemoveDiscount,
  discountError,
}) => {
  const { t } = useTranslation(["common", "shop"]);
  const codeInput = useRef(null);

  const hasDiscount = !isEmpty(discount);

  const handleApplyDiscount = () => {
    const code = codeInput.current.input.value;
    isFunction(onApplyDiscount) && onApplyDiscount(code);
  };

  const handleRemoveDiscount = () => {
    isFunction(onRemoveDiscount) && onRemoveDiscount();
  };

  return (
    <Wrapper>
      <GroupedInput>
        {hasDiscount ? (
          <Input
            readOnly={hasDiscount}
            bordered={false}
            prefix={`${t("shop:discountCode")}:`}
            suffix={
              <Tooltip title={t("shop:removeDiscount")}>
                <RemoveButton type="link" onClick={handleRemoveDiscount}>
                  <CloseCircleOutlined />
                </RemoveButton>
              </Tooltip>
            }
            value={discount.code}
          />
        ) : (
          <>
            <Input
              placeholder={t("shop:applyDiscountCode")}
              bordered={false}
              prefix={<DiscountIcon />}
              ref={codeInput}
              disabled={loading}
            />
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleApplyDiscount}
              loading={loading}
            >
              {loading ? "" : t("common:apply")}
            </Button>
          </>
        )}
      </GroupedInput>
      {discountError && (
        <ErrorMessage>*{t("shop:invalidDiscountCode")}</ErrorMessage>
      )}
      {hasDiscount && (
        <SuccessMessage>
          {t("shop:discountAmount", { amount: discount.amountDiscount })}
        </SuccessMessage>
      )}
    </Wrapper>
  );
};

DiscountInput.defaultProps = {
  loading: false,
  discount: null,
};

DiscountInput.propTypes = {
  loading: PropTypes.bool,
  discount: PropTypes.shape({
    code: PropTypes.string.isRequired,
    amountDiscount: PropTypes.string.isRequired,
  }),
  onApplyDiscount: PropTypes.func,
  onRemoveDiscount: PropTypes.func,
  DiscountError: PropTypes.bool,
};

export default DiscountInput;
