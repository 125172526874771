import React from "react";
import styled from "styled-components";
import Paragraph from "antd/es/typography/Paragraph";
import SnackBar from "../SnackBar/SnackBar";
import { Image } from "antd";
import ProductAddCounter from "../ProductAddCounter/ProductAddCounter";
import useDaysLength from "../../../../hooks/useDaysLength";
import { getCurrencyConfig } from "../../../../helpers/tools";
import Disclaimer from "../../../../components/Disclaimer/Disclaimer";
import baseTheme from "../../../../config/baseTheme";
import { useTranslation } from "react-i18next";
import { PRODUCT_TYPE } from "../../constants";
import Price from "../../../../components/Price/Price";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import { useLocation } from "react-router-dom";
import { getSummary } from "../../../ShopPage/redux/selectors/ShopSelectors";
import queryString from "query-string";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: start;
  box-sizing: border-box;
  background-color: white;
  padding-left: 15px;
  padding-right: 15px;
  gap: 5px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 18px -8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 18px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 18px -8px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  width: 100%;
`;

const Subtitle = styled(Paragraph)`
  margin: 5px 0px 0px !important;
  font-weight: 400;
  font-size: 14px;
`;

const QuoteBuilderRow = ({
  productId,
  title,
  subtitle,
  promos,
  price,
  currency,
  handleOnSubstractProduct,
  handleOnAddProduct,
  quantity,
  handleOnDetailsClick,
  thumbnail,
  type,
  minimumPeriodQuantity,
  rentalPeriodType,
  showSnackbar = true,
  hidePrice = false,
}) => {
  const { t } = useTranslation("shop");
  const supplierData = useSelector(getSupplierData);
  const location = useLocation();
  const quoteSummary = useSelector(getSummary);
  const params = queryString.parse(location.search);
  const daysLength = useDaysLength(
    quoteSummary.period,
    params.from,
    params.to,
    params.pickupTime,
    params.dropoffTime
  );
  return (
    <Container>
      <InfoContainer>
        <ColumnContainer>
          <Paragraph style={{ margin: 0, fontWeight: "600", fontSize: 16 }}>
            {title}
          </Paragraph>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {showSnackbar && (
            <SnackBar
              handleOnDetailsClick={handleOnDetailsClick}
              productId={productId}
              showPromos={promos?.length > 0}
              direction="vertical"
              productType={type}
            />
          )}
        </ColumnContainer>
        {thumbnail && (
          <Image
            src={thumbnail}
            alt={`Alquilar ${title} - ${supplierData.name}`}
            height="90px"
            width="90px"
            preview={false}
            style={{
              objectFit: "contain",
            }}
          />
        )}
      </InfoContainer>
      <RowContainer justifyContent="flex-end">
        {minimumPeriodQuantity > daysLength && (
          <Disclaimer
            bordered={false}
            color={baseTheme.colors.red}
            text={t("minimumBooking", {
              period: t(rentalPeriodType, {
                count: minimumPeriodQuantity,
              }).toLowerCase(),
            })}
          />
        )}
      </RowContainer>
      <RowContainer justifyContent={!hidePrice ? "space-between" : "flex-end"}>
        {!hidePrice && (
          <Price
            price={price}
            currencySymbol={getCurrencyConfig(currency)?.symbol}
            currency={currency}
            showTotal
            rentalPeriodType={rentalPeriodType}
          />
        )}
        {type !== PRODUCT_TYPE.EXPERIENCE && (
          <ProductAddCounter
            onSubstractProduct={handleOnSubstractProduct}
            onAddProduct={handleOnAddProduct}
            quantity={quantity}
            itemId={productId}
            showCounter={type !== PRODUCT_TYPE.EXPERIENCE}
            disabledAdd={daysLength < minimumPeriodQuantity}
            type={type}
          />
        )}
      </RowContainer>
    </Container>
  );
};

export default QuoteBuilderRow;
