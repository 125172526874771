import { DatePicker } from "antd";
import styled from "styled-components";
import locale from "antd/es/date-picker/locale/es_ES";
import React from "react";
import moment from "moment";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const DateProductPicker = ({ dateDisabled, onChange, from, to }) => {
  const handleOnChange = (key, val) => {
    onChange(key, moment(val.format("YYYY-MM-DD")));
  };
  return (
    <Container>
      <DatePicker
        locale={locale}
        placeholder="Inicio"
        format={"DD/MM/YYYY"}
        size="small"
        disabled={dateDisabled}
        onSelect={(val) => handleOnChange("from", val)}
        disabledDate={(current) => to ? to < current : moment() > current}
        allowClear={false}
      />
      <DatePicker
        locale={locale}
        placeholder="Fin"
        format={"DD/MM/YYYY"}
        size="small"
        disabled={dateDisabled}
        onSelect={(val) => handleOnChange("to", val)}
        disabledDate={(current) => from ? from >= current : moment() > current}
        allowClear={false}
      />
    </Container>
  );
};

export default DateProductPicker;
