import { useEffect } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  normalizeProducts,
  toISOFormatString,
  buildItems,
} from "../../../../helpers/tools";
import { getPricingRequest } from "../../redux/actions/shopActions";
import {
  getPricingData,
  getPricingIsFetching,
  getSummary,
  getItems,
} from "../../redux/selectors/ShopSelectors";
import { useLocation } from "react-router-dom";
import useCalculateDates from "../../../../hooks/useCalculateDays";
import useDiscounts from "./hooks/useDiscounts";
import { isEmpty } from "lodash";
import { listTypes } from "../../utils";
import { getWebsiteCurrency } from "../../../../Redux/Selectors/currencySelector";

const PricingContainer = ({ children }) => {
  const dispatch = useDispatch();
  const currency = useSelector(getWebsiteCurrency);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const quoteSummary = useSelector(getSummary);
  const pricingData = useSelector(getPricingData);
  const isPricingFetching = useSelector(getPricingIsFetching);
  const selectedProducts = useSelector((state) =>
    getItems(state, normalizeProducts(quoteSummary?.products))
  );
  const {
    handleApplyDiscount,
    discountCode,
    handleRemoveDiscount,
    firstApplicationIntent,
  } = useDiscounts();

  const { pricingFrom, pricingTo } = useCalculateDates({
    fromDate: params.from,
    toDate: params.to,
    fromTime: params.pickupTime,
    toTime: params.dropoffTime,
    period: quoteSummary.period,
  });

  useEffect(() => {
    if (
      Object.keys(quoteSummary?.products).length > 0 &&
      params.pickupTime &&
      params.dropoffTime &&
      quoteSummary.period
    ) {
      dispatch(
        getPricingRequest(
          toISOFormatString(pricingFrom),
          toISOFormatString(pricingTo),
          quoteSummary.period,
          buildItems(quoteSummary, selectedProducts),
          {},
          currency,
          true,
          listTypes.PRODUCT,
          discountCode
        )
      );
    }
  }, [
    params.pickupTime,
    params.dropoffTime,
    quoteSummary.products,
    params.from,
    params.to,
    quoteSummary.period,
    discountCode,
    currency,
  ]);

  return children({
    pricingData,
    isPricingFetching,
    discount: {
      handleApplyDiscount,
      handleRemoveDiscount,
      discountCode,
      discountError: isEmpty(pricingData?.discounts) && firstApplicationIntent,
    },
  });
};

export default PricingContainer;
