import { omit, union, uniqueId } from "lodash";
import {
  ADD_PASSENGER_QUOTE,
  EDIT_PASSENGER_VALUE,
  GET_BUNDLES_QUOTE_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  GET_PRODUCTS_QUOTE_REQUEST,
  GET_PRODUCTS_QUOTE_SUCCESS,
  GET_QUOTE_PRICING_REQUEST,
  GET_QUOTE_PRICING_SUCCESS,
  QUOTE_ADD_ITEM,
  QUOTE_SUBSTRACT_ITEM,
  REMOVE_PASSENGER_QUOTE,
  RESET_INDIVIDUAL_DATES,
  RESET_PROCESS,
  SEND_EMAIL_SUCCESS,
  SET_ACTIVE_PASSENGER,
  SET_INDIVIDUAL_DATES,
  SET_REQUIRED_INFO_MISSING,
  SELECT_VARIANT,
  RESET_REQUIRED_INFO,
  GET_QUOTE_PRICING_BY_PRODUCT_SUCCESS,
} from "./QuoteActions";

const initialId = uniqueId();

export const INITIAL_STATE = {
  passengers: {
    [initialId]: {
      id: initialId,
      name: "",
      products: {},
      required: [],
      experience: "beginner",
    },
  },
  categories: [],
  products: [],
  bundles: [],
  productsFetching: false,
  activePassenger: 1,
  processFinished: false,
};

export const quoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_PROCESS:
      return {
        ...INITIAL_STATE,
        categories: state.categories,
        products: state.products,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        processFinished: true,
      };
    case SET_REQUIRED_INFO_MISSING:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...state.passengers[action.payload.id],
            required: union(
              state.passengers[action.payload.id].required,
              action.payload.category
            ),
          },
        },
      };
    case RESET_REQUIRED_INFO:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...state.passengers[action.payload.id],
            required: [],
          },
        },
      };
    case SET_ACTIVE_PASSENGER:
      return {
        ...state,
        activePassenger: action.payload.activePassenger,
      };
    case ADD_PASSENGER_QUOTE:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...action.payload,
            experience: "beginner",
          },
        },
      };
    case REMOVE_PASSENGER_QUOTE:
      return {
        ...state,
        passengers: omit(state.passengers, action.payload.id),
      };
    case EDIT_PASSENGER_VALUE:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...state.passengers[action.payload.id],
            ...action.payload.value,
          },
        },
      };
    case GET_PRODUCTS_QUOTE_REQUEST:
      return {
        ...state,
        productsFetching: true,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.results,
      };
    case GET_PRODUCTS_QUOTE_SUCCESS:
      return {
        ...state,
        products: action.payload.results,
        productsFetching: false,
      };
    case GET_QUOTE_PRICING_BY_PRODUCT_SUCCESS:
      return {
        ...state,
        products: addPriceToProduct(
          state.products,
          action.payload.productId,
          action.payload.results
        ),
        bundles: addPriceToProduct(
          state.bundles,
          action.payload.productId,
          action.payload.results
        ),
      };
    case GET_BUNDLES_QUOTE_SUCCESS:
      return {
        ...state,
        bundles: action.payload.results,
      };
    case QUOTE_ADD_ITEM:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...state.passengers[action.payload.id],
            products: {
              ...state.passengers[action.payload.id].products,
              [action.payload.rowId]: {
                productId: action.payload.productId,
                dates: {
                  from: null,
                  to: null,
                },
                total: 0,
                rowId: action.payload.rowId,
                variantSelected: false,
              },
            },
          },
        },
      };
    case QUOTE_SUBSTRACT_ITEM:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...state.passengers[action.payload.id],
            products: omit(
              state.passengers[action.payload.id].products,
              action.payload.rowId
            ),
          },
        },
      };
    case GET_QUOTE_PRICING_REQUEST:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...state.passengers[action.payload.id],
            products: {
              ...state.passengers[action.payload.id].products,
              [action.payload.rowId]: {
                ...state.passengers[action.payload.id].products[
                  action.payload.rowId
                ],
                fetchingPrice: true,
              },
            },
          },
        },
      };
    case GET_QUOTE_PRICING_SUCCESS:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...state.passengers[action.payload.id],
            products: {
              ...state.passengers[action.payload.id].products,
              [action.payload.rowId]: {
                ...state.passengers[action.payload.id].products[
                  action.payload.rowId
                ],
                total: action.payload.total,
                currency: action.payload.hostCurrency,
                timeQuantity: action.payload.timeQuantity,
                fetchingPrice: false,
              },
            },
          },
        },
      };
    case SET_INDIVIDUAL_DATES:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...state.passengers[action.payload.id],
            products: {
              ...state.passengers[action.payload.id].products,
              [action.payload.rowId]: {
                ...state.passengers[action.payload.id].products[
                  action.payload.rowId
                ],
                dates: {
                  ...state.passengers[action.payload.id].products[
                    action.payload.rowId
                  ].dates,
                  [action.payload.key]: action.payload.date,
                },
              },
            },
          },
        },
      };
    case RESET_INDIVIDUAL_DATES:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...state.passengers[action.payload.id],
            products: {
              ...state.passengers[action.payload.id].products,
              [action.payload.rowId]: {
                ...state.passengers[action.payload.id].products[
                  action.payload.rowId
                ],
                dates: {
                  from: null,
                  to: null,
                },
              },
            },
          },
        },
      };
    case SELECT_VARIANT:
      return {
        ...state,
        passengers: {
          ...state.passengers,
          [action.payload.id]: {
            ...state.passengers[action.payload.id],
            products: {
              ...state.passengers[action.payload.id].products,
              [action.payload.rowId]: {
                ...state.passengers[action.payload.id].products[
                  action.payload.rowId
                ],
                variantSelected: !action.payload.autoSelect,
                variants: {
                  ...(state.passengers[action.payload.id].products[
                    action.payload.rowId
                  ].variants || {}),
                  [action.payload.uniqueKey]: action.payload.variants,
                },
              },
            },
          },
        },
      };
    default:
      return state;
  }
};

const addPriceToProduct = (products, productId, results) => {
  const clonedArray = [...products];
  const objIndex = clonedArray.findIndex((obj) => obj._id === productId);

  if (objIndex !== -1) {
    clonedArray[objIndex].pricing = results;
  }
  return clonedArray;
};
