import { isEmpty } from "lodash";
import { put, all, takeLatest, call } from "redux-saga/effects";
import { normalizeParams } from "../../helpers/tools";
import { get } from "../../services/Api";
import environment from "../../config/environment";
import {
  GET_PRESALE_BY_ID_REQUEST,
  getPreSaleByIdFailure,
  getPreSaleByIdSuccess,
} from "./PreSaleActions";
import { preSaleSchema } from "../../Redux/Schemas/preSaleSchema";
import { normalizeData } from "../../Redux/Actions/normalizrActions";
import { getConfigs } from "../../services/httpService";

function* handleGetPreSaleById(action) {
  const { id } = action.payload;

  try {
    const token = yield call(getConfigs);
    const filters = normalizeParams({
      _id: [id],
    });
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/presales`,
      {
        ...token,
        params: {
          ...filters,
        },
      }
    );

    if (response.status === 200) {
      const preSale = response.data.data.docs[0];
      if (!isEmpty(preSale)) {
        yield put(normalizeData(response.data.data.docs[0], preSaleSchema));
        yield put(getPreSaleByIdSuccess(id));
      } else {
        yield put(getPreSaleByIdSuccess(null));
      }
    }
  } catch (err) {
    console.log(err);
    yield put(getPreSaleByIdFailure(err));
  }
}

export default function* preSaleSaga() {
  yield all([takeLatest(GET_PRESALE_BY_ID_REQUEST, handleGetPreSaleById)]);
}
