import React, { useEffect, useState } from "react";
import useVariants from "../../../../hooks/useVariants";
import Select from "antd/es/select";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getItemById } from "../../../ShopPage/redux/selectors/ShopSelectors";
import { Typography } from "antd";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import baseTheme from "../../../../config/baseTheme";
import { requiresVariant } from "../../tools/helpers";
import { UNASSIGNMENT_VARIANT } from "../../../CheckInPage/constants";
const { Text } = Typography;

const PickQuoteVariant = ({ productId, showTitle, handleSelectedVariant }) => {
  const variants = useVariants(productId);
  const supplierData = useSelector(getSupplierData);
  const [hasError, setHasError] = useState(true);
  const product = useSelector((state) => getItemById(state, productId));
  const { t } = useTranslation();
  const handleChange = (value, autoSelect) => {
    setHasError(false);
    handleSelectedVariant(value, autoSelect);
  };

  const defaultVariant = (variants) => {
    return variants.filter(
      (variant) =>
        variant?.type === "none" ||
        variant?.value.toLowerCase() === UNASSIGNMENT_VARIANT.toLowerCase()
    )[0];
  };

  const requestVariant = requiresVariant(
    supplierData?._id,
    product?.category?._id
  );

  useEffect(() => {
    if (variants.length > 0 && defaultVariant(variants)) {
      const variant = defaultVariant(variants);
      // Auto select variant for bundles => true
      handleChange(variant.id, true);
    }
  }, [JSON.stringify(variants)]);

  const renderError = () => {
    if (hasError) {
      return (
        <div>
          <span
            style={{
              fontSize: 12,
              fontWeight: 600,
              marginLeft: 20,
              color: baseTheme.colors.red,
            }}
          >
            Selecciona el talle
          </span>
        </div>
      );
    }
    return null;
  };

  const renderPickVariant = () => {
    return (
      <Select
        data-testid="quote-pick-variant-select"
        onChange={(val) => handleChange(val, false)}
        placeholder={t(`shop:assign.pickVariant`)}
        style={{ marginLeft: 20, marginTop: 10, width: "100%" }}
        status={hasError ? "error" : ""}
      >
        {variants.length === 0 && (
          <Select.Option value={""}>{t("loading")}</Select.Option>
        )}

        {variants?.map((variant, index) => (
          <Select.Option key={index} value={variant?.id}>
            {t(`shop:${variant.unit}`)} {variant.value}
          </Select.Option>
        ))}
      </Select>
    );
  };
  return (
    <div
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: `1px solid ${baseTheme.borders.gray}`,
      }}
    >
      {showTitle && <Text style={{ fontSize: 12 }}>- {product?.title}</Text>}
      {requestVariant && renderPickVariant()}
      {requestVariant && renderError()}
    </div>
  );
};

export default PickQuoteVariant;
