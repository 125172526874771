import { RENTALS, isRental } from "../../../helpers/rentals";
import customOrderCategories from "./customOrderCategories.json";
import { uniq, flatten, isNil, isEmpty } from "lodash";

const requiresInfoData = {
  ski: ["height", "foot"],
  snowboard: ["height", "foot"],
  bootsApreski: ["foot"],
  bootsTrekking: ["foot"],
  bootsSnowboard: ["foot"],
  bootsSki: ["foot"],
  surfTables: ["height"],
  bundles: ["height", "foot"],
  shoes: ["foot"],
  bicycles: ["height"],
  trekkingSticks: ["height"],
  eBikes: ["height"],
  snowshoes: ["foot"],
  skiSticks: ["height"],
  splitboard: ["height", "foot"],
  bundle: ["height"],
};

export const config = {
  foot: {
    min: 11,
    max: 35,
  },
  height: {
    min: 90,
    max: 220,
  },
};

export const requiresInfo = (category, supplierId) => {
  if (
    (isRental(supplierId, RENTALS.travesiaCatedral) ||
      isRental(supplierId, RENTALS.fronteraSur) ||
      isRental(supplierId, RENTALS.windfly)) &&
    requiresInfoData[category]
  ) {
    return requiresInfoData[category].filter((info) => info !== "foot");
  } else {
    return requiresInfoData[category] ? requiresInfoData[category] : [];
  }
};

export const getRequiredFieldsByCategory = (categories, supplierId) => {
  const mapCategories = categories?.map((category) =>
    requiresInfo(category, supplierId)
  );
  return uniq(flatten(mapCategories));
};

export const sortCategories = (categories) => {
  return categories.sort((a, b) => {
    const orderA = customOrderCategories[a] || Infinity;
    const orderB = customOrderCategories[b] || Infinity;
    return orderA - orderB;
  });
};

export const requiresVariant = (supplierId, productCategoryId) => {
  return (
    isRental(supplierId, RENTALS.windfly) &&
    requiresSizeVariant(productCategoryId)
  );
};

export const requiresSizeVariant = (productCategoryId) => {
  return (
    productCategoryId === "61b8b601e15a0c13a0d86cd4" ||
    productCategoryId === "61b8b3fb09e5c6139949f2ce" ||
    productCategoryId === "61b8b7c399d697140841c6f5"
  );
};

export const categorySections = Object.freeze({
  equipment: [
    "ski",
    "bootsSki",
    "skiSticks",
    "snowboard",
    "bootsSnowboard",
    "helmets",
    "goggles",
    "sleds",
    "snowshoes",
  ],
  dress: ["jacket", "jackets", "trousers", "gloves", "bootsApreski"],
  others: ["accessories"],
});

export const sortCategorizedProducts = (categorizedProducts, t) => {
  const sectionOrder = ["equipment", "dress", "others"];
  const altOrder = { ski_alt: -2, snowboard_alt: -1 };

  const orderedProducts = sectionOrder.reduce((acc, section) => {
    if (categorizedProducts[section]) {
      acc[section] = categorizedProducts[section].sort((a, b) => {
        if (!isNil(altOrder[a.label]) || !isNil(altOrder[b.label])) {
          return (altOrder[a.label] || 0) - (altOrder[b.label] || 0);
        }
        return 0;
      });
    }
    return acc;
  }, {});

  return Object.keys(orderedProducts).reduce((sortedCategories, category) => {
    sortedCategories[category] = orderedProducts[category].sort((a, b) => {
      return a.label.localeCompare(b.label, undefined, { sensitivity: "base" });
    });
    return sortedCategories;
  }, {});
};

export const getCategoryLabel = (key, type) => {
  const altKeys = ["ski", "snowboard"];
  const altTypes = ["bundle", "combined"];

  if (altTypes.includes(type) && altKeys.includes(key)) {
    return `${key}_alt`;
  }

  return key;
};

export const categorizeProducts = (
  categories = [],
  bundlesWithOutCategory = [],
  t
) => {
  const result = Object.keys(categorySections).reduce(
    (acc, section) => {
      acc[section] = [];
      return acc;
    },
    { others: [] }
  );

  categories.forEach((key) => {
    const { category, type } = key;
    const section = Object.keys(categorySections).find((sec) =>
      categorySections[sec].includes(category)
    );
    (section ? result[section] : result.others).push({
      category,
      label: getCategoryLabel(t(`common:categoriesList.${category}`), type),
    });
  });
  if (!isEmpty(bundlesWithOutCategory)) {
    bundlesWithOutCategory.forEach((bundle) => {
      result.others.push({
        category: "combo",
        label: bundle.title,
        _id: bundle._id,
      });
    });
  }
  Object.keys(result).forEach((section) => {
    if (isEmpty(result[section])) {
      delete result[section];
    }
  });

  return result;
};

export const combineCategories = (items = []) => {
  const categoryMap = new Map();

  items.forEach((item) => {
    if (item.category && item.category.value && item.visibleOnWeb) {
      const type = item.type === "product" ? "product" : "bundle";
      const category = item.category.value;

      if (categoryMap.has(category)) {
        const existingEntry = categoryMap.get(category);
        if (existingEntry.type !== type) {
          categoryMap.set(category, { type: "combined", category });
        }
      } else {
        categoryMap.set(category, { type, category });
      }
    }
  });

  return Array.from(categoryMap.values());
};

export const findBundleById = (id, bundlesWithOutCategory = []) => {
  if (!isEmpty(bundlesWithOutCategory)) {
    return bundlesWithOutCategory.find((bundle) => bundle._id === id);
  } else {
    return null;
  }
};
