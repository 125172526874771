import { Form, Tabs } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CUSTOMER_TYPE } from "../../../../components/FormInputs/constants";
import { useSupplierConfig } from "../../../../hooks/useSupplierConfig";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import BusinessForm from "./BusinessForm";
import PersonalForm from "./PersonalForm";

const MainMemberForm = ({ onFormIsValid, handleOnContinue }) => {
  const { t } = useTranslation("shop");
  const supplierData = useSelector(getSupplierData);
  const [businessForm] = Form.useForm();
  const [personalForm] = Form.useForm();

  const { createCustomer, createCompany } = useSupplierConfig();
  const [customerType, setCustomerType] = useState(CUSTOMER_TYPE.PERSONAL);

  const onChange = () => {
    if (customerType === CUSTOMER_TYPE.PERSONAL) {
      personalForm
        .validateFields()
        .then(() => {
          onFormIsValid(true);
          personalForm.submit();
        })
        .catch((err) => onFormIsValid(false));
    } else {
      businessForm
        .validateFields()
        .then(() => {
          onFormIsValid(true);
          businessForm.submit();
        })
        .catch(() => onFormIsValid(false));
    }
  };

  const items = [
    {
      key: CUSTOMER_TYPE.PERSONAL,
      label: t("personal"),
      children: (
        <PersonalForm
          onSubmit={handleOnContinue}
          form={personalForm}
          onChange={onChange}
          initialValues={{
            phonePrefix: createCustomer.phonePrefixByRegion,
            type: createCustomer.personalIdType,
            nationality: createCustomer.nationality,
            createdBy: supplierData._id,
            customerType: CUSTOMER_TYPE.PERSONAL,
          }}
        />
      ),
    },
    {
      key: CUSTOMER_TYPE.BUSINESS,
      label: t("business"),
      children: (
        <BusinessForm
          formEnabled={false}
          onSubmit={handleOnContinue}
          onChange={onChange}
          form={businessForm}
          initialValues={{
            phonePrefix: createCompany.phonePrefixByRegion,
            type: createCompany.companyIdType,
            nationality: createCompany.nationality,
            createdBy: supplierData._id,
            customerType: CUSTOMER_TYPE.BUSINESS,
          }}
        />
      ),
    },
  ];

  const onChangeTab = (key) => {
    personalForm.resetFields();
    businessForm.resetFields();
    onFormIsValid(false);
    setCustomerType(key);
  };

  return (
    <>
      <Tabs
        defaultActiveKey={CUSTOMER_TYPE.PERSONAL}
        items={items}
        onChange={onChangeTab}
      />
    </>
  );
};

export default MainMemberForm;
