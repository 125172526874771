import { InfoCircleOutlined, TagsOutlined } from "@ant-design/icons";
import Button from "antd/es/button";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 5px;
`;

const StyledButton = styled(Button)`
  padding: 0px;
  font-size: 14px;
  font-weight: 300;
  height: 20px;
`;

const SnackBar = ({
  handleOnDetailsClick,
  productId,
  showPromos,
  productType,
}) => {
  const { t } = useTranslation(["shop", "common"]);
  return (
    <Container>
      <StyledButton
        type="link"
        icon={<InfoCircleOutlined />}
        onClick={() => handleOnDetailsClick(productId, "details", productType)}
      >
        {t("gearInfo")}
      </StyledButton>
      {showPromos && (
        <StyledButton
          type="link"
          icon={<TagsOutlined />}
          onClick={() => handleOnDetailsClick(productId, "promos", productType)}
        >
          {t("shop:promotions.pickPromo")}
        </StyledButton>
      )}
    </Container>
  );
};

export default SnackBar;
