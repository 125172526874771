import { denormalize } from "normalizr";
import { isEmpty } from "lodash";
import { supplierSchema, suppliersSchema } from "../Schemas/supplierSchema";
import { ActivitiesAndCategories } from "../../helpers/tools";

export const getSupplierPath = (supplier) => {
  if (!isEmpty(supplier)) {
    return supplier?.slug
      ? `/${supplier?.slug}`
      : `/profile/supplier/${supplier?._id}`;
  }
  return "";
};

export const getSupplierById = (state, id) => {
  if (id) {
    const denormalizeData = denormalize(id, supplierSchema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};

export const getSuppliers = (state, arrayOfIds) => {
  if (arrayOfIds) {
    const denormalizeData = denormalize(
      arrayOfIds,
      suppliersSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return [];
  }
  return [];
};

export const getSupplierActivities = (state) => {
  const { activities = [] } = state.pages.shop.products;
  var filteredActivities = ActivitiesAndCategories.filter((localActivity) => {
    return activities.some((remoteActivity) => {
      return remoteActivity === localActivity.value;
    });
  });
  return filteredActivities;
};

export const getSupplierProfileData = (state) => {
  return state.context.supplier?.data;
};
