import React from "react";
import PropTypes from "prop-types";
import Spin from "antd/es/spin";
import { OverlayContainer, SpinContainer } from "./PageLoader.styles";

const PageLoader = ({ loading, children }) => {
  if (!loading) {
    return children;
  }

  return (
    <OverlayContainer>
      <SpinContainer>
        <Spin size="large" />
      </SpinContainer>
    </OverlayContainer>
  );
};

PageLoader.defaultProps = {
  loading: true,
};

PageLoader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default PageLoader;
