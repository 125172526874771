import React from "react";
import PropTypes from "prop-types";
import "bootstrap-icons/font/bootstrap-icons.css";

const BootstrapIcon = ({ iconName, className, ...restProps }) => {
  const classes = `bi bi-${iconName} ${className}`.trim();
  return <span className={classes} {...restProps} />;
};

BootstrapIcon.defaultProps = {
  className: "",
};

BootstrapIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default BootstrapIcon;
