import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "antd/es/modal";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import baseTheme from "../../config/baseTheme";
import { Button } from "antd";

const StyledTitle = styled(Title)`
  margin-bottom: 10px;
`;

const Description = styled(Text)`
  display: block;
  margin-bottom: 20px;
`;

const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  text-align: center;
`;

const BaseModal = ({
  onOk,
  textOk,
  onCancel,
  textCancel,
  showFooter,
  children,
  title,
  description,
  open,
  isLoading,
  maskClosable = true,
}) => {
  const { t } = useTranslation(["common"]);

  const renderModalFooter = [
    <ModalFooter>
      <Button
        onClick={onCancel}
        styles={{ borderColor: baseTheme.borders.gray }}
        shape="default"
      >
        {textCancel || t("common:back")}
      </Button>
      <Button onClick={onOk} shape="default" loading={isLoading} type="primary">
        {textOk || t("common:continue")}
      </Button>
    </ModalFooter>,
  ];

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={showFooter ? renderModalFooter : null}
      onOk={onOk}
      centered
      maskClosable
    >
      <StyledTitle>{title}</StyledTitle>
      <Description>{description}</Description>
      {children}
    </Modal>
  );
};

BaseModal.defaultProps = {
  open: false,
  showFooter: true,
};

BaseModal.propTypes = {
  open: PropTypes.bool,
  showFooter: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  textOk: PropTypes.string,
  textCancel: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default BaseModal;
