import { isEmpty } from "lodash";
import { ITEM_TYPE } from "../../constants";

export const setFieldKey = ({ productId, type, bundleId, state, fieldKey }) => {
  if (fieldKey?.includes("-")) return fieldKey;
  else {
    const id = type === ITEM_TYPE.BUNDLE ? bundleId : productId;
    const productsQuantity = Object.values(state).map((product) => product._id);
    const occurrences = productsQuantity?.reduce((count, currentId) => {
      if (currentId === id) {
        return count + 1;
      }
      return count;
    }, -1);

    return {
      rowFieldKey: `${id}-${occurrences + 1}`,
      bundleVariantFieldKey: `${productId}-${bundleId}-${occurrences + 1}`,
    };
  }
};

/**
 *
 * @param {object} stock
 * @param {Array.<{
 *  variantId: string,
 *  stock: number,
 * }>} results
 * @returns {object}
 */
export const setVariantsStock = (stock, results) => {
  const stockCopy = { ...stock };
  const newStock = results.reduce(
    (prevObject, { variantId, stock }) => ({
      ...prevObject,
      [variantId]: stock,
    }),
    {}
  );
  return {
    ...stockCopy,
    ...newStock,
  };
};

export const parseTransactionErrorMessages = (msg) => {
  if (isEmpty(msg)) return null;
  if (msg === "Item stock unavailable") {
    return "itemStockUnavailable";
  }
  return msg;
};
