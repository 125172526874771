import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";
import Mixpanel from "./services/mixpanel";
import { ConfigProvider as TemplateConfigProvider } from "antd";
import { getImageUrl } from "./helpers/cloudinary";
import configureStore from "./Redux/Store";
import { getAntdThemeConfig } from "./helpers/antd.theme";
import { isRental, RENTALS } from "./helpers/rentals";
import LanguageProvider from "./providers/LanguageProvider";
import AppContainer from "./containers/AppContainer";
import PageLoader from "./components/PageLoader/PageLoader";
import ShopPageScreen from "./features/ShopPage/ShopPageScreen";
import HomePageScreeen from "./features/HomePage/HomePageScreen";
import PassengersScreen from "./features/ShopPage/root/PassengersScreen";
import AssignmentsScreen from "./features/ShopPage/root/AssignmentsScreen";
import CheckoutScreen from "./features/ShopPage/root/CheckoutScreen";
import TransactionPaymentScreen from "./features/PaymentPage/PaymentScreen";
import TransactionResultScreen from "./features/TransactionPage/TransactionResultScreen";
import QuoteScreen from "./features/QuotePage/QuoteScreen";
import ExperienceDetailScreen from "./features/ExperiencesPage/root/ExperienceDetailScreen";
import ExperiencesListPageScreen from "./features/ExperiencesPage/root/ExperiencesListPageScreen";
import ExperienceParticipantsScreen from "./features/ExperiencesPage/root/ExperienceParticipantsScreen";
import SpacesListPageScreen from "./features/SpacesPage/root/SpacesListPageScreen";
import SpaceDetailScreen from "./features/SpacesPage/detail/SpaceDetailScreen";
import SpaceParticipantsScreen from "./features/SpacesPage/root/SpaceParticipantsScreen";
import CheckInScreen from "./features/CheckInPage/CheckInScreen";
import SuccessScreen from "./features/CheckInPage/router/SuccessScreen";
import { sanitizeRichText } from "./helpers/supplier";
import SelectRegion from "./components/SelectRegion/SelectRegion";
import { isEmpty } from "lodash";

const store = configureStore();
Mixpanel();

const isCheckin = window.location.pathname.includes("checkin");

const router = (isIframe, isCheckin) => {
  const shopRouter = [
    {
      path: "/quote",
      element: <QuoteScreen isIframe={isIframe} />,
    },
    {
      path: "/experiences",
      element: <ExperiencesListPageScreen isIframe={isIframe} />,
    },
    {
      path: "/spaces",
      element: <SpacesListPageScreen isIframe={isIframe} />,
    },
    {
      path: "/spaces/:id",
      element: <SpaceDetailScreen isIframe={isIframe} />,
    },
    {
      path: "/spaces/:id/*",
      element: <SpaceDetailScreen isIframe={isIframe} />,
    },
    {
      path: "/spaces/:id/passengers",
      element: <SpaceParticipantsScreen isIframe={isIframe} />,
    },
    {
      path: "/experiences/:id",
      element: <ExperienceDetailScreen isIframe={isIframe} />,
    },
    {
      path: "/experiences/:id/*",
      element: <ExperienceDetailScreen isIframe={isIframe} />,
    },
    {
      path: "/experiences/:id/passengers",
      element: <ExperienceParticipantsScreen isIframe={isIframe} />,
    },
    {
      path: "/shop",
      element: <ShopPageScreen isIframe={isIframe} />,
    },
    {
      path: "/shop/passengers",
      element: <PassengersScreen isIframe={isIframe} />,
    },
    {
      path: "/shop/assignments",
      element: <AssignmentsScreen isIframe={isIframe} />,
    },
    {
      path: "/shop/checkout",
      element: <CheckoutScreen isIframe={isIframe} />,
    },
    {
      path: "/transaction/:id/pay/*",
      element: <TransactionPaymentScreen isIframe={isIframe} />,
    },
    {
      path: "/payment/:paymentId/process",
      element: <TransactionResultScreen isIframe={isIframe} />,
    },
  ];
  if (isIframe) {
    return createBrowserRouter(shopRouter);
  }
  if (isCheckin) {
    return createBrowserRouter([
      {
        path: "/checkin/*",
        element: <CheckInScreen isCheckin />,
      },
      {
        path: "/checkin/done",
        element: <SuccessScreen isCheckin />,
      },
    ]);
  }
  return createBrowserRouter([
    {
      path: "/",
      element: <HomePageScreeen />,
    },
    ...shopRouter,
  ]);
};

function App() {
  const isIframe = window.location !== window.parent.location;
  return (
    <Provider store={store}>
      <HelmetProvider>
        <AppContainer>
          {({
            appConfig,
            theme,
            supplierData,
            language,
            domain,
            region,
            scripts,
          }) => (
            <PageLoader loading={appConfig.loading || !appConfig.ready}>
              <ThemeProvider theme={theme}>
                {supplierData &&
                  isRental(supplierData?._id, RENTALS.spAventuras) &&
                  !region && <SelectRegion idSupplier={supplierData._id} />}
                <TemplateConfigProvider theme={getAntdThemeConfig(theme)}>
                  {supplierData && language && (
                    <LanguageProvider locale={language}>
                      <Helmet>
                        <title>{supplierData?.name}</title>
                        <link rel="canonical" href={`https://${domain}`} />
                        {supplierData?.description && (
                          <meta
                            name="description"
                            content={sanitizeRichText(
                              JSON.parse(supplierData?.description)
                            )}
                          />
                        )}
                        <link
                          rel="icon"
                          href={getImageUrl(supplierData?.logo, {
                            width: 32,
                            height: 32,
                            format: "ico",
                            crop: "pad",
                          })}
                        />
                        <link
                          rel="apple-touch-icon"
                          href={getImageUrl(supplierData?.logo, {
                            width: 192,
                            height: 192,
                            format: "png",
                            crop: "pad",
                          })}
                        />
                        {isRental(
                          supplierData._id,
                          RENTALS.travesiaCatedral
                        ) && (
                          <meta
                            name="facebook-domain-verification"
                            content="ac6ycpo5z90ugwha1s0z9hzbw6x5b8"
                          />
                        )}
                        {!isEmpty(scripts) &&
                          scripts.map((script, index) => (
                            <script key={index}>{script}</script>
                          ))}
                        {isRental(
                          supplierData?._id,
                          RENTALS.campingLaIndia
                        ) && (
                          <script
                            type="text/javascript"
                            id="hs-script-loader"
                            async
                            defer
                            src="//js.hs-scripts.com/43826064.js"
                          ></script>
                        )}
                        {isRental(
                          supplierData._id,
                          RENTALS.travesiaCatedral
                        ) && (
                          <script
                            async
                            src="https://www.googletagmanager.com/gtag/js?id=G-14V4GSW2DJ"
                          ></script>
                        )}
                        {isRental(
                          supplierData._id,
                          RENTALS.travesiaCatedral
                        ) && (
                          <script>
                            {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-14V4GSW2DJ');`}
                          </script>
                        )}
                        {isRental(
                          supplierData._id,
                          RENTALS.travesiaCatedral
                        ) && (
                          <script>
                            {`!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '304663761985417');
    fbq('track', 'PageView');`}
                          </script>
                        )}
                        {isRental(
                          supplierData._id,
                          RENTALS.travesiaCatedral
                        ) && (
                          <script>
                            {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5023315,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
                          </script>
                        )}
                        {isRental(
                          supplierData._id,
                          RENTALS.travesiaCatedral
                        ) && (
                          <noscript>
                            {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=304663761985417&ev=PageView&noscript=1"/>`}
                          </noscript>
                        )}
                        {isRental(supplierData._id, RENTALS.ananda) && (
                          <script
                            async
                            src="https://www.googletagmanager.com/gtag/js?id=G-YX501MV5MN"
                          ></script>
                        )}
                        {isRental(supplierData._id, RENTALS.ananda) && (
                          <script>
                            {`window.dataLayer = window.dataLayer || []; 
                              function gtag(){dataLayer.push(arguments);}
                              gtag('js', new Date());
                              gtag('config', 'G-YX501MV5MN');`}
                          </script>
                        )}
                        {isRental(supplierData._id, RENTALS.spAventuras) && (
                          <script
                            async
                            src="https://www.googletagmanager.com/gtag/js?id=G-L1STW8FBFH"
                          ></script>
                        )}
                        {isRental(supplierData._id, RENTALS.spAventuras) && (
                          <script>
                            {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-L1STW8FBFH');`}
                          </script>
                        )}
                      </Helmet>
                      <RouterProvider router={router(isIframe, isCheckin)} />
                    </LanguageProvider>
                  )}
                </TemplateConfigProvider>
              </ThemeProvider>
            </PageLoader>
          )}
        </AppContainer>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
