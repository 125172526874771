import { useTranslation } from "react-i18next";
import {
  Container,
  Items,
  PriceDetail,
  StyledTitle,
} from "./TicketsSummary.styles";
import useIsMobile from "../../../../hooks/useIsMobile";
import { renderPrice } from "../../../../helpers/tools";
import { Divider } from "antd";
import Text from "antd/es/typography/Text";

const TicketsSummary = ({ pricingData, deleteText }) => {
  const { t } = useTranslation(["experiences"]);
  const { isMobile } = useIsMobile();
  const { symbol, currency } = pricingData?.currency || {};
  return (
    <Container isMobile={isMobile}>
      <StyledTitle level={5}>{t("summary")}</StyledTitle>
      <Items>
        {pricingData?.items.map((price, index) => (
          <PriceDetail key={index}>
            {t(`booking.${price.title.split("Tarifa ")[1]}`, {
              count: price.quantity,
            })}
            <Text style={{ fontSize: 14 }} delete={deleteText}>
              <strong style={{ margin: "0px 5px 0px 15px" }}>{symbol}</strong>
              {renderPrice(price.lineTotal, currency)}
            </Text>
          </PriceDetail>
        ))}
      </Items>
      <Divider style={{ margin: 10 }} />
    </Container>
  );
};

export default TicketsSummary;
