import React from "react";
import PropTypes from "prop-types";
import Space from "antd/es/space";
import { ButtonLink } from "./FooterMenu.styles";

const FooterMenu = ({ links, ...restProps }) => {
  return (
    <Space size="middle" direction="vertical" {...restProps}>
      {links.map(({ title, onClick }, index) => (
        <ButtonLink key={index} type="link" onClick={onClick}>
          {title}
        </ButtonLink>
      ))}
    </Space>
  );
};

FooterMenu.defaultProps = {
  links: [],
};

FooterMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
};

export default FooterMenu;
