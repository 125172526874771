import React from "react";
import PropTypes from "prop-types";
import Space from "antd/es/space";
import { SocialButton } from "./SocialButtons.styles";

const SocialButtons = ({ links, iconSize, ...restProps }) => {
  return (
    <Space {...restProps}>
      {links.map(({ icon, link }, index) => (
        <SocialButton
          key={index}
          href={link}
          target="_blank"
          $iconSize={iconSize}
          type="link"
          icon={icon}
        />
      ))}
    </Space>
  );
};

SocialButtons.defaultProps = {
  direction: "horizontal",
  size: "middle",
  wrap: true,
  iconSize: null,
  links: [],
};

SocialButtons.propTypes = {
  ...Space.propTypes,
  iconSize: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
};

export default SocialButtons;
