import React, { createContext, useReducer } from "react";
import { CityReducer, INITIAL_STATE } from "./CityReducer";
import { get } from "../../../services/httpService";

export const SEARCH_CITIES_REQUEST = "SEARCH_CITIES_REQUEST";
export const SEARCH_CITIES_SUCCESS = "SEARCH_CITIES_SUCCESS";
export const SEARCH_CITIES_FAILURE = "SEARCH_CITIES_FAILURE";

export const CityContext = createContext(INITIAL_STATE);

export const CityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CityReducer, INITIAL_STATE);

  const getCityByName = async (query = "") => {
    dispatch({ type: SEARCH_CITIES_REQUEST });
    try {
      const response = await get(
        `https://t94vq8b6m1.execute-api.sa-east-1.amazonaws.com/cities?search=${query}`
      );
      dispatch({
        type: SEARCH_CITIES_SUCCESS,
        payload: { results: response.data },
      });
    } catch (error) {
      dispatch({
        type: SEARCH_CITIES_FAILURE,
        payload: { error: error.message },
      });
    }
  };

  return (
    <CityContext.Provider
      value={{
        cities: state.cities,
        fetching: state.fetching,
        getCityByName,
      }}
    >
      {children}
    </CityContext.Provider>
  );
};
