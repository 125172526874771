import styled, { css } from "styled-components";
import PaymentMethodCard from "./PaymentMethodCard";
import SummaryPaymentDetail from "./SummaryPaymentDetail";

export const StyledPaymentMethodCard = styled(PaymentMethodCard)`
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.2s;

  ${({ selected }) =>
    selected
      ? css`
          border-color: ${({ theme }) => theme.colors.primary};
          box-shadow: 0px 5px 7px -3px rgba(0, 0, 0, 0.1);
        `
      : null}
`;

export const PageFooter = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.borders.gray};
`;

export const StyledSummaryPaymentDetail = styled(SummaryPaymentDetail)`
  margin-bottom: 20px;
`;
