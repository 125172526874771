import Divider from "antd/es/divider";
import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 10px;
  padding: 12px 20px;
`;

export const CardTitle = styled.h5`
  font-size: 20px;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  text-align: center;
`;

export const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;
