import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReferralsRequest } from "../features/ShopPage/redux/actions/shopActions";
import {
  getReferralsData,
  getReferralsIsFetching,
} from "../features/ShopPage/redux/selectors/ShopSelectors";

const useReferrals = () => {
  const dispatch = useDispatch();
  const referrals = useSelector(getReferralsData);
  const isReferralFetching = useSelector(getReferralsIsFetching);
  useEffect(() => {
    dispatch(getReferralsRequest());
  }, []);

  return { referrals, isReferralFetching };
};

export default useReferrals;
