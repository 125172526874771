import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getPaymentCreationState } from "../redux/paymentSelectors";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import { getPaymentById } from "../../../Redux/Selectors/paymentSelector";
import { PAYMENT_METHOD_LABELS } from "../PaymentMethods";
import {
  getPaymentMethodsFromSupplierRequest,
  transactionPaymentCreateRequest,
} from "../redux/paymentActions";
import { Card } from "antd";
import CustomBookingId from "../../../components/CustomBookingId/CustomBookingId";
import Section from "../components/Section";
import { StyledSummaryPaymentDetail } from "../components/PaymentMethodSelection.styles";
import Paragraph from "antd/es/typography/Paragraph";
import RichTextRender from "../../../components/RichTextRender/RichTextRender";

const CashPaymentMethodScreen = ({
  transactionId,
  transactionCustomId,
  amount,
  currency,
  onReady,
  summary,
  description,
}) => {
  const { t } = useTranslation(["shop", "common"]);
  const dispatch = useDispatch();
  const {
    paymentId,
    loading: paymentCreationLoading,
    error: paymentCreationError,
    success: paymentCreationSuccess,
  } = useSelector(getPaymentCreationState, shallowEqual);
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const payment = useSelector((state) => getPaymentById(state, paymentId));

  useEffect(() => {
    dispatch(
      transactionPaymentCreateRequest({
        provider: PAYMENT_METHOD_LABELS.shopCash,
        amount,
        currency,
        transactionId,
      })
    );
    dispatch(getPaymentMethodsFromSupplierRequest(supplierData._id));
  }, []);

  useEffect(() => {
    if (
      !payment ||
      paymentCreationLoading ||
      (!paymentCreationSuccess && !paymentCreationError)
    ) {
      return;
    }
    onReady();
  }, [payment, paymentCreationSuccess, paymentCreationError]);

  return (
    <Card>
      <CustomBookingId customId={transactionCustomId} />
      {summary && (
        <Section title={t("paymentSummary")}>
          <StyledSummaryPaymentDetail {...summary} />
        </Section>
      )}
      {description && (
        <RichTextRender
          style={{ fontSize: 18, lineHeight: 1.5 }}
          content={description}
        />
      )}
      <Paragraph>{t("messagePaymentCash")}</Paragraph>
      <Paragraph strong>{t("reservationNoConfirm")}</Paragraph>
    </Card>
  );
};

export default CashPaymentMethodScreen;
