import { CommentOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React from "react";
import { useTranslation } from "react-i18next";
import { languageConfigKeys } from "../../helpers/tools";
import ListLanguage from "./ListLanguage";

const LanguageTab = ({ onChangeLanguage }) => {
  const { t } = useTranslation(["experiences"]);

  const renderLanguages = () => {
    const languages = languageConfigKeys();
    return languages.map((item) => (
      <ListLanguage
        onClick={() => onChangeLanguage({ key: item.code })}
        label={`${item.name} ${item.symbol}`}
        icon={item.flagIcon()}
        key={item.code}
      />
    ));
  };

  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <span>
            <CommentOutlined />
            {t("language_other")}
          </span>
        }
        key="1"
      >
        {renderLanguages()}
      </TabPane>
    </Tabs>
  );
};

export default LanguageTab;
