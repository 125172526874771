import { Card, Modal, Space } from "antd";
import Text from "antd/es/typography/Text";
import { useSelector } from "react-redux";
import {
  getAssigmentsData,
  getPassengersData,
} from "../../ShopPage/redux/selectors/ShopSelectors";
import { Button } from "../../../components/Button/Button.styles";
import { ITEM_ACTIONS } from "../constants";
import { getProductsAssigned } from "../helpers";
import { useTranslation } from "react-i18next";
import { AssignmentRow } from "./AssignmentModal.styles";

const AssignmentModal = ({ onClose, onAssignMember, action, productId }) => {
  const { t } = useTranslation("shop");
  const passengers = useSelector(getPassengersData);
  const assignments = useSelector(getAssigmentsData);
  const defaultValue = getProductsAssigned(passengers, assignments, productId);

  const handleOnSelect = (id) => {
    onAssignMember(id);
    onClose();
  };

  return (
    <Modal
      closable
      open
      footer={null}
      onCancel={onClose}
      bodyStyle={{ paddingTop: 30, paddingBottom: 30 }}
      title={
        action === ITEM_ACTIONS.add
          ? t("assign.whoWillUseIt")
          : t("assign.unnasignEquipment")
      }
    >
      <Card bordered>
        {action === ITEM_ACTIONS.add && (
          <Space direction="vertical" style={{ width: "100%" }}>
            {passengers?.map((passenger) => (
              <AssignmentRow>
                <Text>{passenger.name}</Text>
                <Button
                  type={"primary"}
                  onClick={() => handleOnSelect(passenger.id)}
                >
                  {t("assign.assign")}
                </Button>
              </AssignmentRow>
            ))}
          </Space>
        )}
        {action === ITEM_ACTIONS.substract && (
          <Space direction="vertical">
            {defaultValue?.map((passenger) => (
              <AssignmentRow>
                <Text>{passenger.name}</Text>
                <Button
                  type={"light-primary"}
                  onClick={() => handleOnSelect(passenger.id)}
                >
                  {t("assign.remove")}
                </Button>
              </AssignmentRow>
            ))}
          </Space>
        )}
      </Card>
    </Modal>
  );
};

export default AssignmentModal;
