import { Tabs } from "antd";
import styled, { css } from "styled-components";
import { lighten } from "polished";

export const TabsCustomer = styled(Tabs)`
  .ant-tabs-nav-wrap {
    border: 1px solid rgba(218, 216, 216, 0.63);
    border-radius: 5px;
    padding: 3px;
  }
  .ant-tabs-tab {
    padding: 18px;
  }
  .ant-tabs-tab-btn {
    color: black !important;
    font-weight: lighter;
    font-size: 17px;
    transition-duration: 0s;
  }
  .ant-tabs-tab-active {
    border-radius: 3px;
    ${({ type, theme }) =>
      type === "light-primary"
        ? css`
            color: #fff;
            font-weight: 600;
            background-color: ${lighten(0.1, theme.colors.primary)};
            &:hover {
              background-color: ${lighten(0.15, theme.colors.primary)};
            }
          `
        : null}
  }
  .ant-tabs-ink-bar {
    display: none;
  }
`;
