import React from "react";
import PropTypes from "prop-types";
import Container from "../../../../components/Container/Container";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { Logo } from "./AboutUsContent.styles";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";

const AboutUsContent = ({ children, logo, supplierId }) => {
  const supplierData = useSelector(getSupplierData);
  return (
    <Container>
      <Row gutter={[30, 30]} align="top">
        <Col span={24} lg={14}>
          {children}
        </Col>
        <Col span={24} lg={10}>
          <Row align="top">
            <Logo
              style={{
                borderRadius: 10,
              }}
              src={logo}
              alt={supplierData?.name}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

AboutUsContent.propTypes = {
  children: PropTypes.node,
  logo: PropTypes.string.isRequired,
};

export default AboutUsContent;
