export const PRIMARY_LINK_TYPES = {
  EXPERIENCE: "experience",
  SPACE: "space",
};

export const TRANSACTION_TYPE = {
  RENTAL: "rental",
  EXPERIENCE: "experience",
};

export const REVIEWS_PROVIDER = {
  GOOGLE: "google",
  TRIPADVISOR: "tripadvisor",
  GETYOURGUIDE: "getyourguide",
};
