import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Form from "antd/es/form";
import Button from "antd/es/button";
import {
  ContainerButtons,
  StyledForm,
  StyledInput,
  PersonalIdForm,
} from "./ParticipantForm.styles";
import {
  CUSTOMER_TYPE,
  PERSONAL_ID_TYPES,
} from "../../../../../../components/FormInputs/constants";
import DateOfBirthSelector from "../../../../../../components/DateOfBirthSelector/DateOfBirthSelector";
import { DateOfBirthContext } from "../../../../../../components/DateOfBirthSelector/context/DateOfBirthContext";
import { validateDateOfBirth } from "../../../../../ShopPage/components/MainMemberForm/helpers/helpers";
import { cardStatus } from "../../../../../PaymentPage/utils";

const ParticipantForm = ({
  onSubmit,
  index,
  isMobile,
  initialValues,
  size = "large",
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(["experiences"]);
  const { dateOfBirth } = useContext(DateOfBirthContext);
  return (
    <StyledForm
      form={form}
      name={`participant${index}`}
      onFinish={onSubmit}
      isMobile={isMobile}
      initialValues={initialValues}
      layout="vertical"
    >
      <Form.Item
        name={"name"}
        layout="vertical"
        label={t("shop:form.name")}
        rules={[
          {
            required: true,
            message: t("shop:form.error", { field: t("shop:form.name") }),
          },
        ]}
      >
        <StyledInput placeholder={t("placeholder.name")} size={size} />
      </Form.Item>

      <Form.Item
        name={"surname"}
        layout="vertical"
        label={t("shop:form.surname")}
        rules={[
          {
            required: true,
            message: t("shop:form.error", { field: t("shop:form.surname") }),
          },
        ]}
      >
        <StyledInput placeholder={t("placeholder.surname")} size={size} />
      </Form.Item>
      <Form.Item
        layout="vertical"
        name="dateOfBirth"
        label={t("shop:form.dateOfBirth")}
        rules={[
          {
            required: true,
            message: t("shop:form.error", {
              field: t("shop:form.dateOfBirth"),
            }),
            validator: (_) => {
              const { validateStatus, errorMsg } = validateDateOfBirth(
                dateOfBirth,
                t
              );
              if (validateStatus === cardStatus.ERROR) {
                return Promise.reject(errorMsg);
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DateOfBirthSelector />
      </Form.Item>

      <PersonalIdForm
        typeWidth={110}
        customerType={CUSTOMER_TYPE.PERSONAL}
        initialTypeValue={PERSONAL_ID_TYPES.DNI}
        showLabel
      />

      <Form.Item>
        <ContainerButtons>
          <Button
            htmlType="submit"
            size={size}
            type="primary"
            style={{ width: 150, borderRadius: 20 }}
          >
            {t("save")}
          </Button>
        </ContainerButtons>
      </Form.Item>
    </StyledForm>
  );
};

ParticipantForm.propTypes = {
  handleFormFinish: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  fieldKey: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  participantRate: PropTypes.string.isRequired,
};

export default ParticipantForm;
