import { Card, Button, Modal } from "antd";
import React, { useState } from "react";
import Text from "antd/es/typography/Text";
import { useTranslation } from "react-i18next";
import TotalPriceContainer from "../TotalPriceContainer/TotalPriceContainer";
import CalendarContainer from "../../../ShopPage/components/CalendarContainer/CalendarContainer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { modifyValueSearch } from "../../../../Redux/Actions/searchActions";
import DatesInput from "../../components/DatesInput/DatesInput";
import useIsMobile from "../../../../hooks/useIsMobile";
import {
  getPrevDaysForBookingConfig,
  getSupplierData,
  getSupplierSettings,
} from "../../../../Redux/Selectors/appConfigSelector";
import { listRestrictedDates } from "../../../../helpers/supplierSettings";
import { getOpeningDays } from "../../../../helpers/supplier";
import { ContainerDate, WrapperDate } from "./DateContainer.styles";

const DateContainer = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const supplierSettings = useSelector(getSupplierSettings);
  const supplierData = useSelector(getSupplierData);
  const prevDaysForBooking = useSelector((state) =>
    getPrevDaysForBookingConfig(state, shallowEqual)
  );
  const [showModal, setShowModal] = useState(false);

  const opennedDaysOfWeek = getOpeningDays(
    supplierData.openingConfig,
    supplierData.openingHours
  );

  const handleOnSelect = (key, val) => {
    dispatch(modifyValueSearch(key, val));
  };

  const resetModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Card>
        <ContainerDate>
          <WrapperDate isMobile={isMobile}>
            <Text strong>{t("shop:select_rental_dates")}:</Text>
            <div style={{ marginTop: 3, width: "100%" }}>
              <DatesInput
                bordered={false}
                handleShowModal={() => setShowModal(true)}
                showDisclaimer={false}
                prevDaysForBooking={prevDaysForBooking}
              />
            </div>
          </WrapperDate>
          {!isMobile && <TotalPriceContainer />}
        </ContainerDate>
      </Card>
      <Modal
        open={showModal}
        width={1000}
        title={t("common:calendar")}
        onCancel={resetModal}
        onOk={resetModal}
        bodyStyle={{ overflowX: "scroll", height: 500 }}
        centered
        footer={[
          <Button
            shape="round"
            size="large"
            type="primary"
            onClick={resetModal}
          >
            {t("common:apply")}
          </Button>,
        ]}
      >
        <CalendarContainer
          handleCloseModal={resetModal}
          handleOnSelect={handleOnSelect}
          months={2}
          direction={"horizontal"}
          restrictedDates={listRestrictedDates(
            supplierSettings?.restrictedDates
          )}
          opennedDaysOfWeek={opennedDaysOfWeek}
          prevDaysForBooking={prevDaysForBooking}
        />
      </Modal>
    </>
  );
};

export default DateContainer;
