import {
  TRANSACTION_PAYMENT_CREATE_FAILURE,
  TRANSACTION_PAYMENT_CREATE_REQUEST,
  TRANSACTION_PAYMENT_CREATE_SUCCESS,
  TRANSACTION_PAYMENT_RESET,
} from "./paymentActions";

const INITIAL_STATE = {
  pamyent: null,
  loading: false,
  success: false,
  error: false,
};

const paymentCreationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case TRANSACTION_PAYMENT_RESET: {
      return INITIAL_STATE;
    }
    case TRANSACTION_PAYMENT_CREATE_REQUEST: {
      return {
        ...state,
        paymentId: null,
        loading: true,
        success: false,
        error: false,
      };
    }
    case TRANSACTION_PAYMENT_CREATE_SUCCESS: {
      return {
        ...state,
        paymentId: payload.paymentId,
        loading: false,
        success: true,
        error: false,
      };
    }
    case TRANSACTION_PAYMENT_CREATE_FAILURE: {
      return {
        ...state,
        paymentId: null,
        loading: false,
        success: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export default paymentCreationReducer;
