import { Card, Input, Select } from "antd";
import styled from "styled-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editPassengerValue } from "../../redux/QuoteActions";
import { useTranslation } from "react-i18next";
import {
  getInfoValidationByPassenger,
  getPassengerDataById,
} from "../../redux/QuoteSelectors";
import baseTheme from "../../../../config/baseTheme";
import useIsMobile from "../../../../hooks/useIsMobile";
import Text from "antd/es/typography/Text";
import ProductRequiredInfoContainer from "../ProductRequiredInfoContainer/ProductRequiredInfoContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const PassengerDetailsContainer = ({ id }) => {
  const { t } = useTranslation("shop");
  const isMobile = useIsMobile();
  const passengerData = useSelector((state) => getPassengerDataById(state, id));
  const isValidForm = useSelector((state) =>
    getInfoValidationByPassenger(state, id)
  );
  const dispatch = useDispatch();
  return (
    <Card>
      <Container>
        {!isValidForm && (
          <Text
            style={{
              fontWeight: "600",
              fontSize: 14,
              color: baseTheme.colors.red,
            }}
          >
            Completa los campos requeridos:
          </Text>
        )}
        <Row isMobile={isMobile}>
          <Input
            placeholder={t("form.participantName")}
            value={passengerData?.name}
            onChange={(e) =>
              dispatch(editPassengerValue(id, { name: e.target.value }))
            }
          />
        </Row>
        <Row isMobile={isMobile}>
          <Select
            placeholder="Experiencia"
            size="large"
            addonBefore={t("experience")}
            value={passengerData?.experience}
            style={{ width: "100%" }}
            onChange={(val) =>
              dispatch(editPassengerValue(id, { experience: val }))
            }
          >
            <Select.Option value="beginner">
              {t("shop:checkout.beginner")}
            </Select.Option>
            <Select.Option value="intermediate">
              {t("shop:checkout.intermediate")}
            </Select.Option>
            <Select.Option value="expert">
              {t("shop:checkout.expert")}
            </Select.Option>
          </Select>
          <ProductRequiredInfoContainer
            passengerId={id}
            style={{ width: "100%" }}
            onChange={(id, val) => dispatch(editPassengerValue(id, val))}
            orientation={isMobile ? "column" : "row"}
          />
        </Row>
      </Container>
    </Card>
  );
};

export default PassengerDetailsContainer;
