import PropTypes from "prop-types";
import { useForm } from "antd/es/form/Form";

const ContactFormContainer = ({ children }) => {
  const [form] = useForm();

  return children({
    formInstance: form,
  });
};

ContactFormContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ContactFormContainer;
