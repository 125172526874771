import React from "react";
import { DisclaimerText } from "./FreeChargeDisclaimer.styles";
import BootstrapIcon from "../../../../components/BootstrapIcon/BoostrapIcon";
import {
  RENTALS,
  isAnyHighMountain,
  isRental,
} from "../../../../helpers/rentals";
import { shallowEqual, useSelector } from "react-redux";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";

const FreeChargeDisclaimer = ({
  disclaimerLabel,
  fromHour,
  toHour,
  hideText,
  days,
  restDays,
}) => {
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const isLabarraca = isRental(supplierData._id, RENTALS.labarraca);
  const renderDays = (description) => {
    if (isAnyHighMountain(supplierData._id)) {
      return description;
    }
  };
  if (isLabarraca) return null;
  return (
    <div>
      <DisclaimerText type="secondary">
        <BootstrapIcon iconName="clock" /> {disclaimerLabel}
      </DisclaimerText>

      {!hideText && (
        <DisclaimerText strong>
          {renderDays(days)} {fromHour}hs - {toHour}hs
          <br />
          {renderDays(restDays)}
        </DisclaimerText>
      )}
    </div>
  );
};

export default FreeChargeDisclaimer;
