import {
  SHOP_APPLY_DISCOUNT,
  SHOP_RESET_DISCOUNT,
  SHOP_REMOVE_DISCOUNT,
} from "../actions/DiscountActions";

const INITIAL_STATE = {
  discountCode: "",
  firstApplicationIntent: false,
};

const discountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOP_APPLY_DISCOUNT:
      return {
        ...state,
        discountCode: action.payload.discountCode,
        firstApplicationIntent: true,
      };
    case SHOP_REMOVE_DISCOUNT:
      return { ...state, discountCode: "" };
    case SHOP_RESET_DISCOUNT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default discountReducer;
