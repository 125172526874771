import styled from "styled-components";
import Title from "antd/es/typography/Title";

export const SectionTitle = styled(Title)`
  padding: 5px 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff !important;
`;

export const SectionContent = styled.div`
  padding: 10px;
`;
