import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 100px;
`;

export const Body = styled.div`
  display: grid;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: ${({ $isMobile }) => ($isMobile ? "100%" : "2fr 400px")};
  width: 100%;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const FormContainer = styled.div`
  flex: 1;
`;

export const Member = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: auto;
`;
