import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getPaymentMethodsFromSupplierRequest,
  setPaymentMethodIdentifier,
} from "../features/PaymentPage/redux/paymentActions";
import {
  getPaymentMethodIdentifier,
  getPaymentMethodsByCurrency,
} from "../features/PaymentPage/redux/paymentSelectors";
import useTransaction from "./useTransaction";
import usePreSale from "./usePreSale";
/**
 * @param {Object} params
 * @param {string} params.transactionId
 * @param {string} [params.initialPaymentMethodSelected]
 * @param {string} [params.customerId=null]
 */
const useTransactionPayment = (params = {}) => {
  const { transactionId, supplierId, customerId = null } = params;
  const paymentMethodIdentifier = useSelector(
    getPaymentMethodIdentifier,
    shallowEqual
  );
  const paymentMethods = useSelector(getPaymentMethodsByCurrency);
  const [paymentMethodData, setPaymentMethodData] = useState(null);
  const [paymentData, setPaymentData] = useState({});
  const [generalLoading, setGeneralLoading] = useState(true);
  const [saleData, setSaleData] = useState(null);
  const { loading: loadingTransaction, transaction } = useTransaction({
    transactionId,
    customerId,
  });
  const { loading: loadingPreSale, preSale } = usePreSale({
    preSaleId: transactionId,
    customerId,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentMethodsFromSupplierRequest(supplierId));
  }, [transactionId]);

  useEffect(() => {
    if (loadingPreSale || loadingTransaction || !isEmpty(saleData)) {
      return;
    }
    if (!isEmpty(transaction)) {
      setSaleData(transaction);
    } else {
      setSaleData(preSale);
    }
    setGeneralLoading(false);
  }, [transaction, preSale, loadingPreSale, loadingTransaction, paymentData]);

  useEffect(() => {
    if (paymentMethods) {
      const paymentMethodSelectedData = paymentMethods.find(
        (payMethod) => payMethod.paymentIdentifier === paymentMethodIdentifier
      );
      setPaymentMethodData(paymentMethodSelectedData || null);
    }
  }, [paymentMethodIdentifier]);

  useEffect(() => {
    if (!saleData || generalLoading) {
      return;
    }
    const commissionFromHost = saleData.commissionFromHost ?? 1;
    const percentage = commissionFromHost * 100;
    const commissionedAmount = (saleData.hostPrice * percentage) / 100;
    const amountPaid = saleData.hostPrice - saleData.balance;
    const paymentTotal = commissionedAmount - amountPaid;
    setPaymentData({
      percentage,
      amountPaid,
      paymentTotal,
      paymentCompleted: paymentTotal <= 0,
      subtotal: saleData.subtotal,
      discount: saleData.discount,
    });
  }, [saleData, generalLoading]);

  const setPaymentMethodId = (newPaymentMethodIdentifier) => {
    dispatch(setPaymentMethodIdentifier(newPaymentMethodIdentifier));
  };

  return {
    paymentMethod: {
      setPaymentMethodId,
      paymentMethodIdentifier,
      paymentMethodData,
    },
    transaction: {
      loadingTransaction: generalLoading || isEmpty(paymentData),
      transaction: saleData,
    },
    paymentData,
  };
};

export default useTransactionPayment;
