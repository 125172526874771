import React from "react";
import Select from "antd/es/select/index";
import { useTranslation } from "react-i18next";
import {
  BUSINESS_ID_TYPES,
  CUSTOMER_TYPE,
  PERSONAL_ID_TYPES,
} from "../FormInputs/constants";

const IdTypeSelect = ({ width = "100%", customerType, ...props }) => {
  const { t } = useTranslation("shop");

  const idTypes =
    customerType === CUSTOMER_TYPE.PERSONAL
      ? PERSONAL_ID_TYPES
      : BUSINESS_ID_TYPES;

  return (
    <Select style={{ width }} {...props}>
      {Object.values(idTypes).map((type) => {
        return (
          <Select.Option key={type} value={type}>
            {t(type)}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default IdTypeSelect;
