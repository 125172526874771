import { schema } from "normalizr";
import { categorySchema } from "./categorySchema";
import { supplierSchema } from "./supplierSchema";

export const productSchema = new schema.Entity(
  "products",
  {
    owner: supplierSchema,
    category: categorySchema,
  },
  {
    idAttribute: "_id",
  }
);

export const productsSchema = [productSchema];
