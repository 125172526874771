import React from "react";
import styled from "styled-components";
import LayoutWithDataContainer from "../../../../containers/LayoutWithDataContainer";
import BannerCollapsed from "../BannerCollapsed/BannerCollapsed";

const Container = styled.div`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding-top: 20px;
  min-height: 62vh;
`;

const LayoutWrapper = ({ children, isIframe }) => {
  return (
    <LayoutWithDataContainer isIframe={isIframe}>
      {!isIframe && <BannerCollapsed />}
      <Container>{children}</Container>
    </LayoutWithDataContainer>
  );
};

export default LayoutWrapper;