import { Button, Modal } from "antd";
import styled from "styled-components";

export const ButtonCustom = styled(Button)`
  padding: 0px;
  height: 40px;
  width: 80%;
  border-radius: 30px;
`;

export const ModalCustom = styled(Modal)`
  .ant-modal-header {
    text-align: center;
  }
`;
