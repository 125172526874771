import { WarningFilled } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Failed = ({ transactionId }) => {
  const { t } = useTranslation("result");
  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Title>
        <WarningFilled style={{ color: "red" }} /> {t("transactionFailed")}
      </Title>
      <Paragraph>{t("descriptionFailed")}</Paragraph>
      <Paragraph>{t("possibleCauses")}</Paragraph>
      <ul style={{ padding: 10 }}>
        <li>{t("possibleCauseData")}</li>
        <li>{t("possbileCaseCardIssue")}</li>
        <li>{t("possibleCaseCardDisabled")}</li>
      </ul>

      <Link to={`/transaction/${transactionId}/pay`}>{t("retryPayment")}</Link>
    </div>
  );
};

export default Failed;
