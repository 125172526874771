import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import useBreakpoints from "../../hooks/useBreakpoints";

const BannerCarousel = ({ bgImage }) => {
  const { isTablet, isMobile } = useBreakpoints();
  const secondImageTablet =
    "https://res.cloudinary.com/dja5ahopr/image/upload/v1718397849/Websites/bmtbmpc8aob93o0vnwmx.jpg";
  const secondImageMobile =
    "https://res.cloudinary.com/dja5ahopr/image/upload/v1718397849/Websites/bmtbmpc8aob93o0vnwmx.jpg";
  const secondImage =
    "https://res.cloudinary.com/dja5ahopr/image/upload/v1718397139/Websites/icvfzv2bujei6cooyz9i.jpg";

  const firstImageTablet =
    "https://res.cloudinary.com/dja5ahopr/image/upload/v1720890994/Websites/gezpi9wlyf5v1sivbx3z.jpg";
  const firstImageMobile =
    "https://res.cloudinary.com/dja5ahopr/image/upload/v1720890994/Websites/gezpi9wlyf5v1sivbx3z.jpg";
  return (
    <Carousel autoPlay infiniteLoop showStatus={false} showThumbs={false}>
      <a href="/quote">
        <div>
          <img
            alt="Travesía catedral"
            src={
              isTablet
                ? secondImageTablet
                : isMobile
                ? secondImageMobile
                : secondImage
            }
            style={{ width: "100%" }}
          />
        </div>
      </a>
      <a href="/quote">
        <div>
          <img
            alt="Travesía catedral"
            src={
              isTablet
                ? firstImageTablet
                : isMobile
                ? firstImageMobile
                : bgImage
            }
          />
        </div>
      </a>
    </Carousel>
  );
};

export default BannerCarousel;
