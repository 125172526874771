import React, { useEffect } from "react";
import { FormContainer } from "../CheckinScreen.styles";
import { Button, Form, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPassengersDataCheckIn } from "../redux/checkinSelectors";
import Paragraph from "antd/es/typography/Paragraph";
import ParticipantDetailsForm from "./ParticipantDetailsForm/ParticipantDetailsForm";
import { isStagingQa, isVertical } from "../../../helpers/rentals";
import { ANY_NUMBERS } from "../helpers/constants";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import {
  quoteBuildRemovePassenger,
  unassignVariantToPassenger,
} from "../../ShopPage/redux/actions/shopActions";
import { getAssigmentsData } from "../../ShopPage/redux/selectors/ShopSelectors";
import { uniqueId } from "lodash";
import { useForm } from "antd/es/form/Form";
import { quoteBuildAddPassengers } from "../../ShopPage/redux/actions/shopActions";
import { isEmpty } from "lodash";
import { getMainMemberInfo } from "../../ShopPage/redux/selectors/MainMemberSelectors";
import { saveMainMemberInfo } from "../../ShopPage/redux/actions/MainMemberActions";

const ParticipantsForm = ({ onSubmitPassengers }) => {
  const { t } = useTranslation("common");
  const [form] = useForm();
  const dispatch = useDispatch();
  const mainMember = useSelector(getMainMemberInfo);
  const participants = useSelector(getPassengersDataCheckIn);
  const supplierData = useSelector(getSupplierData);
  const participantsData = useSelector(getAssigmentsData);
  const localStorageData = JSON.parse(localStorage.getItem("checkinForm"));

  const handleRemovePassenger = (remove, fieldKey) => {
    const { uniqueId } = form.getFieldValue(["names", fieldKey]);
    const products =
      participantsData &&
      Object.values(participantsData).filter(
        (assignament) => assignament.passenger === uniqueId
      );
    remove(fieldKey);
    dispatch(quoteBuildRemovePassenger(uniqueId));
    products &&
      products.map((product) =>
        dispatch(
          unassignVariantToPassenger({
            index: `${uniqueId}-${product._id}`,
            productId: product._id,
          })
        )
      );
  };

  useEffect(() => {
    if (!isEmpty(localStorageData)) {
      const { mainMember, passengers } = localStorageData;
      dispatch(saveMainMemberInfo(mainMember));
      dispatch(quoteBuildAddPassengers(passengers));
      form.setFieldsValue({
        names: passengers.map((participant) => ({
          uniqueId: participant?.id,
          name: participant?.name,
          experience: participant?.experience,
          height: participant?.height,
          weight: participant?.weight,
          footLength: participant?.footLength,
        })),
      });
      localStorage.setItem("checkinForm", JSON.stringify({}));
    }
  }, [participants]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      localStorage.setItem(
        "checkinForm",
        JSON.stringify({
          passengers: participants,
          mainMember,
        })
      );
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [participants]);

  return (
    <FormContainer>
      <Form
        onFinish={onSubmitPassengers}
        initialValues={{
          names: participants.map((participant) => ({
            uniqueId: participant?.id,
            name: participant?.name,
            experience: participant?.experience,
            height: participant?.height,
            weight: participant?.weight,
            footLength: participant?.footLength,
          })),
        }}
        form={form}
      >
        <Form.List name="names">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item required={false} key={field.key}>
                  <Paragraph
                    style={{ fontSize: 14, marginTop: 10 }}
                    type="secondary"
                  >
                    {t("participant")}:
                  </Paragraph>
                  <Form.Item name={[field.name, "uniqueId"]} noStyle>
                    <Input type="hidden" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "name"]}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        message: t("shop:form.error", {
                          field: t("shop:form.name"),
                        }),
                      },
                      {
                        validator: (_, value) => {
                          if (value && ANY_NUMBERS.test(value)) {
                            return Promise.reject(t("shop:form.onlyLetters"));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      disabled={index === 0}
                      placeholder={t("shop:form.name")}
                      style={{
                        width: "100%",
                        marginRight: 10,
                      }}
                    />
                  </Form.Item>
                  {(isVertical(supplierData?._id) ||
                    isStagingQa(supplierData?._id)) && (
                    <ParticipantDetailsForm field={field} />
                  )}
                  {index !== 0 && fields.length > 0 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        handleRemovePassenger(remove, field.name);
                      }}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  size="middle"
                  onClick={() =>
                    add({
                      uniqueId: Number(uniqueId()),
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                  style={{ fontSize: 14 }}
                >
                  {t("shop:passengers.addMember")}
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item noStyle>
          <Button
            type="primary"
            size="large"
            block
            htmlType="submit"
            style={{ marginTop: 60 }}
          >
            {t("common:continue")}
          </Button>
        </Form.Item>
      </Form>
    </FormContainer>
  );
};

export default ParticipantsForm;
