import { denormalize } from "normalizr";
import { isEmpty, sortBy } from "lodash";
import { paymentSchema, paymentsSchema } from "../Schemas/paymentSchema";

export const getPaymentById = (state, id) => {
  if (id) {
    const denormalizeData = denormalize(id, paymentSchema, state.entities);
    if (!isEmpty(denormalizeData)) {
      return denormalizeData;
    }
    return null;
  }
  return null;
};

export const getPaymentsByIds = (state, arrayOfIds) => {
  if (arrayOfIds) {
    const denormalizeData = denormalize(
      arrayOfIds,
      paymentsSchema,
      state.entities
    );
    if (!isEmpty(denormalizeData)) {
      return sortBy(denormalizeData, "createdAt").reverse();
    }
    return [];
  }
  return [];
};
