import {
  GET_LOCKED_DATES_REQUEST,
  GET_LOCKED_DATES_SUCCESS,
  GET_LOCKED_DATES_FAILURE,
} from "../actions/spacesActions.js";

const INITIAL_STATE = {
  data: null,
  isFetching: false,
  error: false,
};

const spaceAvailabilityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOCKED_DATES_REQUEST:
      return {
        ...state,
        data: null,
        isFetching: true,
        error: false,
      };
    case GET_LOCKED_DATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.payload.lockedDates,
      };
    case GET_LOCKED_DATES_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: true,
      };

    default:
      return state;
  }
};

export default spaceAvailabilityReducer;
