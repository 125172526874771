import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AssignProductsBuilder from "../components/AssignProductsBuilder/AssignProductsBuilder";
import LayoutWrapper from "../components/LayoutWrapper/LayoutWrapper";
import ShopWrapper from "../components/ShopWrapper/ShopWrapper";
import AssignmentsContainer from "../containers/AssignmentsContainer/AssignmentsContainer";
import PricingContainer from "../containers/PricingContainer/PricingContainer";
import ScrollToTop from "../../../hooks/useScrollToTop";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../../services/mixpanel";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import { ScrollRestoration } from "react-router-dom";
import BannerRenderContainer from "../../../components/BannerComponent/BannerRenderContainer";

const AssignmentsScreen = ({ isIframe }) => {
  const { t } = useTranslation();
  const supplierData = useSelector(getSupplierData);

  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "AssignmentPage",
      supplier: supplierData.name,
      supplierId: supplierData._id,
    });
  }, []);

  return (
    <LayoutWrapper isIframe={isIframe}>
      <BannerRenderContainer screen="sizeScreen" />
      <PricingContainer>
        {({
          pricingData,
          isPricingFetching,
          discount: {
            handleApplyDiscount,
            handleRemoveDiscount,
            discountError,
          },
        }) => {
          const discountsProps = {
            onApplyDiscount: handleApplyDiscount,
            onRemoveDiscount: handleRemoveDiscount,
            discountError,
          };
          return (
            <AssignmentsContainer>
              {({
                quoteData,
                handleOnAssignProductsContinue,
                handleOnSubmitAssign,
                assignFormController,
                quoteSummary,
                itemsLength,
                isButtonEnabled,
                supplierData,
                selectedProducts,
                searchValues,
              }) => {
                return (
                  <ShopWrapper
                    itemsLength={itemsLength}
                    pricingData={pricingData}
                    quoteSummary={quoteSummary}
                    selectedProducts={selectedProducts}
                    isPricingFetching={isPricingFetching}
                    handleOnSummaryClick={handleOnAssignProductsContinue}
                    buttonLabel={t("common:continue")}
                    isButtonEnabled={isButtonEnabled}
                  >
                    <ScrollToTop />
                    <AssignProductsBuilder
                      quoteData={quoteData}
                      initialFormData={quoteData}
                      handleOnSubmitAssign={handleOnSubmitAssign}
                      formController={assignFormController}
                      supplierData={supplierData}
                      handleOnClick={handleOnAssignProductsContinue}
                      pricingData={pricingData}
                      isPricingFetching={isPricingFetching}
                      quoteSummary={quoteSummary}
                      itemsLength={itemsLength}
                      selectedProducts={selectedProducts}
                      isButtonEnabled={isButtonEnabled}
                      searchValues={searchValues}
                      discounts={discountsProps}
                    />
                  </ShopWrapper>
                );
              }}
            </AssignmentsContainer>
          );
        }}
      </PricingContainer>
      <ScrollRestoration />
    </LayoutWrapper>
  );
};

export default AssignmentsScreen;
