import { useSelector } from "react-redux";
import {
  getSupplierData,
  getSupplierSettings,
} from "../Redux/Selectors/appConfigSelector";
import { PERIODS, createUTCDate } from "../helpers/tools";
import { isAnyHighMountain } from "../helpers/rentals";
import { isEmpty } from "lodash";
import { generateDateTime } from "../helpers/dates";

const DATETIME_TYPES = {
  from: "from",
  to: "to",
};

const useCalculateDates = ({
  fromDate,
  toDate,
  fromTime,
  toTime,
  period = PERIODS.day,
}) => {
  const settings = useSelector(getSupplierSettings);
  const supplierData = useSelector(getSupplierData);
  const pickupTime = settings.pickupTime;
  const dropoffTime = settings.dropoffTime;

  const arrayRange = (start, stop, step) => {
    const parsedStart = Number(start);
    const parsedStop = Number(stop);
    return Array.from(
      { length: (parsedStop - parsedStart) / step + 1 },
      (value, index) => parsedStart + index * step
    );
  };

  const isPickupIncluded = !isEmpty(pickupTime)
    ? arrayRange(
        pickupTime[0].split(":")[0],
        pickupTime[1].split(":")[0],
        1
      ).includes(Number(fromTime?.split(":")[0]))
    : false;

  const isDropoffIncluded = !isEmpty(dropoffTime)
    ? arrayRange(
        dropoffTime[0].split(":")[0],
        dropoffTime[1].split(":")[0],
        1
      ).includes(Number(toTime?.split(":")[0]))
    : false;

  const generatePricingDateTime = (dateTime, period, type) => {
    const clonedDateTime = dateTime.clone();
    if (period === PERIODS.hour && type === DATETIME_TYPES.to) {
      clonedDateTime.subtract(1, "hour");
    }
    if (period === PERIODS.day) {
      if (
        isAnyHighMountain(supplierData._id) &&
        type === DATETIME_TYPES.from &&
        from.day() === 6
      ) {
        clonedDateTime.add(1, "day");
      } else if (
        isAnyHighMountain(supplierData._id) &&
        type === DATETIME_TYPES.from &&
        from.isSameOrAfter(createUTCDate("2023-09-15"), "day") &&
        from.isSameOrBefore(createUTCDate("2023-09-17"), "day")
      ) {
        clonedDateTime.add(1, "day");
      } else if (type === DATETIME_TYPES.from && isPickupIncluded) {
        clonedDateTime.add(1, "day");
      } else if (type === DATETIME_TYPES.to && isDropoffIncluded) {
        clonedDateTime.subtract(1, "day");
      }
    }
    return clonedDateTime;
  };

  const generatePricingDates = (from, to, period) => {
    const pricingFrom = generatePricingDateTime(
      from,
      period,
      DATETIME_TYPES.from
    );
    const pricingTo = generatePricingDateTime(to, period, DATETIME_TYPES.to);
    const isInvalidRange =
      period !== PERIODS.hour && pricingTo.isBefore(pricingFrom, "day");
    return {
      pricingFrom: isInvalidRange ? from : pricingFrom,
      pricingTo: isInvalidRange ? from : pricingTo,
    };
  };

  const from = generateDateTime(fromDate, fromTime);
  const to = generateDateTime(toDate, toTime);
  const { pricingFrom, pricingTo } = generatePricingDates(from, to, period);

  return {
    from,
    to,
    pricingFrom,
    pricingTo,
  };
};

export default useCalculateDates;
