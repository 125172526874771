import styled from "styled-components";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import mediaQueries from "../../utils/mediaQueries";
import PriceComponent from "../../components/Price/Price";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 72vh;
  width: 100%;
  justify-content: center;
  display: flex;
`;

export const ExperiencesWrapper = styled.div`
  max-width: 1150px;
  padding-top: 20px;
`;

export const TitleExperiences = styled(Title)`
  padding: 20px 0;

  @media ${mediaQueries.desktop} {
    padding: 20px 20px;
  }
`;

export const ExperienceContent = styled.div`
  display: grid;
  gap: 20px;
  justify-items: center;
  margin-bottom: 10%;
  grid-template-columns: repeat(1, 1fr);

  @media ${mediaQueries.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${mediaQueries.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${mediaQueries.largeDesktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ContainerCard = styled.div`
  position: relative;
  width: 100%;
  maxWidth: 300px;
  border: 1px solid ${({ theme }) => theme.borders.light};
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
`;

export const Location = styled(Text)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const TitleCard = styled(Title)`
  margin: 0;
  margin-top: 5px;
`;

export const ExperienceImage = styled.img`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 200px;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 16 / 9;
`;

export const ContainerActivity = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  top: 20px;
  right: 20px;
`;

export const ActivityText = styled(Text)`
  color: #fff;
  font-size: 12px;
  line-height: 1;
`;

export const BodyCard = styled.div`
  padding: 0 10px;
  @media ${mediaQueries.mobile} {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    min-height: 150px;
  }
`;

export const TopCard = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Price = styled(PriceComponent)`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const DetailsCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 15px;
`;

export const ContainerItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  color: #000000;
`;
