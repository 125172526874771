import {
  APP_CONFIG_FAILURE,
  APP_CONFIG_REQUEST,
  APP_CONFIG_SUCCESS,
} from "../Actions/appConfigActions";

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
  data: null,
};

export const appConfigReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case APP_CONFIG_REQUEST: {
      return {
        loading: true,
        success: false,
        error: false,
        data: null,
      };
    }
    case APP_CONFIG_SUCCESS: {
      return {
        loading: false,
        success: true,
        error: false,
        data: payload.data,
      };
    }
    case APP_CONFIG_FAILURE: {
      return {
        loading: false,
        success: false,
        error: true,
        data: null,
      };
    }
    default:
      return state;
  }
};

export default appConfigReducer;
