import { Modal } from "antd";
import React from "react";
import LanguageTab from "./LanguageTab";

const ModalSwitchLanguage = ({ visible, onCancel, onChangeLanguage }) => {
  return (
    <Modal open={visible} onCancel={onCancel} footer={null} width={600}>
      <LanguageTab onChangeLanguage={onChangeLanguage} />
    </Modal>
  );
};

export default ModalSwitchLanguage;
