import styled from "styled-components";
import mediaQueries from "../../utils/mediaQueries";

const SkeletonContainer = styled.div`
  min-height: 65vh;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media ${mediaQueries.tablet} {
    width: 768px;
  }
  @media ${mediaQueries.desktop} {
    width: 1024px;
  }
  @media ${mediaQueries.largeDesktop} {
    width: 1200px;
  }
`;

export default SkeletonContainer;
