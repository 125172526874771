import styled, { css } from "styled-components";

const Container = styled.div`
  margin: auto;
  ${({ fullsize }) =>
    !fullsize
      ? css`
          max-width: 1150px;
        `
      : null}
`;

export default Container;
