import React from "react";
import styled from "styled-components";
import Text from "antd/es/typography/Text";
import useWindowSize from "../../hooks/useWindowSize";
import ContextMenu from "../ContextMenu/ContextMenu";
import { Logo } from "./MultiBranchSelector";
import useIsMobile from "../../hooks/useIsMobile";
import { Modal } from "antd";
import Item from "../ContextMenu/Item";
import BootstrapIcon from "../BootstrapIcon/BoostrapIcon";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../Redux/Selectors/appConfigSelector";

const Container = styled.div`
  position: relative;
  height: ${({ height }) => height - 63}px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 9999;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 20px;
`;

const BranchSelectorModal = ({ onClose, logo }) => {
  const { height } = useWindowSize();
  const supplierData = useSelector(getSupplierData);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const items = [
    {
      label: "Santiago",
      url: "santiago.rentalhighmountain.cl",
      icon: () => <BootstrapIcon iconName="geo-alt" />,
    },
    {
      label: "Curacautín",
      url: "curacautin.rentalhighmountain.cl",
      icon: () => <BootstrapIcon iconName="geo-alt" />,
    },
  ];

  const renderHeader = () => {
    return (
      <Header>
        <Logo src={logo} alt={supplierData?.name} />{" "}
        <Text type="secondary">{t("pickAbranch")}</Text>
      </Header>
    );
  };
  if (isMobile) {
    return (
      <Container height={height} onClick={onClose}>
        <ContextMenu items={items} renderHeader={renderHeader} />
      </Container>
    );
  } else {
    return (
      <Modal
        open={true}
        title={renderHeader()}
        footer={null}
        centered
        onCancel={onClose}
      >
        {items.map((item) => (
          <Item label={item.label} url={item.url} icon={item.icon} />
        ))}
      </Modal>
    );
  }
};

export default BranchSelectorModal;
