import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ParticipantsForm from "../forms/ParticipantsForm";
import Container from "../../../components/Container/Container";
import Paragraph from "antd/es/typography/Paragraph";
import ParticipantsContainer from "../containers/ParticipantsContainer";

const ParticipantsScreen = (props) => {
  const { t } = useTranslation("shop");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <div style={{ marginTop: 10 }}>
        <Paragraph style={{ fontSize: 14 }} type="secondary">
          {t("checkin.participants.shouldYouBeAccompanied")}
        </Paragraph>
        <Paragraph style={{ fontSize: 14 }} type="secondary">
          {t(
            "checkin.participants.thisWayWeCanAssignTheCorrespondingServiceToEachOne"
          )}
        </Paragraph>
        <Paragraph style={{ fontSize: 14 }} type="secondary">
          {t("checkin.participants.ifYouAreComingAlone")}
        </Paragraph>
        <ParticipantsContainer>
          {({ onSubmitPassengers }) => (
            <ParticipantsForm onSubmitPassengers={onSubmitPassengers} />
          )}
        </ParticipantsContainer>
      </div>
    </Container>
  );
};
export default ParticipantsScreen;
