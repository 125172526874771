import React from "react";
import PropTypes from "prop-types";
import { SectionTitle, SectionContent } from "./Section.styles";

const Section = ({ children, title, ...restProps }) => {
  return (
    <div {...restProps}>
      <SectionTitle level={5}>{title}</SectionTitle>
      <SectionContent>{children}</SectionContent>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Section;
