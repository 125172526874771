import React from "react";
import styled from "styled-components";
import ProductContainer from "../ProductContainer/ProductContainer";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const QuoteBuilder = ({
  supplierData,
  productsByCategory,
  searchName,
  categories,
  handleOnSubstractProduct,
  handleOnAddProduct,
  quoteSummary,
  isProductsFetching,
  handleOnDetailsClick,
  selectedProducts,
  pricingData,
  isPricingFetching,
  itemsLength,
  handleOnSummaryClick,
  discounts,
  showPickUpAndDropOffModal,
  handleShowPickUpAndDropOffModal,
}) => {
  return (
    <Container>
      <ProductContainer
        categories={categories}
        productsByCategory={productsByCategory}
        handleOnSubstractProduct={handleOnSubstractProduct}
        handleOnAddProduct={handleOnAddProduct}
        quoteSummary={quoteSummary}
        handleOnDetailsClick={handleOnDetailsClick}
        isLoading={isProductsFetching}
        supplierName={supplierData?.name}
        supplierActivities={supplierData?.activities}
        isMobile={false}
        visible={!isProductsFetching}
        pricingData={pricingData}
        isPricingFetching={isPricingFetching}
        itemsLength={itemsLength}
        selectedProducts={selectedProducts}
        isClosed={false}
        handleOnSummaryClick={handleOnSummaryClick}
        searchName={searchName}
        openingHours={supplierData?.openingHours}
        discounts={discounts}
        handleShowPickUpAndDropOffModal={handleShowPickUpAndDropOffModal}
        showPickUpAndDropOffModal={showPickUpAndDropOffModal}
      />
    </Container>
  );
};

export default QuoteBuilder;
