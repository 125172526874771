import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { WhatsAppOutlined } from "@ant-design/icons";
import Button from "../../../../../components/Button/Button";

const CallToActionButton = ({ onClick, data, ...restProps }) => {
  const { t } = useTranslation("experiences");

  const generateMessage = () => {
    return t("whatsappMessageExperience", {
      experienceTitle: data.experienceTitle,
    });
  };

  return (
    <a
      href={`https://wa.me/${data.phoneNumber}?text=${generateMessage()}`}
      target="_blank"
      rel="noreferrer"
    >
      <Button
        type="primary"
        size="large"
        onClick={onClick}
        icon={<WhatsAppOutlined />}
        {...restProps}
      >
        {t("checkAvailability")}
      </Button>
    </a>
  );
};

CallToActionButton.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    phoneNumber: PropTypes.string.isRequired,
    experienceTitle: PropTypes.string.isRequired,
  }),
};

export default CallToActionButton;
