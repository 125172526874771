import React from "react";
import { intersection } from "lodash";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Wrapper, ContainerImages, Image } from "./PaymentIntegrations.styles";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { integrations } from "./helpers/helpers";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";

const PaymentIntegrations = ({ paymentMethods }) => {
  const { t } = useTranslation(["home"]);
  const supplierData = useSelector(getSupplierData);

  const renderIntegrations = integrations(supplierData?._id).map(
    (method, index) => {
      if (
        method.country.includes(supplierData.country) &&
        intersection(paymentMethods, method.label).length
      ) {
        return (
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              gap: 5,
              width: "auto",
            }}
          >
            <Image key={index} src={method.url} alt={`${method.label}`} />
            {(method.label[0] === "wireTransfer" ||
              method.label[0] === "shopCash") && (
              <Text strong>{t(`shop:${method.label}`)}</Text>
            )}
          </div>
        );
      }
      return null;
    }
  );

  return (
    <Wrapper>
      <Title level={4}>{t("titles.paymentMethods")}</Title>
      <ContainerImages
        gap={renderIntegrations.length > 2 ? "100px" : "20px"}
        justifyContent={
          renderIntegrations.length > 2 ? "center" : "space-around"
        }
      >
        {renderIntegrations}
      </ContainerImages>
    </Wrapper>
  );
};

PaymentIntegrations.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default PaymentIntegrations;
