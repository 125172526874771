import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import {
  CustomCalendar,
  StyledInput,
  CalendarIcon,
  CustomNavigation,
  HeaderCalendarContainer,
  TitleHeaderCalendar,
  DatePickerWrapper,
  Spinner,
} from "./AvailabilityCalendar.styles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getCalendarLanguage, filterAvailableDates } from "./helpers";
import { getLanguage } from "../../helpers/tools";
import { capitalize } from "../../helpers/string";

const AvailabilityCalendar = ({
  availableDates,
  highlightDates,
  onChangeMonth,
  onClickOnDate,
  placeholder,
  renderReference,
  isLoading,
  onCalendarOpen,
  initialDate,
  inline,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [isInitialDateInitialized, setIsInitialDateInitialized] =
    useState(false);

  useEffect(() => {
    if (initialDate && !isInitialDateInitialized) {
      setSelectedDate(initialDate);
      setIsInitialDateInitialized(true);
    }
  }, [initialDate, isInitialDateInitialized]);

  const handleChange = (date) => {
    onClickOnDate(date);
    setSelectedDate(date);
  };

  const handleMonthChange = (date) => {
    onChangeMonth(date);
  };

  const renderCustomHeader = (
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  ) => {
    return (
      <HeaderCalendarContainer>
        <CustomNavigation
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          {!prevMonthButtonDisabled && <LeftOutlined />}
        </CustomNavigation>
        <TitleHeaderCalendar level={5}>
          {capitalize(
            date.toLocaleDateString(getLanguage(), {
              year: "numeric",
              month: "long",
            })
          )}
        </TitleHeaderCalendar>
        <CustomNavigation
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <RightOutlined />
        </CustomNavigation>
      </HeaderCalendarContainer>
    );
  };

  return (
    <DatePickerWrapper {...restProps}>
      <DatePicker
        placeholderText={placeholder ? placeholder : t("select")}
        inline={inline}
        selected={selectedDate}
        onChange={handleChange}
        onMonthChange={handleMonthChange}
        onCalendarOpen={onCalendarOpen}
        dateFormat="dd/MM/yyyy"
        locale={getCalendarLanguage(getLanguage())}
        minDate={new Date()}
        customInput={<StyledInput size="large" suffix={<CalendarIcon />} />}
        calendarContainer={isLoading ? Spinner : CustomCalendar}
        showPreviousMonths={false}
        filterDate={(dates) => filterAvailableDates(dates, availableDates)}
        dayClassName={(date) => {
          const availabilityInfo = highlightDates?.find(
            (item) => item.date.toDateString() === date.toDateString()
          );
          return availabilityInfo ? availabilityInfo?.className : "";
        }}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) =>
          renderCustomHeader(
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          )
        }
      >
        {renderReference}
      </DatePicker>
    </DatePickerWrapper>
  );
};

AvailabilityCalendar.propTypes = {
  availableDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  highlightDates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      className: PropTypes.string.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  onChangeMonth: PropTypes.func.isRequired,
  onClickOnDate: PropTypes.func.isRequired,
  onCalendarOpen: PropTypes.func,
  renderReference: PropTypes.node,
  isLoading: PropTypes.bool,
  inline: PropTypes.bool,
  initialDate: PropTypes.instanceOf(Date()),
};

export default AvailabilityCalendar;
