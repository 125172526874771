import styled from "styled-components";
import Text from "antd/es/typography/Text";
import Collapse from "antd/es/collapse";

export const ProductListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

export const SizedText = styled(Text)`
  font-size: 16px;
`;

export const Disclaimer = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 10px;
`;

export const PaidAmount = styled(SizedText)`
  color: #3ea9b2;
`;

export const StyledCollapse = styled(Collapse)`
  margin-bottom: 10px;

  .ant-collapse-header {
    padding: 0 !important;
    margin-bottom: 5px !important;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const ConvertedTotal = styled.div`
  padding: 10px 0;
  margin-top: 10px;
  text-align: right;
  border-top: 1px solid ${({ theme }) => theme.borders.gray};
  border-bottom: 1px solid ${({ theme }) => theme.borders.gray};
  display: flex;
  justify-content: space-between;
`;
