import styled from "styled-components";
import mediaQueries from "../../../../utils/mediaQueries";

export const Logo = styled.img`
  display: block;
  max-width: 100%;
  max-height: 500px;
  margin: auto;

  @media ${mediaQueries.desktop} {
    max-width: 100%;
    max-height: 500px;
    object-fit: contain;
    border-radius: 20px;
    overflow: hidden;
  }
`;
