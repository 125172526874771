import { useEffect, useState } from "react";

/**
 * @param {Array} [products=[]]
 * @param {number} [daysLength=1]
 */
const useProductsInConflict = ({ products = [], daysLength = 1 }) => {
  const [productsInConflict, setProductsInConflict] = useState([]);
  useEffect(() => {
    if (products?.length > 0) {
      setProductsInConflict(
        products.filter(
          (product) => product?.minimumPeriodQuantity > daysLength
        )
      );
    }
  }, [products]);

  return {
    hasConflicts: productsInConflict.length > 0,
    productsInConflict,
  };
};

export default useProductsInConflict;
