import logger from "redux-logger";
import { applyMiddleware, createStore, compose } from "redux";
import { normalize } from "normalizr";
import createSagaMiddleware from "redux-saga";
import { NORMALIZE_DATA, setNormalizedData } from "./Actions/normalizrActions";
import rootReducers from "./Reducers/rootReducers";
import rootSaga from "./Saga/rootSaga";

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];

  if (process.env.REACT_APP_HOST_TYPE === "dev") {
    middlewares.push(logger);
  }
  /* ------------ Normalizr Middleware ------------ */

  const normalizrMiddleware = () => (next) => (action) => {
    const { type, payload } = action;

    if (type === NORMALIZE_DATA) {
      const normalizedData = normalize(payload.data, payload.schema);
      next(setNormalizedData(normalizedData));
    }
    next(action);
  };

  middlewares.push(normalizrMiddleware);
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducers, preloadedState, composedEnhancers);
  sagaMiddleware.run(rootSaga);
  return store;
}
