import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button/Button.styles";
import { ReactComponent as ChevronLeft } from "../../../components/Icons/chevronLeft.svg";
import baseTheme from "../../../config/baseTheme";

const GoBack = () => {
  const navigate = useNavigate();
  return (
    <Button
      type="secondary"
      onClick={() => navigate(-1)}
      style={{ position: "absolute", left: 10, top: 20 }}
    >
      <ChevronLeft
        style={{ width: 25, height: 25, color: baseTheme.text.darkGray }}
      />
    </Button>
  );
};

export default GoBack;
