import styled from "styled-components";
import TitleComponent from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import Container from "../../../../components/Container/Container";
import mediaQueries from "../../../../utils/mediaQueries";
import Button from "../../../../components/Button/Button";

export const Wrapper = styled.div`
  position: relative;
  padding: 250px 0;
  background-image: ${({ bgMobileImage }) => `url(${bgMobileImage})`};
  background-size: cover;
  background-position: center center;
  text-align: center;

  @media ${mediaQueries.tablet} {
    padding: 400px 0;
    background-image: ${({ bgTabletImage }) => `url(${bgTabletImage})`};
  }

  @media ${mediaQueries.desktop} {
    padding: 500px 0;
    background-image: ${({ bgImage }) => `url(${bgImage})`};
  }
`;

export const WrapperWithOverlay = styled.div`
  position: relative;
  padding: 80px 20px;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
  background-position: center center;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(35, 35, 35, 0.6);
  }

  @media ${mediaQueries.tablet} {
    padding: 200px 0;
  }

  @media ${mediaQueries.desktop} {
    padding: 250px 0;
  }
`;

export const Content = styled(Container)`
  position: relative;
`;

export const Title = styled(TitleComponent)`
  display: block;
  margin-top: 0;
  font-family: "Francois One", sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white} !important;

  @media ${mediaQueries.desktop} {
    font-size: 65px !important;
  }
`;

export const Description = styled(Text)`
  display: block;
  max-width: 800px;
  margin: auto;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};

  @media ${mediaQueries.desktop} {
    font-size: 25px;
    line-height: 35px;
  }
`;
export const ContainerButtons = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

export const CustomButton = styled(Button)`
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${mediaQueries.desktop} {
    width: 190px;
  }
`;

export const SocialRow = styled(Container)`
  position: relative;
  display: flex;
  justify-content: center;

  @media ${mediaQueries.tablet} {
    position: absolute;
    justify-content: end;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;
