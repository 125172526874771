import styled from "styled-components";

export const FloatContainer = styled.div`
  position: ${({ height }) => (height > 600 ? "sticky" : "relative")};
  top: ${({ height }) => (height > 600 ? "90px" : "0px")};
  margin-top: 20px;
`;

export const Container = styled.div`
  padding: 10px;
  padding-bottom: 20px;
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  border-radius: 10px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const QuantityWrapper = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
`

export const Footer = styled.div`
    display: flex;
    gap: 40px;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
`