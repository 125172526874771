import {
  GET_VARIANTS_FROM_PRODUCT_FAILURE,
  GET_VARIANTS_FROM_PRODUCT_REQUEST,
  GET_VARIANTS_FROM_PRODUCT_SUCCESS,
  SET_PRODUCTS_VARIANTS,
} from "../actions/shopActions";

export const INITIAL_STATE = {
  results: [],
  products: {},
  isFetching: false,
  error: false,
};

export const variantsShopReducer = (state = INITIAL_STATE, action) => {
  const { payload: { results, variants, product } = {} } = action;
  switch (action.type) {
    case GET_VARIANTS_FROM_PRODUCT_REQUEST:
      return {
        ...state,
        results: [],
        isFetching: true,
        error: false,
      };
    case GET_VARIANTS_FROM_PRODUCT_SUCCESS:
      return {
        ...state,
        results,
        isFetching: false,
      };
    case GET_VARIANTS_FROM_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case SET_PRODUCTS_VARIANTS:
      return {
        ...state,
        products: { ...state.products, [product]: variants },
        isFetching: false,
      };
    default:
      return state;
  }
};

export default variantsShopReducer;
